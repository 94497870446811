import React from 'react';

interface Props {
  nr: string;
}

export const TelTo: React.FC<React.PropsWithChildren<Props>> = ({ nr, children }) => (
  <a className="vl-link" href={`tel:${nr}`}>
    {children || nr}
  </a>
);

export const telTo = (nr?: string) => (nr ? <TelTo nr={nr} /> : nr);
