import React from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { OrganisatieForm, OrganisatieFormValues } from './organisatie-form';
import { OrganisatieJsonRequest, OrganisatieJsonResponse, OrganisatieType } from '../generated';
import { sortVerdelingsgebiedOptionsWithGBAFirst } from './organisatie-form-fields';

const updateMutation = async (request: OrganisatieJsonRequest) => axios.put<OrganisatieJsonResponse>(`/ui/organisatie/${request.organisatiecode}`, request);

const OrganisatieUpdateForm: React.FC<{ organisatie: OrganisatieJsonResponse }> = ({ organisatie }) => {
  const defaultValues: OrganisatieFormValues = {
    organisatiecode: organisatie.organisatiecode,
    organisatienaam: organisatie.organisatienaam ?? 'Onbekend',
    emailadres: organisatie.emailadres ?? '',
    inzamelaarHuishoudelijkAfval: !!organisatie.meldingInzamelingVanaf,
    verdeler: organisatie.types.includes(OrganisatieType.VERDELER_HUISHOUDELIJK_AFVAL),
    extraCodesVoorVerdeling: sortVerdelingsgebiedOptionsWithGBAFirst(organisatie.verdelingsgebied ?? []).map((value) => value.code),
    meldingInzamelingVanaf: dayjs(organisatie.meldingInzamelingVanaf),
    inzamelaarBedrijfsafval: organisatie.types.includes(OrganisatieType.INZAMELAAR_BEDRIJFSAFVAL),
  };
  return (
    <OrganisatieForm mutateFn={updateMutation} cancelLink={`/administratie/${organisatie.organisatiecode}`} submitLabel="Organisatie corrigeren" initialValues={defaultValues} />
  );
};

export default OrganisatieUpdateForm;
