import { PaginationProps } from 'antd/lib/pagination';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import React from 'react';
import { Link } from 'react-router-dom';
import OverviewTable, { RecordProps } from '../components/table/overview-table';
import { Exclusiviteit, InzamelrechtHAFiltersJsonRequest, InzamelrechtHAOverviewJsonResponse } from '../generated';
import getFilterValues from '../util/table-filter-utils';
import { createSelectWithSearchFilterColumn, createTextInputFilterColumn, createTimeRangeFilterColumn } from '../components/table/default.datatable.options';
import { useAfvalCodeOptions, useInzamelwijzeOptions, useVlaamsePostcodeOptions } from '../melding/melding-form-fields';
import { InzamelrechtHAPagingAndFiltering, useInzamelrechtenHA } from './inzamelrecht-ha.hook';
import { displayClean } from '../melding/melding-detail.hook';
import { toKwartaal } from '../melding/periode.model';
import ActionGroupRight from '../components/button/action-group';
import RouteButton from '../components/button/route-button';
import { mapPaginationValues } from '../util/pagination-util';

export interface IInzamelrechtHAOverview extends RecordProps {
  key: string;
  eigenaar: string;
  organisatienaam?: string;
  periode: string;
  afvalcodes: string;
  werkingsgebied: string;
  inzamelwijzen: string;
  exclusiviteit: string;
}

const mapFilterValues = (filters: Record<string, FilterValue | null>): InzamelrechtHAFiltersJsonRequest => ({
  eigenaar: getFilterValues('eigenaar', filters)?.[0],
  organisatienaam: getFilterValues('organisatienaam', filters)?.[0],
  periodeVan: getFilterValues('periode', filters)?.[0],
  periodeTot: getFilterValues('periode', filters)?.[1],
  afvalcodes: getFilterValues('afvalcodes', filters),
  postcodes: getFilterValues('werkingsgebied', filters),
  inzamelwijzen: getFilterValues('inzamelwijzen', filters),
  exclusiviteit: getFilterValues('exclusiviteit', filters)?.[0],
});

const toInzamelrechtHAOverview = (value: InzamelrechtHAOverviewJsonResponse): IInzamelrechtHAOverview => ({
  ...value,
  key: value.referentie,
  eigenaar: value.eigenaarCode,
  organisatienaam: value.eigenaarNaam,
  periode: toKwartaal(value.periodeVan.waarde).concat(' tot ', value.periodeTot?.waarde ? toKwartaal(value.periodeTot?.waarde) : '...'),
  afvalcodes: value.afvalcodes.toSorted((a, b) => a.localeCompare(b)).join(', ') || 'Geen beperkingen',
  werkingsgebied: value.werkingsgebied.toSorted((a, b) => a.localeCompare(b)).join(', ') || 'Geen beperkingen',
  inzamelwijzen: value.inzamelwijzen.toSorted((a, b) => a.localeCompare(b)).join(', ') || 'Geen beperkingen',
  exclusiviteit: displayClean(value.exclusief),
});

interface InzamelrechtHAOverviewProps {
  onChange: (newValues: InzamelrechtHAPagingAndFiltering) => void;
  pagingAndFiltering: InzamelrechtHAPagingAndFiltering;
  defaultPageSize: number;
}

const exclusiviteiten = [Exclusiviteit.JA, Exclusiviteit.NEE] as const;

export const useExclusiefOptions = () => ({
  isLoading: false,
  options: exclusiviteiten.map((exclusiviteit: Exclusiviteit) => ({
    value: exclusiviteit,
    label: displayClean(exclusiviteit),
  })),
});

const InzamelrechtHAOverview: React.FC<InzamelrechtHAOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const { error, isLoading, isFetching, data: page } = useInzamelrechtenHA(pagingAndFiltering);

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapPaginationValues(pagination, defaultPageSize),
      ...mapFilterValues(filters),
    });
  };

  const columns: ColumnsType<IInzamelrechtHAOverview> = [
    {
      ...createTextInputFilterColumn<IInzamelrechtHAOverview>('Eigenaar', 'eigenaar', pagingAndFiltering.eigenaar),
      render: (organisatiecode: string, recht: IInzamelrechtHAOverview) => <Link to={`/inzamelrechten/huishoudelijk-afval/${recht.eigenaar}/${recht.key}`}>{organisatiecode}</Link>,
    },
    createTextInputFilterColumn<IInzamelrechtHAOverview>('Naam', 'organisatienaam', pagingAndFiltering.organisatienaam),
    createTimeRangeFilterColumn<IInzamelrechtHAOverview>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot, 'quarter'),
    createSelectWithSearchFilterColumn<IInzamelrechtHAOverview>(
      'Afvalcodes',
      'afvalcodes',
      'afvalcodes',
      useAfvalCodeOptions,
      pagingAndFiltering.afvalcodes?.toSorted().join(', '),
    ),
    createSelectWithSearchFilterColumn<IInzamelrechtHAOverview>(
      'Werkingsgebied',
      'werkingsgebied',
      'postcodes',
      useVlaamsePostcodeOptions,
      pagingAndFiltering.postcodes?.toSorted().join(', '),
    ),
    createSelectWithSearchFilterColumn<IInzamelrechtHAOverview>(
      'Inzamelwijzen',
      'inzamelwijzen',
      'inzamelwijzen',
      useInzamelwijzeOptions,
      pagingAndFiltering.inzamelwijzen?.toSorted().join(', '),
    ),
    createSelectWithSearchFilterColumn<IInzamelrechtHAOverview>('Exclusief', 'exclusiviteit', 'exclusiviteit', useExclusiefOptions, pagingAndFiltering.exclusiviteit),
  ].flatMap((col) => (col ? [col] : []));

  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toInzamelrechtHAOverview),
      }
    : undefined;

  return (
    <OverviewTable
      title="Inzamelrechten Huishoudelijk Afval"
      itemsLabel="inzamelrechten"
      columns={columns}
      page={mappedPage}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
      error={error}
      notFoundMessage="Geen inzamelrechten gevonden."
      isLoading={isLoading}
      isFetching={isFetching}
    >
      <div className="vl-col--1-1 vl-u-spacer">
        <ActionGroupRight>
          <div className="vl-u-spacer">
            <RouteButton className="vl-button--narrow" label="Nieuw inzamelrecht HA" link="/inzamelrechten/huishoudelijk-afval/nieuw" />
          </div>
        </ActionGroupRight>
      </div>
    </OverviewTable>
  );
};

export default InzamelrechtHAOverview;
