import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContactgegevensForm from './contactgegevens-form';
import ContactgegevensSection from './contactgegevens-section';
import Alert from '../components/alert/alert';

interface ContactgegevensCreateFormProps {}

const ContactgegevensCreateForm: React.FC<ContactgegevensCreateFormProps> = () => {
  const navigate = useNavigate();
  return (
    <>
      <BreadcrumbsItem to="/">Contactgegevens</BreadcrumbsItem>
      <ContactgegevensSection
        render={(gegevens) => (
          <>
            <Alert type="info" small dataCy="create-message">
              De OVAM vraagt u om uw contactgegevens aan te vullen of te bevestigen. Om verder te gaan, moet een email-adres voor uw organisatie ingevuld zijn. U kan uw
              contactgegevens achteraf ook wijzigen via de startpagina of via uw profiel.
            </Alert>
            <ContactgegevensForm
              contactgegevens={gegevens}
              submitButtonLabel="Contactgegevens bevestigen"
              submitButtonDisabledWhenNoChanges={false}
              showCancelButton={false}
              onUpdated={() => navigate('/')}
            />
          </>
        )}
      />
    </>
  );
};

export default ContactgegevensCreateForm;
