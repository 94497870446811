import React from 'react';
import axios from 'axios';
import { OrganisatieForm, OrganisatieFormValues } from './organisatie-form';
import { OrganisatieJsonRequest, OrganisatieJsonResponse } from '../generated';

const createMutation = async (request: OrganisatieJsonRequest) => axios.post<OrganisatieJsonResponse>('/ui/organisatie', request);

const defaultValues: OrganisatieFormValues = {
  organisatiecode: '',
  organisatienaam: 'Onbekend',
  emailadres: '',
  inzamelaarHuishoudelijkAfval: false,
  verdeler: false,
  extraCodesVoorVerdeling: [],
  meldingInzamelingVanaf: null,
  inzamelaarBedrijfsafval: false,
};

const OrganisatieCreateForm: React.FC<any> = () => (
  <OrganisatieForm mutateFn={createMutation} cancelLink="/administratie" submitLabel="Organisatie aanmaken" initialValues={defaultValues} />
);

export default OrganisatieCreateForm;
