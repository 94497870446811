import React from 'react';
import { uniq } from 'lodash';
import { saveAs } from 'file-saver';
import { DownloadResponse } from '../../button/download-button';
import CsvProcessingReportContainer from './csv-processing-report-container';
import './csv-processing-report.css';
import ExternalHomepageLink from '../../navigation/external-homepage-link';
import { CsvLineJsonResponse } from '../../../generated';
import { CsvLineStatus } from '../csv.types';

interface CsvProcessingReportLineProps {
  line: CsvLineJsonResponse;
}

const CsvProcessingReportLine: React.FC<CsvProcessingReportLineProps> = ({ line }) => {
  if (line.status !== 'ONGELDIG') {
    return null;
  }
  const errors = (
    <ul>
      {uniq(line.errors).map((err) => (
        <li key={line.recordNumber + err}>{err}</li>
      ))}
    </ul>
  );

  return (
    <li>
      <strong>
        Rij {line.recordNumber}
        {!line.recordId || ` (Identificatie: ${line.recordId})`}:
      </strong>{' '}
      {errors}
    </li>
  );
};

export interface ICsvProcessingReportProps {
  results: CsvLineJsonResponse[];
  fileName?: string;
  summary?: string[];
  failedCsvFile: DownloadResponse | null;
  csvEntitiesLabel: string;
  chapterInManual: string;
}

const countStatus = (results: CsvLineJsonResponse[], status: CsvLineStatus) => results.filter((value) => value.status === status).length;

const CsvProcessingReport: React.FC<ICsvProcessingReportProps> = ({ results, summary, fileName, failedCsvFile, csvEntitiesLabel, chapterInManual }) => {
  const countFailure = countStatus(results, 'ONGELDIG');
  const countNieuw = countStatus(results, 'NIEUW');
  const countCorrecties = countStatus(results, 'CORRECTIE');
  const countOngewijzigd = countStatus(results, 'ONGEWIJZIGD');

  const handleDownloadOfFailedLinesCsv = () => {
    if (failedCsvFile) {
      saveAs(failedCsvFile.fileContents, failedCsvFile.filename, { autoBom: false });
    }
  };

  return (
    <div data-testid="csv-processing-report">
      <h2 className="vl-title vl-title--h3">
        Uw CSV-bestand <i>&lsquo;{fileName}&rsquo;</i> is verwerkt. Het resultaat is:
      </h2>
      <div className="vl-layout">
        <CsvProcessingReportContainer>
          <>
            <h2 className="vl-title vl-title--h3">
              Van de {results.length} {csvEntitiesLabel} in de CSV zijn:
            </h2>
            {fileName && (
              <>
                <div className="vl-grid">
                  <div className="vl-col--4-12">
                    <strong>Nieuwe</strong> {csvEntitiesLabel} opgeslagen:
                  </div>
                  <div className="vl-col--8-12">{countNieuw}</div>
                  <div className="vl-col--4-12">
                    <strong>Correcties</strong> doorgevoerd:
                  </div>
                  <div className="vl-col--8-12">{countCorrecties}</div>
                  <div className="vl-col--4-12">
                    <strong>Ongewijzigde</strong> {csvEntitiesLabel}:
                  </div>
                  <div className="vl-col--8-12">{countOngewijzigd}</div>
                  <div className="vl-col--4-12">
                    <strong>Foutieve</strong> {csvEntitiesLabel}, niet verwerkt:
                  </div>
                  <div className="vl-col--8-12">{countFailure}</div>
                </div>
                <p className="report-info">
                  Komt dit niet overeen met de verwachte resultaten? Lees aandachtig het hoofdstuk &lsquo;{chapterInManual}&rsquo; in de handleiding. Neem eventueel contact op met
                  de OVAM om de gegevens recht te zetten via het MATIS-contactformulier op de <ExternalHomepageLink>OVAM-website</ExternalHomepageLink>.
                </p>
                {countFailure > 0 && (
                  <div className="margin-invalid">
                    {summary && (
                      <>
                        <p className="foutieve-csv-lijnen-header">
                          Uw bestand bevat <strong>meer dan 5.000 fouten</strong>. Onderstaande lijst geeft een&nbsp;
                          <strong>overzicht van fouten die meer dan 100 keer voorkomen</strong>. Via onderstaande &ldquo;Download&rdquo; knop krijgt u een volledige lijst met
                          foutieve meldingen inclusief individuele foutmeldingen.
                        </p>
                        <ul className="matis-errors-list">
                          {summary.map((value) => (
                            <li key={value}>{value}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    {!summary && (
                      <>
                        <h5 className="vl-title--h5 foutieve-csv-lijnen-header">
                          <strong>Foutieve</strong> {csvEntitiesLabel}
                        </h5>
                        <ul className="matis-errors-list">
                          {results
                            .filter((value) => value.status === 'ONGELDIG')
                            .map((value) => (
                              <CsvProcessingReportLine key={value.recordNumber} line={value} />
                            ))}
                        </ul>
                      </>
                    )}
                    {failedCsvFile && (
                      <button
                        type="button"
                        className="vl-button vl-button--error"
                        onClick={handleDownloadOfFailedLinesCsv}
                        title={`Download foutieve ${csvEntitiesLabel}`}
                        data-cy="download-foutieve-csv-lijnen-button"
                      >
                        Download foutieve {csvEntitiesLabel}
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        </CsvProcessingReportContainer>
      </div>
    </div>
  );
};

export default CsvProcessingReport;
