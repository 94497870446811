import React, { useState } from 'react';
import { useRoleHook } from '../../services/security/use-role-hook';
import { ContactgegevensInfoModal } from './contactgegevens-info-modal';
import LinkButton from '../button/link-button';
import { GebruikerDetailsJsonResponse } from '../../generated';

export const GebruikerInfo: React.FC<{ gebruiker: GebruikerDetailsJsonResponse; organisatie: string }> = ({ gebruiker, organisatie }) => {
  const { isBeheerder } = useRoleHook();
  const [open, setOpen] = useState(false);

  return (
    <>
      {isBeheerder ? (
        <>
          <ContactgegevensInfoModal gebruiker={gebruiker} open={open} onClose={() => setOpen(false)} />
          <LinkButton data-cy="gebruiker-link" handleClick={() => setOpen(true)}>
            <span data-cy="gebruiker">{gebruiker.naam}</span>
          </LinkButton>
        </>
      ) : (
        <span data-cy="gebruiker">{gebruiker.naam}</span>
      )}
      <span className="vl-description-data__subdata">{organisatie}</span>
    </>
  );
};
