import React from 'react';
import * as Yup from 'yup';
import { useField } from 'formik';
import { COMMENTAAR, LAND, POSTCODE } from './bestemming.model';
import { BelgischePostcodeSelect, LandSelect, useVlarema9VanToepassing } from '../melding-form-fields';
import TextField from '../../components/form/text-field';
import TextArea from '../../components/form/text-area';
import { prefixNamespace } from './use-land-hook';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import { belgischePostcodeSchema, buitenlandsePostcodeSchema } from '../postcode.schema';

export const bestemmingParticulierValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  [POSTCODE]: Yup.string().when(['land'], {
    is: (land: string) => land === 'BE',
    then: (schema) => schema.concat(belgischePostcodeSchema),
    otherwise: (schema) => schema.concat(buitenlandsePostcodeSchema),
  }),
  [LAND]: Yup.string(),
  [COMMENTAAR]: vrijeTekstSchema().max(4000, 'Commentaar oorsprong kan niet meer dan 4000 tekens bevatten'),
});

const BestemmingParticulierForm: React.FC = () => {
  const withNamespace = prefixNamespace('bestemming');
  const vlarema9VanToepassing = useVlarema9VanToepassing();
  const [, landMeta] = useField(withNamespace(LAND));

  return vlarema9VanToepassing ? (
    <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
  ) : (
    <>
      {landMeta.value === 'BE' && <BelgischePostcodeSelect label="Postcode" name={withNamespace(POSTCODE)} />}
      {landMeta.value !== 'BE' && <TextField label="Postcode" name={withNamespace(POSTCODE)} />}
      <LandSelect label="Land" name={withNamespace(LAND)} />
      <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
    </>
  );
};

export default BestemmingParticulierForm;
