import React from 'react';
import cx from 'classnames';
import './link-button.module.css';

interface ILinkButtonProps {
  className?: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}

const LinkButton: React.FC<React.PropsWithChildren<ILinkButtonProps>> = (props) => {
  const { className, handleClick, children, ...other } = props;
  return (
    <button type="button" {...other} className={cx('vl-link', className)} onClick={handleClick}>
      {children}
    </button>
  );
};

export default LinkButton;
