import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadNewMeldingInzamelingHACsv } from './melding-csv.hook';
import { CREATE_MELDINGEN_DESCRIPTION, MELDING_INZAMELING_HA_CHAPTER_IN_MANUAL } from './melding.labels';
import MeldingCsvUploader from './melding-csv-uploader';

const MeldingInzamelingHACsvCreatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/inzameling/huishoudelijk-afval/csv-nieuw">{CREATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadNewMeldingInzamelingHACsv}
      title={`${CREATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_INZAMELING_HA_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingInzamelingHACsvCreatePage;
