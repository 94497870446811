import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

interface AlertProps {
  type: 'info' | 'error' | 'warning' | 'success';
  title?: string;
  scrollIntoView?: boolean;
  scrollable?: boolean;
  small?: boolean;
  dataCy?: string;
}

const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({ type, title, scrollIntoView = false, scrollable, small = false, dataCy, children }) => {
  const alertRef = useRef<HTMLElement>();
  useEffect(() => {
    if (scrollIntoView) {
      alertRef.current?.scrollIntoView();
    }
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <div className={cx(`vl-alert vl-alert--${type}`, { 'vl-alert--small': small })} data-cy={`alert-${type}${dataCy ? `-${dataCy}` : ''}`} role="alert" ref={alertRef}>
        <div className="vl-alert__icon">
          <i
            className={cx('vl-vi', {
              'vl-vi-check': type === 'success',
              'vl-vi-warning': type === 'warning' || type === 'error',
              'vl-vi-info': type === 'info',
            })}
            aria-hidden="true"
          />
        </div>
        <div className="vl-alert__content">
          {title && (
            <p className="vl-alert__title" data-cy="alert-title">
              {title}
            </p>
          )}
          <div data-cy="alert-message" className={cx('vl-alert__message', { scrollable })}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Alert;
