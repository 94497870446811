import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Loading from '../components/details/loading';
import ErrorAlert from '../components/alert/error-alert';
import { useInzamelrechtHA } from './inzamelrecht-ha.hook';
import InzamelrechtHADetails from './inzamelrecht-ha-details';

const InzamelrechtHADetailPage: React.FC<any> = () => {
  const { eigenaar, referentie } = useParams() as { eigenaar: string; referentie: string };
  const { data: recht, error, isLoading } = useInzamelrechtHA(referentie);

  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/inzamelrechten/huishoudelijk-afval/${eigenaar}/${referentie}`}>{referentie}</BreadcrumbsItem>

        {error && !isLoading && (
          <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van het inzamelrecht. Gelieve later nog eens opnieuw te proberen." />
        )}
        {!error && isLoading && <Loading message={`Bezig met het ophalen van inzamelrecht ${referentie}`} />}
        {!error && !isLoading && recht && <InzamelrechtHADetails recht={recht} />}
      </div>
    </section>
  );
};

export default InzamelrechtHADetailPage;
