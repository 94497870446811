import React from 'react';
import cx from 'classnames';
import styles from './navigation-section.module.css';

import vuilniszak from './icoon-vuilniszak.png';
import verdeling from './icoon-verdeling.png';
import container from './icoon-container.png';
import bedrijf from './icoon-bedrijf.png';
import rapport from './icoon-rapport.png';
import vraag from './icoon-vraag.png';
import administratie from './icoon-administratie.png';
import assertNever from '../../types/assert-never';

interface NavigationSectionProps {
  title: string;
  icon: 'vuilniszak' | 'verdeling' | 'container' | 'bedrijf' | 'rapport' | 'vraag' | 'administratie';
}

const getIconSrc = ({ icon }: Pick<NavigationSectionProps, 'icon'>) => {
  switch (icon) {
    case 'vuilniszak':
      return vuilniszak;
    case 'verdeling':
      return verdeling;
    case 'container':
      return container;
    case 'bedrijf':
      return bedrijf;
    case 'rapport':
      return rapport;
    case 'vraag':
      return vraag;
    case 'administratie':
      return administratie;
    default:
      return assertNever(icon);
  }
};

const NavigationSection: React.FC<React.PropsWithChildren<NavigationSectionProps>> = ({ title, icon, children }) => (
  <div className={cx(styles.navtile, 'vl-info-tile')}>
    <header className={cx(styles.navheader, 'vl-info-tile__header')} role="presentation">
      <div className="vl-info-tile__badge__wrapper">
        <div className="vl-badge vl-badge--icon vl-badge--medium">
          <img className="vl-badge__icon" src={getIconSrc({ icon })} alt="" />
        </div>
      </div>
      <div className="vl-info-tile__header__wrapper">
        <p className={cx(styles.sectiontitle, 'vl-info-tile__header__title')}>{title}</p>
      </div>
    </header>
    <div className="vl-info-tile__content">
      <div className={cx(styles.sectioncontent, 'vl-info-tile__content__wrapper')}>{children}</div>
    </div>
  </div>
);

export default NavigationSection;
