import { BestemmingJsonResponse, EnumeratedListJsonResponse } from '../../generated';
import { isVlaanderen } from './bestemming.model';
import { EURALCODE } from '../materiaal.model';
import { displayClean } from '../melding-detail.hook';
import { AfvalverwerkendeWerf } from './afvalverwerkende-werf';

class TijdelijkeInstallatie extends AfvalverwerkendeWerf {
  constructor() {
    super(['1701', '1703', '1705', '1913', '200202']);
  }

  matches = (bestemming: BestemmingJsonResponse, vlaamsePostcodes?: EnumeratedListJsonResponse[], euralcode?: string): boolean =>
    isVlaanderen(bestemming, vlaamsePostcodes) && this.supportsEuralcode(euralcode ?? '');

  errorMessage = (bestemming: BestemmingJsonResponse): string =>
    `${EURALCODE}s die beginnen met ${this.formatPrefixesForErrorMessage()} zijn enkel toegelaten voor bestemming '${displayClean(
      bestemming.type,
    )}' met een postcode in Vlaanderen`;
}

export const WERF_ALS_TIJDELIJKE_INSTALLATIE = new TijdelijkeInstallatie();
