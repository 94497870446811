import { Link } from 'react-router-dom';
import React from 'react';
import cx from 'classnames';
import styles from './separated-breadcrumb.module.css';

const WrappedBreadcrumb: React.FC<any> = ({ separatorClassName, children }) => (
  <li className="vl-breadcrumb__list__item">
    <span className={cx('vl-breadcrumb__list__item__separator', separatorClassName)} aria-hidden="true" />
    {children}
  </li>
);

export const SeparatedBreadcrumb: React.FC<any> = ({ separatorClassName, to, children }) => (
  <WrappedBreadcrumb separatorClassName={separatorClassName}>
    <Link className={cx('vl-breadcrumb__list__item__cta', 'vl-link', styles.undovllink)} to={to}>
      {children}
    </Link>
  </WrappedBreadcrumb>
);

export const LastSeparatedBreadcrumb: React.FC<any> = ({ separatorClassName, children }) => (
  <WrappedBreadcrumb separatorClassName={separatorClassName}>
    <span className="vl-breadcrumb__list__item__cta">{children}</span>
  </WrappedBreadcrumb>
);
