import React, { useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Routes } from 'react-router-dom';
import { useAuthorisationInfo } from '../services/security/authorisation';
import { PeriodeZonderMeldingPagingAndFiltering } from './periode-zonder-melding.hook';
import LoadingPage from '../general/loading-page';
import AuthorisedScope from '../general/authorised-scope';
import { Scope } from '../generated';
import PeriodeZonderMeldingOverview from './periode-zonder-melding-overview';
import PeriodeZonderMeldingCreatePage from './periode-zonder-melding-create.page';
import PeriodeZonderMeldingDetailPage from './periode-zonder-melding-detail.page';
import PeriodeZonderMeldingUpdatePage from './periode-zonder-melding-update.page';

const defaultPageSize = 100;

const PeriodeZonderMeldingOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<PeriodeZonderMeldingPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.PERIODE_ZONDER_MELDING}>
        <BreadcrumbsItem to="/periodes-zonder-melding">Periodes zonder melding</BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<PeriodeZonderMeldingOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
          <Route path="nieuw" element={<PeriodeZonderMeldingCreatePage />} />
          <Route path=":referentie" element={<PeriodeZonderMeldingDetailPage />} />
          <Route path=":referentie/correctie" element={<PeriodeZonderMeldingUpdatePage />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default PeriodeZonderMeldingOverviewPage;
