import React from 'react';
import locale from 'antd/lib/locale/nl_BE';
import { useField } from 'formik';
import { ConfigProvider, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import InputField, { IInputFieldProps } from './input-field';
import { formats } from '../../melding/periode.model';

export interface DateFieldProps extends IInputFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  showTime?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({ name, label, showTime, autoFocus, required, disabled, disabledDate }) => {
  const format = showTime ? `${formats.DAG} HH:mm:ss` : formats.DAG;

  const [, meta, helpers] = useField(name);
  const onChangeHandler = (value: Dayjs | null) => {
    helpers.setValue(value);
  };

  const dateValue = meta.value ? dayjs(meta.value, format) : undefined;

  return (
    <ConfigProvider locale={locale}>
      <InputField name={name} label={label} required={required}>
        <DatePicker
          id={name}
          name={name}
          value={dateValue}
          className="vl-input-field"
          data-testid={`input-${name}`}
          data-cy={`input-${name}`}
          autoFocus={autoFocus ?? false}
          disabled={disabled}
          onChange={onChangeHandler}
          disabledDate={disabledDate}
          size="large"
          style={{ width: showTime ? 175 : 155 }}
          format={format}
          showTime={showTime}
        />
      </InputField>
    </ConfigProvider>
  );
};

export default DateField;
