import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadNewValidatievraagCsv } from './validatievraag.hook';
import ValidatievraagCsvUploader from './validatievraag-csv-uploader';

export const CREATE_VALIDATIEVRAGEN_DESCRIPTION = 'Importeer nieuwe validatievragen';
export const VALIDATIEVRAAG_CHAPTER_IN_MANUAL = 'Via CSV gegevens over validatievragen toevoegen';

const ValidatievraagCsvCreatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/validatievraag/csv-nieuw">{CREATE_VALIDATIEVRAGEN_DESCRIPTION}</BreadcrumbsItem>
    <ValidatievraagCsvUploader
      handleUpload={uploadNewValidatievraagCsv}
      title={`${CREATE_VALIDATIEVRAGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={VALIDATIEVRAAG_CHAPTER_IN_MANUAL}
    />
  </>
);

export default ValidatievraagCsvCreatePage;
