import React from 'react';
import { ErrorMessage } from 'formik';

export interface IInputFieldProps {
  label: string;
  name: string;
  required?: boolean;
}

const InputField: React.FC<React.PropsWithChildren<IInputFieldProps>> = ({ name, label, children, required }) => {
  const fieldDataAttribute = `input-field.${name}`;
  const errorMessageDataAttribute = `field-error.${name}`;
  return (
    <>
      <div className="vl-form-col--3-12">
        <label className="vl-form__label" htmlFor={name}>
          {label} {required && <span className="vl-form__annotation">(verplicht)</span>}
        </label>
      </div>
      <div className="vl-form-col--9-12" data-cy={fieldDataAttribute} data-testid={fieldDataAttribute}>
        {children}
        <ErrorMessage
          name={name}
          render={(msg: any) => (
            <p className="vl-form__error" data-cy={errorMessageDataAttribute} data-testid={errorMessageDataAttribute}>
              {typeof msg === 'string' ? msg : msg.value}
            </p>
          )}
        />
      </div>
    </>
  );
};

export default InputField;
