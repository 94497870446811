export interface Some<T> {
  readonly type: 'some';
  readonly value: T;
}

export interface None {
  readonly type: 'none';
}

export type Maybe<T> = Some<T> | None;

const none: None = {
  type: 'none',
};

function isNone(m: Maybe<any>): m is None {
  return m.type === none.type;
}

function some<T>(value: T): Some<T> {
  return {
    type: 'some',
    value,
  };
}

function fromNullable<T>(v?: T): Maybe<T> {
  return v ? some(v) : none;
}

function toNullable<T>(m: Maybe<T>) {
  return isNone(m) ? null : m.value;
}

function orElse<T>(m: Maybe<T>, otherValue: T) {
  return toNullable(m) ?? otherValue;
}

function map<T, R>(m: Maybe<T>, mapf: (v: T) => R): Maybe<R> {
  return isNone(m) ? m : Maybe.some(mapf(m.value));
}

// no-redeclare -- intentionally naming the variable the same as the type
// eslint-disable-next-line
export const Maybe = {
  fromNullable,
  map,
  orElse,
  none,
  some,
  toNullable,
};
