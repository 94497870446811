import React from 'react';
import cx from 'classnames';
import { ApiError, isApiError } from '../../util/axios-error-mapping';
import DownloadButton from '../button/download-button';

interface CsvDownloadButtonProps {
  url: string;
  filters: any;
  downloadLimit: number;
  totalElementsInOverview: number;
  csvEntitiesLabel: string;
  onErrorMessageChanged: (errorMessage: string | null) => void;

  className?: string;
  isNarrow?: boolean;
}

const DOWNLOAD_LIMIT_EXCEEDED_ERROR_MESSAGE = 'downloadLimitExceeded';

const getBrowserTimezone = () => {
  const resolvedOptions = new Intl.DateTimeFormat().resolvedOptions();
  return resolvedOptions.timeZone;
};

const CsvDownloadButton: React.FC<CsvDownloadButtonProps> = ({
  url,
  filters,
  downloadLimit,
  totalElementsInOverview,
  csvEntitiesLabel,
  onErrorMessageChanged,
  className,
  isNarrow = false,
}) => {
  const queryParams = {
    ...filters,
    timezone: getBrowserTimezone(),
  };

  const mapErrorMessage = (error: ApiError | Error) => {
    if (isApiError(error)) {
      return error.errorCode;
    }
    if (error?.message === DOWNLOAD_LIMIT_EXCEEDED_ERROR_MESSAGE) {
      return `Er kunnen maximum ${downloadLimit.toLocaleString('nl')} ${csvEntitiesLabel} gedownload worden.`;
    }
    return 'Er is een fout opgetreden bij het downloaden van de CSV. Gelieve later nog eens opnieuw te proberen.';
  };

  const handleDownloadFailed = (error: ApiError | Error) => {
    onErrorMessageChanged(mapErrorMessage(error));
  };

  const handleBeforeDownloadStarted = () => {
    onErrorMessageChanged(null);
    if (totalElementsInOverview > downloadLimit) {
      throw new Error(DOWNLOAD_LIMIT_EXCEEDED_ERROR_MESSAGE);
    }
  };

  return (
    <DownloadButton
      className={cx(className, { 'vl-button--narrow': isNarrow })}
      label="CSV downloaden"
      url={url}
      queryParams={queryParams}
      disabled={totalElementsInOverview === 0}
      beforeDownloadStarted={handleBeforeDownloadStarted}
      onDownloadFailed={handleDownloadFailed}
    />
  );
};

export default CsvDownloadButton;
