import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useGetPeriodeZonderMelding } from './periode-zonder-melding.hook';
import ErrorAlert from '../components/alert/error-alert';
import Loading from '../components/details/loading';
import PeriodeZonderMeldingUpdateForm from './periode-zonder-melding-update-form';

const PeriodeZonderMeldingUpdatePage: React.FC<any> = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: periode, error, isLoading } = useGetPeriodeZonderMelding(referentie);

  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/periodes-zonder-melding/${referentie}/correctie`}>{referentie}</BreadcrumbsItem>

        {error && !isLoading && (
          <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van de periode zonder melding. Gelieve later nog eens opnieuw te proberen." />
        )}
        {!error && isLoading && <Loading message={`Bezig met het ophalen van de periode zonder melding ${referentie}`} />}
        {!error && !isLoading && periode && <PeriodeZonderMeldingUpdateForm periode={periode} />}
      </div>
    </section>
  );
};

export default PeriodeZonderMeldingUpdatePage;
