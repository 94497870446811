import { useField } from 'formik';
import { useEffect } from 'react';
import { BTW_NUMMER, LAND, POSTCODE } from './bestemming.model';
import { isValidLandcode } from '../../adres/land.model';
import { useLanden } from '../../enumerated-lists/enumeratedlists.hook';

export const prefixNamespace = (namespace: string) => (namespace ? (fieldName: string) => `${namespace}.${fieldName}` : (fieldName: string) => fieldName);

const useLandHook = (namespace: string) => {
  const withNamespace = prefixNamespace(namespace);

  const [, btwMeta] = useField(withNamespace(BTW_NUMMER));
  const [, landMeta, landHelpers] = useField(withNamespace(LAND));
  const [, , postCodeHelpers] = useField(withNamespace(POSTCODE));
  const { data: landen } = useLanden(false);
  const landIso2 = btwMeta.value.substring(0, 2);

  useEffect(() => {
    if (landen) {
      landHelpers.setValue(isValidLandcode(landIso2, landen) ? landIso2 : '', false);
    }
    if (landMeta.initialValue !== 'BE' && landIso2 === 'BE') {
      postCodeHelpers.setValue('', false);
    }
  }, [landIso2, landen]);
  return {
    withNamespace,
    landIso2,
  };
};

export default useLandHook;
