import React, { useContext } from 'react';
import AuthenticationContext from '../services/security/authentication.context';
import AlertWithContact from '../components/alert/alert-with-contact';

const IncompleteProfile: React.FC<any> = () => {
  const { rollen, gebruiker } = useContext(AuthenticationContext);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <h2 className="vl-title vl-title--h2" data-cy="page-title">
          MATIS Startpagina
        </h2>
        <AlertWithContact type="error" title="Opgelet!">
          {rollen.length === 0 && gebruiker.organisatie === 'Onbekend' && (
            <p>
              U bent aangemeld als natuurlijk persoon. MATIS is enkel beschikbaar voor organisaties. Vraag aan de lokale beheerder van uw organisatie om u de nodige rechten te
              geven in het Gebruikersbeheer van de Vlaamse overheid en meldt u aan namens uw organisatie.
            </p>
          )}
          {rollen.length === 0 && gebruiker.organisatie !== 'Onbekend' && <p>U heeft geen rechten om deze actie uit te voeren. Vraag de nodige rechten aan.</p>}
          {gebruiker.organisatie === 'Onbekend' && rollen.length !== 0 && <p>U heeft niet doorgegeven voor welke organisatie u handelt. Vul uw profiel aan.</p>}
        </AlertWithContact>
      </div>
    </section>
  );
};

export default IncompleteProfile;
