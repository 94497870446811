import Keycloak from 'keycloak-js';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { mapAxiosError } from '../../util/axios-error-mapping';
import { GebruikerJsonResponse, Scope } from '../../generated';

export interface IGebruiker {
  naam: string;
  organisatie: string;
}

export const initialGebruiker: IGebruiker = {
  naam: '',
  organisatie: '',
};

export interface IAuthentication {
  authenticated: boolean;
  authorised: boolean;
  busyAuthenticating: boolean;
  rollen: string[];
  gebruiker: IGebruiker;
  authorisedScopes: Scope[];
  updateAuthorisedScopes?: (authorisedScopes: Scope[]) => void;
}

export const initialAuthentication: IAuthentication = {
  authenticated: false,
  authorised: false,
  busyAuthenticating: true,
  rollen: [],
  gebruiker: initialGebruiker,
  authorisedScopes: [],
};

export const keycloak = new Keycloak({
  url: window.APP_CONFIG.authServerUrl,
  realm: 'ovam',
  clientId: 'matis-ui',
});

interface IOidcSession {
  token: string;
  refreshToken: string;
  idToken: string;
}

class OidcSession {
  private static attribute: string = 'oidc';

  static get = (): IOidcSession => {
    const oidcString: string | null = localStorage.getItem(OidcSession.attribute);
    return oidcString ? JSON.parse(oidcString) : {};
  };

  static put = (): void => {
    localStorage.setItem(
      OidcSession.attribute,
      JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken,
      }),
    );
  };

  static clear = (): void => localStorage.removeItem(OidcSession.attribute);
}

export const login = () =>
  keycloak.init({
    onLoad: 'login-required',
  });

export const logout = () => {
  OidcSession.clear();
  keycloak.logout({});
};

keycloak.onAuthLogout = logout;

const handleAuthenticated = async (): Promise<IAuthentication> => {
  OidcSession.put();
  // @ts-ignore
  const { name, orgcode, orgnaam, orgcode_mandaatgever: orgcodeMandaatgever, orgnaam_mandaatgever: orgnaamMandaatgever } = keycloak.idTokenParsed!!;
  const matisRoles = keycloak.resourceAccess?.[keycloak.clientId!!]?.roles ?? [];
  const authentication = {
    authenticated: true,
    busyAuthenticating: false,
    authorised: matisRoles.length > 0 && !!orgcode,
    rollen: matisRoles,
    authorisedScopes: [],
    gebruiker: {
      naam: name,
      organisatie: orgnaamMandaatgever ?? orgcodeMandaatgever ?? orgnaam ?? orgcode ?? 'Onbekend',
    },
  };

  if (!authentication.authorised) {
    return Promise.resolve(authentication);
  }
  return axios.post<GebruikerJsonResponse>('/ui/gebruiker/markeer-login').then(({ data: responseBody }) => ({
    ...authentication,
    authorisedScopes: responseBody.applicationScopes ?? [],
  }));
};

const handleNotAuthenticated = (): IAuthentication => {
  OidcSession.clear();
  keycloak.clearToken();
  return {
    ...initialAuthentication,
    busyAuthenticating: false,
  };
};

export async function checkAuthenticated(): Promise<IAuthentication> {
  const authenticated = await keycloak.init({
    onLoad: 'check-sso',
    ...OidcSession.get(),
  });
  return authenticated ? handleAuthenticated() : handleNotAuthenticated();
}

async function fetchKeycloakToken(): Promise<string | undefined> {
  try {
    await keycloak.updateToken(30);
    OidcSession.put();
  } catch (e) {
    console.error('Token refresh failed', e);
  }
  return keycloak.token;
}

const authenticationRequestInterceptorOnFulfilled = (config: InternalAxiosRequestConfig<any>) =>
  fetchKeycloakToken().then((token) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return Promise.resolve(config);
  });
const authenticationRequestInterceptorOnRejected = (error: any) => Promise.reject(error);

axios.interceptors.request.use(authenticationRequestInterceptorOnFulfilled, authenticationRequestInterceptorOnRejected);

const axiosResponseInterceptorOnFulfilled = (response: AxiosResponse) => response;
const axiosResponseInterceptorOnRejected = (error: any) => Promise.reject(mapAxiosError(error));

axios.interceptors.response.use(axiosResponseInterceptorOnFulfilled, axiosResponseInterceptorOnRejected);

export const createAxiosClientWithRetry = (retryConfig: IAxiosRetryConfig) => {
  const client = axios.create();
  client.interceptors.request.use(authenticationRequestInterceptorOnFulfilled, authenticationRequestInterceptorOnRejected);
  client.interceptors.response.use(axiosResponseInterceptorOnFulfilled, axiosResponseInterceptorOnRejected);

  axiosRetry(client, retryConfig);
  return client;
};
