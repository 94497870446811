import { BestemmingJsonResponse, EnumeratedListJsonResponse } from '../../generated';
import Materiaal from '../materiaal.model';

export abstract class AfvalverwerkendeWerf {
  readonly euralcodePrefixes: string[];

  protected constructor(euralcodePrefixes: string[]) {
    this.euralcodePrefixes = euralcodePrefixes;
  }

  protected formatPrefixesForErrorMessage = () =>
    this.euralcodePrefixes
      .map((prefix) => `'${Materiaal.formatEuralcode(prefix)}'`)
      .join(', ')
      .replace(/, ([^,]*)$/, ' of $1');

  supportsEuralcode = (euralcode: string) => this.euralcodePrefixes.some((prefix) => euralcode.startsWith(prefix));

  abstract matches(bestemming: BestemmingJsonResponse, vlaamsePostcodes?: EnumeratedListJsonResponse[], euralcode?: string): boolean;

  abstract errorMessage(bestemming: BestemmingJsonResponse): string;
}
