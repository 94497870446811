import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import RouteButton from '../components/button/route-button';
import LinkButton from '../components/button/link-button';
import { OrganisatieJsonResponse, OrganisatieType } from '../generated';
import { formatVerdelingsgebiedOptie, sortVerdelingsgebiedOptionsWithGBAFirst } from './organisatie-form-fields';
import { booleanFlagToLabel } from '../util/boolean-flag-to-label';

const isInzamelaarHuishoudelijkAfval = (organisatie: OrganisatieJsonResponse): boolean => !!organisatie.meldingInzamelingVanaf;

const OrganisatieDetails: React.FC<{ organisatie: OrganisatieJsonResponse }> = ({ organisatie }) => {
  const navigate = useNavigate();
  const sortedExtraCodesVoorVerdeling = sortVerdelingsgebiedOptionsWithGBAFirst(organisatie.verdelingsgebied).map(formatVerdelingsgebiedOptie);
  return (
    <>
      <div className="vl-description-data">
        <dl className="vl-grid vl-grid--is-stacked">
          <div className="vl-col--3-12">
            <dt className="vl-description-data__label">Organisatiecode</dt>
            <dd className="vl-description-data__value" data-cy="organisatiecode">
              {organisatie.organisatiecode}
            </dd>
          </div>
          <div className="vl-col--4-12">
            <dt className="vl-description-data__label">Organisatienaam</dt>
            <dd className="vl-description-data__value" data-cy="organisatienaam">
              {organisatie.organisatienaam ?? 'Onbekend'}
            </dd>
          </div>
          <div className="vl-col--5-12">
            <dt className="vl-description-data__label">E-mailadres</dt>
            <dd className="vl-description-data__value" data-cy="emailadres">
              {organisatie.emailadres ?? 'Geen e-mailadres'}
            </dd>
          </div>
        </dl>
      </div>

      <DetailsForm>
        <DetailsFormSection title="Huishoudelijk afval">
          <DetailsFormField label="Inzamelaar" data={booleanFlagToLabel(isInzamelaarHuishoudelijkAfval(organisatie))} dataCy="inzamelaar-huishoudelijk-afval" />
          {isInzamelaarHuishoudelijkAfval(organisatie) && (
            <>
              <DetailsFormField label="Mag melden vanaf" data={organisatie.meldingInzamelingVanaf} />
              <DetailsFormField
                label="Inzamelrechten"
                data={<LinkButton handleClick={() => navigate(`/inzamelrechten/huishoudelijk-afval/${organisatie.organisatiecode}`)}>Overzicht raadplegen</LinkButton>}
              />
            </>
          )}
          <DetailsFormField
            label="Verdeler"
            data={booleanFlagToLabel(organisatie.types.includes(OrganisatieType.VERDELER_HUISHOUDELIJK_AFVAL))}
            dataCy="verdeler-huishoudelijk-afval"
          />
          {organisatie.types.includes(OrganisatieType.VERDELER_HUISHOUDELIJK_AFVAL) && (
            <DetailsFormField label="Extra codes voor verdeling" showLabelIfEmpty data={sortedExtraCodesVoorVerdeling.join(', ') || 'Geen beperkingen'} />
          )}
        </DetailsFormSection>
        <DetailsFormSection title="Bedrijfsafval">
          <DetailsFormField label="Inzamelaar" data={booleanFlagToLabel(organisatie.types.includes(OrganisatieType.INZAMELAAR_BEDRIJFSAFVAL))} dataCy="inzamelaar-bedrijfsafval" />
        </DetailsFormSection>
      </DetailsForm>
      <div className="vl-form-col--1-1">
        <div className="vl-action-group vl-action-group--align-right">
          <RouteButton label="Corrigeren" link={`/administratie/${organisatie.organisatiecode}/correctie`} />
          <RouteButton label="Sluiten" className="vl-button--secondary" link="/administratie" />
        </div>
      </div>
    </>
  );
};

export default OrganisatieDetails;
