import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues, useField } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import { UseMutationResult } from 'react-query/types/react/types';
import FormTitle from '../components/form/form-title';
import Checkbox from '../components/form/checkbox';
import { AfvalcodeMultiSelect, InzamelwijzeMultiSelect, WerkingsgebiedMultiSelect } from './organisatie-form-fields';
import SubmitButton from '../components/button/submit-button';
import CancelButton from '../components/button/cancel-button';
import { ApiError } from '../util/axios-error-mapping';
import ErrorAlert from '../components/alert/error-alert';
import { Exclusiviteit, InzamelrechtHAJsonRequest, InzamelrechtHAOverviewJsonResponse, OrganisatieOverviewJsonResponse, PeriodeEenheid } from '../generated';
import { formHasChanges } from '../util/form-has-changes';
import requiredField from '../util/required-field';
import { formats, parseKwartaal, patterns, periodePlaceholder, toKwartaal } from '../melding/periode.model';
import { SimpleSelect } from '../components/form/select/select-with-search';
import { useAllOrganisaties } from './organisatie.hooks';
import TextField from '../components/form/text-field';
import { MELDING_START_DATUM } from '../melding/melding.config';
import { isEmptyString } from '../util/string-utils';

export interface InzamelrechtHAFormValues {
  eigenaar: string;
  periodeVan: string;
  periodeTot?: string;
  afvalcodes: Array<string>;
  werkingsgebied: Array<string>;
  inzamelwijzen: Array<string>;
  exclusief: boolean;
}

interface InzamelrechtHAFormProps {
  mutateFn: (request: InzamelrechtHAJsonRequest) => Promise<AxiosResponse>;
  cancelLink: string;
  submitLabel: string;
  initialValues: InzamelrechtHAFormValues;
}

interface InzamelrechtHAInnerFormProps {
  mutation: UseMutationResult<AxiosResponse<InzamelrechtHAOverviewJsonResponse>, Error | ApiError, InzamelrechtHAJsonRequest>;
  submitLabel: string;
  cancelLink: string;
  formik: FormikProps<InzamelrechtHAFormValues>;
}

const assertFixedQuarterBefore2024 = (value: string, expectedQuarter: 1 | 2 | 3 | 4) => {
  const kwartaal = parseKwartaal(value);
  return kwartaal.year() >= 2024 || kwartaal.quarter() === expectedQuarter;
};

const kwartaalValidationSchema = Yup.string()
  .trim()
  .matches(patterns[PeriodeEenheid.KWARTAAL], 'Ongeldig kwartaal')
  .test(
    'periode-vanaf-2020-Q1',
    'Periode start vanaf 2020-Q1',
    ((value: string) => isEmptyString(value) || !parseKwartaal(value).isBefore(dayjs(MELDING_START_DATUM))) as TestFunction<any, any>,
  );

const createValidationSchema = (): Yup.ObjectSchema<any> =>
  Yup.object().shape({
    eigenaar: requiredField(),
    periodeVan: requiredField(
      kwartaalValidationSchema.test('q1-before-2024', 'Inzamelrechten die beginnen vóór 2024 moeten altijd starten met het eerste kwartaal van het jaar', ((value: string) =>
        assertFixedQuarterBefore2024(value, 1)) as TestFunction<any, any>),
    ),
    periodeTot: kwartaalValidationSchema
      .test(
        'q4-before-2024',
        'Inzamelrechten die eindigen vóór 2024 moeten altijd stoppen met het vierde kwartaal van het jaar',
        ((value: string) => isEmptyString(value) || assertFixedQuarterBefore2024(value, 4)) as TestFunction<any, any>,
      )
      .when('periodeVan', ([periodeVan], schema) =>
        periodeVan
          ? schema.test(
              'van-before-tot',
              'Periode tot mag niet voor Periode van liggen',
              ((value: string) => isEmptyString(value) || !parseKwartaal(value).isBefore(parseKwartaal(periodeVan))) as TestFunction<any, any>,
            )
          : schema,
      ),
  });

const validationSchema = createValidationSchema();

const InzamelrechtHAInnerForm: React.FC<InzamelrechtHAInnerFormProps> = ({ mutation, submitLabel, cancelLink, formik }) => {
  const inzamelaars = useAllOrganisaties({
    inzamelaarHAFlag: true,
  });

  const options = useMemo(
    () =>
      inzamelaars.map((inzamelaar: OrganisatieOverviewJsonResponse) => ({
        value: inzamelaar.organisatiecode,
        label: `${inzamelaar.organisatiecode}-${inzamelaar.organisatienaam}` ?? 'Organisatienaam onbekend',
      })),
    [inzamelaars],
  );

  const [eigenaarField] = useField('eigenaar');
  const [periodeVanField, , periodeVanHelper] = useField('periodeVan');

  useEffect(() => {
    if (eigenaarField.value && !periodeVanField.value) {
      periodeVanHelper.setValue(toKwartaal(inzamelaars.find((org) => org.organisatiecode === eigenaarField.value)?.meldenVanafHA));
    }
  }, [eigenaarField.value]);

  return (
    <Form spellCheck={false}>
      {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het opslaan van het inzamelrecht is mislukt." />}
      <div className="vl-form-grid vl-form-grid--is-stacked">
        <FormTitle title="Inzamelrecht Huishoudelijk Afval" />
        <SimpleSelect disabled={!!formik.initialValues.eigenaar} name="eigenaar" label="Eigenaar" noDataLabel="inzamelaars" isLoading={false} options={options} required />

        <TextField label="Periode van" name="periodeVan" required placeholder={periodePlaceholder(PeriodeEenheid.KWARTAAL)} valueMapper={(v) => v.toUpperCase()} />
        <TextField label="Periode tot" name="periodeTot" placeholder={periodePlaceholder(PeriodeEenheid.KWARTAAL)} valueMapper={(v) => v.toUpperCase()} />
        <AfvalcodeMultiSelect />
        <WerkingsgebiedMultiSelect />
        <InzamelwijzeMultiSelect />
        <Checkbox label="Exclusief" name="exclusief" />

        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <SubmitButton label={submitLabel} isSubmitting={mutation.isLoading} isDisabled={!formHasChanges(formik)} />
            <CancelButton link={cancelLink} isDisabled={mutation.isLoading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export const InzamelrechtHAForm: React.FC<InzamelrechtHAFormProps> = ({ mutateFn, cancelLink, submitLabel, initialValues }) => {
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<InzamelrechtHAOverviewJsonResponse>, Error | ApiError, InzamelrechtHAJsonRequest, unknown>((request) => mutateFn(request));

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    try {
      const request: InzamelrechtHAJsonRequest = {
        eigenaar: values.eigenaar,
        periodeVan: parseKwartaal(values.periodeVan).format(formats.DAG),
        periodeTot: values.periodeTot ? parseKwartaal(values.periodeTot).format(formats.DAG) : undefined,
        afvalcodes: values.afvalcodes,
        werkingsgebied: values.werkingsgebied,
        inzamelwijzen: values.inzamelwijzen,
        exclusief: values.exclusief ? Exclusiviteit.JA : Exclusiviteit.NEE,
      };
      const { data: recht } = await mutation.mutateAsync(request);
      navigate(`/inzamelrechten/huishoudelijk-afval/${recht.eigenaarCode}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik validateOnBlur={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => <InzamelrechtHAInnerForm mutation={mutation} submitLabel={submitLabel} cancelLink={cancelLink} formik={formik} />}
    </Formik>
  );
};
