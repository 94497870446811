import React from 'react';
import { DEFAULT_LOCALE } from '../../configuration';
import { useConfigContext } from '../context/config-context';
import { Maybe } from '../../util/maybe';
import ExternalLink from '../externallink/external-link';
import { ConfigJsonResponse } from '../../generated';

const formatNumber = (value?: number) => value?.toLocaleString(DEFAULT_LOCALE);

export type UploadLimitExtractor = (config: ConfigJsonResponse) => number;

interface CsvInfoBoxProps {
  csvEntitiesLabel: string;
  uploadLimitExtractor: UploadLimitExtractor;
  additionalInfoBullets?: string[];
}

const CsvInfoBox: React.FC<CsvInfoBoxProps> = ({ csvEntitiesLabel, uploadLimitExtractor, additionalInfoBullets }) => {
  const ctxData = useConfigContext();
  const config = Maybe.toNullable(ctxData);
  const csv = config?.csv;

  return csv ? (
    <div className="vl-typography">
      <h4>Info</h4>
      <ul>
        {additionalInfoBullets &&
          additionalInfoBullets.map((additionalInfoBullet) => (
            <li data-testid="additionalInfoBullet" key={additionalInfoBullet}>
              {additionalInfoBullet}
            </li>
          ))}
        <li>
          U vindt de handleiding en het sjabloon voor het CSV-bestand terug op de <ExternalLink href={csv.sjabloonLink}>OVAM-website</ExternalLink>
        </li>
        <li>
          Persoonsgegevens van particulieren worden niet opgeslagen omwille van de Europese GDPR-wetgeving voor gegevensbescherming. Ook overbodige informatie wordt niet
          opgeslagen.
        </li>
        <li>
          De maximale bestandsgrootte: <strong data-testid="fileSize">{formatNumber(csv.maxFileSizeMB)} MB</strong>
        </li>
        <li data-testid="uploadLimitDescription">
          Het maximale aantal {csvEntitiesLabel} per CSV-bestand:
          <strong data-testid="uploadLimit"> {formatNumber(uploadLimitExtractor(config))}</strong>
        </li>
      </ul>
    </div>
  ) : null;
};
export default CsvInfoBox;
