import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useAuthorisationInfo } from '../../../services/security/authorisation';
import MeldingInzamelingBACsvCreatePage from './melding-inzameling-ba-csv-create.page';
import MeldingInzamelingBACsvUpdatePage from './melding-inzameling-ba-csv-update.page';
import MeldingInzamelingBAOverview from './melding-inzameling-ba-overview';
import { MeldingInzamelingBAPagingAndFiltering } from '../../melding.hook';
import LoadingPage from '../../../general/loading-page';
import AuthorisedScope from '../../../general/authorised-scope';
import { Scope } from '../../../generated';

const defaultPageSize = 100;

const MeldingInzamelingBAOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<MeldingInzamelingBAPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.INZAMELING_BEDRIJFSAFVAL}>
        <BreadcrumbsItem to="/melding/inzameling/bedrijfsafval">Meldingen inzameling bedrijfsafval</BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<MeldingInzamelingBAOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
          <Route path="csv-nieuw" element={<MeldingInzamelingBACsvCreatePage />} />
          <Route path="csv-aanvulling" element={<MeldingInzamelingBACsvUpdatePage />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default MeldingInzamelingBAOverviewPage;
