import React from 'react';
import cx from 'classnames';

interface FormTitleProps {
  title: string;
  label?: string;
}

const FormTitle: React.FC<FormTitleProps> = ({ title, label }) => (
  <>
    <div className={cx({ 'vl-form-col--12-12': !label, 'vl-form-col--6-12': !!label })}>
      <h1 className="vl-properties__title">{title}</h1>
    </div>
    {!!label && (
      <div className="vl-form-col--6-12 vl-u-align-right vl-u-text--bold" data-cy={`form-title-label_${title}`}>
        {label}
      </div>
    )}
  </>
);

export default FormTitle;
