import React, { useEffect, useState } from 'react';

const DelayedRender: React.FC<any> = ({ children }) => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setRendered(true), 200);
    return () => clearTimeout(timeout);
  });

  return rendered ? children : null;
};

export default DelayedRender;
