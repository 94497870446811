import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

const ScrollToTopOnNavigation: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location]);
  return <>{children}</>;
};

export default ScrollToTopOnNavigation;
