import React from 'react';
import { Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { isNumber } from 'lodash';
import { FilterButtons } from './component.filter.buttons';
import TonnageInput from '../../../melding/tonnage-input';
import './filter.css';

interface TonnageRangeFilterDropdownProps extends FilterDropdownProps {
  dataCy: string;
}

const TonnageRangeFilterDropdown: React.FC<TonnageRangeFilterDropdownProps> = ({ dataCy, selectedKeys, setSelectedKeys, clearFilters, confirm }) => {
  const [selectedVan, selectedTot] = selectedKeys && selectedKeys.length === 2 ? selectedKeys.map((value) => (value ? Number(value) : undefined)) : [undefined, undefined];
  const selectedVanIsPresent = isNumber(selectedVan);
  const selectedTotIsPresent = isNumber(selectedTot);
  const isEmpty = !selectedVanIsPresent && !selectedTotIsPresent;
  const isValid = isEmpty || (selectedVanIsPresent && selectedTotIsPresent);

  const confirmIfValid = () => {
    if (isValid) {
      confirm();
    }
  };

  return (
    <div data-cy={`${dataCy}-filter-dropdown`} className="filter-dropdown">
      <div className="tonnage-range-filter">
        <Input.Group compact>
          <TonnageInput
            name="tonnageVan"
            value={selectedVan}
            max={selectedTot}
            placeholder="Tonnage van"
            style={{
              width: 200,
              borderRight: 0,
            }}
            onChange={(value) => setSelectedKeys([`${value || ''}`, selectedTotIsPresent ? `${selectedTot}` : ''])}
            onPressEnter={confirmIfValid}
            autoFocus
          />
          <Input
            className="site-input-split vl-input-field vl-input-field--block"
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder=">"
            disabled
          />
          <TonnageInput
            name="tonnageTot"
            value={selectedTot}
            min={selectedVan}
            placeholder="Tonnage tot"
            style={{
              width: 200,
              borderLeft: 0,
            }}
            onChange={(value) => setSelectedKeys([selectedVanIsPresent ? `${selectedVan}` : '', `${value || ''}`])}
            onPressEnter={confirmIfValid}
          />
        </Input.Group>
      </div>
      <FilterButtons
        onReset={() => {
          clearFilters?.();
          setSelectedKeys([]);
        }}
        onConfirm={() => confirm()}
        resetDisabled={isEmpty}
        submitDisabled={!isValid}
      />
    </div>
  );
};

export default TonnageRangeFilterDropdown;
