import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import styles from '../header/breadcrumbs/separated-breadcrumb.module.css';
import HomeView from './home-view';
import MeldingInzamelingHAOverviewPage from '../melding/melding-inzameling-ha-overview.page';
import MeldingVerwerkingOverviewPage from '../melding/melding-verwerking-overview.page';
import VerdelingOverviewPage from '../verdeling/verdeling-overview.page';
import OrganisatieOverviewPage from '../administratie/organisatie-overview.page';
import ContactgegevensRoutes from '../contactgegevens/contactgegevens-routes';
import MeldingInzamelingBAOverviewPage from '../melding/inzameling/bedrijfsafval/melding-inzameling-ba-overview.page';
import ValidatievraagRoutes from '../validatievraag/validatievraag-routes';
import {
  MeldingInzamelingBedrijfsafvalRapport,
  MeldingInzamelingHAEigenRapport,
  MeldingInzamelingHAGemeenteRapport,
  MeldingInzamelingHAIntercommunaleRapport,
  MeldingVerwerkingRapport,
} from '../rapportering/melding-rapport';
import InzamelrechtHAOverviewPage from '../administratie/inzamelrecht-ha-overview.page';
import PeriodeZonderMeldingOverviewPage from '../administratie/periode-zonder-melding-overview.page';
import AuthorisedScope from './authorised-scope';
import { Scope } from '../generated';
import ContactgegevensCreateForm from '../contactgegevens/contactgegevens-create-form';

const HomePage: React.FC<any> = () => {
  const addprops = {
    separatorClassName: styles.hidden,
  };
  return (
    <>
      <AuthorisedScope requiredScope={Scope.BEHEER_CONTACTGEGEVENS_VERPLICHT}>
        <ContactgegevensCreateForm />
      </AuthorisedScope>
      <AuthorisedScope requiredScope={Scope.BEHEER_CONTACTGEGEVENS}>
        <BreadcrumbsItem to="/" {...addprops}>
          Startpagina
        </BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/melding/inzameling/huishoudelijk-afval/*" element={<MeldingInzamelingHAOverviewPage />} />
          <Route path="/melding/inzameling/bedrijfsafval/*" element={<MeldingInzamelingBAOverviewPage />} />
          <Route path="/verdeling/*" element={<VerdelingOverviewPage />} />
          <Route path="/rapporten/inzameling/huishoudelijk-afval/eigen" element={<MeldingInzamelingHAEigenRapport />} />
          <Route path="/rapporten/inzameling/huishoudelijk-afval/gemeente" element={<MeldingInzamelingHAGemeenteRapport />} />
          <Route path="/rapporten/inzameling/huishoudelijk-afval/intercommunale" element={<MeldingInzamelingHAIntercommunaleRapport />} />
          <Route path="/rapporten/inzameling/bedrijfsafval" element={<MeldingInzamelingBedrijfsafvalRapport />} />
          <Route path="/rapporten/verwerking" element={<MeldingVerwerkingRapport />} />
          <Route path="/melding/verwerking/*" element={<MeldingVerwerkingOverviewPage />} />
          <Route path="/administratie/*" element={<OrganisatieOverviewPage />} />
          <Route path="/inzamelrechten/huishoudelijk-afval/*" element={<InzamelrechtHAOverviewPage />} />
          <Route path="/contactgegevens/*" element={<ContactgegevensRoutes />} />
          <Route path="/validatievraag/*" element={<ValidatievraagRoutes />} />
          <Route path="/periodes-zonder-melding/*" element={<PeriodeZonderMeldingOverviewPage />} />
        </Routes>
      </AuthorisedScope>
    </>
  );
};

export default HomePage;
