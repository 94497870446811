import React, { useMemo } from 'react';
import { SimpleSelect } from '../../components/form/select/select-with-search';
import { sortByCode, useDierlijkeBijproducten } from '../../enumerated-lists/enumeratedlists.hook';
import { EnumeratedListJsonResponse } from '../../generated';

const useDierlijkBijproductOptions = () => {
  const { data: inzamelwijzes, isLoading } = useDierlijkeBijproducten();

  const options = useMemo(
    () =>
      sortByCode(inzamelwijzes).map((dierlijkBijproduct: EnumeratedListJsonResponse) => ({
        value: dierlijkBijproduct.code,
        label: dierlijkBijproduct.omschrijving,
      })),
    [inzamelwijzes],
  );
  return {
    isLoading,
    options,
  };
};

const DierlijkBijproductSelect: React.FC<{ required?: boolean }> = ({ required }) => {
  const { isLoading, options } = useDierlijkBijproductOptions();
  return (
    <SimpleSelect
      disabled={false}
      name="dierlijkBijproduct"
      label="Dierlijk bijproduct"
      noDataLabel="dierlijke bijproducten"
      isLoading={isLoading}
      options={options}
      required={required}
    />
  );
};

export default DierlijkBijproductSelect;
