import { string } from 'yup';
import { DEFAULT_LOCALE } from '../configuration';
import { formatNumber } from '../util/number-formatting';

class Tonnage {
  static readonly MAX_VALUE = 99999999.999999;
  value: number;

  constructor(value: number) {
    this.value = value;
  }

  formatted = () =>
    `${formatNumber(this.value, {
      minimumFractionDigits: 6,
    })} ton`;

  static validateValue = (value: string | undefined): boolean => {
    if (value !== undefined) {
      const convertedValue = parseFloat(value.replaceAll(',', '.'));
      return !Number.isNaN(convertedValue) && convertedValue >= 0 && convertedValue <= 99999999.999999;
    }
    return false;
  };

  static validateFormat = (value: string | undefined): boolean =>
    string()
      .matches(/^(?:\d{1,8}(?:[,]\d{1,6})?){1}$/gm)
      .isValidSync(value);

  static formatValue = (value?: number): string => (value ? new Intl.NumberFormat(DEFAULT_LOCALE, { maximumFractionDigits: 6 }).format(value) : '');

  static parseValue = (value?: string): number => (value ? Number(value?.replaceAll(/\./g, '')?.replace(/,/g, '.')) : 0);
}

export default Tonnage;
