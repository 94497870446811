import * as Yup from 'yup';
import { Nullable } from '../types/nullable';
import { isEmptyString } from '../util/string-utils';
import ModuloCheck from './modulo-check.model';

export const ondernemingsnummerValidationSchema: Yup.StringSchema<any> = Yup.string()
  .trim()
  .test('ondernemingsnummer-format', 'Formaat klopt niet: xxxx.xxx.xxx', (value: Nullable<string>) => !value || isEmptyString(value) || /^\d{4}[.]\d{3}[.]\d{3}$/.test(value))
  .test(
    'ondernemingsnummer-starting-number',
    'Een ondernemingsnummer start met een 0 of een 1',
    (value: string | undefined) => isEmptyString(value) || Yup.string().matches(/^[01]/gm).isValidSync(value),
  )
  .test('ondernemingsnummer-valid', 'Ongeldig ondernemingsnummer', (value: Nullable<string>) => !value || value.length === 0 || ModuloCheck.isValid(value));
