import React from 'react';
import cx from 'classnames';
import { Tooltip, TooltipProps } from 'antd';
import { useNavigate } from 'react-router-dom';

interface RouteButtonProps {
  link: string;
  label: string;
  title?: string;
  tooltip?: TooltipProps;
  className?: string;
  isDisabled?: boolean;
}

const RouteButton: React.FC<RouteButtonProps> = ({ link, label, title, tooltip, className, isDisabled = false }) => {
  const navigate = useNavigate();
  const button = (
    <button
      type="button"
      disabled={isDisabled}
      className={cx('vl-button', className, { 'vl-button--disabled': isDisabled })}
      onClick={() => navigate(link)}
      title={title ?? ''}
      data-cy="route-button"
    >
      <span className="vl-button__label">{label}</span>
    </button>
  );
  return tooltip ? <Tooltip {...tooltip}>{button}</Tooltip> : button;
};

export default RouteButton;
