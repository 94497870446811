import React from 'react';
import { displayClean, verwerkingswijzeLabels } from './melding-detail.hook';
import RouteButton from '../components/button/route-button';
import MeldingDetailHeader from './melding-detail.header';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import MeldingDetailsFormSection from './melding-details-form-section';
import BestemmingDetailsFormSection from './bestemming-details-form-section';
import IhmDetailsFormSection from './ihm-details-form-section';
import OorsprongDetailsFormSection from './bestemming/oorsprong-details-form-section';
import Materiaal, { EURALCODE, MATERIAALCODE } from './materiaal.model';
import { useDeleteMeldingVerwerking } from './melding.hook';
import DeleteEntityButton from '../components/button/delete-entity-button';
import ErrorAlert from '../components/alert/error-alert';
import MultiLineText from '../components/details/multi-line-text';
import { MeldingVerwerkingDetailsJsonResponse } from '../generated';
import { useDierlijkeBijproducten } from '../enumerated-lists/enumeratedlists.hook';

const MeldingVerwerkingDetails: React.FC<{ melding: MeldingVerwerkingDetailsJsonResponse }> = ({ melding }) => {
  const deleteMutation = useDeleteMeldingVerwerking();
  const { resolveOmschrijving: resolveDierlijkBijproductOmschrijving } = useDierlijkeBijproducten();

  return (
    <>
      {deleteMutation.isError && (
        <ErrorAlert error={deleteMutation.error} defaultMessage="Er is een fout opgetreden bij het verwijderen van de melding. Gelieve later nog eens opnieuw te proberen." />
      )}
      <MeldingDetailHeader melding={melding} />
      <DetailsForm>
        <MeldingDetailsFormSection melding={melding}>
          <DetailsFormField label="Melding type" data={displayClean(melding.meldingType)} />
        </MeldingDetailsFormSection>
        <DetailsFormSection title="Materiaalidentificatie">
          <DetailsFormField label={MATERIAALCODE} data={Materiaal.format(MATERIAALCODE, melding.materiaal.materiaalcode ?? '')} />
          <DetailsFormField label={EURALCODE} data={Materiaal.format(EURALCODE, melding.materiaal.euralcode ?? '')} />
          <DetailsFormField label="Omschrijving" data={melding.materiaal.omschrijving && <MultiLineText value={melding.materiaal.omschrijving} />} />
          <DetailsFormField label="Kwaliteit" data={melding.materiaal.kwaliteit && <MultiLineText value={melding.materiaal.kwaliteit} />} />
          <DetailsFormField label="Dierlijk bijproduct" data={resolveDierlijkBijproductOmschrijving(melding.materiaal.dierlijkBijproduct)} />
        </DetailsFormSection>
        <OorsprongDetailsFormSection melding={melding} />
        <BestemmingDetailsFormSection melding={melding} />
        <DetailsFormSection title="Verwerking">
          <DetailsFormField label="R/D-code" data={melding.verwerking.rdCode} />
          <DetailsFormField label="Verwerkingswijze" data={verwerkingswijzeLabels[melding.verwerking.wijze ?? ''] || ''} />
          <DetailsFormField label="Omschrijving" data={melding.verwerking.omschrijving && <MultiLineText value={displayClean(melding.verwerking.omschrijving)} />} />
          <DetailsFormField label="Input in recyclage" data={melding.verwerking.inputInRecyclage && <MultiLineText value={melding.verwerking.inputInRecyclage} />} />
          <DetailsFormField label="Toepassingswijze" data={displayClean(melding.toepassingswijze)} />
        </DetailsFormSection>
        <IhmDetailsFormSection melding={melding} />
        <DetailsFormSection title="Vervoer">
          <DetailsFormField label="Vervoerswijze" data={displayClean(melding.vervoerswijze)} />
        </DetailsFormSection>
      </DetailsForm>
      <div className="vl-form-col--1-1">
        <div className="vl-action-group vl-action-group--align-right">
          <RouteButton label="Corrigeren" link={`/melding/verwerking/${melding.referentie}/correctie`} isDisabled={deleteMutation.isLoading} />
          <DeleteEntityButton entity={melding} successLink="/melding/verwerking/" deleteMutation={deleteMutation} confirmation="Bent u zeker dat u deze melding wil verwijderen?" />
          <RouteButton label="Sluiten" className="vl-button--secondary" link="/melding/verwerking/" isDisabled={deleteMutation.isLoading} />
        </div>
      </div>
    </>
  );
};

export default MeldingVerwerkingDetails;
