import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { ContactgegevensJsonRequest, ContactgegevensJsonResponse } from '../generated';

const contactgegevensUrl = '/ui/contactgegevens';

export const useContactgegevens = (gebruikerId?: string): UseQueryResult<ContactgegevensJsonResponse, Error> => {
  const url = `${contactgegevensUrl}${gebruikerId ? `/${gebruikerId}` : ''}`;

  return useQuery<ContactgegevensJsonResponse, Error>(url, async () => {
    try {
      const { data } = await axios.get<ContactgegevensJsonResponse>(url);
      return data;
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          gebruikerNaam: '',
          gebruikerEmailadres: '',
          gebruikerTelefoonnummer: '',
          organisatienaam: '',
          organisatieEmailadres: '',
        } as ContactgegevensJsonResponse;
      }
      throw error;
    }
  });
};

export const createOrUpdateContactgegevens = (request: ContactgegevensJsonRequest) => axios.post<ContactgegevensJsonResponse>(contactgegevensUrl, request);
