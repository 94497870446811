import React from 'react';
import Alert from './alert';
import ExternalHomepageLink from '../navigation/external-homepage-link';

interface IAlertWithContactProps {
  title?: string;
  type?: 'info' | 'error' | 'warning' | 'success';
  scrollIntoView?: boolean;
}

const AlertWithContact: React.FC<React.PropsWithChildren<IAlertWithContactProps>> = ({ title = 'Fout!', type = 'error', scrollIntoView = false, children }) => (
  <Alert type={type} title={title} scrollIntoView={scrollIntoView}>
    {children}
    <p>
      Indien u denkt dat dit niet correct is, gelieve contact op te nemen met de OVAM via het MATIS-contactformulier op de <ExternalHomepageLink>OVAM-website</ExternalHomepageLink>
      .
    </p>
  </Alert>
);

export default AlertWithContact;
