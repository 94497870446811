import React from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

interface IUploaderProps {
  uploaderTitle: string;
  uploaderDescription: string;
  action?: string;
  accept: string;
  multiple: boolean;
  onChange?: (info: UploadChangeParam) => void;
  beforeUpload?: (file: RcFile, FileList: RcFile[]) => boolean;
  maxCount?: number;
  disabled?: boolean;
  fileList: Array<UploadFile>;
}

const Uploader: React.FC<IUploaderProps> = ({ uploaderTitle, uploaderDescription, action, accept, multiple, onChange, beforeUpload, maxCount, disabled = false, fileList }) => (
  <Dragger
    action={action}
    accept={accept}
    multiple={multiple}
    onChange={onChange}
    beforeUpload={beforeUpload}
    maxCount={maxCount}
    disabled={disabled}
    fileList={fileList}
    data-testid="upload-dragger"
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">{uploaderTitle}</p>
    <p className="ant-upload-hint">{uploaderDescription}</p>
  </Dragger>
);

export default Uploader;
