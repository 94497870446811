import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadExistingMeldingVerwerkingCsv } from './melding-csv.hook';
import { MELDING_VERWERKING_CHAPTER_IN_MANUAL, UPDATE_MELDINGEN_DESCRIPTION } from './melding.labels';
import MeldingCsvUploader from './melding-csv-uploader';

const MeldingVerwerkingCsvUpdatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/verwerking/csv-aanvulling">{UPDATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadExistingMeldingVerwerkingCsv}
      title={`${UPDATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_VERWERKING_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingVerwerkingCsvUpdatePage;
