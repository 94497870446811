import React from 'react';

interface LabelFieldProps {
  name: string;
  label: string;
  value: string;
}

const FixedValueField: React.FC<LabelFieldProps> = ({ name, label, value }) => (
  <>
    <div className="vl-form-col--3-12">
      <label className="vl-form__label" htmlFor={name}>
        {label}
      </label>
    </div>
    <div className="vl-form-col--9-12">
      <input className="vl-input-field vl-input-field--block vl-input-field--disabled" type="text" name={name} value={value} disabled />
    </div>
  </>
);

export default FixedValueField;
