import React, { useState } from 'react';
import { ColumnsType, ExpandableConfig } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';
import { uniq } from 'lodash';
import { Pageable } from '../components/table/pageable';
import { useValidatievragen } from './validatievraag-hooks';
import OverviewTable, { RecordProps } from '../components/table/overview-table';
import formatDate from '../util/date-formatting';
import JiraRenderedFieldViewer from '../jira/jira-rendered-field-viewer';
import IconButton from '../components/button/icon-button';
import ValidatievraagAntwoordForm from './validatievraag-antwoord-form';
import { AntwoordType, ValidatievraagOverviewJsonResponse } from '../generated';

interface ValidatievraagOverviewProps {
  pageable: Pageable;
  defaultPageSize: number;
  onChange: (newValues: Pageable) => void;
}

interface ValidatievraagOverviewRow extends RecordProps, ValidatievraagOverviewJsonResponse {}

type UseState<T> = [T, React.Dispatch<React.SetStateAction<T>>];

interface ActiveAntwoordForm {
  key: string;
  antwoord: AntwoordType;
}

const beschrijvingExpandable = (
  expandedRowKeys: string[],
  setExpandedRowKeys: React.Dispatch<React.SetStateAction<string[]>>,
  [activeAntwoordForms, setActiveAntwoordForms]: UseState<ActiveAntwoordForm[]>,
  refreshOverview: () => void,
): ExpandableConfig<ValidatievraagOverviewRow> => ({
  expandedRowRender: (row) => {
    const activeAntwoordForm = activeAntwoordForms.find((form) => form.key === row.key);
    const closeForm = () => setActiveAntwoordForms(activeAntwoordForms.filter((form) => form.key !== row.key));
    return (
      <div>
        {activeAntwoordForm && (
          <>
            <ValidatievraagAntwoordForm
              antwoord={activeAntwoordForm.antwoord}
              validatievraag={row}
              onClose={() => closeForm()}
              onFinish={() => {
                closeForm();
                refreshOverview();
              }}
            />
            <hr className="vl-u-spacer-bottom vl-u-spacer-top" />
          </>
        )}
        <JiraRenderedFieldViewer fieldContents={row.beschrijving} className="vl-u-spacer-left" />
      </div>
    );
  },
  expandedRowKeys,
  onExpandedRowsChange: (updated) => setExpandedRowKeys(updated as string[]),
});

const ValidatievraagOverview: React.FC<ValidatievraagOverviewProps> = ({ pageable, defaultPageSize, onChange }) => {
  const { error, isLoading, isFetching, data: page, refetch } = useValidatievragen(pageable);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [activeAntwoordForms, setActiveAntwoordForms] = useState<ActiveAntwoordForm[]>([]);

  const mappedPage = page
    ? {
        ...page,
        content: page.content.map((row) => ({ ...row, referentie: row.key, tijdstipLaatsteWijziging: formatDate(row.tijdstipLaatsteWijziging) })),
      }
    : undefined;

  const updatePaging = (pagination: PaginationProps) => {
    onChange({
      page: (pagination.current ?? 1) - 1,
      size: pagination.pageSize ?? defaultPageSize,
    });
  };
  const expandRow = (row: ValidatievraagOverviewRow) => {
    setExpandedRowKeys(uniq([...expandedRowKeys, row.key]));
  };
  const refreshOverview = async () => {
    setExpandedRowKeys([]);
    setActiveAntwoordForms([]);
    await refetch();
  };

  const openAntwoordForm = (row: ValidatievraagOverviewRow, antwoord: AntwoordType) => {
    expandRow(row);
    setActiveAntwoordForms([...activeAntwoordForms.filter((form) => form.key !== row.key), { key: row.key, antwoord }]);
  };
  const antwoordFormIsActive = (row: ValidatievraagOverviewRow): boolean => activeAntwoordForms.some((form) => form.key === row.key);

  const columns: ColumnsType<ValidatievraagOverviewRow> = [
    { title: 'Samenvatting', dataIndex: 'samenvatting', key: 'samenvatting', className: 'samenvatting-column' },
    { title: 'Status', dataIndex: 'status', key: 'status', className: 'status-column' },
    { title: 'Laatst gewijzigd', dataIndex: 'tijdstipLaatsteWijziging', key: 'tijdstipLaatsteWijziging', className: 'tijdstipLaatsteWijziging-column' },
    {
      title: 'Acties',
      render: (ignore, row) =>
        row.status === 'Te behandelen door de organisatie' ? (
          <div>
            <IconButton
              iconClasses="fas fa-check fa-xs"
              className="vl-u-spacer-right--xsmall"
              description="De vaststelling is juist"
              isNarrow
              isDisabled={antwoordFormIsActive(row)}
              onClick={() => openAntwoordForm(row, AntwoordType.VASTSTELLING_JUIST)}
            />
            <IconButton
              iconClasses="fas fa-times fa-xs"
              type="error"
              description="De vaststelling is fout"
              isNarrow
              isDisabled={antwoordFormIsActive(row)}
              onClick={() => openAntwoordForm(row, AntwoordType.VASTSTELLING_FOUT)}
            />
          </div>
        ) : (
          ''
        ),
    },
  ];

  return (
    <OverviewTable
      title="Validatievragen"
      itemsLabel="validatievragen"
      columns={columns}
      page={mappedPage}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePaging}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      notFoundMessage="Geen validatievragen gevonden."
      expandable={beschrijvingExpandable(expandedRowKeys, setExpandedRowKeys, [activeAntwoordForms, setActiveAntwoordForms], refreshOverview)}
    />
  );
};

export default ValidatievraagOverview;
