import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams } from 'react-router-dom';
import DetailsLoading from '../components/details/details-loading';
import { useMeldingVerwerking } from './melding-detail.hook';
import MeldingVerwerkingUpdateForm from './melding-verwerking-update-form';

const MeldingVerwerkingUpdatePage: React.FC<any> = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: melding, isLoading } = useMeldingVerwerking(referentie);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/melding/verwerking/${referentie}`}>{melding?.identificatie ?? '...'}</BreadcrumbsItem>
        {isLoading && <DetailsLoading />}
        {!isLoading && melding && <MeldingVerwerkingUpdateForm melding={melding} />}
      </div>
    </section>
  );
};

export default MeldingVerwerkingUpdatePage;
