import React from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import FormTitle from '../components/form/form-title';
import TextField from '../components/form/text-field';
import {
  BestemmingTypeVerwerkingRadio,
  MateriaalEnumSelect,
  OorsprongTypeVerwerkingRadio,
  PeriodeSelection,
  PeriodeWaardeField,
  RDcodeSelect,
  Toepassingswijze,
  TonnageField,
  TypeMelding,
  VervoerswijzeSelect,
  VerwerkingswijzeSelect,
} from './melding-form-fields';
import { createMeldingVerwerkingRequestValidationSchema, defaultMeldingVerwerkingFormValues, MeldingVerwerkingForm } from './melding-verwerking-form';
import TextArea from '../components/form/text-area';
import BestemmingForm from './bestemming/bestemming-form';
import OorsprongForm from './bestemming/oorsprong-form';
import { useRoleHook } from '../services/security/use-role-hook';
import { validatieSchemaOrganisatiecodeVrijeTekst } from '../organisatie/organisatiecode.model';
import { vrijeTekstSchema } from './vrije-tekst-schema';
import requiredField from '../util/required-field';
import BtwTextField from './bestemming/btw-text-field';
import { EnumeratedListJsonResponse, MeldingVerwerkingJsonRequest } from '../generated';
import DierlijkBijproductSelect from './materiaal/dierlijk-bijproduct-select';

const identificatieMaxLength = 36;

const createMeldingRequestValidationSchema = (vlaamsePostcodes: EnumeratedListJsonResponse[]) =>
  createMeldingVerwerkingRequestValidationSchema(vlaamsePostcodes).concat(
    Yup.object().shape({
      identificatie: requiredField(vrijeTekstSchema().max(identificatieMaxLength, `Identificatie kan niet meer dan ${identificatieMaxLength} tekens bevatten`)),
    }),
  );

const createMeldingRequestValidationSchemaBeheerder = (vlaamsePostcodes: EnumeratedListJsonResponse[]) =>
  createMeldingRequestValidationSchema(vlaamsePostcodes).concat(
    Yup.object().shape({
      eigenaar: validatieSchemaOrganisatiecodeVrijeTekst('Eigenaar'),
    }),
  );

const MeldingVerwerkingCreateForm: React.FC = () => {
  const { isBeheerder } = useRoleHook();

  const createMutation = async (createRequest: MeldingVerwerkingJsonRequest) => axios.post<any>('/ui/meldingen/verwerking', createRequest);
  return (
    <MeldingVerwerkingForm
      mutateFn={createMutation}
      initialValues={defaultMeldingVerwerkingFormValues}
      validationSchemaFactory={isBeheerder ? createMeldingRequestValidationSchemaBeheerder : createMeldingRequestValidationSchema}
      cancelLink="/melding/verwerking"
      submitLabel="Melding aanmaken"
    >
      <FormTitle title="Melding" />
      <TextField label="Identificatie" name="identificatie" required autoFocus />
      {isBeheerder && <TextField label="Eigenaar" name="eigenaar" required />}
      <TypeMelding />
      <TonnageField />
      <PeriodeSelection />
      <PeriodeWaardeField />
      <FormTitle title="Materiaalidentificatie" />
      <MateriaalEnumSelect required />
      <TextArea placeholder="Beschrijving van de afvalstof of materiaal" required label="Materiaal omschrijving" name="materiaalOmschrijving" />
      <TextArea placeholder="Beschrijving in functie van de kwaliteit voor verdere verwerking" label="Materiaal kwaliteit" name="materiaalKwaliteit" />
      <DierlijkBijproductSelect />
      <FormTitle title="Oorsprong" />
      <OorsprongTypeVerwerkingRadio />
      <OorsprongForm />
      <FormTitle title="Bestemming" />
      <BestemmingTypeVerwerkingRadio />
      <BestemmingForm />
      <FormTitle title="Verwerking" />
      <RDcodeSelect />
      <VerwerkingswijzeSelect />
      <TextArea label="Omschrijving" placeholder="Duiding bij verwerkingswijze of bij combinatie van verwerkingswijzes" name="verwerkingOmschrijving" />
      <TextArea label="Input in recyclage" name="inputInRecyclage" />
      <Toepassingswijze />
      <FormTitle title="IHM" />
      <BtwTextField label="BTW-nummer" name="ihmBtwNummer" placeholder="BE0123456789" />
      <FormTitle title="Vervoer" />
      <VervoerswijzeSelect required />
    </MeldingVerwerkingForm>
  );
};

export default MeldingVerwerkingCreateForm;
