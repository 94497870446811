import React from 'react';
import styles from './disclaimer.module.css';

interface DisclaimerProps {
  text: string;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ text }) => (
  <div className="vl-form-col--1-1">
    <h3 className={styles.h3}>Disclaimer</h3>
    <p className={styles.p}>{text}</p>
  </div>
);

export default Disclaimer;
