import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Page } from '../components/table/page';
import { OrganisatieFiltersQueryParametersRequest, OrganisatieJsonResponse, OrganisatieOverviewJsonResponse, PageableParamsJsonRequest } from '../generated';
import { axiosParamsSerializer } from '../util/axios-params-serializer';

export interface OrganisatiePagingAndFilter extends OrganisatieFiltersQueryParametersRequest, PageableParamsJsonRequest {}

export const useOrganisaties = (params: OrganisatiePagingAndFilter): UseQueryResult<Page<OrganisatieOverviewJsonResponse>, Error> => {
  const organisatieUrl = '/ui/organisatie';
  return useQuery<Page<OrganisatieOverviewJsonResponse>, Error>([organisatieUrl, params], async () => {
    const { data } = await axios.get<Page<OrganisatieOverviewJsonResponse>>(organisatieUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });
};

export const useAllOrganisaties = (filters: OrganisatieFiltersQueryParametersRequest): OrganisatieOverviewJsonResponse[] => {
  let records: OrganisatieOverviewJsonResponse[] = [];
  let currentPage = 0;
  let totalPages = 1;
  do {
    const { data: page } = useOrganisaties({
      ...filters,
      page: currentPage,
      size: 500,
    });
    records = records.concat(page?.content ?? []);
    totalPages = page?.totalPages ?? totalPages;
    currentPage = (page?.pageNr ?? currentPage) + 1;
  } while (currentPage < totalPages);
  return records;
};

export const useOrganisatie = (organisatiecode: string): UseQueryResult<OrganisatieJsonResponse, Error> => {
  const organisatieUrl = `/ui/organisatie/${organisatiecode}`;
  return useQuery<OrganisatieJsonResponse, Error>(organisatieUrl, async () => {
    const { data } = await axios.get<OrganisatieJsonResponse>(organisatieUrl);
    return data;
  });
};
