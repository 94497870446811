import { EnumeratedListJsonResponse } from '../generated';

class Afvalcode {
  code: string;

  omschrijving: string;

  constructor(code: string, omschrijving: string) {
    this.code = code;
    this.omschrijving = omschrijving;
  }

  formatted = () => `${this.code} ${this.omschrijving}`.trim();

  static readonly format = ({ code, omschrijving }: EnumeratedListJsonResponse) => `${code} ${omschrijving?.replaceAll(',', '')}`.trim();
}

export default Afvalcode;
