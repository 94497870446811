import { BestemmingJsonResponse, EnumeratedListJsonResponse } from '../../generated';
import { isBelgie, isVlaanderen } from './bestemming.model';
import { EURALCODE } from '../materiaal.model';
import { displayClean } from '../melding-detail.hook';
import { AfvalverwerkendeWerf } from './afvalverwerkende-werf';

class BelgischeWerfBuitenVlaanderen extends AfvalverwerkendeWerf {
  constructor() {
    super(['10', '1705', '1901']);
  }

  matches = (bestemming: BestemmingJsonResponse, vlaamsePostcodes?: EnumeratedListJsonResponse[], euralcode?: string): boolean =>
    !isVlaanderen(bestemming, vlaamsePostcodes) && isBelgie(bestemming) && this.supportsEuralcode(euralcode ?? '');

  errorMessage = (bestemming: BestemmingJsonResponse): string =>
    `${EURALCODE}s die beginnen met ${this.formatPrefixesForErrorMessage()} zijn enkel toegelaten voor bestemming '${displayClean(
      bestemming.type,
    )}' met een postcode in Wallonië of het Brussels Hoofdstedelijk Gewest`;
}

export const BELGISCHE_WERF_BUITEN_VLAANDEREN = new BelgischeWerfBuitenVlaanderen();
