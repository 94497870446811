import React from 'react';
import { Tooltip } from 'antd';
import 'antd/dist/reset.css';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';
import { Link } from 'react-router-dom';
import {
  createSelectWithSearchFilterColumn,
  createTextInputFilterColumn,
  createTimeRangeFilterColumn,
  createTonnageRangeFilterColumn,
} from '../components/table/default.datatable.options';
import { MeldingFilters, MeldingInzamelingHAPagingAndFiltering, useDeleteAllSelectedMeldingenInzamelingHA, useMeldingenInzamelingHA } from './melding.hook';
import { useRoleHook } from '../services/security/use-role-hook';
import getFilterValues from '../util/table-filter-utils';
import { useAfvalCodeOptions, useInzamelwijzeOptions } from './melding-form-fields';
import { mapMeldingFilterValues, MeldingOverview } from './melding-overview';
import { MeldingOverviewTable } from './melding-overview-table';
import { mapMeldingPaginationValues, toMeldingOverview } from './melding-overview-common';

export interface MeldingInzamelingHAOverviewProps {
  onChange: (newValues: MeldingInzamelingHAPagingAndFiltering) => void;
  pagingAndFiltering: MeldingInzamelingHAPagingAndFiltering;
  defaultPageSize: number;
}

const mapMeldingInzamelingHAFilterValues = (filters: Record<string, FilterValue | null>): MeldingFilters => ({
  ...mapMeldingFilterValues(filters),
  oorsprongPostcode: getFilterValues('oorsprong', filters)?.[0],
  inzamelwijzeCode: getFilterValues('inzamelwijze', filters)?.[0],
});

const MELDING_INZAMELING_HA_BASE_URL = '/melding/inzameling/huishoudelijk-afval';

const MeldingInzamelingHAOverview: React.FC<MeldingInzamelingHAOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const meldingenQuery = useMeldingenInzamelingHA(pagingAndFiltering);
  const deleteAllMutation = useDeleteAllSelectedMeldingenInzamelingHA();
  const { isBeheerder } = useRoleHook();

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapMeldingPaginationValues(pagination, defaultPageSize),
      ...mapMeldingInzamelingHAFilterValues(filters),
    });
  };

  const columns: ColumnsType<MeldingOverview> = [
    isBeheerder ? createTextInputFilterColumn<MeldingOverview>('Eigenaar', 'eigenaar', pagingAndFiltering.eigenaar) : null,
    {
      ...createTextInputFilterColumn<MeldingOverview>('Identificatie', 'identificatie', pagingAndFiltering.identificatie),
      render: (identificatie: string, melding: MeldingOverview) => <Link to={`${MELDING_INZAMELING_HA_BASE_URL}/${melding.referentie}`}>{identificatie}</Link>,
    },
    createTimeRangeFilterColumn<MeldingOverview>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot),
    {
      ...createTextInputFilterColumn<MeldingOverview>('Oorsprong', 'oorsprong', pagingAndFiltering.oorsprongPostcode),
      ellipsis: {
        showTitle: false,
      },
      render: (oorsprong: any) => (
        <Tooltip placement="topLeft" title={oorsprong}>
          {oorsprong}
        </Tooltip>
      ),
    },
    createTextInputFilterColumn<MeldingOverview>('Bestemming', 'bestemming', pagingAndFiltering.bestemming),
    createTonnageRangeFilterColumn<MeldingOverview>('Tonnage', 'tonnage', pagingAndFiltering.tonnageVan, pagingAndFiltering.tonnageTot),
    {
      ...createSelectWithSearchFilterColumn<MeldingOverview>('Materiaal', 'materiaal', 'afvalcodes', useAfvalCodeOptions, pagingAndFiltering.materiaalcode),
      ellipsis: {
        showTitle: false,
      },
      render: (materiaal: any) => (
        <Tooltip placement="topLeft" title={materiaal}>
          {materiaal}
        </Tooltip>
      ),
    },
    createSelectWithSearchFilterColumn<MeldingOverview>('Inzamelwijze', 'inzamelwijze', 'inzamelwijzes', useInzamelwijzeOptions, pagingAndFiltering.inzamelwijzeCode),
    createTimeRangeFilterColumn<MeldingOverview>('Laatst gewijzigd', 'versieTijdstip', true, pagingAndFiltering.versieTijdstipVan, pagingAndFiltering.versieTijdstipTot),
  ].flatMap((col) => (col ? [col] : []));

  const page = meldingenQuery.data;
  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toMeldingOverview),
      }
    : undefined;

  return (
    <MeldingOverviewTable
      title="Meldingen inzameling huishoudelijk afval"
      baseUrl={MELDING_INZAMELING_HA_BASE_URL}
      downloadUrl="/ui/meldingen/inzameling/huishoudelijk-afval/download"
      allowDownload={!isBeheerder}
      queryStatus={meldingenQuery}
      deleteAllMutation={deleteAllMutation}
      meldingFilters={pagingAndFiltering}
      columns={columns}
      defaultPageSize={defaultPageSize}
      page={mappedPage}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
    />
  );
};

export default MeldingInzamelingHAOverview;
