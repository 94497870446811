import { FieldInputProps, useField } from 'formik';
import { useCallback } from 'react';

export const useHandleSelect = (
  name: string,
  allowClear: boolean,
  fieldName: string,
): {
  handleClear: () => void;
  handleChange: (newValue: string, option: any) => void;
  field: FieldInputProps<any>;
} => {
  const [field, , valueHelpers] = useField<any>(fieldName);
  const [, , typeHelpers] = useField<any>(`${name}.type`);
  const handleChange = useCallback((newValue: any, option: any) => {
    valueHelpers.setValue(newValue);
    typeHelpers.setValue(option.group);
  }, []);
  const handleClear = useCallback(() => {
    if (allowClear) {
      valueHelpers.setValue('');
      typeHelpers.setValue('');
    }
  }, [allowClear]);
  return {
    field,
    handleChange,
    handleClear,
  };
};
