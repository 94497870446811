import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams } from 'react-router-dom';
import MeldingInzamelingHAUpdateForm from './melding-inzameling-ha-update-form';
import { useMeldingInzamelingHA } from './melding-detail.hook';
import DetailsLoading from '../components/details/details-loading';

const MeldingInzamelingHAUpdatePage: React.FC<any> = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: melding, isLoading } = useMeldingInzamelingHA(referentie);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/melding/inzameling/huishoudelijk-afval/${referentie}`}>{melding?.identificatie ?? '...'}</BreadcrumbsItem>
        {isLoading && <DetailsLoading />}
        {!isLoading && melding && <MeldingInzamelingHAUpdateForm melding={melding} />}
      </div>
    </section>
  );
};

export default MeldingInzamelingHAUpdatePage;
