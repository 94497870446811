import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import ContactgegevensSection from './contactgegevens-section';
import ContactgegevensForm from './contactgegevens-form';

export const ContactgegevensFormPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <BreadcrumbsItem to="/contactgegevens/correctie">Corrigeren</BreadcrumbsItem>
      <ContactgegevensSection
        render={(gegevens) => <ContactgegevensForm contactgegevens={gegevens} submitButtonLabel="Contactgegevens corrigeren" onUpdated={() => navigate(-1)} />}
      />
    </>
  );
};
