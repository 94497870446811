import { OorsprongType } from '../../generated';

export interface OorsprongFormValues {
  type: OorsprongType | '';
  vestigingsnummer: string;
  vestigingsnummerVerplicht?: boolean;
  btwNummer: string;
  ondernemingsnummer: string;
  naam: string;
  straat: string;
  huisnummer: string;
  uitbreiding: string;
  postcode: string;
  gemeente: string;
  land: string;
  commentaar: string;
}

export const oorsprongTypesVerwerking = [
  OorsprongType.BELGISCHE_VESTIGING,
  OorsprongType.BUITENLANDSE_VESTIGING,
  OorsprongType.TRADER,
  OorsprongType.INZAMELRONDE,
  OorsprongType.WERF,
  OorsprongType.GEEN_ONDERNEMING,
  OorsprongType.PARTICULIER,
] as const;

export const initialOorsprongValues: OorsprongFormValues = {
  type: '',
  vestigingsnummer: '',
  vestigingsnummerVerplicht: false,
  btwNummer: '',
  ondernemingsnummer: '',
  naam: '',
  straat: '',
  huisnummer: '',
  uitbreiding: '',
  postcode: '',
  gemeente: '',
  land: '',
  commentaar: '',
};

const checkedProp = (n: keyof OorsprongFormValues): string => n;

export const TYPE = checkedProp('type');
export const VESTIGINGSNUMMER = checkedProp('vestigingsnummer');
export const BTW_NUMMER = checkedProp('btwNummer');
export const NAAM = checkedProp('naam');
export const STRAAT = checkedProp('straat');
export const HUISNUMMER = checkedProp('huisnummer');
export const UITBREIDING = checkedProp('uitbreiding');
export const POSTCODE = checkedProp('postcode');
export const GEMEENTE = checkedProp('gemeente');
export const LAND = checkedProp('land');
export const COMMENTAAR = checkedProp('commentaar');
