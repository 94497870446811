import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import MeldingVerwerkingOverview from './melding-verwerking-overview';
import { MeldingVerwerkingPagingAndFiltering } from './melding.hook';
import MeldingVerwerkingDetailPage from './melding-verwerking-detail.page';
import MeldingVerwerkingCsvCreatePage from './melding-verwerking-csv-create-page';
import MeldingVerwerkingCsvUpdatePage from './melding-verwerking-csv-update-page';
import MeldingVerwerkingCreatePage from './melding-verwerking-create.page';
import MeldingVerwerkingUpdatePage from './melding-verwerking-update.page';
import { useAuthorisationInfo } from '../services/security/authorisation';
import { Scope } from '../generated';
import AuthorisedScope from '../general/authorised-scope';
import LoadingPage from '../general/loading-page';

const defaultPageSize = 100;

const MeldingVerwerkingOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<MeldingVerwerkingPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });
  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.AFVALVERWERKING}>
        <BreadcrumbsItem to="/melding/verwerking">Meldingen afvalverwerking</BreadcrumbsItem>
        <Routes>
          <Route path=":referentie/correctie" element={<MeldingVerwerkingUpdatePage />} />
          <Route path="csv" element={<Navigate to=".." />} />
          <Route path="csv-nieuw" element={<MeldingVerwerkingCsvCreatePage />} />
          <Route path="csv-aanvulling" element={<MeldingVerwerkingCsvUpdatePage />} />
          <Route path="nieuw" element={<MeldingVerwerkingCreatePage />} />
          <Route path=":referentie" element={<MeldingVerwerkingDetailPage />} />
          <Route path="/" element={<MeldingVerwerkingOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default MeldingVerwerkingOverviewPage;
