import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { login, logout } from '../../services/security/authentication';
import { Profile, ProfileLink } from '../profile/profile';
import AuthenticationContext from '../../services/security/authentication.context';
import { ActionSpinner } from '../../components/actionspinner/action-spinner';
import { EnvironmentTheme } from '../../components/theme/environment-theme';
import ContactInfoPopover from './contact-info-popover';

interface IAppHeaderProps {
  title: string;
}

interface HeaderPlugin {
  key: string;
  node: React.ReactNode;
}

const accentColorStyle = EnvironmentTheme.getEnvColorTheme((envColorTheme) => ({ '--accent-color': envColorTheme, '--accent-color-darker': envColorTheme }) as React.CSSProperties);
const environmentName = EnvironmentTheme.getName();

const AppHeader: React.FC<React.PropsWithChildren<IAppHeaderProps>> = ({ title, children }) => {
  const { busyAuthenticating, authenticated, gebruiker } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const headerPlugins: HeaderPlugin[] = useMemo(() => {
    if (busyAuthenticating) {
      return [{ key: 'actionSpinner', node: <ActionSpinner /> }];
    } else if (authenticated) {
      return [
        { key: 'authenticatedUserProfile', node: <Profile naam={gebruiker.naam} organisatie={gebruiker.organisatie} /> },
        {
          key: 'authenticatedUserProfileLink',
          node: (
            <Tooltip placement="bottom" title="Afmelden">
              <div>
                <ProfileLink
                  handleClick={() => {
                    navigate('/');
                    logout();
                  }}
                  dataCyPostfix="afmelden"
                >
                  <svg viewBox="0 0 32 32" role="presentation" className="logout-button">
                    <use href="#icon-logout" />
                  </svg>
                </ProfileLink>
              </div>
            </Tooltip>
          ),
        },
      ];
    } else {
      return [
        {
          key: 'unauthenticatedUserProfileLink',
          node: (
            <ProfileLink handleClick={login} dataCyPostfix="aanmelden">
              Aanmelden
            </ProfileLink>
          ),
        },
      ];
    }
  }, [busyAuthenticating, authenticated, gebruiker.naam, gebruiker.organisatie]);

  return (
    <div className="header-container">
      <div className="header-placeholder" />
      <header className="matis-header" role="presentation" style={accentColorStyle}>
        <div className="primary-bar">
          <div className="primary-bar__brand">
            <a href="https://www.vlaanderen.be" className="primary-bar__brand__umbrella" aria-label="Vlaanderen">
              <div className="primary_bar__brand__umbrella__logo">
                <svg viewBox="0 0 32 32" role="presentation" className="primary-bar__brand__umbrella__logo__icon">
                  <use href="#icon-flanders-logo" />
                </svg>
                <span className="primary-bar__brand__umbrella__logo__after" aria-hidden="true" />
                <span className="primary-bar__brand__umbrella__logo__label">Vlaanderen</span>
                <span className="primary-bar__brand__umbrella__logo__label__after" aria-hidden="true" />
              </div>
            </a>
            <a href="/" className="primary-bar__brand__host" data-cy="application-title">
              {title}
              {environmentName && (
                <div className="vl-pill vl-pill__environment-name">
                  <span className="vl-pill__text">{environmentName}</span>
                </div>
              )}
            </a>
          </div>
          <div className="primary-bar__plugins">
            {headerPlugins.map((headerPlugin) => (
              <div className="primary-bar__plugins__item" key={`header-plugin-${headerPlugin.key}`}>
                {headerPlugin.node}
              </div>
            ))}
            <div className="primary-bar__plugins__item primary-bar__plugins__item__contact">
              <ContactInfoPopover>
                <button type="button" className="contact__toggle">
                  <span className="contact__toggle__label">Hulp nodig</span>
                  <svg viewBox="0 0 32 32" aria-hidden="true" className="contact__toggle__icon">
                    <use href="#icon-question-outline" />
                  </svg>
                </button>
              </ContactInfoPopover>
            </div>
          </div>
        </div>
      </header>
      {children}
    </div>
  );
};

export default AppHeader;
