import React, { useEffect } from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { BTW_NUMMER, COMMENTAAR, GEMEENTE, HUISNUMMER, LAND, NAAM, ONDERNEMINGSNUMMER, POSTCODE, STRAAT, UITBREIDING, VESTIGINGSNUMMER } from './bestemming.model';
import { belgischBtwNummerValidationSchema } from '../../organisatie/btw-nummer.model';
import TextField from '../../components/form/text-field';
import TextArea from '../../components/form/text-area';
import BtwTextField from './btw-text-field';
import { prefixNamespace } from './use-land-hook';
import { BelgischePostcodeSelect, LandSelect } from '../melding-form-fields';
import Vestigingsnummer from '../../organisatie/vestigingsnummer.model';
import { isEmptyString } from '../../util/string-utils';
import OrganisatienaamField, { organisatienaamSchema } from '../../organisatie/organisatienaam-field';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import requiredField from '../../util/required-field';
import { ondernemingsnummerValidationSchema } from '../../organisatie/ondernemingsnummer.model';
import { Nullable } from '../../types/nullable';
import ModuloCheck from '../../organisatie/modulo-check.model';
import { belgischePostcodeSchema } from '../postcode.schema';

const markRequiredWhenVestigingsnummerIsOptionalAndEmpty = (message: string) => ({
  is: (vestigingsnummer: string, vestigingsnummerVerplicht: boolean) => isEmptyString(vestigingsnummer) && !vestigingsnummerVerplicht,
  then: (schema: Yup.StringSchema) => requiredField(schema, message),
  otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
});
const REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE = 'Verplicht veld wanneer vestigingsnummer niet is ingevuld';

export const vestigingsnummerValidatie = Yup.string()
  .trim()
  .when('vestigingsnummerVerplicht', {
    is: (vestigingsnummerVerplicht: boolean) => vestigingsnummerVerplicht,
    then: (schema: Yup.StringSchema) => requiredField(schema),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  })
  .test(
    'vestigingsnummer-starting-number',
    'Een vestigingsnummer start met een cijfer van 2 t.e.m 8',
    (value: string | undefined) => isEmptyString(value) || Vestigingsnummer.validateStartingNumber(value),
  )
  .test('vestigingsnummer-format', 'Formaat klopt niet: x.xxx.xxx.xxx', (value: string | undefined) => isEmptyString(value) || Vestigingsnummer.validateFormat(value))
  .test('vestigingsnummer-modulo', 'Modulo 97 klopt niet', (value: string | undefined) => isEmptyString(value) || ModuloCheck.isValid(value));

export const belgischeVestigingValidationSchema = Yup.object().shape(
  {
    [VESTIGINGSNUMMER]: vestigingsnummerValidatie,
    [BTW_NUMMER]: belgischBtwNummerValidationSchema.when(['vestigingsnummer', 'vestigingsnummerVerplicht', 'ondernemingsnummer'], {
      is: (vestigingsnummer: string, vestigingsnummerVerplicht: boolean, ondernemingsnummer: string) =>
        !vestigingsnummerVerplicht && isEmptyString(vestigingsnummer) && isEmptyString(ondernemingsnummer),
      then: (schema: Yup.StringSchema) => requiredField(schema, 'Verplicht veld wanneer vestigingsnummer en ondernemingsnummer niet zijn ingevuld'),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    }),
    [ONDERNEMINGSNUMMER]: ondernemingsnummerValidationSchema
      .when(['vestigingsnummer', 'vestigingsnummerVerplicht', 'btwNummer'], {
        is: (vestigingsnummer: string, vestigingsnummerVerplicht: boolean, btwNummer: string) =>
          !vestigingsnummerVerplicht && isEmptyString(vestigingsnummer) && isEmptyString(btwNummer),
        then: (schema: Yup.StringSchema) => requiredField(schema, 'Verplicht veld wanneer vestigingsnummer en BTW-nummer niet zijn ingevuld'),
        otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
      })
      .when('btwNummer', ([btwNummer], schema: Yup.StringSchema) =>
        schema.test({
          test: (ondernemingsnummer: Nullable<string>) => isEmptyString(btwNummer) || btwNummer.endsWith(((ondernemingsnummer || '').replaceAll(/\D/g, '') || '').trim()),
          message: 'Het ondernemingsnummer moet overeenkomen met het gekozen BTW-nummer',
        }),
      ),
    [NAAM]: organisatienaamSchema.when(
      ['vestigingsnummer', 'vestigingsnummerVerplicht'],
      markRequiredWhenVestigingsnummerIsOptionalAndEmpty(REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE),
    ),
    [STRAAT]: vrijeTekstSchema()
      .max(400, 'Straat kan niet meer dan 400 tekens bevatten')
      .when(['vestigingsnummer', 'vestigingsnummerVerplicht'], markRequiredWhenVestigingsnummerIsOptionalAndEmpty(REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE)),
    [HUISNUMMER]: vrijeTekstSchema()
      .max(80, 'Huis- en busnummer kan niet meer dan 80 tekens bevatten')
      .when(['vestigingsnummer', 'vestigingsnummerVerplicht'], markRequiredWhenVestigingsnummerIsOptionalAndEmpty(REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE)),
    [UITBREIDING]: vrijeTekstSchema().max(400, 'Adresuitbreiding kan niet meer dan 400 tekens bevatten'),
    [POSTCODE]: belgischePostcodeSchema.when(
      ['vestigingsnummer', 'vestigingsnummerVerplicht'],
      markRequiredWhenVestigingsnummerIsOptionalAndEmpty(REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE),
    ),
    [GEMEENTE]: vrijeTekstSchema()
      .max(400, 'Gemeente kan niet meer dan 400 tekens bevatten')
      .when(['vestigingsnummer', 'vestigingsnummerVerplicht'], markRequiredWhenVestigingsnummerIsOptionalAndEmpty(REQUIRED_WHEN_VESTIGINGSNUMMER_IS_EMPTY_MESSAGE)),
    [LAND]: Yup.string(),
    [COMMENTAAR]: vrijeTekstSchema().max(4000, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten'),
  },
  [['btwNummer', 'ondernemingsnummer']],
);

export interface IBelgischeVestigingFormProps {
  namespace: string;
}

const BelgischeVestigingForm: React.FC<IBelgischeVestigingFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);

  const [, , landHelpers] = useField(withNamespace(LAND));
  useEffect(() => {
    landHelpers.setValue('BE', false);
  }, []);

  return (
    <>
      <TextField label="Vestigingseenheid" name={withNamespace(VESTIGINGSNUMMER)} placeholder="2.123.456.789" />
      <BtwTextField label="BTW-nummer" name={withNamespace(BTW_NUMMER)} placeholder="BE0123456789" />
      <TextField label="Ondernemingsnummer" name={withNamespace(ONDERNEMINGSNUMMER)} />
      <OrganisatienaamField label="Naam" name={withNamespace(NAAM)} />
      <TextField label="Straat" name={withNamespace(STRAAT)} />
      <TextField label="Huis- en busnummer" name={withNamespace(HUISNUMMER)} />
      <TextField label="Adresuitbreiding" name={withNamespace(UITBREIDING)} />
      <BelgischePostcodeSelect label="Postcode" name={withNamespace(POSTCODE)} />
      <TextField label="Gemeente" name={withNamespace(GEMEENTE)} />
      <LandSelect label="Land" disabled name={withNamespace(LAND)} />
      <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
    </>
  );
};

export default BelgischeVestigingForm;
