import React, { PropsWithChildren } from 'react';
import { ActionSpinner } from '../components/actionspinner/action-spinner';

type LoadingPageProps = PropsWithChildren<{ isLoading: boolean }>;

const LoadingPage: React.FC<LoadingPageProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return <ActionSpinner />;
  }
  return <>{children}</>;
};

export default LoadingPage;
