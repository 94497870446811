import { string } from 'yup';

class Vestigingsnummer {
  value: string;

  constructor(value: string) {
    this.value = value?.replace(/\D/g, '');
  }

  static validateStartingNumber = (value: string | undefined): boolean =>
    string()
      .matches(/^[2-8]/gm)
      .isValidSync(value);

  static validateFormat = (value: string | undefined): boolean =>
    string()
      .matches(/^\d[.]\d{3}[.]\d{3}[.]\d{3}$/gm)
      .isValidSync(value);

  static validateModulo = (value: string | undefined): boolean => {
    if (value) {
      const cleaned = value.replace(/\./g, '');
      const num = Number(cleaned.substring(0, 8));
      const mod = Number(cleaned.substring(8));
      return 97 - (num % 97) === mod;
    }
    return false;
  };
}

export default Vestigingsnummer;
