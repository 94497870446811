import assertNever from '../../types/assert-never';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'error';

export const getClassForButtonType = (type: ButtonType) => {
  switch (type) {
    case 'primary':
      return '';
    case 'secondary':
      return 'vl-button--secondary';
    case 'tertiary':
      return 'vl-button--tertiary';
    case 'error':
      return 'vl-button--error';
    default:
      return assertNever(type);
  }
};
