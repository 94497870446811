import React from 'react';
import formatDate from '../../util/date-formatting';
import { GebruikerDetailsJsonResponse } from '../../generated';
import { GebruikerInfo } from './gebruiker-info';

interface DetailHeaderProps {
  creatieTijdstip: string;
  versieTijdstip: string;
  versieGebruiker: GebruikerDetailsJsonResponse;
  versie?: number;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({ creatieTijdstip, versieTijdstip, versieGebruiker, versie }) => (
  <div className="vl-description-data">
    <dl className="vl-grid vl-grid--is-stacked">
      <div className="vl-col--1-3">
        <dt className="vl-description-data__label">Aangemaakt op</dt>
        <dd className="vl-description-data__value" data-cy="aangemaakt-op">
          {formatDate(creatieTijdstip)}
        </dd>
      </div>
      <div className="vl-col--1-3">
        <dt className="vl-description-data__label">Laatst gewijzigd op</dt>
        <dd className="vl-description-data__value ">
          <span data-cy="versie-tijdstip">{formatDate(versieTijdstip)}</span>
          {versie && (
            <span className="vl-description-data__subdata" data-cy="versie">
              {' '}
              Versie {versie}
            </span>
          )}
        </dd>
      </div>
      <div className="vl-col--1-3">
        <dt className="vl-description-data__label">Laatst gewijzigd door</dt>
        <dd className="vl-description-data__value ">
          <GebruikerInfo gebruiker={versieGebruiker} organisatie={versieGebruiker.organisatienaam} />
        </dd>
      </div>
    </dl>
  </div>
);

export default DetailHeader;
