import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UseMutationResult } from 'react-query/types/react/types';
import { bestemmingValidationSchema } from './bestemming/bestemming-form';
import { createBtwNummerValidationSchema } from '../organisatie/btw-nummer.model';
import AlertWithContact from '../components/alert/alert-with-contact';
import CancelButton from '../components/button/cancel-button';
import SubmitButton from '../components/button/submit-button';
import { BestemmingFormValues, initialBestemmingValues } from './bestemming/bestemming.model';
import { ApiError } from '../util/axios-error-mapping';
import InzamelwijzeCode from './inzamelwijze.model';
import { periodeWaardeValidationSchema, tonnageValidationSchema } from './melding-form';
import ValidatieFoutenAlert from '../components/alert/validatie-fouten-alert';
import { initialOorsprongValues, OorsprongFormValues } from './bestemming/oorsprong.model';
import { vrijeTekstSchema } from './vrije-tekst-schema';
import requiredField from '../util/required-field';
import ErrorAlert from '../components/alert/error-alert';
import { MeerdereOorsprongen, MeldingInzamelingHAJsonRequest, ValidatieFout, ValidationResponseMeldingInzamelingHAJsonRequest } from '../generated';
import Alert from '../components/alert/alert';
import { isVlarema9VanToepassing } from './periode.model';
import { ScrollToFieldError } from './scroll-to-field-error';

export interface MeldingInzamelingHAFormValues {
  identificatie: string;
  eigenaar?: string;
  oorsprong: OorsprongFormValues;
  bestemming: BestemmingFormValues;
  meerdereOorsprongen?: MeerdereOorsprongen;
  ihmBtwNummer: string;
  afvalcode: string;
  materiaalOmschrijving: string;
  dierlijkBijproduct: string;
  periodeEenheid: string;
  periodeWaarde: string;
  tonnage: string;
  inzamelwijzeCode: string;
  inzamelwijzeCommentaar: string;
  verwerkingswijze: string;
}

export const defaultMeldingFormValues: MeldingInzamelingHAFormValues = {
  identificatie: '',
  eigenaar: '',
  oorsprong: {
    ...initialOorsprongValues,
  },
  bestemming: {
    ...initialBestemmingValues,
  },
  meerdereOorsprongen: MeerdereOorsprongen.ONBEKEND,
  ihmBtwNummer: '',
  afvalcode: '',
  materiaalOmschrijving: '',
  dierlijkBijproduct: '',
  periodeEenheid: '',
  periodeWaarde: '',
  tonnage: '',
  inzamelwijzeCode: '',
  inzamelwijzeCommentaar: '',
  verwerkingswijze: '',
};

export interface IErrorMessageFlags {
  validatieErrors: ValidatieFout[];
}

export interface MeldingInzamelingHAFormProps {
  mutateFn: (request: MeldingInzamelingHAJsonRequest) => Promise<AxiosResponse>;
  initialValues: MeldingInzamelingHAFormValues;
  validationSchema: Yup.ObjectSchema<any>;
  submitLabel: string;
  cancelLink: string;
}

interface MeldingsInzamelingHAInnerFormProps {
  mutation: UseMutationResult<AxiosResponse<ValidationResponseMeldingInzamelingHAJsonRequest>, Error | ApiError, MeldingInzamelingHAJsonRequest>;
  errorMessageFlags: IErrorMessageFlags;
  submitLabel: string;
  cancelLink: string;
  formik: FormikProps<MeldingInzamelingHAFormValues>;
}

const createMeldingInzamelingHARequest = (values: FormikValues): MeldingInzamelingHAJsonRequest => ({
  identificatie: values.identificatie?.trim() || null,
  eigenaar: values.eigenaar?.trim() || null,
  oorsprong: {
    postcode: values.oorsprong.postcode?.trim(),
  },
  bestemming: {
    type: values.bestemming.type?.trim(),
    vestigingsnummer: values.bestemming.vestigingsnummer ? values.bestemming.vestigingsnummer.replaceAll('.', '')?.trim() : null,
    btwNummer: values.bestemming.btwNummer?.trim() || null,
    ondernemingsnummer: values.bestemming.ondernemingsnummer?.trim() || null,
    naam: values.bestemming.naam?.trim() || null,
    straat: values.bestemming.straat?.trim() || null,
    huisnummer: values.bestemming.huisnummer?.trim() || null,
    uitbreiding: values.bestemming.uitbreiding?.trim() || null,
    postcode: values.bestemming.postcode?.trim() || null,
    gemeente: values.bestemming.gemeente?.trim() || null,
    land: values.bestemming.land?.trim() || null,
    commentaar: values.bestemming.commentaar?.trim() || null,
  },
  meerdereOorsprongen: values.meerdereOorsprongen?.trim(),
  verwerkingswijze: values.verwerkingswijze?.trim() || null,
  ihm: {
    btwNummer: values.ihmBtwNummer?.trim() || null,
  },
  periode: {
    eenheid: values.periodeEenheid?.trim(),
    waarde: values.periodeWaarde?.trim(),
  },
  tonnage: values.tonnage?.trim(),
  materiaalidentificatie: {
    afvalcode: values.afvalcode?.trim(),
    materiaalOmschrijving: values.materiaalOmschrijving?.trim() || null,
    dierlijkBijproduct: values.dierlijkBijproduct || null,
  },
  inzamelwijze: {
    code: values.inzamelwijzeCode?.trim(),
    commentaar: values.inzamelwijzeCommentaar?.trim() || null,
  },
});

export const createMeldingInzamelingHARequestValidationSchema = (kanVerdelen: boolean) =>
  Yup.object().shape({
    oorsprong: Yup.object().shape({
      postcode: requiredField(vrijeTekstSchema()),
    }),
    bestemming: bestemmingValidationSchema,
    ...(kanVerdelen ? { meerdereOorsprongen: requiredField() } : {}),
    ihmBtwNummer: createBtwNummerValidationSchema(),
    afvalcode: requiredField(),
    materiaalOmschrijving: vrijeTekstSchema().nullable().max(1000, 'Materiaal omschrijving kan niet meer dan 1000 tekens bevatten'),
    periodeEenheid: requiredField(),
    periodeWaarde: periodeWaardeValidationSchema,
    tonnage: tonnageValidationSchema,
    inzamelwijzeCode: requiredField(),
    inzamelwijzeCommentaar: vrijeTekstSchema()
      .max(4000, 'Commentaar inzamelwijze kan niet meer dan 4000 tekens bevatten')
      .when('inzamelwijzeCode', ([code], schema: Yup.StringSchema) => (InzamelwijzeCode.isAndere(code) ? requiredField(schema) : schema)),
    verwerkingswijze: Yup.string().when(['periodeEenheid', 'periodeWaarde'], {
      is: (periodeEenheid: string, periodeWaarde: string) => isVlarema9VanToepassing(periodeEenheid, periodeWaarde),
      then: (schema: Yup.StringSchema) => requiredField(schema),
      otherwise: (schema: Yup.StringSchema) => schema.optional(),
    }),
  });

function disableSubmit({ values, initialValues }: FormikProps<MeldingInzamelingHAFormValues>) {
  return JSON.stringify(values) === JSON.stringify(initialValues);
}

const PERIODE_VALIDATED_NEW_VALUE_ERROR_CODE = 'periode.validated.newValue';
const INZAMELRECHT_HA_TOEGEKEND_ERROR_CODE = 'inzamelrecht_ha.toegekend';

const MeldingInzamelingHAInnerForm: React.FC<React.PropsWithChildren<MeldingsInzamelingHAInnerFormProps>> = ({
  mutation,
  errorMessageFlags,
  submitLabel,
  cancelLink,
  formik,
  children,
}) => {
  const validatiefouten = errorMessageFlags.validatieErrors.filter(({ code }) => ![PERIODE_VALIDATED_NEW_VALUE_ERROR_CODE, INZAMELRECHT_HA_TOEGEKEND_ERROR_CODE].includes(code!!));
  const periodeInvalidError = errorMessageFlags.validatieErrors.find(({ code }) => code === PERIODE_VALIDATED_NEW_VALUE_ERROR_CODE);
  const rechtInvalidError = errorMessageFlags.validatieErrors.find(({ code }) => code === INZAMELRECHT_HA_TOEGEKEND_ERROR_CODE);

  return (
    <Form spellCheck={false}>
      {!!periodeInvalidError && (
        <Alert type="error" title="Validatiefout" scrollIntoView>
          {`Melding met periode waarde voorafgaand aan ${periodeInvalidError?.parameters?.[0]} kan u niet meer doorgeven`}
        </Alert>
      )}
      {!!rechtInvalidError && (
        <AlertWithContact type="error" title="Validatiefout" scrollIntoView>
          <p>{`Uw organisatie heeft geen recht om te melden voor periode ${rechtInvalidError?.parameters?.[0]} in combinatie met afvalcode ${rechtInvalidError?.parameters?.[1]}, oorsprong ${rechtInvalidError?.parameters?.[2]} en inzamelwijze ${rechtInvalidError?.parameters?.[3]}.`}</p>
        </AlertWithContact>
      )}
      {mutation.isError && (
        <ErrorAlert error={mutation.error} defaultMessage="Er is een fout opgetreden bij het ingeven van de melding. Gelieve later nog eens opnieuw te proberen." scrollIntoView />
      )}
      {validatiefouten.length > 0 && <ValidatieFoutenAlert errors={validatiefouten} />}
      <div className="vl-form-grid vl-form-grid--is-stacked">
        {children}

        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <SubmitButton label={submitLabel} isSubmitting={mutation.isLoading} isDisabled={disableSubmit(formik) || mutation.isLoading} />
            <CancelButton link={cancelLink} isDisabled={mutation.isLoading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export const MeldingInzamelingHAForm: React.FC<React.PropsWithChildren<MeldingInzamelingHAFormProps>> = ({
  mutateFn,
  cancelLink,
  initialValues,
  validationSchema,
  submitLabel,
  children,
}) => {
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<ValidationResponseMeldingInzamelingHAJsonRequest>, Error | ApiError, MeldingInzamelingHAJsonRequest, unknown>((values) =>
    mutateFn(values),
  );

  const [errorMessageFlags, setErrorMessageFlags] = useState<IErrorMessageFlags>({
    validatieErrors: [],
  });

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<MeldingInzamelingHAFormValues>) => {
    const request = createMeldingInzamelingHARequest(values);
    try {
      const {
        data: { validatieFouten },
      } = await mutation.mutateAsync(request);
      if (!validatieFouten || validatieFouten?.length > 0) {
        setErrorMessageFlags((prevState) => ({
          ...prevState,
          validatieErrors: validatieFouten ?? [],
        }));
        window.scrollTo(0, 0);
        setSubmitting(false);
      } else {
        navigate(cancelLink);
      }
    } finally {
      if (mutation.isError) {
        window.scrollTo(0, 0);
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnBlur={false}>
      {(formik) => (
        <>
          <ScrollToFieldError />
          <MeldingInzamelingHAInnerForm mutation={mutation} errorMessageFlags={errorMessageFlags} submitLabel={submitLabel} cancelLink={cancelLink} formik={formik}>
            {children}
          </MeldingInzamelingHAInnerForm>
        </>
      )}
    </Formik>
  );
};
