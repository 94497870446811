import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { Pageable } from '../components/table/pageable';
import { Page } from '../components/table/page';
import { InzamelrechtHADetailsJsonResponse, InzamelrechtHAFiltersJsonRequest, InzamelrechtHAOverviewJsonResponse } from '../generated';
import { axiosParamsSerializer } from '../util/axios-params-serializer';

export const inzamelrechtenBaseUrl = '/ui/inzamelrechten';

export interface InzamelrechtHAPagingAndFiltering extends Pageable, InzamelrechtHAFiltersJsonRequest {}

export const useInzamelrechtenHA = (params: InzamelrechtHAPagingAndFiltering): UseQueryResult<Page<InzamelrechtHAOverviewJsonResponse>, Error> => {
  const inzamelrechtHAUrl = `${inzamelrechtenBaseUrl}/huishoudelijk-afval`;
  return useQuery<Page<InzamelrechtHAOverviewJsonResponse>, Error>([inzamelrechtHAUrl, params], async () => {
    const { data } = await axios.get<Page<InzamelrechtHAOverviewJsonResponse>>(inzamelrechtHAUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });
};

export const useInzamelrechtHA = (referentie: string): UseQueryResult<InzamelrechtHADetailsJsonResponse, Error> => {
  const inzamelrechtHAUrl = `${inzamelrechtenBaseUrl}/huishoudelijk-afval/${referentie}`;
  return useQuery<InzamelrechtHADetailsJsonResponse, Error>(inzamelrechtHAUrl, async () => {
    const { data } = await axios.get<InzamelrechtHADetailsJsonResponse>(inzamelrechtHAUrl);
    return data;
  });
};

export const useDeleteInzamelrechtHA = (): UseMutationResult<AxiosResponse, Error, InzamelrechtHAOverviewJsonResponse> =>
  useMutation<AxiosResponse, Error, InzamelrechtHAOverviewJsonResponse, unknown>((r) => axios.delete(`${inzamelrechtenBaseUrl}/huishoudelijk-afval/${r.referentie}`));
