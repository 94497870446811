import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import {
  MeerdereOorsprongen,
  MeldingInzamelingHADetailsJsonResponse,
  MeldingVerwerkingDetailsJsonResponse,
  Toepassingswijze,
  Vervoerswijze,
  VerwerkingswijzeCode,
} from '../generated';

export const verwerkingswijzeTypes = Object.values(VerwerkingswijzeCode);
export const verwerkingswijzeLabels: { [key: string]: string } = {
  [VerwerkingswijzeCode.ANDERE_AFVALVERBRANDING]: 'Andere afvalverbranding',
  [VerwerkingswijzeCode.ANDERE_VOORBEHANDELING]: 'Andere voorbehandeling',
  [VerwerkingswijzeCode.COMPOSTEREN_VERGISTEN]: 'Composteren vergisten',
  [VerwerkingswijzeCode.DROGEN_SCHEIDEN]: 'Drogen-scheiden',
  [VerwerkingswijzeCode.HERGEBRUIK]: 'Hergebruik',
  [VerwerkingswijzeCode.OP_EN_OVERSLAG]: 'Op- en overslag',
  [VerwerkingswijzeCode.RECYCLAGE]: 'Recyclage',
  [VerwerkingswijzeCode.SORTEREN]: 'Sorteren',
  [VerwerkingswijzeCode.STORTEN]: 'Storten',
  [VerwerkingswijzeCode.VERBRANDEN_MET_ENERGIERECUPERATIE]: 'Verbranden met energierecuperatie',
};

export const toepassingswijzeTypes = Object.values(Toepassingswijze);
export const vervoerswijzesTypes = Object.values(Vervoerswijze);
export const meerdereOorsprongen = Object.values(MeerdereOorsprongen);

export const displayClean = (value?: string) => {
  if (value && value.length > 1) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().replaceAll('_', ' ');
  }
  return value || '';
};

export const useMeldingInzamelingHA = (referentie: string): UseQueryResult<MeldingInzamelingHADetailsJsonResponse, Error> => {
  const meldingenUrl = `/ui/meldingen/inzameling/huishoudelijk-afval/${referentie}`;
  return useQuery<MeldingInzamelingHADetailsJsonResponse, Error>(meldingenUrl, async () => {
    const { data } = await axios.get<MeldingInzamelingHADetailsJsonResponse>(meldingenUrl);
    return data;
  });
};

export const useMeldingVerwerking = (referentie: string): UseQueryResult<MeldingVerwerkingDetailsJsonResponse, Error> => {
  const meldingenUrl = `/ui/meldingen/verwerking/${referentie}`;
  return useQuery<MeldingVerwerkingDetailsJsonResponse, Error>(meldingenUrl, async () => {
    const { data } = await axios.get<MeldingVerwerkingDetailsJsonResponse>(meldingenUrl);
    return data;
  });
};
