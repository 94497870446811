/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum VerwerkingswijzeCode {
  ANDERE_AFVALVERBRANDING = 'ANDERE_AFVALVERBRANDING',
  ANDERE_VOORBEHANDELING = 'ANDERE_VOORBEHANDELING',
  COMPOSTEREN_VERGISTEN = 'COMPOSTEREN_VERGISTEN',
  DROGEN_SCHEIDEN = 'DROGEN_SCHEIDEN',
  HERGEBRUIK = 'HERGEBRUIK',
  OP_EN_OVERSLAG = 'OP_EN_OVERSLAG',
  RECYCLAGE = 'RECYCLAGE',
  SORTEREN = 'SORTEREN',
  STORTEN = 'STORTEN',
  VERBRANDEN_MET_ENERGIERECUPERATIE = 'VERBRANDEN_MET_ENERGIERECUPERATIE',
}
