import React from 'react';
import cx from 'classnames';

interface SubmitButtonProps {
  label: string;
  className?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  isNarrow?: boolean;
  onClick?: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ label, className, isSubmitting = false, isDisabled = false, isNarrow = false, onClick }) => (
  <button
    type="submit"
    disabled={isDisabled}
    className={cx('vl-button', className, {
      'vl-button--disabled': isDisabled,
      'vl-button--loading': isSubmitting,
      'vl-button--narrow': isNarrow,
    })}
    onClick={onClick}
  >
    <span className="vl-button__label">{label}</span>
  </button>
);

export default SubmitButton;
