import { useContext } from 'react';
import AuthenticationContext from './authentication.context';
import { Scope } from '../../generated';

export interface RoleHookResponse {
  authorisedScopes: Scope[];
  isBeheerder: boolean;
  isMelder: boolean;
}

export const bevatRol = (rolTeZoeken: string, rollen: string[]) => rollen.some((rol) => rol.toLowerCase() === rolTeZoeken);
export const bevatRolBeheerder = (rollen: string[]) => bevatRol('beheerder', rollen);
export const bevatRolMelder = (rollen: string[]) => bevatRol('melder', rollen);

const supportedRoles: string[] = ['lezer', 'melder', 'beheerder'];
export const extractSupportedRoles = (roles: string[]) => roles.filter((role) => supportedRoles.includes(role.toLowerCase()));

export const useRoleHook = (): RoleHookResponse => {
  const { rollen, authorisedScopes } = useContext(AuthenticationContext);

  return {
    authorisedScopes,
    isBeheerder: bevatRolBeheerder(rollen),
    isMelder: bevatRolMelder(rollen),
  };
};
