import { AxiosResponse } from 'axios';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues, useField } from 'formik';
import * as Yup from 'yup';
import { UseMutationResult } from 'react-query/types/react/types';
import dayjs, { Dayjs } from 'dayjs';
import FormTitle from '../components/form/form-title';
import Checkbox from '../components/form/checkbox';
import { VerdelingsgebiedMultiSelect } from './organisatie-form-fields';
import SubmitButton from '../components/button/submit-button';
import CancelButton from '../components/button/cancel-button';
import { ApiError } from '../util/axios-error-mapping';
import TextField from '../components/form/text-field';
import { validatieSchemaOrganisatiecodeVrijeTekst } from '../organisatie/organisatiecode.model';
import ErrorAlert from '../components/alert/error-alert';
import { OrganisatieJsonRequest, OrganisatieJsonResponse } from '../generated';
import DateField from '../components/form/date-field';
import { MELDING_START_DATUM } from '../melding/melding.config';
import { formHasChanges } from '../util/form-has-changes';
import { VERPLICHT_VELD_MESSAGE } from '../util/required-field';
import emailadresField from '../util/emailadres-field';
import { formats } from '../melding/periode.model';

export interface OrganisatieFormValues {
  organisatiecode: string;
  organisatienaam: string;
  emailadres?: string;
  inzamelaarHuishoudelijkAfval: boolean;
  verdeler: boolean;
  extraCodesVoorVerdeling: Array<string>;
  meldingInzamelingVanaf?: Dayjs | null;
  inzamelaarBedrijfsafval: boolean;
}

interface OrganisatieFormProps {
  mutateFn: (request: OrganisatieJsonRequest) => Promise<AxiosResponse>;
  cancelLink: string;
  submitLabel: string;
  initialValues: OrganisatieFormValues;
}

interface OrganisatieInnerFormProps {
  mutation: UseMutationResult<AxiosResponse<OrganisatieJsonResponse>, Error | ApiError, OrganisatieJsonRequest>;
  initialValues: OrganisatieFormValues;
  submitLabel: string;
  cancelLink: string;
  formik: FormikProps<OrganisatieFormValues>;
}

const createOrganisatieValidationSchema = (): Yup.ObjectSchema<any> =>
  Yup.object().shape({
    inzamelaarHuishoudelijkAfval: Yup.boolean(),
    organisatiecode: validatieSchemaOrganisatiecodeVrijeTekst('Organisatiecode'),
    emailadres: emailadresField(),
    meldingInzamelingVanaf: Yup.date()
      .nullable()
      .when('inzamelaarHuishoudelijkAfval', ([value], schema) => (value ? schema.required(VERPLICHT_VELD_MESSAGE) : schema)),
  });

const validationSchema = createOrganisatieValidationSchema();

const meldingStartDatum = dayjs(MELDING_START_DATUM, formats.DAG);

const OrganisatieInnerForm: React.FC<OrganisatieInnerFormProps> = ({ mutation, initialValues, submitLabel, cancelLink, formik }) => {
  const [inzamelaarHuishoudelijkAfvalField, , inzamelaarHuishoudelijkAfvalHelpers] = useField('inzamelaarHuishoudelijkAfval');
  const [verdelerField, , verdelerHelpers] = useField('verdeler');
  const [, , meldingInzamelingVanafHelpers] = useField('meldingInzamelingVanaf');

  useEffect(() => {
    if (!inzamelaarHuishoudelijkAfvalField.value) {
      verdelerHelpers.setValue(false);
      meldingInzamelingVanafHelpers.setValue(null);
    }
  }, [inzamelaarHuishoudelijkAfvalField.value]);

  useEffect(() => {
    if (verdelerField.value) {
      inzamelaarHuishoudelijkAfvalHelpers.setValue(true);
    }
  }, [verdelerField.value]);

  const isDateAfterMeldingStartDatum = (date: Dayjs) => date.isBefore(meldingStartDatum);

  return (
    <Form spellCheck={false}>
      {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het opslaan van de organisatie is mislukt." />}
      <div className="vl-form-grid vl-form-grid--is-stacked">
        <FormTitle title="Organisatie" />
        <TextField label="Organisatiecode" name="organisatiecode" required disabled={!!initialValues.organisatiecode} />
        <TextField label="Organisatienaam" name="organisatienaam" disabled />
        <TextField label="E-mailadres" name="emailadres" />

        <FormTitle title="Huishoudelijk afval" />
        <Checkbox label="Inzamelaar" name="inzamelaarHuishoudelijkAfval" />
        {inzamelaarHuishoudelijkAfvalField.value && <DateField label="Mag melden vanaf" name="meldingInzamelingVanaf" required disabledDate={isDateAfterMeldingStartDatum} />}
        <Checkbox label="Verdeler" name="verdeler" />
        {verdelerField.value && <VerdelingsgebiedMultiSelect />}

        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <SubmitButton label={submitLabel} isSubmitting={mutation.isLoading} isDisabled={!formHasChanges(formik)} />
            <CancelButton link={cancelLink} isDisabled={mutation.isLoading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export const OrganisatieForm: React.FC<OrganisatieFormProps> = ({ mutateFn, cancelLink, submitLabel, initialValues }) => {
  const navigate = useNavigate();
  const organisatiecodeOnLastSubmit = useRef(null);

  const mutation = useMutation<AxiosResponse<OrganisatieJsonResponse>, Error | ApiError, OrganisatieJsonRequest, unknown>((request) => mutateFn(request));

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    try {
      organisatiecodeOnLastSubmit.current = values.organisatiecode;
      const request: OrganisatieJsonRequest = {
        organisatiecode: values.organisatiecode?.trim() || undefined,
        emailadres: values.emailadres?.trim() || undefined,
      };
      if (values.inzamelaarHuishoudelijkAfval) {
        request.meldingInzamelingVanaf = values.meldingInzamelingVanaf ? values.meldingInzamelingVanaf.format(formats.DAG) : null;
      }
      if (values.verdeler) {
        request.verdelingsgebied = [...values.extraCodesVoorVerdeling];
      }
      const { data: organisatie } = await mutation.mutateAsync(request);
      navigate(`/administratie/${organisatie.organisatiecode}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik validateOnBlur={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => <OrganisatieInnerForm mutation={mutation} initialValues={initialValues} submitLabel={submitLabel} cancelLink={cancelLink} formik={formik} />}
    </Formik>
  );
};
