import React from 'react';
import cx from 'classnames';

interface SecondaryButtonProps {
  label: string;
  className?: string;
  isDisabled?: boolean;
  dataCy?: string;
  onClick?: () => void;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ label, className, isDisabled, dataCy, onClick }) => (
  <button
    type="button"
    className={cx('vl-button', 'vl-button--secondary', className, isDisabled ? 'vl-button--disabled' : '')}
    disabled={isDisabled}
    onClick={onClick}
    data-cy={dataCy}
  >
    <span className="vl-button__label">{label}</span>
  </button>
);

export default SecondaryButton;
