import { AxiosResponse } from 'axios';
import React from 'react';
import { UseMutationResult } from 'react-query/types/react/types';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues, useField } from 'formik';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ApiError } from '../util/axios-error-mapping';
import { parseKwartaal, patterns, periodePlaceholder } from '../melding/periode.model';
import { isEmptyString } from '../util/string-utils';
import { MELDING_START_DATUM } from '../melding/melding.config';
import requiredField from '../util/required-field';
import ErrorAlert from '../components/alert/error-alert';
import FormTitle from '../components/form/form-title';
import TextField from '../components/form/text-field';
import SubmitButton from '../components/button/submit-button';
import { formHasChanges } from '../util/form-has-changes';
import CancelButton from '../components/button/cancel-button';
import { useRoleHook } from '../services/security/use-role-hook';
import { PeriodeEenheid, PeriodeZonderMeldingJsonRequest, PeriodeZonderMeldingJsonResponse } from '../generated';
import { vestigingsnummerValidatie } from '../melding/bestemming/belgische-vestiging-form';
import Radio from '../components/form/radio';
import Disclaimer from '../components/disclaimer/disclaimer';
import { ondernemingsnummerValidationSchema } from '../organisatie/ondernemingsnummer.model';

export interface PeriodeZonderMeldingFormValues {
  referentie?: string;
  eigenaar?: string;
  onderdeel: string;
  vestigingsnummer?: string;
  periodeVan: string;
  periodeTot: string;
  motivering: string;
  andereMotivering?: string;
  medeExploitant?: string;
}

interface PeriodeZonderMeldingFormProps {
  mutateFn: (request: PeriodeZonderMeldingJsonRequest) => Promise<AxiosResponse>;
  cancelLink: string;
  submitLabel: string;
  initialValues: PeriodeZonderMeldingFormValues;
}

interface PeriodeZonderMeldingInnerFormProps {
  mutation: UseMutationResult<AxiosResponse<PeriodeZonderMeldingJsonResponse>, Error | ApiError, PeriodeZonderMeldingJsonRequest>;
  submitLabel: string;
  cancelLink: string;
  formik: FormikProps<PeriodeZonderMeldingFormValues>;
}

const kwartaalValidationSchema = Yup.string()
  .trim()
  .matches(patterns[PeriodeEenheid.KWARTAAL], 'Ongeldig kwartaal')
  .test(
    'periode-vanaf-2020-Q1',
    'Periode start vanaf 2020-Q1',
    ((value: string) => isEmptyString(value) || !parseKwartaal(value).isBefore(dayjs(MELDING_START_DATUM))) as TestFunction<any, any>,
  );

const createValidationSchema = (): Yup.ObjectSchema<any> =>
  Yup.object().shape({
    onderdeel: requiredField(),
    periodeVan: requiredField(kwartaalValidationSchema),
    periodeTot: requiredField(
      kwartaalValidationSchema.when('periodeVan', ([periodeVan], schema) =>
        periodeVan
          ? schema.test(
              'van-before-tot',
              'Periode tot mag niet voor Periode van liggen',
              ((value: string) => isEmptyString(value) || !parseKwartaal(value).isBefore(parseKwartaal(periodeVan))) as TestFunction<any, any>,
            )
          : schema,
      ),
    ),
    motivering: requiredField(),
    vestigingsnummer: vestigingsnummerValidatie,
    medeExploitant: ondernemingsnummerValidationSchema,
  });

const validationSchema = createValidationSchema();

export const onderdeelOptions = [
  {
    label: 'Inzameling huishoudelijk afval',
    value: 'HA',
  },
  {
    label: 'Inzameling bedrijfsafval',
    value: 'BA',
  },
  {
    label: 'Afvalverwerking',
    value: 'VW',
  },
];

export const motiveringOptions = [
  {
    label: 'De activiteiten zijn nog niet opgestart',
    value: 'NIET_OPGESTART',
  },
  {
    label: 'Tijdelijk geen afvalinzameling',
    value: 'GEEN_INZAMELING',
  },
  {
    label: 'Tijdelijk geen aan- of afvoer van afval en geen afvoer van grondstoffen',
    value: 'GEEN_AFVOER',
  },
  {
    label: 'Een mede-exploitant van de vergunning meldt alle gegevens in MATIS',
    value: 'MEDE_EXPLOITANT',
  },
  {
    label: 'Andere',
    value: 'ANDERE',
  },
];

export const motiveringOptionsVerwerking = motiveringOptions.filter((m) => m.value !== 'GEEN_INZAMELING');

export const motiveringOptionsAfval = motiveringOptions.filter((m) => m.value !== 'GEEN_AFVOER' && m.value !== 'MEDE_EXPLOITANT');

export const mapMotivering = (motivering: string) =>
  motiveringOptionsAfval
    .concat(motiveringOptionsVerwerking)
    .filter((m) => motivering === m.value)
    .map((m) => m.label)[0];

export const mapOnderdeel = (onderdeel: string) => onderdeelOptions.filter((o) => onderdeel === o.value).map((o) => o.label)[0];

const PeriodeZonderMeldingInnerForm: React.FC<PeriodeZonderMeldingInnerFormProps> = ({ mutation, submitLabel, cancelLink, formik }) => {
  const { isBeheerder } = useRoleHook();
  const [onderdeelField] = useField('onderdeel');
  const [motiveringField] = useField('motivering');
  const disclaimerText =
    '"Alle geregistreerde IHM\'s en alle exploitanten van een vergunde inrichting in het Vlaamse Gewest zijn verplicht om driemaandelijks aan de OVAM te melden over ' +
    "de afvalstoffen die ze hebben ingezameld of verwerkt. In uitzonderlijke omstandigheden kan u aan deze verplichting voldoen door een 'Periode zonder melding' " +
    'door te geven. U bent verplicht om dit te motiveren. U kan hier geen uitstel vragen."';

  return (
    <Form spellCheck={false}>
      {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het opslaan van de Periode Zonder Melding is mislukt." />}
      <div className="vl-form-grid vl-form-grid--is-stacked">
        <FormTitle title="Periode zonder melding" />

        {isBeheerder && <TextField label="Organisatiecode" name="eigenaar" required disabled={!!formik.initialValues.eigenaar} />}
        <Radio
          options={onderdeelOptions}
          label="Onderdeel"
          name="onderdeel"
          required
          onChange={(e) => {
            const validMotiveringen = e.target.value === 'VW' ? motiveringOptionsVerwerking : motiveringOptionsAfval;
            formik.setValues({
              ...formik.values,
              onderdeel: e.target.value,
              motivering: validMotiveringen.some((option) => option.value === formik.values.motivering) ? formik.values.motivering : '',
            });
          }}
        />
        {onderdeelField.value === 'VW' && <TextField label="Vestigingsnummer" name="vestigingsnummer" required />}
        <TextField label="Periode van" name="periodeVan" required placeholder={periodePlaceholder(PeriodeEenheid.KWARTAAL)} valueMapper={(v) => v.toUpperCase()} />
        <TextField label="Periode tot" name="periodeTot" required placeholder={periodePlaceholder(PeriodeEenheid.KWARTAAL)} valueMapper={(v) => v.toUpperCase()} />
        <Radio options={onderdeelField.value === 'VW' ? motiveringOptionsVerwerking : motiveringOptionsAfval} label="Motivering" name="motivering" vertical required />
        {motiveringField.value === 'ANDERE' && <TextField label="Andere motivering" name="andereMotivering" required />}
        {motiveringField.value === 'MEDE_EXPLOITANT' && <TextField label="Ondernemingsnummer mede-exploitant" name="medeExploitant" required />}
        <Disclaimer text={disclaimerText} />
        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <SubmitButton label={submitLabel} isSubmitting={mutation.isLoading} isDisabled={!formHasChanges(formik)} />
            <CancelButton link={cancelLink} isDisabled={mutation.isLoading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export const PeriodeZonderMeldingForm: React.FC<PeriodeZonderMeldingFormProps> = ({ mutateFn, cancelLink, submitLabel, initialValues }) => {
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<PeriodeZonderMeldingJsonResponse>, Error | ApiError, PeriodeZonderMeldingJsonRequest, unknown>((request) => mutateFn(request));

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    try {
      const request: PeriodeZonderMeldingJsonRequest = {
        referentie: values.referentie ? values.referentie : undefined,
        eigenaar: values.eigenaar ? values.eigenaar : undefined,
        vestigingsnummer: values.onderdeel === 'VW' && values.vestigingsnummer ? values.vestigingsnummer : undefined,
        periodeVan: values.periodeVan,
        periodeTot: values.periodeTot,
        onderdeel: values.onderdeel,
        motivering: values.motivering,
        andereMotivering: values.motivering === 'ANDERE' && values.andereMotivering ? values.andereMotivering : undefined,
        medeExploitant: values.motivering === 'MEDE_EXPLOITANT' && values.medeExploitant ? values.medeExploitant : undefined,
      };
      await mutation.mutateAsync(request);
      navigate('/periodes-zonder-melding');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik validateOnBlur={false} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(formik) => <PeriodeZonderMeldingInnerForm mutation={mutation} submitLabel={submitLabel} cancelLink={cancelLink} formik={formik} />}
    </Formik>
  );
};
