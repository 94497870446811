import React from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '../components/form/text-field';
import TextArea from '../components/form/text-area';
import SubmitButton from '../components/button/submit-button';
import { ApiError } from '../util/axios-error-mapping';
import { formHasChanges } from '../util/form-has-changes';
import ErrorAlert from '../components/alert/error-alert';
import FormTitle from '../components/form/form-title';
import requiredField from '../util/required-field';
import { PeriodeEenheid, ValidatievraagJsonRequest } from '../generated';
import { PeriodeWaardeField } from '../melding/melding-form-fields';
import Radio from '../components/form/radio';
import { displayClean } from '../melding/melding-detail.hook';
import { validatieSchemaOrganisatiecodeVrijeTekst } from '../organisatie/organisatiecode.model';
import { vrijeTekstSchema } from '../melding/vrije-tekst-schema';
import DateField from '../components/form/date-field';
import CancelButton from '../components/button/cancel-button';
import { formats } from '../melding/periode.model';
import { periodeWaardeValidationSchema } from '../melding/melding-form';

interface ValidatievraagFormValues {
  regelcode: string;
  samenvatting: string;
  beschrijving: string;
  domein: string;
  periodeEenheid: string;
  periodeWaarde: string;
  tijdstipUitvoering: Dayjs;
  eigenaarCode: string;
  eigenaarNaam: string;
}

const domeinOptions: { label: string; value: string }[] = [
  {
    label: 'Huishoudelijk Afval',
    value: 'HA',
  },
  {
    label: 'Bedrijfsafval',
    value: 'BA',
  },
  {
    label: 'Verwerking',
    value: 'VW',
  },
];

const typesToRadioOptions = (input: any) =>
  input.map((value: any) => ({
    value,
    label: displayClean(value),
  }));

const initialValues: ValidatievraagFormValues = {
  regelcode: '',
  samenvatting: '',
  beschrijving: '',
  domein: domeinOptions[0].value,
  periodeEenheid: PeriodeEenheid.KWARTAAL,
  periodeWaarde: '',
  tijdstipUitvoering: dayjs(),
  eigenaarCode: '',
  eigenaarNaam: '',
};

const validatievraagValidationSchema = Yup.object().shape({
  regelcode: requiredField(vrijeTekstSchema().max(40, (params) => `Code van de validatieregel kan niet meer dan ${params.max} tekens bevatten`)),
  samenvatting: requiredField(vrijeTekstSchema().max(200, (params) => `Samenvatting van de validatieregel kan niet meer dan ${params.max} tekens bevatten`)),
  beschrijving: requiredField(vrijeTekstSchema().max(4000, (params) => `Beschrijving van de validatieregel kan niet meer dan ${params.max} tekens bevatten`)),
  domein: requiredField(),
  periodeEenheid: requiredField(),
  periodeWaarde: periodeWaardeValidationSchema,
  tijdstipUitvoering: requiredField(),
  eigenaarCode: validatieSchemaOrganisatiecodeVrijeTekst('Organisatiecode'),
  eigenaarNaam: requiredField(vrijeTekstSchema().max(200, (params) => `Organisatienaam kan niet meer dan ${params.max} tekens bevatten`)),
});

const ValidatievraagCreateForm: React.FC = () => {
  const mutation = useMutation<AxiosResponse<any>, Error | ApiError, ValidatievraagJsonRequest, unknown>((request: ValidatievraagJsonRequest) =>
    axios.post('/ui/validatievraag', request),
  );

  const handleSubmit = async (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<ValidatievraagFormValues>) => {
    try {
      const request: ValidatievraagJsonRequest = {
        regelcode: values.regelcode.trim(),
        samenvatting: values.samenvatting.trim(),
        beschrijving: values.beschrijving.trim(),
        onderdeel: values.domein,
        periode: {
          eenheid: values.periodeEenheid,
          waarde: values.periodeWaarde.trim(),
        },
        tijdstipUitvoering: values.tijdstipUitvoering.format(`${formats.DAG}THH:mm:ss`),
        eigenaarCode: values.eigenaarCode.trim(),
        eigenaarNaam: values.eigenaarNaam.trim(),
      };
      await mutation.mutateAsync(request);
      resetForm(initialValues as any);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik validateOnBlur={false} initialValues={{ ...initialValues, tijdstipUitvoering: dayjs() }} validationSchema={validatievraagValidationSchema} onSubmit={handleSubmit}>
      {(formik) => (
        <Form spellCheck={false}>
          {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het aanmaken van de validatievraag is mislukt." />}

          <div className="vl-form-grid vl-form-grid--is-stacked">
            <FormTitle title="Validatieregel" />
            <TextField label="Code" name="regelcode" placeholder="Code van de validatieregel" required />
            <TextField label="Samenvatting" name="samenvatting" placeholder="Samenvatting van de validatieregel" required />
            <TextArea label="Beschrijving" name="beschrijving" placeholder="Uitgebreide beschrijving van de validatieregel" maxLength={4000} required />
            <Radio label="Domein" name="domein" options={domeinOptions} required />
            <FormTitle title="Uitvoering" />
            <Radio required label="Periode eenheid" name="periodeEenheid" options={typesToRadioOptions([PeriodeEenheid.JAAR, PeriodeEenheid.KWARTAAL])} />
            <PeriodeWaardeField />
            <DateField label="Tijdstip uitvoering" name="tijdstipUitvoering" showTime required />
            <FormTitle title="Ontvangende organisatie" />
            <TextField label="Organisatiecode" name="eigenaarCode" placeholder="Organisatiecode" required />
            <TextField label="Organisatienaam" name="eigenaarNaam" placeholder="Organisatienaam" required />

            <div className="vl-form-col--1-1 vl-u-spacer-top--medium">
              <div className="vl-action-group vl-action-group--align-right">
                <SubmitButton label="Validatievraag aanmaken" isSubmitting={mutation.isLoading} isDisabled={!formHasChanges(formik)} />
                <CancelButton link="/" isDisabled={mutation.isLoading} />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ValidatievraagCreateForm;
