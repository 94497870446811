import React, { useState } from 'react';
import { orderBy } from 'lodash';
import { formatAfvalcodes } from './verdeling-detail.hook';
import VerdelingDetailHeader from './verdeling-detail.header';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import MultiLineText from '../components/details/multi-line-text';
import VerdelingDetailFormSection from './verdeling-detail-form-section';
import RouteButton from '../components/button/route-button';
import DeleteEntityButton from '../components/button/delete-entity-button';
import { useDeleteVerdeling } from './verdeling.hook';
import ErrorAlert from '../components/alert/error-alert';
import { VerdelingDetailsJsonResponse } from '../generated';
import MatchendeMeldingenOverview from './matchende-meldingen-overview';
import SecondaryButton from '../components/button/secondary-button';
import { formatNumber } from '../util/number-formatting';

const VerdelingDetails: React.FC<{ verdeling: VerdelingDetailsJsonResponse }> = ({ verdeling }) => {
  const deleteMutation = useDeleteVerdeling();
  const renderVerdeelsleutelRows = orderBy(verdeling.verdeelsleutel, (aandeel) => aandeel.oorsprongNaVerdeling).map((value) => (
    <tr data-cy="row" key={`aandeel-${value.oorsprongNaVerdeling}`}>
      <td data-title="Oorsprong na verdeling">{value.oorsprongNaVerdeling}</td>
      <td data-title="Percentage">{formatNumber(value.percentage)}%</td>
    </tr>
  ));
  const [toonMeldingen, setToonMeldingen] = useState<boolean>(false);

  return (
    <>
      {deleteMutation.isError && (
        <ErrorAlert error={deleteMutation.error} defaultMessage="Er is een fout opgetreden bij het verwijderen van de verdeling. Gelieve later nog eens opnieuw te proberen." />
      )}
      <VerdelingDetailHeader verdeling={verdeling} />
      <DetailsForm>
        <VerdelingDetailFormSection verdeling={verdeling} />

        <DetailsFormSection title="Oorsprong">
          <DetailsFormField label="Gemeente" data={verdeling.criteria.oorsprong} />
        </DetailsFormSection>

        <DetailsFormSection title="Materiaalidentificatie">
          <DetailsFormField label="OVAM afvalcode" data={formatAfvalcodes(verdeling)} />
          <DetailsFormField label="Inzamelwijze" data={verdeling.criteria.inzamelwijze} />
          <DetailsFormField label="Commentaar" data={verdeling.commentaar && <MultiLineText value={verdeling.commentaar} />} />
        </DetailsFormSection>

        <DetailsFormSection title="Verdeelsleutel">
          <table className="vl-data-table vl-data-table--">
            <thead>
              <tr>
                <th>Oorsprong na verdeling</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody data-cy="verdeelsleutel-table-body">{renderVerdeelsleutelRows}</tbody>
          </table>
        </DetailsFormSection>
      </DetailsForm>
      <div className="vl-form-grid">
        <div className="vl-form-col--4-12">
          <SecondaryButton label="Meldingen tonen" isDisabled={toonMeldingen} onClick={() => setToonMeldingen(true)} dataCy="meldingen-tonen" />
        </div>
        <div className="vl-form-col--8-12">
          <div className="vl-action-group vl-action-group--align-right">
            <DeleteEntityButton
              entity={verdeling}
              deleteMutation={deleteMutation}
              successLink="/verdeling/"
              confirmation="Bent u zeker dat u deze verdeling wil verwijderen?"
              tooltip={verdeling.bevroren ? { title: 'Gevalideerde verdelingen kan u niet verwijderen', placement: 'top' } : undefined}
              isDisabled={verdeling.bevroren}
            />
            <RouteButton label="Sluiten" className="vl-button--secondary" link="/verdeling/" />
          </div>
        </div>
      </div>
      <div>{toonMeldingen && <MatchendeMeldingenOverview verdelingReferentie={verdeling.referentie} />}</div>
    </>
  );
};

export default VerdelingDetails;
