import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import PeriodeZonderMeldingCreateForm from './periode-zonder-melding-create-form';

const PeriodeZonderMeldingCreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/periodes-zonder-melding/nieuw">Nieuwe periode zonder melding</BreadcrumbsItem>
      <PeriodeZonderMeldingCreateForm />
    </div>
  </section>
);

export default PeriodeZonderMeldingCreatePage;
