import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './list-details.module.css';

export type ListDetailsType = 'vertical' | 'horizontal' | 'comma-separated';

const styleMap = new Map<string, string>([
  ['vertical', styles.vertical],
  ['horizontal', styles.horizontal],
  ['comma-separated', cx(styles.horizontal, styles.commaseparated)],
]);

interface ListDetailsProps {
  data: string[] | ReactNode[];
  type: ListDetailsType;
  sorted?: boolean;
  whenEmpty?: string | ReactNode;
}

const sort = (input: string[]) => input.toSorted((a: string, b: string) => a.localeCompare(b));

const ListDetails: React.FC<ListDetailsProps> = ({ data, type, sorted, whenEmpty }) =>
  data.length > 0 ? (
    <ul className={styleMap.get(type)}>
      {(sorted ? sort(data as string[]) : data).map((el: string | ReactNode) => (
        <li key={el as string}>{el}</li>
      ))}
    </ul>
  ) : (
    whenEmpty
  );

export default ListDetails;
