import * as Yup from 'yup';
import requiredField from '../util/required-field';
import { Nullable } from '../types/nullable';
import ModuloCheck from './modulo-check.model';

export const belgischBtwNummerValidationSchema: Yup.StringSchema<any> = Yup.string()
  .max(12, 'Belgisch BTW-nummer kan niet meer dan 12 tekens bevatten')
  .test('btwnummer-belgisch', 'Ongeldig Belgisch BTW-nummer', (value: Nullable<string>) => {
    const btw: string = value || '';
    const empty: boolean = btw.length === 0;
    const belgian: boolean = btw.startsWith('BE');
    return empty || (belgian && /^BE[01]\d{9}$/.test(btw) && ModuloCheck.isValid(btw.substring(2)));
  });

const isValidBuitenlandsBtwNummer = (value: string) => {
  const empty: boolean = value.length === 0;
  const notBelgian: boolean = !value.toUpperCase().startsWith('BE');
  return empty || (notBelgian && /^[A-Z]{2}/.test(value) && value.length >= 3);
};

export const buitenlandsBtwNummerValidationSchema: Yup.StringSchema<any> = Yup.string()
  .max(70, 'Buitenlands BTW-nummer kan niet meer dan 70 tekens bevatten')
  .test('btwnummer-buitenlands', 'Ongeldig buitenlands BTW-nummer', (value: Nullable<string>) => isValidBuitenlandsBtwNummer(value || ''));

export const createBtwNummerValidationSchema = (required: boolean = false) =>
  Yup.lazy((value: Nullable<string>) => {
    const btw: string = value || '';
    const btwNummerValidationSchema = btw.startsWith('BE') ? belgischBtwNummerValidationSchema : buitenlandsBtwNummerValidationSchema;
    if (required) {
      return requiredField(btwNummerValidationSchema);
    }
    return btwNummerValidationSchema;
  });
