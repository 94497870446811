import React from 'react';
import axios from 'axios';
import MeldingDetailHeader from './melding-detail.header';
import { createMeldingInzamelingHARequestValidationSchema, MeldingInzamelingHAForm, MeldingInzamelingHAFormValues } from './melding-inzameling-ha-form';
import FormTitle from '../components/form/form-title';
import BestemmingForm from './bestemming/bestemming-form';
import TextArea from '../components/form/text-area';
import {
  AfvalcodeSelect,
  BestemmingTypeInzamelingHARadio,
  InzamelwijzeCommentaar,
  InzamelwijzeSelect,
  MeerdereOorsprongenSelection,
  PeriodeSelection,
  PeriodeWaardeField,
  TonnageField,
  VerwerkingswijzeSelect,
  VlaamsePostcodeSelect,
} from './melding-form-fields';
import TextField from '../components/form/text-field';
import FixedValueField from '../components/form/fixed-value-field';
import BtwTextField from './bestemming/btw-text-field';
import { useAuthorisationInfo } from '../services/security/authorisation';
import { MeldingInzamelingHADetailsJsonResponse, MeldingInzamelingHAJsonRequest, OorsprongType } from '../generated';
import DierlijkBijproductSelect from './materiaal/dierlijk-bijproduct-select';

const MeldingInzamelingHAUpdateForm: React.FC<{ melding: MeldingInzamelingHADetailsJsonResponse }> = ({ melding }) => {
  const { isVerdeler } = useAuthorisationInfo();

  const formValues: MeldingInzamelingHAFormValues = {
    identificatie: melding?.identificatie ?? '',
    oorsprong: {
      type: OorsprongType.VLAAMSE_GEMEENTE,
      vestigingsnummer: '',
      vestigingsnummerVerplicht: false,
      btwNummer: '',
      ondernemingsnummer: '',
      naam: '',
      straat: '',
      huisnummer: '',
      uitbreiding: '',
      postcode: melding?.oorsprong?.postcode ?? '',
      gemeente: '',
      land: '',
      commentaar: '',
    },
    bestemming: {
      type: melding?.bestemming?.type,
      vestigingsnummer: melding?.bestemming?.vestigingsnummer ?? '',
      vestigingsnummerVerplicht: false,
      btwNummer: melding?.bestemming?.btwNummer ?? '',
      ondernemingsnummer: melding?.bestemming?.ondernemingsnummer ?? '',
      naam: melding?.bestemming?.naam ?? '',
      straat: melding?.bestemming?.straat ?? '',
      huisnummer: melding?.bestemming?.huisnummer ?? '',
      uitbreiding: melding?.bestemming?.uitbreiding ?? '',
      postcode: melding?.bestemming?.postcode ?? '',
      gemeente: melding?.bestemming?.gemeente ?? '',
      land: melding?.bestemming?.land ?? '',
      commentaar: melding?.bestemming?.commentaar ?? '',
    },
    verwerkingswijze: melding?.verwerkingswijze ?? '',
    ihmBtwNummer: melding?.btwNummer ?? '',
    afvalcode: melding?.materiaal.afvalcode ?? '',
    materiaalOmschrijving: melding?.materiaal.materiaalOmschrijving ?? '',
    dierlijkBijproduct: melding?.materiaal.dierlijkBijproduct ?? '',
    periodeEenheid: melding?.periode.eenheid ?? '',
    periodeWaarde: melding?.periode.waarde ?? '',
    tonnage: melding?.tonnage?.toString() ? melding.tonnage.toString().replace('.', ',') : '',
    inzamelwijzeCode: melding?.inzamelwijze.code ?? '',
    inzamelwijzeCommentaar: melding?.inzamelwijze.commentaar ?? '',
  };
  if (isVerdeler) {
    formValues.meerdereOorsprongen = melding?.meerdereOorsprongen;
  }

  const updateMutation = async (request: MeldingInzamelingHAJsonRequest) => axios.put<any>(`/ui/meldingen/inzameling/huishoudelijk-afval/${melding.referentie}`, request);

  return (
    <>
      <MeldingDetailHeader melding={melding} />
      <MeldingInzamelingHAForm
        initialValues={formValues}
        validationSchema={createMeldingInzamelingHARequestValidationSchema(isVerdeler)}
        mutateFn={updateMutation}
        submitLabel="Melding corrigeren"
        cancelLink={`/melding/inzameling/huishoudelijk-afval/${melding.referentie}/`}
      >
        <FormTitle title="Melding" />
        <FixedValueField name="referentie" label="OVAM Referentie" value={melding.referentie} />
        <TextField label="Identificatie" name="identificatie" disabled />
        <TonnageField />
        <PeriodeSelection />
        <PeriodeWaardeField />
        <FormTitle title="Materiaalidentificatie" />
        <AfvalcodeSelect required />
        <TextArea label="Materiaal omschrijving" name="materiaalOmschrijving" />
        <DierlijkBijproductSelect />
        <InzamelwijzeSelect required />
        <InzamelwijzeCommentaar />
        <FormTitle title="Oorsprong" />
        <VlaamsePostcodeSelect label="Gemeente" name="oorsprong.postcode" required />
        {isVerdeler && <MeerdereOorsprongenSelection />}
        <FormTitle title="Bestemming" />
        <BestemmingTypeInzamelingHARadio />
        <BestemmingForm />
        <FormTitle title="Verwerking" />
        <VerwerkingswijzeSelect />
        <FormTitle title="IHM" />
        <BtwTextField label="BTW-nummer" name="ihmBtwNummer" placeholder="BE0123456789" />
      </MeldingInzamelingHAForm>
    </>
  );
};

export default MeldingInzamelingHAUpdateForm;
