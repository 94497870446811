import React from 'react';
import CsvUploader from '../components/file/csv-uploader';
import { ReferentieJsonResponse } from '../generated';

export interface VerdelingCsvProps {
  handleUpload: (formData: FormData) => Promise<ReferentieJsonResponse>;
  title: string;
}

const VerdelingCsvUploader: React.FC<VerdelingCsvProps> = ({ handleUpload, title }) => (
  <CsvUploader
    handleUpload={handleUpload}
    title={title}
    csvEntitiesLabel="verdelingen"
    chapterInManual="Verdelen afvalinzameling"
    uploadLimitExtractor={(config) => config.csv.verdelingUploadLimit}
    additionalInfoBullets={[
      "Meldingen van afvalinzameling zonder meerdere oorsprongen (veld 'Meerdere oorsprongen' = 'NEE') worden niet verdeeld, ook al voldoen deze meldingen aan de criteria van een verdeling.",
    ]}
  />
);
export default VerdelingCsvUploader;
