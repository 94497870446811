import React from 'react';
import { EnumeratedListJsonResponse } from '../generated';

interface WerkingsgebiedDetailsProps {
  data: string[] | EnumeratedListJsonResponse[];
}

const WerkingsgebiedDetails: React.FC<WerkingsgebiedDetailsProps> = ({ data }) => (
  <>
    {data
      .map((e) => (typeof e === 'string' ? e : e.code))
      .toSorted()
      .join(', ') || 'Geen beperkingen'}
  </>
);

export default WerkingsgebiedDetails;
