import { EnumeratedListJsonResponse, InzamelwijzeDetailsJsonResponse } from '../generated';

class InzamelwijzeCode {
  static format({ code, omschrijving }: InzamelwijzeDetailsJsonResponse | EnumeratedListJsonResponse): string {
    return `${code} ${omschrijving}`;
  }

  static isAndere(code: string) {
    return code === 'Z';
  }
}

export default InzamelwijzeCode;
