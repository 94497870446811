import React, { useState } from 'react';
import { useVerdelingMeldingen } from './verdeling.hook';
import { PageableParamsJsonRequest, VerdelingMeldingJsonResponse } from '../generated';
import MatchendeMeldingenOverviewTable from './matchende-meldingen-overview-table';
import { Page } from '../components/table/page';

interface MatchendeMeldingenOverviewProps {
  verdelingReferentie: string;
}

const matchendeMeldingenPageSize = 10;

const MatchendeMeldingenOverview: React.FC<MatchendeMeldingenOverviewProps> = ({ verdelingReferentie }) => {
  const [pageParams, setPageParams] = useState<PageableParamsJsonRequest>({ page: 0, size: matchendeMeldingenPageSize });
  const { data: meldingen, isLoading, isFetching, error } = useVerdelingMeldingen(verdelingReferentie, pageParams);

  return (
    <MatchendeMeldingenOverviewTable
      page={meldingen as Page<VerdelingMeldingJsonResponse>}
      updatePaging={(pagination) => {
        setPageParams({
          page: (pagination.current ?? 1) - 1,
          size: pagination.pageSize ?? matchendeMeldingenPageSize,
        });
      }}
      queryStatus={{ isLoading, isFetching, error }}
    />
  );
};

export default MatchendeMeldingenOverview;
