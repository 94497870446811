import { useRoleHook } from './use-role-hook';
import { Scope } from '../../generated';

export interface AuthorisationInfo {
  authorisedScopes: Scope[];
  isBeheerder: boolean;
  isVerdeler: boolean;
  isLoading: boolean;
}

export const useAuthorisationInfo = (): AuthorisationInfo => {
  const { authorisedScopes, isBeheerder } = useRoleHook();

  return {
    authorisedScopes,
    isBeheerder,
    isVerdeler: authorisedScopes.some((scope) => scope === Scope.VERDELING_HUISHOUDELIJK_AFVAL),
    isLoading: false,
  };
};
