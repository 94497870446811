import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './link-list-item.module.css';

interface LinkListItemProps {
  label: string;
  link: string;
  iconClasses: string;
}

const LinkListItem: React.FC<LinkListItemProps> = ({ label, link, iconClasses }) => (
  <li className="vl-link-list__item" data-cy="link-list-item">
    <Link to={link} className="vl-link">
      <span className={cx('vl-link__icon', 'vl-link__icon--before', iconClasses, styles.icon)} aria-hidden />
      {label}
    </Link>
  </li>
);

export default LinkListItem;
