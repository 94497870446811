import React from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

interface CancelButtonProps {
  link: string;
  label?: string;
  className?: string;
  isDisabled?: boolean;
}

const CancelButton: React.FC<CancelButtonProps> = ({ link, label, className, isDisabled = false }) => {
  const navigate = useNavigate();
  return (
    <button type="button" disabled={isDisabled} className={cx('vl-button vl-button--secondary', className)} onClick={() => navigate(link)}>
      <span className="vl-button__label">{label ?? 'Annuleren'}</span>
    </button>
  );
};

export default CancelButton;
