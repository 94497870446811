import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query/types/react/types';
import { Page } from '../components/table/page';
import { Pageable } from '../components/table/pageable';
import {
  MeldingInzamelingBAFiltersJsonRequest,
  MeldingInzamelingBAOverviewJsonResponse,
  MeldingInzamelingHADetailsJsonResponse,
  MeldingInzamelingHAFiltersJsonRequest,
  MeldingInzamelingHAOverviewJsonResponse,
  MeldingVerwerkingDetailsJsonResponse,
  MeldingVerwerkingFiltersJsonRequest,
  MeldingVerwerkingOverviewJsonResponse,
} from '../generated';
import { axiosParamsSerializer } from '../util/axios-params-serializer';

export type MeldingFilters = MeldingInzamelingHAFiltersJsonRequest | MeldingVerwerkingFiltersJsonRequest;

export interface MeldingInzamelingHAPagingAndFiltering extends Pageable, MeldingInzamelingHAFiltersJsonRequest {}
export interface MeldingInzamelingBAPagingAndFiltering extends Pageable, MeldingInzamelingBAFiltersJsonRequest {}

export interface MeldingVerwerkingPagingAndFiltering extends Pageable, MeldingVerwerkingFiltersJsonRequest {}

export const useMeldingVerwerkingen = (params: MeldingVerwerkingPagingAndFiltering): UseQueryResult<Page<MeldingVerwerkingOverviewJsonResponse>, Error> => {
  const meldingenUrl = '/ui/meldingen/verwerking';
  return useQuery<Page<MeldingVerwerkingOverviewJsonResponse>, Error>([meldingenUrl, params], async () => {
    const { data } = await axios.get<Page<MeldingVerwerkingOverviewJsonResponse>>(meldingenUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });
};

export const useMeldingenInzamelingHA = (params: MeldingInzamelingHAPagingAndFiltering): UseQueryResult<Page<MeldingInzamelingHAOverviewJsonResponse>, Error> => {
  const meldingenUrl = '/ui/meldingen/inzameling/huishoudelijk-afval';
  return useQuery<Page<MeldingInzamelingHAOverviewJsonResponse>, Error>([meldingenUrl, params], async () => {
    const { data } = await axios.get<Page<MeldingInzamelingHAOverviewJsonResponse>>(meldingenUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });
};

export const useMeldingenInzamelingBA = (params: MeldingInzamelingBAPagingAndFiltering): UseQueryResult<Page<MeldingInzamelingBAOverviewJsonResponse>, Error> => {
  const meldingenUrl = '/ui/meldingen/inzameling/bedrijfsafval';
  return useQuery<Page<MeldingInzamelingBAOverviewJsonResponse>, Error>([meldingenUrl, params], async () => {
    const { data } = await axios.get<Page<MeldingInzamelingBAOverviewJsonResponse>>(meldingenUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });
};

export function useDeleteMeldingInzamelingHA(): UseMutationResult<AxiosResponse, Error, MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse> {
  return useMutation<AxiosResponse, Error, MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse, unknown>((m) =>
    axios.delete(`/ui/meldingen/inzameling/huishoudelijk-afval/${m.referentie}`),
  );
}

export function useDeleteMeldingVerwerking(): UseMutationResult<AxiosResponse, Error, MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse> {
  return useMutation<AxiosResponse, Error, MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse, unknown>((m) =>
    axios.delete(`/ui/meldingen/verwerking/${m.referentie}`),
  );
}

export function useDeleteAllSelectedMeldingenInzamelingHA(): UseMutationResult<AxiosResponse, Error, string[]> {
  const queryClient = useQueryClient();
  const meldingenUrl = '/ui/meldingen/inzameling/huishoudelijk-afval';
  return useMutation<AxiosResponse, Error, string[], unknown>((m) => axios.post('/ui/meldingen/inzameling/huishoudelijk-afval/delete-all', m), {
    onSuccess: () => {
      queryClient.invalidateQueries(meldingenUrl);
    },
  });
}

export function useDeleteAllSelectedMeldingenInzamelingBA(): UseMutationResult<AxiosResponse, Error, string[]> {
  const queryClient = useQueryClient();
  const meldingenUrl = '/ui/meldingen/inzameling/bedrijfsafval';
  return useMutation<AxiosResponse, Error, string[], unknown>((m) => axios.post('/ui/meldingen/inzameling/bedrijfsafval/delete-all', m), {
    onSuccess: () => {
      queryClient.invalidateQueries(meldingenUrl);
    },
  });
}

export function useDeleteAllSelectedMeldingenVerwerking(): UseMutationResult<AxiosResponse, Error, string[]> {
  const queryClient = useQueryClient();
  const meldingenUrl = '/ui/meldingen/verwerking';
  return useMutation<AxiosResponse, Error, string[], unknown>((m) => axios.post('/ui/meldingen/verwerking/delete-all', m), {
    onSuccess: () => {
      queryClient.invalidateQueries(meldingenUrl);
    },
  });
}
