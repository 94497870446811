import React from 'react';
import { DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import Tonnage from './tonnage.model';
import { MeldingInzamelingHADetailsJsonResponse, MeldingVerwerkingDetailsJsonResponse } from '../generated';
import { useRoleHook } from '../services/security/use-role-hook';

interface MeldingDetailsFormSectionProps {
  melding: MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse;
  children?: React.ReactNode;
}

const MeldingDetailsFormSection: React.FC<MeldingDetailsFormSectionProps> = ({ melding, children }) => {
  const { isBeheerder } = useRoleHook();
  return (
    <DetailsFormSection title="Melding">
      <DetailsFormField label="OVAM Referentie" data={melding.referentie} />
      {children}
      <DetailsFormField label="Identificatie" data={melding.identificatie} />
      {isBeheerder && <DetailsFormField label="Eigenaar" data={melding.eigenaar} />}
      <DetailsFormField label="Tonnage" data={new Tonnage(melding.tonnage).formatted()} />
      <DetailsFormField label="Periode" data={melding.periode.waarde} />
    </DetailsFormSection>
  );
};
export default MeldingDetailsFormSection;
