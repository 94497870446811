import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseMutationResult } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { TooltipProps } from 'antd';
import DeleteButtonWithConfirm from './delete-with-confirm';

interface Props<T> {
  entity: T;
  successLink: string;
  deleteMutation: UseMutationResult<AxiosResponse, Error, T>;
  confirmation?: string;
  title?: string;
  tooltip?: TooltipProps;
  isDisabled?: boolean;
}

const DeleteEntityButton: React.FC<Props<any>> = ({ entity, successLink, deleteMutation, confirmation, title, tooltip, isDisabled = false }) => {
  const navigate = useNavigate();

  const handleDelete = useCallback(
    () =>
      deleteMutation.mutate(entity, {
        onSuccess: () => navigate(successLink),
      }),
    [entity, deleteMutation],
  );

  return (
    <DeleteButtonWithConfirm
      confirmationTitle={confirmation ?? 'Bent u zeker dat u dit wilt verwijderen?'}
      onDelete={handleDelete}
      title={title}
      tooltip={tooltip}
      isDisabled={isDisabled || deleteMutation.isLoading}
    />
  );
};

export default DeleteEntityButton;
