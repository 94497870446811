import React from 'react';
import cx from 'classnames';
import styles from './info-tile.module.css';

interface IInfoTileProps {
  title: string;
}

const InfoTile: React.FC<React.PropsWithChildren<IInfoTileProps>> = ({ title, children }) => (
  <div className={cx('vl-info-tile', styles.maxheight)}>
    <header className="vl-info-tile__header" role="presentation">
      <div className="vl-info-tile__header__wrapper">
        <h3 className="vl-info-tile__header__title">{title}</h3>
      </div>
    </header>
    <div className="vl-info-tile__content">
      <div className="vl-info-tile__content__wrapper">{children}</div>
    </div>
  </div>
);

export default InfoTile;
