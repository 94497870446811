import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

export interface IEmbedConfig {
  reports: IReport[];
  embedToken: IEmbedToken;
}

export interface IReport {
  embedUrl: string;
}

export interface IEmbedToken {
  expiration: string;
  token: string;
  tokenId: string;
}

export const reportNotFound = (error: Error) => axios.isAxiosError(error) && error.response?.status === 404;

function useRapportConfig(url: string, enabled = true) {
  const baseUrl = '/ui/rapportering';
  return useQuery<IEmbedConfig, Error>(
    `${baseUrl}${url}`,
    async () => {
      const { data } = await axios.get<IEmbedConfig>(`${baseUrl}${url}`);
      return data;
    },
    {
      enabled,
    },
  );
}

export const useMeldingenInzamelingHARapportConfig = (): UseQueryResult<IEmbedConfig, Error> => useRapportConfig('/meldingen/inzameling/huishoudelijk-afval/eigen');
export const useMeldingenVerwerkingRapportConfig = (): UseQueryResult<IEmbedConfig, Error> => useRapportConfig('/meldingen/verwerking');
export const useInzamelingHAGemeenteRapportConfig = (): UseQueryResult<IEmbedConfig, Error> => useRapportConfig('/meldingen/inzameling/huishoudelijk-afval/gemeente');
export const useInzamelingBARapportConfig = (): UseQueryResult<IEmbedConfig, Error> => useRapportConfig('/meldingen/inzameling/bedrijfsafval');
export const useInzamelingHAIntercommunaleRapportConfig = (): UseQueryResult<IEmbedConfig, Error> => useRapportConfig('/meldingen/inzameling/huishoudelijk-afval/intercommunale');
