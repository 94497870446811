import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { BestemmingFormValues, initialBestemmingValues, TYPE } from './bestemming.model';
import BelgischeVestigingForm, { belgischeVestigingValidationSchema } from './belgische-vestiging-form';
import BuitenlandseVestigingForm, { buitenlandseVestigingValidationSchema } from './buitenlandse-vestiging-form';
import { GeregistreerdeTraderForm, traderValidationSchema } from './trader-form';
import GebruikDoorBurgersForm, { gebruikDoorBurgersValidationSchema } from './gebruik-door-burgers-form';
import { useGeregistreerdeTraders } from '../../enumerated-lists/enumeratedlists.hook';
import WerfForm, { werfValidationSchema } from './werf-form';
import GeenOndernemingForm, { geenOndernemingValidationSchema } from './geen-onderming-form';
import DispersGebruikForm, { dispersGebruikValidationSchema } from './dispers-gebruik-form';
import requiredField from '../../util/required-field';
import BestemmingParticulierForm, { bestemmingParticulierValidationSchema } from './bestemming-particulier-form';

export const bestemmingValidationSchema = Yup.lazy((value: BestemmingFormValues) => {
  switch (value.type) {
    case 'BELGISCHE_VESTIGING':
      return belgischeVestigingValidationSchema;
    case 'BUITENLANDSE_VESTIGING':
      return buitenlandseVestigingValidationSchema;
    case 'TRADER':
      return traderValidationSchema;
    case 'GEBRUIK_DOOR_BURGERS':
      return gebruikDoorBurgersValidationSchema;
    case 'WERF':
      return werfValidationSchema;
    case 'GEEN_ONDERNEMING':
      return geenOndernemingValidationSchema;
    case 'DISPERS_GEBRUIK':
      return dispersGebruikValidationSchema;
    case 'PARTICULIER':
      return bestemmingParticulierValidationSchema;
    default:
      return Yup.object().shape({
        [TYPE]: requiredField().nullable(),
      });
  }
});

const BestemmingForm: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const [field, meta, helpers] = useField('bestemming');
  const { data: traders } = useGeregistreerdeTraders();
  const { type } = field.value;
  const namespace = 'bestemming';

  useEffect(() => {
    if (initialized || type !== meta.initialValue.type) {
      if (type === 'BELGISCHE_VESTIGING') {
        helpers.setValue(
          {
            ...field.value,
            postcode: field.value.land === 'BE' ? field.value.postcode : '',
            land: 'BE',
          },
          true,
        );
      } else if (type === 'BUITENLANDSE_VESTIGING') {
        helpers.setValue(
          {
            ...field.value,
            vestigingsnummer: '',
            land: field.value.land === 'BE' ? '' : field.value.land,
          },
          true,
        );
      } else if (type === 'TRADER') {
        const btwValue = field.value.btwNummer;
        const verifiedBtw = traders?.some((trader) => trader.code === btwValue) ? btwValue : '';
        helpers.setValue(
          {
            ...field.value,
            btwNummer: verifiedBtw,
            vestigingsnummer: '',
          },
          true,
        );
      } else if (type === 'GEBRUIK_DOOR_BURGERS' || type === 'DISPERS_GEBRUIK') {
        const newValue = {
          ...initialBestemmingValues,
          type: field.value.type,
          commentaar: field.value.commentaar,
        };
        helpers.setValue(newValue, true);
      } else if (type === 'WERF' || type === 'GEEN_ONDERNEMING' || type === 'PARTICULIER') {
        helpers.setValue(
          {
            ...field.value,
            vestigingsnummer: '',
          },
          true,
        );
      }
    } else {
      setInitialized(true);
    }
  }, [type, traders]);

  return (
    <>
      {type === 'BELGISCHE_VESTIGING' && <BelgischeVestigingForm namespace={namespace} />}
      {type === 'BUITENLANDSE_VESTIGING' && <BuitenlandseVestigingForm namespace={namespace} />}
      {type === 'TRADER' && <GeregistreerdeTraderForm namespace={namespace} />}
      {type === 'GEBRUIK_DOOR_BURGERS' && <GebruikDoorBurgersForm namespace={namespace} />}
      {type === 'WERF' && <WerfForm namespace={namespace} />}
      {type === 'GEEN_ONDERNEMING' && <GeenOndernemingForm namespace={namespace} />}
      {type === 'DISPERS_GEBRUIK' && <DispersGebruikForm namespace={namespace} />}
      {type === 'PARTICULIER' && <BestemmingParticulierForm />}
    </>
  );
};

export default BestemmingForm;
