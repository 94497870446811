import React from 'react';
import { Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FilterButtons } from './component.filter.buttons';
import './filter.css';

interface IFilterTextInputProps extends FilterDropdownProps {
  placeholder: string;
  dataCy: string;
}

const FilterTextInputDropdown: React.FC<IFilterTextInputProps> = (props) => {
  const { dataCy, placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
  const isResetDisabled = selectedKeys.length === 0;

  return (
    <div data-cy={dataCy} className="filter-dropdown">
      <Input
        autoFocus
        placeholder={`Zoek ${placeholder}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys([e.target.value])}
        onPressEnter={() => confirm()}
        className="filter-text-input"
        data-cy="search-input"
      />
      <FilterButtons onReset={() => clearFilters?.()} onConfirm={() => confirm()} resetDisabled={isResetDisabled} />
    </div>
  );
};

export default FilterTextInputDropdown;
