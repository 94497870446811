import React, { ReactNode } from 'react';
import { ContactgegevensJsonResponse } from '../generated';
import { useContactgegevens } from './contactgegevens.hooks';
import ErrorAlert from '../components/alert/error-alert';

export interface ContactgegevensLoaderProps {
  render: (contactgegevens: ContactgegevensJsonResponse) => ReactNode;
  gebruikerId?: string;
}

const SimpleLoading: React.FC<{ message: string }> = ({ message }) => (
  <div className="vl-u-align-center">
    <div className="vl-loader" role="status" />
    <p>{message}</p>
  </div>
);

export const ContactgegevensLoader: React.FC<ContactgegevensLoaderProps> = ({ gebruikerId, render }) => {
  const { data: contactgegevens, error, isLoading } = useContactgegevens(gebruikerId);

  return (
    <>
      {error && !isLoading && (
        <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van de contactgegevens. Gelieve later nog eens opnieuw te proberen." />
      )}
      {!error && isLoading && <SimpleLoading message="Bezig met het ophalen van de contactgegevens..." />}
      {!isLoading && !error && contactgegevens && render(contactgegevens)}
    </>
  );
};
