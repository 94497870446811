import React from 'react';
import { useConfigContext } from '../context/config-context';
import { Maybe } from '../../util/maybe';
import ExternalLink from '../externallink/external-link';

interface ExternalHomepageLinkProps {
  hideExternalIcon?: boolean;
  className?: string;
}

const ExternalHomepageLink: React.FC<React.PropsWithChildren<ExternalHomepageLinkProps>> = ({ hideExternalIcon, className, children }) => {
  const context = useConfigContext();
  const homepageUrl = Maybe.toNullable(context)?.homepage || 'https://ovam.vlaanderen.be/materialen-informatiesysteem';

  return (
    <ExternalLink href={homepageUrl} hideExternalIcon={hideExternalIcon} className={className}>
      {children}
    </ExternalLink>
  );
};

export default ExternalHomepageLink;
