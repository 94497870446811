import React from 'react';
import 'antd/dist/reset.css';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';
import {
  createSelectWithSearchFilterColumn,
  createTextInputFilterColumn,
  createTimeRangeFilterColumn,
  createTonnageRangeFilterColumn,
} from '../../../components/table/default.datatable.options';
import { MeldingFilters, MeldingInzamelingBAPagingAndFiltering, useDeleteAllSelectedMeldingenInzamelingBA, useMeldingenInzamelingBA } from '../../melding.hook';
import { useRoleHook } from '../../../services/security/use-role-hook';
import getFilterValues from '../../../util/table-filter-utils';
import { useEuralcodeOptions, useInzamelwijzeBAOptions } from '../../melding-form-fields';
import { mapMeldingFilterValues, MeldingOverview } from '../../melding-overview';
import { MeldingOverviewTable } from '../../melding-overview-table';
import { mapMeldingPaginationValues, renderMateriaal, toMeldingOverview } from '../../melding-overview-common';
import { useInzamelwijzesBA } from '../../../enumerated-lists/enumeratedlists.hook';

export interface MeldingInzamelingBAOverviewProps {
  onChange: (newValues: MeldingInzamelingBAPagingAndFiltering) => void;
  pagingAndFiltering: MeldingInzamelingBAPagingAndFiltering;
  defaultPageSize: number;
}

const mapMeldingInzamelingBAFilterValues = (filters: Record<string, FilterValue | null>): MeldingFilters => ({
  ...mapMeldingFilterValues(filters),
  oorsprongPostcode: getFilterValues('oorsprong', filters)?.[0],
  inzamelwijzeCode: getFilterValues('inzamelwijze', filters)?.[0],
});

const MeldingInzamelingBAOverview: React.FC<MeldingInzamelingBAOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const { resolveOmschrijving: resolveInzamelwijzeOmschrijving } = useInzamelwijzesBA();
  const meldingenQuery = useMeldingenInzamelingBA(pagingAndFiltering);
  const deleteAllMutation = useDeleteAllSelectedMeldingenInzamelingBA();
  const { isBeheerder } = useRoleHook();

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapMeldingPaginationValues(pagination, defaultPageSize),
      ...mapMeldingInzamelingBAFilterValues(filters),
    });
  };

  const columns: ColumnsType<MeldingOverview> = [
    isBeheerder ? createTextInputFilterColumn<MeldingOverview>('Eigenaar', 'eigenaar', pagingAndFiltering.eigenaar) : null,
    createTextInputFilterColumn<MeldingOverview>('Identificatie', 'identificatie', pagingAndFiltering.identificatie),
    createTimeRangeFilterColumn<MeldingOverview>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot),
    createTextInputFilterColumn<MeldingOverview>('Oorsprong', 'oorsprong', pagingAndFiltering.oorsprong),
    createTextInputFilterColumn<MeldingOverview>('Bestemming', 'bestemming', pagingAndFiltering.bestemming),
    createTonnageRangeFilterColumn<MeldingOverview>('Tonnage', 'tonnage', pagingAndFiltering.tonnageVan, pagingAndFiltering.tonnageTot),
    {
      ...createSelectWithSearchFilterColumn<MeldingOverview>('Materiaal', 'materiaal', 'euralcodes', useEuralcodeOptions, pagingAndFiltering.materiaalcode),
      ellipsis: {
        showTitle: false,
      },
      render: (materiaal: any) => renderMateriaal(materiaal),
    },
    {
      ...createSelectWithSearchFilterColumn<MeldingOverview>('Inzamelwijze', 'inzamelwijze', 'inzamelwijzes', useInzamelwijzeBAOptions, pagingAndFiltering.inzamelwijzeCode),
      render: (code: any) => resolveInzamelwijzeOmschrijving(code),
    },
    createTimeRangeFilterColumn<MeldingOverview>('Laatst gewijzigd', 'versieTijdstip', true, pagingAndFiltering.versieTijdstipVan, pagingAndFiltering.versieTijdstipTot),
  ].flatMap((col) => (col ? [col] : []));

  const page = meldingenQuery.data;
  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toMeldingOverview),
      }
    : undefined;

  return (
    <MeldingOverviewTable
      title="Meldingen inzameling bedrijfsafval"
      baseUrl="/melding/inzameling/bedrijfsafval"
      downloadUrl="/ui/meldingen/inzameling/bedrijfsafval/download"
      allowDownload={!isBeheerder}
      allowNew={false}
      queryStatus={meldingenQuery}
      deleteAllMutation={deleteAllMutation}
      meldingFilters={pagingAndFiltering}
      columns={columns}
      defaultPageSize={defaultPageSize}
      page={mappedPage}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
    />
  );
};

export default MeldingInzamelingBAOverview;
