import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import FormTitle from '../components/form/form-title';
import { createOrUpdateContactgegevens } from './contactgegevens.hooks';
import ErrorAlert from '../components/alert/error-alert';
import { ContactgegevensJsonRequest, ContactgegevensJsonResponse } from '../generated';
import { ApiError } from '../util/axios-error-mapping';
import TextField from '../components/form/text-field';
import SubmitButton from '../components/button/submit-button';
import CancelButton from '../components/button/cancel-button';
import requiredField from '../util/required-field';
import Alert from '../components/alert/alert';
import emailadresField from '../util/emailadres-field';
import { formHasChanges } from '../util/form-has-changes';
import AuthenticationContext from '../services/security/authentication.context';
import InputField from '../components/form/input-field';
import MailTo from '../components/mailto/mail-to';
import ExternalLink from '../components/externallink/external-link';

interface ContactgegevensFormProps {
  contactgegevens: ContactgegevensJsonResponse;
  submitButtonLabel: string;
  submitButtonDisabledWhenNoChanges?: boolean;
  showCancelButton?: boolean;
  onUpdated?: () => void;
}

interface ContactgegevensFormValues {
  gebruikerEmailadres?: string;
  gebruikerTelefoonnummer?: string;
  organisatieEmailadres: string;
}

const contactgegevensValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  gebruikerEmailadres: emailadresField().nullable(),
  gebruikerTelefoonnummer: Yup.string()
    .nullable()
    .matches(/^\+[1-9]\d{0,2}\s(?:\d\s?){3,19}\d$/, 'Geldig telefoonnummer verwacht (voorbeeld: +32 2 111 22 33)'),
  organisatieEmailadres: requiredField(emailadresField()),
});

const composeContactgegevensFormValues = (contactgegevens: ContactgegevensJsonResponse): ContactgegevensFormValues => ({
  gebruikerEmailadres: contactgegevens?.gebruikerEmailadres ?? '',
  gebruikerTelefoonnummer: contactgegevens?.gebruikerTelefoonnummer ?? '',
  organisatieEmailadres: contactgegevens?.organisatieEmailadres ?? '',
});

const ContactgegevensForm: React.FC<ContactgegevensFormProps> = ({
  contactgegevens,
  submitButtonLabel,
  submitButtonDisabledWhenNoChanges = true,
  showCancelButton = true,
  onUpdated = () => {},
}) => {
  const mutation = useMutation<AxiosResponse<ContactgegevensJsonResponse>, Error | ApiError, ContactgegevensJsonRequest, unknown>(createOrUpdateContactgegevens);
  const [formValues, setFormValues] = useState<ContactgegevensFormValues>(composeContactgegevensFormValues(contactgegevens));
  const { gebruiker, updateAuthorisedScopes } = useContext(AuthenticationContext);

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    try {
      const request: ContactgegevensJsonRequest = {
        gebruikerEmailadres: values.gebruikerEmailadres?.trim() || undefined,
        gebruikerTelefoonnummer: values.gebruikerTelefoonnummer?.trim() || undefined,
        organisatieEmailadres: values.organisatieEmailadres?.trim() || undefined,
      };
      const { data: updatedContactgegevens } = await mutation.mutateAsync(request);
      updateAuthorisedScopes && axios.get('/ui/gebruiker/authorised-scopes').then(({ data: responseBody }) => updateAuthorisedScopes(responseBody));
      onUpdated();
      setFormValues(composeContactgegevensFormValues(updatedContactgegevens));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={formValues} enableReinitialize onSubmit={handleSubmit} validationSchema={contactgegevensValidationSchema} validateOnBlur={false}>
      {(formik) => (
        <Form spellCheck={false}>
          {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het opslaan van de contactgegevens is mislukt." />}
          <div className="vl-form-grid vl-form-grid--is-stacked">
            <FormTitle title="Gebruiker" />
            <InputField name="gebruiker.naam" label="Naam">
              <span>{contactgegevens?.gebruikerNaam ?? gebruiker.naam}</span>
            </InputField>
            <TextField label="E-mailadres" name="gebruikerEmailadres" placeholder="E-mailadres van de gebruiker" />
            <TextField label="Telefoonnummer" name="gebruikerTelefoonnummer" placeholder="Telefoonnummer van de gebruiker (voorbeeld: +32 2 123 45 67)" />

            <FormTitle title="Organisatie" />
            <InputField name="organisatie.naam" label="Naam">
              <span>{contactgegevens?.organisatienaam || gebruiker.organisatie}</span>
            </InputField>
            <TextField label="E-mailadres" name="organisatieEmailadres" placeholder="E-mailadres van de organisatie" required />

            <div className="vl-form-col--1-1 vl-u-spacer-top--medium">
              <Alert type="info" small dataCy="GDPR-message">
                De OVAM gebruikt deze persoonsgegevens alleen om contact op te nemen in geval van vragen of opmerkingen over het datamonitoringsysteem ‘MATIS’. We verwerken de
                gegevens altijd in overeenstemming met de bepalingen van de algemene verordening gegevensbescherming (AVG) en met de bepalingen van de federale en Vlaamse wet- en
                regelgeving over de bescherming van natuurlijke personen bij de verwerking van persoonsgegevens. Als u algemene vragen hebt over de manier waarop we uw
                persoonsgegevens verwerken, kunt u contact opnemen met de functionaris voor gegevensbescherming (FG) van de OVAM door te mailen naar <MailTo email="dpo@ovam.be" />.
                In het Engels is dat de data protection officer (DPO). Op de <ExternalLink href="https://ovam.vlaanderen.be/disclaimer">privacyverklaring</ExternalLink> kunt u meer
                informatie vinden over hoe de OVAM omgaat met uw persoonsgegevens.
              </Alert>
            </div>

            <div className="vl-form-col--1-1">
              <div className="vl-action-group vl-action-group--align-right">
                <SubmitButton label={submitButtonLabel} isSubmitting={mutation.isLoading} isDisabled={submitButtonDisabledWhenNoChanges && !formHasChanges(formik)} />
                {showCancelButton && <CancelButton link="/contactgegevens" label="Annuleren" isDisabled={mutation.isLoading} />}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactgegevensForm;
