import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { CREATE_MELDINGEN_DESCRIPTION, MELDING_INZAMELING_BA_CHAPTER_IN_MANUAL } from '../../melding.labels';
import MeldingCsvUploader from '../../melding-csv-uploader';
import { uploadNewMeldingInzamelingBACsv } from '../../melding-csv.hook';

const MeldingInzamelingBACsvCreatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/inzameling/bedrijfsafval/csv-nieuw">{CREATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadNewMeldingInzamelingBACsv}
      title={`${CREATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_INZAMELING_BA_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingInzamelingBACsvCreatePage;
