import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadNewMeldingVerwerkingCsv } from './melding-csv.hook';
import { CREATE_MELDINGEN_DESCRIPTION, MELDING_VERWERKING_CHAPTER_IN_MANUAL } from './melding.labels';
import MeldingCsvUploader from './melding-csv-uploader';

const MeldingVerwerkingCsvCreatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/verwerking/csv-nieuw">{CREATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadNewMeldingVerwerkingCsv}
      title={`${CREATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_VERWERKING_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingVerwerkingCsvCreatePage;
