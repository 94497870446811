import React from 'react';
import { DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import { MeldingInzamelingHADetailsJsonResponse, MeldingVerwerkingDetailsJsonResponse } from '../generated';

interface IhmDetailsFormSectionProps {
  melding: MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse;
}

const IhmDetailsFormSection: React.FC<IhmDetailsFormSectionProps> = ({ melding }) => {
  if (melding.btwNummer) {
    return (
      <DetailsFormSection title="IHM">
        <DetailsFormField label="BTW-nummer" data={melding.btwNummer} />
      </DetailsFormSection>
    );
  }
  return null;
};

export default IhmDetailsFormSection;
