/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OorsprongType {
  VLAAMSE_GEMEENTE = 'VLAAMSE_GEMEENTE',
  INZAMELRONDE = 'INZAMELRONDE',
  TRADER = 'TRADER',
  BELGISCHE_VESTIGING = 'BELGISCHE_VESTIGING',
  BUITENLANDSE_VESTIGING = 'BUITENLANDSE_VESTIGING',
  WERF = 'WERF',
  GEEN_ONDERNEMING = 'GEEN_ONDERNEMING',
  PARTICULIER = 'PARTICULIER',
  ZEESCHIP = 'ZEESCHIP',
}
