import React, { useState } from 'react';
import { Popover } from 'antd';
import ExternalHomepageLink from '../../components/navigation/external-homepage-link';

const ContactInfoPopover: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [contactInfoOpen, setContactInfoOpen] = useState<boolean>(false);
  const popoverContent = (
    <section className="contact-panel">
      <header role="none" className="contact-panel__header">
        <h1 className="contact-panel__header__title">Hulp nodig?</h1>
        <button type="button" className="contact-panel__header__close" onClick={() => setContactInfoOpen(false)}>
          <span className="vl-u-visually-hidden">Sluit hulp</span>
          <svg viewBox="0 0 32 32" aria-hidden="true" className="contact-panel__header__close__icon">
            <use href="#icon-cross" />
          </svg>
        </button>
      </header>
      <section className="contact-panel__content">
        <div className="contact-panel__scroll">
          <div>
            <div className="contact-panel__layout">
              <ul className="contact-panel__icon-list contact-panel__icon-list--bordered">
                <li className="contact-panel__icon-list__item">
                  <ExternalHomepageLink className="contact-panel__icon-list__item__block contact-panel__icon-list__item__block--has-action" hideExternalIcon>
                    <div className="contact-panel__icon-list__item__block__icon contact-panel__icon-list__item__block__icon--success">
                      <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" className="contact-panel__icon-list__item__block__icon__svg">
                        <use href="#icon-invert-info" />
                      </svg>
                    </div>
                    <div className="contact-panel__icon-list__item__block__content">
                      <div className="contact-panel__icon-list__item__block__content__main">
                        <span className="contact-panel__icon-list__item__block__content__main__title">
                          Op de OVAM-website vindt u alle informatie en handleidingen voor het gebruik van MATIS.
                        </span>
                      </div>
                      <svg viewBox="0 0 32 32" aria-hidden="true" role="img" className="contact-panel__icon-list__item__block__content__svg">
                        <use href="#icon-arrow-right-fat" />
                      </svg>
                      <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
                    </div>
                  </ExternalHomepageLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
  return (
    <Popover placement="bottom" open={contactInfoOpen} trigger="click" onOpenChange={setContactInfoOpen} overlayClassName="contact-panel-wrapper" content={popoverContent}>
      {children}
    </Popover>
  );
};

export default ContactInfoPopover;
