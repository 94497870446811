import React from 'react';
import { Field, useField } from 'formik';
import InputField, { IInputFieldProps } from './input-field';

export interface TextFieldProps extends IInputFieldProps {
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  valueMapper?: (val: string) => string;
}

const defaultValueMapper = (val: string) => val;

const TextField: React.FC<TextFieldProps> = ({ name, label, autoFocus, required, placeholder, disabled, valueMapper = defaultValueMapper }) => {
  const [, , helpers] = useField(name);

  const onChangeHandler = (e: any) => {
    const value = e?.target?.value ?? '';
    helpers.setValue(valueMapper(value));
  };

  return (
    <InputField name={name} label={label} required={required}>
      <Field
        id={name}
        name={name}
        data-testid={`input-${name}`}
        type="text"
        className="vl-input-field vl-input-field--block"
        autoFocus={autoFocus ?? false}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeHandler}
      />
    </InputField>
  );
};

export default TextField;
