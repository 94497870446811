import React from 'react';
import DelayedRender from '../delayedrender/delayed-render';

export interface ActionFeedbackSpinnerProps {
  message?: string;
}

export const ActionSpinnerWithoutDelay: React.FC<ActionFeedbackSpinnerProps> = ({ message }) => (
  <div className="vl-u-align-center">
    <div className="vl-loader" role="alert" aria-busy="true" />
    {message && (
      <p className="">
        <b>{message}</b>
      </p>
    )}
  </div>
);

export const ActionSpinner: React.FC<ActionFeedbackSpinnerProps> = () => (
  <DelayedRender>
    <ActionSpinnerWithoutDelay />
  </DelayedRender>
);
