import { ColumnProps } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import FilterTextInputDropdown from './filter/component.filter.text.input';
import FilterTimeRangeDropdown from './filter/component.filter.time';
import FilterSelectWithSearchDropdown, { UseOptionsResult } from './filter/component.filter.select.with.search';
import TonnageRangeFilterDropdown from './filter/tonnage-range-filter-dropdown';

type FilterDropdown = (props: FilterDropdownProps) => React.ReactNode;

function createFilterColumn<T>(title: string, label: string, key: string, filterDropdown: FilterDropdown): ColumnProps<T> {
  return {
    title,
    key,
    dataIndex: key,
    filterIcon: <SearchOutlined />,
    filterDropdown,
    className: `${label}-column`,
  };
}

const trimLabel = (title: string) => title.toLowerCase().replace(' ', '');

export function createTextInputFilterColumn<T>(title: string, key: string, selectedFilter?: React.Key): ColumnProps<T> {
  const labelTrimmed = trimLabel(title);
  const dropdown: FilterDropdown = (props) => <FilterTextInputDropdown dataCy={`${labelTrimmed}-filter-dropdown`} placeholder={title} {...props} />;
  const columnProps: ColumnProps<T> = createFilterColumn(title, labelTrimmed, key, dropdown);
  return {
    ...columnProps,
    filteredValue: [selectedFilter ?? ''].filter((s) => s),
  };
}

export function createTimeRangeFilterColumn<T>(title: string, key: string, timePicker: boolean, fromFilter?: React.Key, toFilter?: React.Key, picker?: any): ColumnProps<T> {
  const labelTrimmed = trimLabel(title);
  const dropdown: FilterDropdown = (props) => <FilterTimeRangeDropdown timePicker={timePicker} dataCy={labelTrimmed} {...props} picker={picker} />;
  const columnProps: ColumnProps<T> = createFilterColumn(title, labelTrimmed, key, dropdown);
  const filters = [fromFilter ?? '', toFilter ?? ''];
  const filteredValue = filters.filter((s) => s).length > 0 ? filters : null;
  return {
    ...columnProps,
    filteredValue,
  };
}

export function createSelectWithSearchFilterColumn<T>(
  title: string,
  key: string,
  noDataLabel: string,
  useOptionsHook: () => UseOptionsResult,
  selectedFilter?: React.Key,
): ColumnProps<T> {
  const labelTrimmed = trimLabel(title);
  const dropdown: FilterDropdown = (props) => (
    <FilterSelectWithSearchDropdown dataCy={`${labelTrimmed}-filter-dropdown`} noDataLabel={noDataLabel} useOptionsHook={useOptionsHook} placeholder={title} {...props} />
  );
  const columnProps: ColumnProps<T> = createFilterColumn(title, labelTrimmed, key, dropdown);
  return {
    ...columnProps,
    filteredValue: [selectedFilter ?? ''].filter((s) => s),
  };
}

export function createTonnageRangeFilterColumn<T>(title: string, key: string, fromFilter?: React.Key, toFilter?: React.Key): ColumnProps<T> {
  const labelTrimmed = trimLabel(title);
  const filterDropdown: FilterDropdown = (props) => <TonnageRangeFilterDropdown dataCy={labelTrimmed} {...props} />;
  const columnProps: ColumnProps<T> = createFilterColumn(title, labelTrimmed, key, filterDropdown);
  const filters = [fromFilter ?? '', toFilter ?? ''];
  const filteredValue = filters.filter((s) => s).length > 0 ? filters : null;
  return {
    ...columnProps,
    filteredValue,
  };
}
