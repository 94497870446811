import { FilterValue } from 'antd/lib/table/interface';
import { RecordProps } from '../components/table/overview-table';
import { MeldingFilters } from './melding.hook';
import getFilterValues from '../util/table-filter-utils';
import { MateriaalDetailsJsonResponse } from '../generated';

export interface MeldingOverview extends RecordProps {
  key: string;
  eigenaar: string;
  identificatie: string;
  periode: string;
  oorsprong: string;
  bestemming: string;
  tonnage: string;
  materiaal: string | MateriaalDetailsJsonResponse;
  versieTijdstip: string;
}

export const mapMeldingFilterValues = (filters: Record<string, FilterValue | null>): MeldingFilters => ({
  materiaalcode: getFilterValues('materiaal', filters)?.[0],
  versieTijdstipVan: getFilterValues('versieTijdstip', filters)?.[0],
  versieTijdstipTot: getFilterValues('versieTijdstip', filters)?.[1],
  identificatie: getFilterValues('identificatie', filters)?.[0],
  eigenaar: getFilterValues('eigenaar', filters)?.[0],
  oorsprong: getFilterValues('oorsprong', filters)?.[0],
  bestemming: getFilterValues('bestemming', filters)?.[0],
  tonnageVan: getFilterValues('tonnage', filters)?.[0],
  tonnageTot: getFilterValues('tonnage', filters)?.[1],
  periodeVan: getFilterValues('periode', filters)?.[0],
  periodeTot: getFilterValues('periode', filters)?.[1],
});
