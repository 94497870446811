import React from 'react';
import cx from 'classnames';
import { ButtonType, getClassForButtonType } from './button-type';

type Props = {
  label: string;
  type?: ButtonType;
  className?: string;
  isDisabled?: boolean;
  isNarrow?: boolean;
  dataCy?: string;
  onClick?: () => void;
};

const VlButton: React.FC<Props> = ({ type = 'primary', label, className, isDisabled, isNarrow, dataCy, onClick }) => (
  <button
    type="button"
    className={cx('vl-button', getClassForButtonType(type), className, { 'vl-button--narrow': isNarrow, 'vl-button--disabled': isDisabled })}
    disabled={isDisabled}
    onClick={onClick}
    data-cy={dataCy}
  >
    <span className="vl-button__label">{label}</span>
  </button>
);

export default VlButton;
