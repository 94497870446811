import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { sortByCode, useAfvalcodes, useInzamelwijzes, useNutsCodes, useVlaamsePostcodes } from '../enumerated-lists/enumeratedlists.hook';
import Afvalcode from '../melding/afvalcode.model';
import { EnumeratedListJsonResponse } from '../generated';
import { MultiSelect } from '../components/form/select/select-with-search';

export const AfvalcodeMultiSelect: React.FC = () => {
  const { data: afvalcodes } = useAfvalcodes();

  const selectOptions = useMemo(
    () =>
      sortByCode(afvalcodes).map((code) => ({
        value: code.code,
        label: Afvalcode.format(code),
      })),
    [afvalcodes],
  );

  return <MultiSelect options={selectOptions} label="Afvalcodes" name="afvalcodes" placeholder="Geen beperking" />;
};

export const WerkingsgebiedMultiSelect: React.FC = () => {
  const { data: postcodes } = useVlaamsePostcodes();

  const selectOptions = useMemo(
    () =>
      sortByCode(postcodes).map((code) => ({
        value: code.code,
        label: code.code,
      })),
    [postcodes],
  );

  return <MultiSelect options={selectOptions} label="Werkingsgebied" name="werkingsgebied" placeholder="Geen beperking" />;
};

export const InzamelwijzeMultiSelect: React.FC = () => {
  const { data: inzamelwijzen } = useInzamelwijzes();

  const selectOptions = useMemo(
    () =>
      sortByCode(inzamelwijzen).map((code) => ({
        value: code.code,
        label: `${code.code} ${code.omschrijving}`.trim(),
      })),
    [inzamelwijzen],
  );

  return <MultiSelect options={selectOptions} label="Inzamelwijzen" name="inzamelwijzen" placeholder="Geen beperking" />;
};

export const GBA_OPTION = { code: 'GBA', omschrijving: 'Gelijkaardig bedrijfsafval' };

export const sortVerdelingsgebiedOptionsWithGBAFirst = (options?: EnumeratedListJsonResponse[]): EnumeratedListJsonResponse[] =>
  sortBy(options || [], [(value) => value.code !== GBA_OPTION.code, (value) => value.code]);

export const formatVerdelingsgebiedOptie = (optie: EnumeratedListJsonResponse): string => `${optie.code} (${optie.omschrijving})`.trim();

export const VerdelingsgebiedMultiSelect: React.FC = () => {
  const { data: nutsCodes } = useNutsCodes();

  const selectOptions = useMemo(
    () =>
      sortVerdelingsgebiedOptionsWithGBAFirst(nutsCodes || []).map((option) => ({
        value: option.code,
        label: formatVerdelingsgebiedOptie(option),
      })),
    [nutsCodes],
  );

  return (
    <MultiSelect
      options={[
        {
          value: GBA_OPTION.code,
          label: formatVerdelingsgebiedOptie(GBA_OPTION),
        },
        ...selectOptions,
      ]}
      label="Extra codes voor verdeling"
      name="extraCodesVoorVerdeling"
    />
  );
};
