import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Routes } from 'react-router-dom';
import OrganisatieOverview from './organisatie-overview';
import OrganisatieDetailPage from './organisatie-detail.page';
import OrganisatieUpdatePage from './organisatie-update.page';
import OrganisatieCreatePage from './organisatie-create.page';
import { Scope } from '../generated';
import LoadingPage from '../general/loading-page';
import AuthorisedScope from '../general/authorised-scope';
import { useAuthorisationInfo } from '../services/security/authorisation';

const title = 'Administratie organisaties';
const OrganisatieOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.BEHEER_ORGANISATIE}>
        <BreadcrumbsItem to="/administratie">{title}</BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<OrganisatieOverview title={title} />} />
          <Route path="nieuw" element={<OrganisatieCreatePage />} />
          <Route path=":organisatiecode/correctie" element={<OrganisatieUpdatePage />} />
          <Route path=":organisatiecode" element={<OrganisatieDetailPage />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default OrganisatieOverviewPage;
