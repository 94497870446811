import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues, useField } from 'formik';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { UseMutationResult } from 'react-query/types/react/types';
import SubmitButton from '../components/button/submit-button';
import CancelButton from '../components/button/cancel-button';
import { bestemmingValidationSchema } from './bestemming/bestemming-form';
import { createBtwNummerValidationSchema } from '../organisatie/btw-nummer.model';
import { oorsprongValidationSchema } from './bestemming/oorsprong-form';
import { initialOorsprongValues, OorsprongFormValues } from './bestemming/oorsprong.model';
import { BestemmingFormValues, isBelgie } from './bestemming/bestemming.model';
import { ApiError } from '../util/axios-error-mapping';
import { periodeWaardeValidationSchema, tonnageValidationSchema } from './melding-form';
import { useVlaamsePostcodes } from '../enumerated-lists/enumeratedlists.hook';
import { EURALCODE, MATERIAALCODE } from './materiaal.model';
import ValidatieFoutenAlert from '../components/alert/validatie-fouten-alert';
import { isBestemmingForEuralcode, isToepassingswijzeRequired, isVerwerkingRequired } from './melding-form-fields';
import { vrijeTekstSchema } from './vrije-tekst-schema';
import requiredField from '../util/required-field';
import ErrorAlert from '../components/alert/error-alert';
import { ScrollToFieldError } from './scroll-to-field-error';
import {
  BestemmingJsonResponse,
  EnumeratedListJsonResponse,
  MeldingType,
  MeldingVerwerkingJsonRequest,
  ValidatieFout,
  ValidationResponseMeldingVerwerkingJsonRequest,
} from '../generated';
import { displayClean } from './melding-detail.hook';
import { isVlarema9VanToepassing } from './periode.model';
import { BELGISCHE_WERF_BUITEN_VLAANDEREN } from './bestemming/belgische-werf-buiten-vlaanderen';
import { WERF_ALS_TIJDELIJKE_INSTALLATIE } from './bestemming/tijdelijke-installatie';

export interface IMateriaalEnum {
  type: string;
  value: string;
}

export type MeldingVerwerkingFormValues = {
  identificatie: string;
  eigenaar?: string;
  meldingType: string;
  tonnage: string;
  periodeEenheid: string;
  periodeWaarde: string;
  materiaalEnum: IMateriaalEnum;
  materiaalOmschrijving: string;
  materiaalKwaliteit: string;
  dierlijkBijproduct: string;
  oorsprong: OorsprongFormValues;
  bestemming: BestemmingFormValues;
  rdCode: string;
  verwerkingswijze: string;
  verwerkingOmschrijving: string;
  inputInRecyclage: string;
  vervoerswijze: string;
  toepassingswijze: string;
  ihmBtwNummer: string;
};

export const defaultMeldingVerwerkingFormValues: MeldingVerwerkingFormValues = {
  identificatie: '',
  eigenaar: '',
  meldingType: '',
  tonnage: '',
  periodeEenheid: '',
  periodeWaarde: '',
  materiaalEnum: {
    type: '',
    value: '',
  },
  materiaalOmschrijving: '',
  materiaalKwaliteit: '',
  dierlijkBijproduct: '',
  oorsprong: {
    ...initialOorsprongValues,
  },
  bestemming: {
    type: '',
    vestigingsnummer: '',
    vestigingsnummerVerplicht: false,
    btwNummer: '',
    ondernemingsnummer: '',
    naam: '',
    straat: '',
    huisnummer: '',
    uitbreiding: '',
    postcode: '',
    gemeente: '',
    land: '',
    commentaar: '',
  },
  rdCode: '',
  verwerkingswijze: '',
  verwerkingOmschrijving: '',
  inputInRecyclage: '',
  vervoerswijze: '',
  toepassingswijze: '',
  ihmBtwNummer: '',
};

export interface MeldingVerwerkingFormProps {
  mutateFn: (request: MeldingVerwerkingJsonRequest) => Promise<AxiosResponse>;
  initialValues: MeldingVerwerkingFormValues;
  validationSchemaFactory: (vlaamsePostcodes: EnumeratedListJsonResponse[]) => Yup.ObjectSchema<any>;
  submitLabel: string;
  cancelLink: string;
}

export interface MeldingVerwerkingInnerFormProps {
  mutation: UseMutationResult<AxiosResponse<ValidationResponseMeldingVerwerkingJsonRequest>, Error | ApiError, MeldingVerwerkingJsonRequest>;
  validatieErrors: ValidatieFout[];
  submitLabel: string;
  cancelLink: string;
  formik: FormikProps<MeldingVerwerkingFormValues>;
}

const createMeldingVerwerkingRequest = (values: FormikValues): MeldingVerwerkingJsonRequest => {
  const { oorsprong, bestemming } = values;

  return {
    identificatie: values.identificatie?.trim() || null,
    eigenaar: values.eigenaar?.trim() || null,
    meldingType: values.meldingType?.trim(),
    tonnage: values.tonnage?.trim(),
    periode: {
      eenheid: values.periodeEenheid?.trim(),
      waarde: values.periodeWaarde?.trim(),
    },
    materiaal: {
      materiaalcode: values.materiaalEnum.type === MATERIAALCODE ? values.materiaalEnum.value : null,
      euralcode: values.materiaalEnum.type === EURALCODE ? values.materiaalEnum.value : null,
      omschrijving: values.materiaalOmschrijving?.trim(),
      kwaliteit: values.materiaalKwaliteit?.trim() || null,
      dierlijkBijproduct: values.dierlijkBijproduct || null,
    },
    oorsprong: {
      type: oorsprong.type,
      vestigingsnummer: oorsprong.vestigingsnummer ? oorsprong.vestigingsnummer.replaceAll('.', '')?.trim() : null,
      btwNummer: oorsprong.btwNummer?.trim() || null,
      ondernemingsnummer: oorsprong.ondernemingsnummer?.trim() || null,
      naam: oorsprong.naam?.trim() || null,
      straat: oorsprong.straat?.trim() || null,
      huisnummer: oorsprong.huisnummer?.trim() || null,
      uitbreiding: oorsprong.uitbreiding?.trim() || null,
      postcode: oorsprong.postcode?.trim() || null,
      gemeente: oorsprong.gemeente?.trim() || null,
      land: oorsprong.land?.trim() || null,
      commentaar: oorsprong.commentaar?.trim() || null,
    },
    bestemming: {
      type: bestemming.type?.trim(),
      vestigingsnummer: bestemming.vestigingsnummer ? bestemming.vestigingsnummer.replaceAll('.', '')?.trim() : null,
      btwNummer: bestemming.btwNummer?.trim() || null,
      ondernemingsnummer: bestemming.ondernemingsnummer?.trim() || null,
      naam: bestemming.naam?.trim() || null,
      straat: bestemming.straat?.trim() || null,
      huisnummer: bestemming.huisnummer?.trim() || null,
      uitbreiding: bestemming.uitbreiding?.trim() || null,
      postcode: bestemming.postcode?.trim() || null,
      gemeente: bestemming.gemeente?.trim() || null,
      land: bestemming.land?.trim() || null,
      commentaar: bestemming.commentaar?.trim() || null,
    },
    verwerking: {
      rdCode: values.rdCode?.trim() || null,
      wijze: values.verwerkingswijze?.trim() || null,
      omschrijving: values.verwerkingOmschrijving?.trim() || null,
      inputInRecyclage: values.inputInRecyclage?.trim() || null,
    },
    toepassingswijze: values.toepassingswijze?.trim() || null,
    ihm: {
      btwNummer: values.ihmBtwNummer?.trim() || null,
    },
    vervoerswijze: values.vervoerswijze?.trim(),
  };
};

function disableSubmit({ values, initialValues }: FormikProps<MeldingVerwerkingFormValues>) {
  return JSON.stringify(values) === JSON.stringify(initialValues);
}

const verwerkingField = (vlaamsePostcodes: EnumeratedListJsonResponse[]) =>
  Yup.string().when(['meldingType', 'bestemming', 'materiaalEnum', 'periodeEenheid', 'periodeWaarde'], {
    is: (type: string, bestemming: BestemmingJsonResponse, materiaalEnum: IMateriaalEnum, periodeEenheid: string, periodeWaarde: string) =>
      isVerwerkingRequired(isVlarema9VanToepassing(periodeEenheid, periodeWaarde), materiaalEnum, type, bestemming, vlaamsePostcodes),
    then: (schema: Yup.StringSchema) => requiredField(schema),
    otherwise: (schema: Yup.StringSchema) => schema,
  });

export const createMeldingVerwerkingRequestValidationSchema = (vlaamsePostcodes: EnumeratedListJsonResponse[]) =>
  Yup.object().shape({
    meldingType: requiredField(),
    periodeEenheid: requiredField(),
    periodeWaarde: periodeWaardeValidationSchema,
    tonnage: tonnageValidationSchema,
    materiaalOmschrijving: requiredField(vrijeTekstSchema().max(1000, 'Materiaal omschrijving kan niet meer dan 1000 tekens bevatten')),
    materiaalEnum: Yup.object().when('bestemming', ([bestemming], schema) =>
      schema.shape({
        value: Yup.string().when('type', ([type], valueSchema: Yup.StringSchema) => {
          if (type === EURALCODE && bestemming.type === 'WERF') {
            return valueSchema
              .test('euralcode-bestemming-werf', `Type bestemming '${displayClean(bestemming.type)}' is niet toegelaten voor de gekozen ${EURALCODE}`, ((
                euralcode: string | undefined,
              ) => [WERF_ALS_TIJDELIJKE_INSTALLATIE, BELGISCHE_WERF_BUITEN_VLAANDEREN].some((awv) => awv.supportsEuralcode(euralcode ?? ''))) as TestFunction<any, any>)
              .test('euralcode-bestemming-werf-belgie', `Enkel een Belgische werf is als bestemming toegelaten voor de gekozen ${EURALCODE}`, (() =>
                isBelgie(bestemming)) as TestFunction<any, any>)
              .test(
                'euralcode-bestemming-werf-buiten-vlaanderen',
                BELGISCHE_WERF_BUITEN_VLAANDEREN.errorMessage(bestemming),
                ((euralcode: string | undefined) =>
                  WERF_ALS_TIJDELIJKE_INSTALLATIE.supportsEuralcode(euralcode ?? '') ||
                  BELGISCHE_WERF_BUITEN_VLAANDEREN.matches(bestemming, vlaamsePostcodes, euralcode ?? '')) as TestFunction<any, any>,
              )
              .test(
                'euralcode-bestemming-werf-tijdelijke-installatie',
                WERF_ALS_TIJDELIJKE_INSTALLATIE.errorMessage(bestemming),
                ((euralcode: string | undefined) =>
                  BELGISCHE_WERF_BUITEN_VLAANDEREN.supportsEuralcode(euralcode ?? '') ||
                  WERF_ALS_TIJDELIJKE_INSTALLATIE.matches(bestemming, vlaamsePostcodes, euralcode ?? '')) as TestFunction<any, any>,
              );
          }
          return type === MATERIAALCODE || isBestemmingForEuralcode(bestemming?.type ?? '')
            ? valueSchema
            : valueSchema.max(0, `${EURALCODE} mag niet geselecteerd worden in combinatie met bestemming '${displayClean(bestemming.type)}'`);
        }),
      }),
    ),
    materiaalKwaliteit: vrijeTekstSchema().nullable().max(1000, 'Materiaal kwaliteit kan niet meer dan 1000 tekens bevatten'),
    oorsprong: oorsprongValidationSchema,
    bestemming: bestemmingValidationSchema,
    rdCode: verwerkingField(vlaamsePostcodes),
    verwerkingswijze: verwerkingField(vlaamsePostcodes),
    verwerkingOmschrijving: vrijeTekstSchema().nullable().max(1000, 'Verwerking omschrijving kan niet meer dan 1000 tekens bevatten'),
    inputInRecyclage: vrijeTekstSchema().nullable().max(1000, 'Verwerking input in recyclage kan niet meer dan 1000 tekens bevatten'),
    vervoerswijze: requiredField(),
    toepassingswijze: Yup.string().when(['bestemming', 'materiaalEnum'], {
      is: (bestemming: BestemmingJsonResponse, materiaalEnum: IMateriaalEnum) => isToepassingswijzeRequired(bestemming, materiaalEnum, vlaamsePostcodes),
      then: (schema: Yup.StringSchema) => requiredField(schema),
      otherwise: (schema: Yup.StringSchema) => schema.optional(),
    }),
    ihmBtwNummer: createBtwNummerValidationSchema(),
  });

export const isVestigingsnummerRequiredForOorsprong = (meldingType: string) => meldingType !== MeldingType.IN;
export const isVestigingsnummerRequiredForBestemming = (meldingType: string) => meldingType !== MeldingType.UIT;

const MeldingVerwerkingInnerForm: React.FC<React.PropsWithChildren<MeldingVerwerkingInnerFormProps>> = ({
  mutation,
  validatieErrors,
  submitLabel,
  cancelLink,
  formik,
  children,
}) => {
  const [, , oorsprongVestigingsnummerVerplicht] = useField('oorsprong.vestigingsnummerVerplicht');
  const [, , bestemmingVestigingsnummerVerplicht] = useField('bestemming.vestigingsnummerVerplicht');
  const [{ value: meldingType }] = useField('meldingType');
  useEffect(() => {
    bestemmingVestigingsnummerVerplicht.setValue(isVestigingsnummerRequiredForBestemming(meldingType));
    oorsprongVestigingsnummerVerplicht.setValue(isVestigingsnummerRequiredForOorsprong(meldingType));
  }, [meldingType]);

  return (
    <Form spellCheck={false}>
      {mutation.isError && (
        <ErrorAlert error={mutation.error} defaultMessage="Er is een fout opgetreden bij het ingeven van de melding. Gelieve later nog eens opnieuw te proberen." scrollIntoView />
      )}
      {validatieErrors.length > 0 && <ValidatieFoutenAlert errors={validatieErrors} />}
      <div className="vl-form-grid vl-form-grid--is-stacked">
        {children}

        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <SubmitButton label={submitLabel} isSubmitting={mutation.isLoading} isDisabled={disableSubmit(formik) || mutation.isLoading} />
            <CancelButton link={cancelLink} isDisabled={mutation.isLoading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export const MeldingVerwerkingForm: React.FC<React.PropsWithChildren<MeldingVerwerkingFormProps>> = ({
  mutateFn,
  cancelLink,
  initialValues,
  validationSchemaFactory,
  submitLabel,
  children,
}) => {
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<ValidationResponseMeldingVerwerkingJsonRequest>, Error | ApiError, MeldingVerwerkingJsonRequest, unknown>((values) =>
    mutateFn(values),
  );
  const [validatieErrors, setValidatieErrors] = useState<ValidatieFout[]>([]);
  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<MeldingVerwerkingFormValues>) => {
    console.log(`handling submit ${values}`); // eslint-disable-line no-console
    const meldingVerwerkingRequest = createMeldingVerwerkingRequest(values);
    setValidatieErrors([]);
    try {
      const {
        data: { validatieFouten },
      } = await mutation.mutateAsync(meldingVerwerkingRequest);
      if (!validatieFouten || validatieFouten?.length > 0) {
        setValidatieErrors(validatieFouten ?? []);
        window.scrollTo(0, 0);
        setSubmitting(false);
      } else {
        navigate(cancelLink);
      }
    } finally {
      if (mutation.isError) {
        window.scrollTo(0, 0);
        setSubmitting(false);
      }
    }
  };
  const { data: vlaamsePostcodes } = useVlaamsePostcodes();
  const validationSchema = useMemo(() => validationSchemaFactory(vlaamsePostcodes ?? []), [vlaamsePostcodes]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnBlur={false}>
      {(formik) => (
        <>
          <ScrollToFieldError />
          <MeldingVerwerkingInnerForm mutation={mutation} validatieErrors={validatieErrors} submitLabel={submitLabel} cancelLink={cancelLink} formik={formik}>
            {children}
          </MeldingVerwerkingInnerForm>
        </>
      )}
    </Formik>
  );
};
