import React from 'react';
import CsvUploader from '../components/file/csv-uploader';
import { ReferentieJsonResponse } from '../generated';

export interface ValidatievraagCsvProps {
  handleUpload: (formData: FormData) => Promise<ReferentieJsonResponse>;
  title: string;
  chapterInManual: string;
}

const ValidatievraagCsvUploader: React.FC<ValidatievraagCsvProps> = ({ handleUpload, title, chapterInManual }) => (
  <CsvUploader
    handleUpload={handleUpload}
    title={title}
    csvEntitiesLabel="validatievragen"
    chapterInManual={chapterInManual}
    uploadLimitExtractor={(config) => config.csv.validatievraagUploadLimit}
  />
);
export default ValidatievraagCsvUploader;
