import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import React from 'react';
import InzamelrechtHACreateForm from './inzamelrecht-ha-create-form';

const InzamelrechtHACreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/inzamelrechten/huishoudelijk-afval/nieuw">Nieuw inzamelrecht HA</BreadcrumbsItem>
      <InzamelrechtHACreateForm />
    </div>
  </section>
);

export default InzamelrechtHACreatePage;
