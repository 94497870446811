import { useQuery } from 'react-query';
import axios from 'axios';
import { sortBy } from 'lodash';
import { EnumeratedListJsonResponse } from '../generated';

function useEnumeratedList(url: string, enabled = true): EnumeratedListsHookResponse {
  const baseUrl = '/ui/list';
  const { data, isLoading } = useQuery<EnumeratedListJsonResponse[], Error>(
    `${baseUrl}${url}`,
    async () => {
      const { data: response } = await axios.get<EnumeratedListJsonResponse[]>(`${baseUrl}${url}`);
      return response;
    },
    { enabled },
  );
  return { data, isLoading, resolveOmschrijving: (code?: string) => data?.find((value) => value.code === code)?.omschrijving };
}

interface EnumeratedListsHookResponse {
  data?: EnumeratedListJsonResponse[];
  isLoading: boolean;
  resolveOmschrijving: (code?: string) => string | undefined;
}

export const sortByCode = (data?: EnumeratedListJsonResponse[]): EnumeratedListJsonResponse[] => (data ? sortBy(data, [(value) => value.code]) : []);

export const useAfvalcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/afvalcodes');

export const useInzamelwijzes = (): EnumeratedListsHookResponse => useEnumeratedList('/inzamelwijzes');

export const useInzamelwijzesBA = (): EnumeratedListsHookResponse => useEnumeratedList('/inzamelwijzes-ba');

export const useMateriaalcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/materiaalcodes');

export const useEuralcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/euralcodes');

export const usePostcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/postcodes');

export const useVlaamsePostcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/vlaamse-postcodes');

export const useLanden = (enabled: boolean): EnumeratedListsHookResponse => useEnumeratedList('/landen', enabled);

export const useGeregistreerdeTraders = (): EnumeratedListsHookResponse => useEnumeratedList('/geregistreerde-traders');

export const useRDcodes = (): EnumeratedListsHookResponse => useEnumeratedList('/rd-codes');

export const useNutsCodes = (): EnumeratedListsHookResponse => useEnumeratedList('/nuts-codes');

export const useDierlijkeBijproducten = (): EnumeratedListsHookResponse => useEnumeratedList('/dierlijke-bijproducten');
