import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query/types/react/types';
import { Pageable } from '../components/table/pageable';
import { Page } from '../components/table/page';
import { uploadCsv } from '../melding/melding-csv.hook';
import {
  PageableParamsJsonRequest,
  PageVerdelingMeldingJsonResponse,
  ReferentieJsonResponse,
  VerdelingDetailsJsonResponse,
  VerdelingOverviewJsonResponse,
  VerdelingFiltersJsonRequest,
} from '../generated';
import { axiosParamsSerializer } from '../util/axios-params-serializer';

export const verdelingenBaseUrl = '/ui/verdelingen';

export interface VerdelingPagingAndFiltering extends Pageable, VerdelingFiltersJsonRequest {}

export const useVerdelingen = (params: VerdelingPagingAndFiltering): UseQueryResult<Page<VerdelingOverviewJsonResponse>, Error> =>
  useQuery<Page<VerdelingOverviewJsonResponse>, Error>([verdelingenBaseUrl, params], async () => {
    const { data } = await axios.get<Page<VerdelingOverviewJsonResponse>>(verdelingenBaseUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });

export function useDeleteVerdeling(): UseMutationResult<AxiosResponse, Error, VerdelingDetailsJsonResponse> {
  return useMutation<AxiosResponse, Error, VerdelingDetailsJsonResponse, unknown>((v) => axios.delete(`${verdelingenBaseUrl}/${v.referentie}`));
}
export function useDeleteAllSelectedVerdelingen(): UseMutationResult<AxiosResponse, Error, string[]> {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, Error, string[], unknown>((m) => axios.post(`${verdelingenBaseUrl}/delete-all`, m), {
    onSuccess: () => {
      queryClient.invalidateQueries(verdelingenBaseUrl);
    },
  });
}

export const useVerdelingMeldingen = (referentie: string, pageParams: PageableParamsJsonRequest): UseQueryResult<PageVerdelingMeldingJsonResponse, Error> => {
  const verdelingMeldingenUrl = `${verdelingenBaseUrl}/${referentie}/meldingen`;
  return useQuery<PageVerdelingMeldingJsonResponse, Error>([verdelingMeldingenUrl, pageParams], async () => {
    const { data } = await axios.get<PageVerdelingMeldingJsonResponse>(verdelingMeldingenUrl, { params: pageParams, paramsSerializer: axiosParamsSerializer });
    return data;
  });
};

export const uploadNewVerdelingCsv = async (formData: FormData): Promise<ReferentieJsonResponse> => {
  const response = await axios.post<ReferentieJsonResponse>(`${verdelingenBaseUrl}/upload-new`, formData);
  return response.data;
};

export const uploadExistingVerdelingCsv = async (formData: FormData): Promise<ReferentieJsonResponse> => uploadCsv(`${verdelingenBaseUrl}/upload-existing`, formData);
