import React from 'react';
import axios, { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import FileSaver from 'file-saver';
import cx from 'classnames';
import { useQuery } from 'react-query';
import { ApiError } from '../../util/axios-error-mapping';
import { axiosParamsSerializer } from '../../util/axios-params-serializer';

export interface DownloadResponse {
  fileContents: Blob;
  filename: string;
}

interface DownloadButtonProps {
  label: string;
  url: string;
  queryParams?: any;
  beforeDownloadStarted?: () => void;
  onDownloadFailed?: (error: ApiError | Error) => void;
  className?: string;
  disabled?: boolean;
}

const extractFilename = (headers: AxiosResponseHeaders | Partial<RawAxiosResponseHeaders>) => {
  const contentDispositionHeader = headers['content-disposition'];
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = regex.exec(contentDispositionHeader || '');
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return null;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ label, url, queryParams, beforeDownloadStarted, onDownloadFailed, className, disabled = false }) => {
  const { isFetching, refetch: performDownload } = useQuery<AxiosResponse>(
    [url, queryParams],
    () =>
      axios.get(url, {
        params: queryParams,
        paramsSerializer: axiosParamsSerializer,
      }),
    {
      enabled: false,
      retry: false,
    },
  );

  const handleDownload = async () => {
    try {
      beforeDownloadStarted?.();
      const { data: downloadResponse } = await performDownload<AxiosResponse>({ throwOnError: true });
      if (downloadResponse?.data && downloadResponse?.headers) {
        FileSaver.saveAs(new Blob([downloadResponse.data]), extractFilename(downloadResponse.headers) || '');
      } else {
        onDownloadFailed?.(new Error('Download response was empty'));
      }
    } catch (error) {
      onDownloadFailed?.(error as Error);
    }
  };

  return (
    <button
      type="button"
      data-cy="download-button"
      className={cx('vl-button', 'vl-button--secondary', className || '', { 'vl-button--loading': isFetching }, { 'vl-button--disabled': isFetching || disabled })}
      disabled={isFetching || disabled}
      title={label}
      onClick={handleDownload}
    >
      <span className="vl-button__label">{label}</span>
    </button>
  );
};

export default DownloadButton;
