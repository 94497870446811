import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadExistingMeldingInzamelingHACsv } from './melding-csv.hook';
import { MELDING_INZAMELING_HA_CHAPTER_IN_MANUAL, UPDATE_MELDINGEN_DESCRIPTION } from './melding.labels';
import MeldingCsvUploader from './melding-csv-uploader';

const MeldingInzamelingHACsvUpdatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/inzameling/huishoudelijk-afval/csv-aanvulling">{UPDATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadExistingMeldingInzamelingHACsv}
      title={`${UPDATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_INZAMELING_HA_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingInzamelingHACsvUpdatePage;
