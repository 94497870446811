import React from 'react';
import axios from 'axios';
import { InzamelrechtHAJsonRequest, InzamelrechtHAOverviewJsonResponse } from '../generated';
import { InzamelrechtHAForm, InzamelrechtHAFormValues } from './inzamelrecht-ha-form';

const createMutation = async (request: InzamelrechtHAJsonRequest) => axios.post<InzamelrechtHAOverviewJsonResponse>('/ui/inzamelrechten/huishoudelijk-afval', request);

const defaultValues: InzamelrechtHAFormValues = {
  eigenaar: '',
  periodeVan: '',
  periodeTot: '',
  afvalcodes: [],
  werkingsgebied: [],
  inzamelwijzen: [],
  exclusief: false,
};

const InzamelrechtHACreateForm: React.FC<any> = () => (
  <InzamelrechtHAForm mutateFn={createMutation} cancelLink="/inzamelrechten/huishoudelijk-afval" submitLabel="Inzamelrecht aanmaken" initialValues={defaultValues} />
);

export default InzamelrechtHACreateForm;
