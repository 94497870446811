import React, { Key, useCallback, useState } from 'react';
import { UseMutationResult } from 'react-query/types/react/types';
import { AxiosResponse } from 'axios';
import { Tooltip } from 'antd';
import OverviewTable, { OverviewTableProps, RecordProps } from '../components/table/overview-table';
import Alert from '../components/alert/alert';
import ActionGroupRight from '../components/button/action-group';
import RouteButton from '../components/button/route-button';
import { CREATE_MELDINGEN_DESCRIPTION, UPDATE_MELDINGEN_DESCRIPTION } from './melding.labels';
import MeldingCsvDownloadButton from './melding-csv-download-button';
import { MeldingFilters } from './melding.hook';
import ErrorAlert from '../components/alert/error-alert';
import DeleteButtonWithConfirm from '../components/button/delete-with-confirm';
import { QueryStatusInfo } from '../components/table/query-status-info';

interface PartialOverviewTableProps<RESPONSE> extends Pick<OverviewTableProps<RESPONSE>, 'columns' | 'defaultPageSize' | 'page' | 'updatePagingAndFilteringState'> {}

export interface MeldingOverviewTableProps<RESPONSE> extends PartialOverviewTableProps<RESPONSE> {
  title: string;
  baseUrl: string;
  downloadUrl: string;
  allowDownload: boolean;
  allowDeleteAll?: boolean;
  allowNew?: boolean;
  queryStatus: QueryStatusInfo;
  deleteAllMutation: UseMutationResult<AxiosResponse, Error, string[]>;
  meldingFilters: MeldingFilters;
}

export const MeldingOverviewTable = <T extends RecordProps>({
  title,
  baseUrl,
  downloadUrl,
  allowDownload,
  allowDeleteAll = true,
  allowNew = true,
  queryStatus,
  deleteAllMutation,
  meldingFilters,
  columns,
  defaultPageSize,
  page,
  updatePagingAndFilteringState,
}: MeldingOverviewTableProps<T>) => {
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const handleClickDelete = useCallback(() => {
    if (selectedRowKeys.length > 0) {
      deleteAllMutation.mutate(selectedRowKeys.map((k) => k.toString()));
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys, deleteAllMutation, setSelectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record: T) => ({ disabled: record?.bevroren ?? false }),
    renderCell: (value: boolean, record: T, index: number, originNode: React.ReactNode) =>
      record.bevroren ? (
        <Tooltip title="Gevalideerde meldingen kan u niet verwijderen" placement="right">
          {originNode}
        </Tooltip>
      ) : (
        originNode
      ),
  };

  return (
    <OverviewTable<T>
      title={title}
      itemsLabel="meldingen"
      columns={columns}
      page={page}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
      error={queryStatus.error}
      notFoundMessage="Geen meldingen gevonden."
      isLoading={queryStatus.isLoading || deleteAllMutation.isLoading}
      isFetching={queryStatus.isFetching}
      rowSelection={rowSelection}
    >
      <div className="vl-grid">
        {!!downloadErrorMessage && (
          <div className="vl-col--1-1">
            <Alert type="error" title="Fout" scrollIntoView>
              {downloadErrorMessage}
            </Alert>
          </div>
        )}
        {deleteAllMutation.isError && (
          <div className="vl-col--1-1">
            <ErrorAlert
              error={deleteAllMutation.error}
              defaultMessage="Er is een fout opgetreden bij het verwijderen van de meldingen. Gelieve later nog eens opnieuw te proberen."
              scrollIntoView
            />
          </div>
        )}
        <div className="vl-col--1-1 vl-u-spacer">
          <ActionGroupRight>
            <div className="vl-u-spacer">
              <RouteButton className="vl-button--secondary vl-button--narrow" label={CREATE_MELDINGEN_DESCRIPTION} link={`${baseUrl}/csv-nieuw`} />
              <RouteButton className="vl-button--secondary vl-button--narrow" label={UPDATE_MELDINGEN_DESCRIPTION} link={`${baseUrl}/csv-aanvulling`} />
              {allowDeleteAll && (
                <DeleteButtonWithConfirm
                  className="vl-button--narrow"
                  confirmationTitle={`Bent u zeker dat u deze ${selectedRowKeys.length} meldingen wil verwijderen?`}
                  onDelete={handleClickDelete}
                  isDisabled={selectedRowKeys.length === 0}
                  isDeleting={deleteAllMutation.isLoading}
                />
              )}
              {allowDownload && (
                <MeldingCsvDownloadButton
                  url={downloadUrl}
                  meldingFilters={meldingFilters}
                  totalElementsInOverview={page?.totalElements ?? 0}
                  onErrorMessageChanged={(errorMessage) => setDownloadErrorMessage(errorMessage)}
                />
              )}
              {allowNew && <RouteButton className=" vl-button--narrow" label="Nieuwe melding" link={`${baseUrl}/nieuw`} />}
            </div>
          </ActionGroupRight>
        </div>
      </div>
    </OverviewTable>
  );
};
