import React from 'react';
import TextField, { TextFieldProps } from '../components/form/text-field';
import { formulaInjectionChars, vrijeTekstSchema } from '../melding/vrije-tekst-schema';

const formulaInjectionCharsOrganisatienaam = formulaInjectionChars.filter((char) => char !== '@');
const messageOrganisatienaam = (chars: string[]) => `Naam mag niet beginnen met één van de volgende leestekens [${chars.join(', ')}]`;
const organisatienaamMapper = (value: string) => value.replace(/[@]/g, '(a)');

export const organisatienaamSchema = vrijeTekstSchema(formulaInjectionCharsOrganisatienaam, messageOrganisatienaam(formulaInjectionCharsOrganisatienaam)).max(
  200,
  'Naam kan niet meer dan 200 tekens bevatten',
);

const OrganisatienaamField: React.FC<TextFieldProps> = ({ name, label, placeholder, required }) => (
  <TextField placeholder={placeholder} required={required} valueMapper={organisatienaamMapper} label={label} name={name} />
);
export default OrganisatienaamField;
