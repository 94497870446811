import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import ValidatievraagCreateForm from './validatievraag-create-form';

const ValidatievraagCreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/validatievraag/nieuw">Nieuwe validatievraag</BreadcrumbsItem>
      <ValidatievraagCreateForm />
    </div>
  </section>
);
export default ValidatievraagCreatePage;
