import React from 'react';
import { InputNumber } from 'antd';
import Tonnage from './tonnage.model';

export interface TonnageInputProps {
  name?: string;
  value?: number;
  min?: number;
  max?: number;
  placeholder?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  onChange?: (value: number | null) => void;
  onPressEnter?: () => void;
}

const TonnageInput: React.FC<TonnageInputProps> = ({ name, value, min, max, placeholder, style, autoFocus, onChange, onPressEnter }) => (
  <InputNumber
    className="vl-input-field vl-input-field--block"
    name={name || 'tonnageInput'}
    value={value}
    min={min || 0}
    max={max || Tonnage.MAX_VALUE}
    step={0.000001}
    formatter={Tonnage.formatValue}
    parser={Tonnage.parseValue}
    style={style}
    autoFocus={autoFocus}
    onChange={onChange}
    onPressEnter={onPressEnter}
    placeholder={placeholder}
    stringMode
  />
);

export default TonnageInput;
