import React from 'react';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import RouteButton from '../components/button/route-button';
import { Exclusiviteit, InzamelrechtHADetailsJsonResponse } from '../generated';
import { booleanFlagToLabel } from '../util/boolean-flag-to-label';
import { toKwartaal } from '../melding/periode.model';
import DeleteEntityButton from '../components/button/delete-entity-button';
import { useDeleteInzamelrechtHA } from './inzamelrecht-ha.hook';
import ErrorAlert from '../components/alert/error-alert';
import WerkingsgebiedDetails from '../organisatie/werkingsgebied-details';
import Afvalcode from '../melding/afvalcode.model';
import ListDetails from '../components/details/list-details';

const InzamelrechtHADetails: React.FC<{ recht: InzamelrechtHADetailsJsonResponse }> = ({ recht }) => {
  const deleteMutation = useDeleteInzamelrechtHA();
  return (
    <>
      {deleteMutation.isError && (
        <ErrorAlert error={deleteMutation.error} defaultMessage="Er is een fout opgetreden bij het verwijderen van de melding. Gelieve later nog eens opnieuw te proberen." />
      )}
      <div className="vl-description-data">
        <dl className="vl-grid vl-grid--is-stacked">
          <div className="vl-col--4-12">
            <dt className="vl-description-data__label">Eigenaar</dt>
            <dd className="vl-description-data__value" data-cy="eigenaar">
              {recht.eigenaarCode}
            </dd>
          </div>
          <div className="vl-col--8-12">
            <dt className="vl-description-data__label">Organisatienaam</dt>
            <dd className="vl-description-data__value" data-cy="organisatienaam">
              {recht.eigenaarNaam ?? 'Onbekend'}
            </dd>
          </div>
        </dl>
      </div>

      <DetailsForm>
        <DetailsFormSection title="Inzamelrecht Huishoudelijk Afval">
          <DetailsFormField label="Periode" data={`van ${toKwartaal(recht.periodeVan)} tot ${toKwartaal(recht.periodeTot) || '...'}`} />
          <DetailsFormField
            label="Afvalcodes"
            showLabelIfEmpty
            data={<ListDetails type="vertical" sorted data={recht.afvalcodes.map((el) => Afvalcode.format(el))} whenEmpty="Geen beperkingen" />}
          />
          <DetailsFormField label="Werkingsgebied" showLabelIfEmpty data={<WerkingsgebiedDetails data={recht.werkingsgebied} />} />
          <DetailsFormField
            label="Inzamelwijzen"
            showLabelIfEmpty
            data={<ListDetails type="vertical" sorted data={recht.inzamelwijzen.map((el) => `${el.code} ${el.omschrijving}`.trim())} whenEmpty="Geen beperkingen" />}
          />
          <DetailsFormField label="Exclusief" data={booleanFlagToLabel(recht.exclusief === Exclusiviteit.JA)} />
        </DetailsFormSection>
      </DetailsForm>
      <div className="vl-form-col--1-1">
        <div className="vl-action-group vl-action-group--align-right">
          <RouteButton label="Corrigeren" link={`/inzamelrechten/huishoudelijk-afval/${recht.eigenaarCode}/${recht.referentie}/correctie`} />
          <DeleteEntityButton
            entity={recht}
            confirmation="Bent u zeker dat u dit inzamelrecht wilt verwijderen?"
            successLink={`/inzamelrechten/huishoudelijk-afval/${recht.eigenaarCode}`}
            deleteMutation={deleteMutation}
          />
          <RouteButton label="Sluiten" className="vl-button--secondary" link={`/inzamelrechten/huishoudelijk-afval/${recht.eigenaarCode}`} />
        </div>
      </div>
    </>
  );
};

export default InzamelrechtHADetails;
