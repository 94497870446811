import React, { useEffect } from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { BTW_NUMMER, COMMENTAAR, GEMEENTE, HUISNUMMER, LAND, NAAM, POSTCODE, STRAAT, UITBREIDING } from './bestemming.model';
import { createBtwNummerValidationSchema } from '../../organisatie/btw-nummer.model';
import TextField from '../../components/form/text-field';
import TextArea from '../../components/form/text-area';
import BtwTextField from './btw-text-field';
import { LandSelect } from '../melding-form-fields';
import { prefixNamespace } from './use-land-hook';
import OrganisatienaamField, { organisatienaamSchema } from '../../organisatie/organisatienaam-field';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import requiredField from '../../util/required-field';
import { isEuLand, isValidLandcode } from '../../adres/land.model';
import { useLanden } from '../../enumerated-lists/enumeratedlists.hook';
import { buitenlandsePostcodeSchema } from '../postcode.schema';

export const buitenlandseVestigingValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  [BTW_NUMMER]: Yup.string().when(['land'], {
    is: (land: string) => isEuLand(land),
    then: () => createBtwNummerValidationSchema(true),
    otherwise: () => createBtwNummerValidationSchema(false),
  }),
  [NAAM]: requiredField(organisatienaamSchema),
  [STRAAT]: requiredField(vrijeTekstSchema().max(400, 'Straat kan niet meer dan 400 tekens bevatten')),
  [HUISNUMMER]: requiredField(vrijeTekstSchema().max(80, 'Huis- en busnummer kan niet meer dan 80 tekens bevatten')),
  [UITBREIDING]: vrijeTekstSchema().max(400, 'Adresuitbreiding kan niet meer dan 400 tekens bevatten'),
  [POSTCODE]: requiredField(buitenlandsePostcodeSchema),
  [GEMEENTE]: requiredField(vrijeTekstSchema().max(400, 'Gemeente kan niet meer dan 400 tekens bevatten')),
  [LAND]: requiredField(),
  [COMMENTAAR]: vrijeTekstSchema().optional().max(4000, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten'),
});

export interface IBuitenlandseVestigingFormProps {
  namespace: string;
}

const BuitenlandseVestigingForm: React.FC<IBuitenlandseVestigingFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);

  const [, btwMeta] = useField(withNamespace(BTW_NUMMER));
  const [, landMeta, landHelpers] = useField(withNamespace(LAND));
  const landIso2 = btwMeta.value.substring(0, 2);
  const { data: landen } = useLanden(true);

  useEffect(() => {
    if (landen) {
      const updated = landIso2 !== 'BE' && isValidLandcode(landIso2, landen) ? landIso2 : '';
      if (updated) {
        landHelpers.setValue(updated, false);
      }
    }
  }, [landIso2, landen]);

  return (
    <>
      <BtwTextField label="BTW-nummer" name={withNamespace(BTW_NUMMER)} required={landMeta.value && isEuLand(landMeta.value)} placeholder="DE012345678" />
      <OrganisatienaamField label="Naam" name={withNamespace(NAAM)} required />
      <TextField label="Straat" name={withNamespace(STRAAT)} required />
      <TextField label="Huis- en busnummer" name={withNamespace(HUISNUMMER)} required />
      <TextField label="Adresuitbreiding" name={withNamespace(UITBREIDING)} />
      <TextField label="Postcode" name={withNamespace(POSTCODE)} required />
      <TextField label="Gemeente" name={withNamespace(GEMEENTE)} required />
      <LandSelect label="Land" name={withNamespace(LAND)} required filter={(land) => land.code !== 'BE'} />
      <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
    </>
  );
};

export default BuitenlandseVestigingForm;
