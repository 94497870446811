import axios from 'axios';
import React from 'react';
import { PeriodeZonderMeldingJsonRequest, PeriodeZonderMeldingJsonResponse } from '../generated';
import { PeriodeZonderMeldingForm, PeriodeZonderMeldingFormValues } from './periode-zonder-melding-form';
import { periodeZonderMeldingBaseUrl } from './periode-zonder-melding.hook';

export const createPzmMutation = async (request: PeriodeZonderMeldingJsonRequest) => axios.post<PeriodeZonderMeldingJsonResponse>(periodeZonderMeldingBaseUrl, request);

const defaultValues: PeriodeZonderMeldingFormValues = {
  periodeVan: '',
  periodeTot: '',
  onderdeel: '',
  motivering: '',
};

const PeriodeZonderMeldingCreateForm: React.FC<any> = () => (
  <PeriodeZonderMeldingForm mutateFn={createPzmMutation} cancelLink="/periodes-zonder-melding" submitLabel="Periode aanmaken" initialValues={defaultValues} />
);

export default PeriodeZonderMeldingCreateForm;
