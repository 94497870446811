import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import MeldingInzamelingHACreateForm from './melding-inzameling-ha-create-form';

const MeldingInzamelingHACreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/melding/inzameling/huishoudelijk-afval/nieuw">Nieuw</BreadcrumbsItem>
      <MeldingInzamelingHACreateForm />
    </div>
  </section>
);

export default MeldingInzamelingHACreatePage;
