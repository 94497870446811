import { isEmptyString } from '../../util/string-utils';
import { BestemmingJsonResponse, BestemmingType, EnumeratedListJsonResponse } from '../../generated';

export interface BestemmingFormValues {
  type: BestemmingType | '';
  vestigingsnummer: string;
  vestigingsnummerVerplicht?: boolean;
  btwNummer: string;
  ondernemingsnummer: string;
  naam: string;
  straat: string;
  huisnummer: string;
  uitbreiding: string;
  postcode: string;
  gemeente: string;
  land: string;
  commentaar: string;
}

export const bestemmingTypesInzamelingHA = [
  BestemmingType.BELGISCHE_VESTIGING,
  BestemmingType.BUITENLANDSE_VESTIGING,
  BestemmingType.TRADER,
  BestemmingType.GEBRUIK_DOOR_BURGERS,
] as const;
export const bestemmingTypesVerwerking = [
  BestemmingType.BELGISCHE_VESTIGING,
  BestemmingType.BUITENLANDSE_VESTIGING,
  BestemmingType.TRADER,
  BestemmingType.WERF,
  BestemmingType.GEEN_ONDERNEMING,
  BestemmingType.DISPERS_GEBRUIK,
  BestemmingType.PARTICULIER,
] as const;

export const initialBestemmingValues: BestemmingFormValues = {
  type: '',
  vestigingsnummer: '',
  vestigingsnummerVerplicht: false,
  btwNummer: '',
  ondernemingsnummer: '',
  naam: '',
  straat: '',
  huisnummer: '',
  uitbreiding: '',
  postcode: '',
  gemeente: '',
  land: '',
  commentaar: '',
};

export const bestemmingLabels: { [key: string]: string } = {
  [BestemmingType.BELGISCHE_VESTIGING]: 'Belgische vestiging',
  [BestemmingType.BUITENLANDSE_VESTIGING]: 'Buitenlandse vestiging',
  [BestemmingType.TRADER]: 'Trader (binnen- of buitenland)',
  [BestemmingType.GEBRUIK_DOOR_BURGERS]: 'Gebruik door burgers',
  [BestemmingType.WERF]: 'Werf',
  [BestemmingType.GEEN_ONDERNEMING]: 'Geen onderneming',
  [BestemmingType.DISPERS_GEBRUIK]: 'Dispers gebruik',
  [BestemmingType.PARTICULIER]: 'Particulier',
};

const checkedProp = (n: keyof BestemmingFormValues): string => n;

export const TYPE = checkedProp('type');
export const VESTIGINGSNUMMER = checkedProp('vestigingsnummer');
export const BTW_NUMMER = checkedProp('btwNummer');
export const ONDERNEMINGSNUMMER = checkedProp('ondernemingsnummer');
export const NAAM = checkedProp('naam');
export const STRAAT = checkedProp('straat');
export const HUISNUMMER = checkedProp('huisnummer');
export const UITBREIDING = checkedProp('uitbreiding');
export const POSTCODE = checkedProp('postcode');
export const GEMEENTE = checkedProp('gemeente');
export const LAND = checkedProp('land');
export const COMMENTAAR = checkedProp('commentaar');

export const isBelgie = (bestemming: BestemmingJsonResponse) => bestemming.land === 'BE' || isEmptyString(bestemming.land);

export const isVlaanderen = (bestemming: BestemmingJsonResponse, vlaamsePostcodes?: EnumeratedListJsonResponse[]): boolean => {
  const postcodeNotPresent: boolean = isEmptyString(bestemming.postcode);
  const vlaams: boolean = vlaamsePostcodes?.some((en) => en.code === bestemming.postcode) ?? false;
  return isBelgie(bestemming) && (postcodeNotPresent || vlaams);
};

export const isBelgischeWerfBuitenVlaanderen = (bestemming: BestemmingJsonResponse, vlaamsePostcodes?: EnumeratedListJsonResponse[]): boolean =>
  bestemming.type === BestemmingType.WERF && isBelgie(bestemming) && !isVlaanderen(bestemming, vlaamsePostcodes);
