import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams } from 'react-router-dom';
import MeldingVerwerkingDetails from './melding-verwerking-details';
import { useMeldingVerwerking } from './melding-detail.hook';
import DetailsLoading from '../components/details/details-loading';
import ErrorAlert from '../components/alert/error-alert';

const MeldingVerwerkingDetailPage: React.FC = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: melding, error, isLoading } = useMeldingVerwerking(referentie);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/melding/verwerking/${referentie}`}>{melding?.identificatie ?? '...'}</BreadcrumbsItem>

        {error && !isLoading && (
          <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van de melding verwerking. Gelieve later nog eens opnieuw te proberen." />
        )}
        {!error && isLoading && <DetailsLoading />}
        {!error && !isLoading && melding && <MeldingVerwerkingDetails melding={melding} />}
      </div>
    </section>
  );
};

export default MeldingVerwerkingDetailPage;
