import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams } from 'react-router-dom';
import MeldingInzamelingHADetails from './melding-inzameling-ha-details';
import { useMeldingInzamelingHA } from './melding-detail.hook';
import DetailsLoading from '../components/details/details-loading';
import ErrorAlert from '../components/alert/error-alert';

const MeldingInzamelingHADetailsPage: React.FC = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: melding, error, isLoading } = useMeldingInzamelingHA(referentie);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/melding/inzameling/huishoudelijk-afval/${referentie}`}>{melding?.identificatie ?? '...'}</BreadcrumbsItem>
        {error && !isLoading && (
          <ErrorAlert
            error={error}
            defaultMessage="Er is een fout opgetreden bij het ophalen van de melding inzameling huishoudelijk afval. Gelieve later nog eens opnieuw te proberen."
          />
        )}
        {!error && isLoading && <DetailsLoading />}
        {!error && !isLoading && melding && <MeldingInzamelingHADetails melding={melding} />}
      </div>
    </section>
  );
};

export default MeldingInzamelingHADetailsPage;
