import React from 'react';
import { useField } from 'formik';
import { Input } from 'antd';
import InputField, { IInputFieldProps } from './input-field';

const TextArea: React.FC<IInputFieldProps & { maxLength?: number; placeholder?: string }> = ({ name, label, maxLength = 1000, required, placeholder = '' }) => {
  const [field] = useField(name);
  const { TextArea: TextAreaAnt } = Input;

  return (
    <InputField name={name} label={label} required={required}>
      <TextAreaAnt placeholder={placeholder} showCount maxLength={maxLength} cols={40} rows={4} {...field} />
    </InputField>
  );
};

export default TextArea;
