import React from 'react';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import { MeldingFilters, MeldingVerwerkingPagingAndFiltering, useDeleteAllSelectedMeldingenVerwerking, useMeldingVerwerkingen } from './melding.hook';
import { useRoleHook } from '../services/security/use-role-hook';
import {
  createSelectWithSearchFilterColumn,
  createTextInputFilterColumn,
  createTimeRangeFilterColumn,
  createTonnageRangeFilterColumn,
} from '../components/table/default.datatable.options';
import { displayClean } from './melding-detail.hook';
import getFilterValues from '../util/table-filter-utils';
import { mapMeldingFilterValues, MeldingOverview } from './melding-overview';
import { MeldingOverviewTable } from './melding-overview-table';
import { meldingTypes } from './melding.type';
import { MeldingType, MeldingVerwerkingOverviewJsonResponse } from '../generated';
import { mapMeldingPaginationValues, renderMateriaal, toMeldingOverview } from './melding-overview-common';
import { useMateriaalOptions } from './melding-form-fields';

type MeldingVerwerkingOverviewType = MeldingOverview & { type: string };

const toMeldingVerwerkingOverview = (value: MeldingVerwerkingOverviewJsonResponse): MeldingVerwerkingOverviewType => ({
  ...toMeldingOverview(value),
  type: displayClean(value.meldingType),
});

export interface MeldingVerwerkingOverviewProps {
  onChange: (newValues: MeldingVerwerkingPagingAndFiltering) => void;
  pagingAndFiltering: MeldingVerwerkingPagingAndFiltering;
  defaultPageSize: number;
}

const mapMeldingVerwerkingFilterValues = (filters: Record<string, FilterValue | null>): MeldingFilters => ({
  ...mapMeldingFilterValues(filters),
  oorsprong: getFilterValues('oorsprong', filters)?.[0],
  inzamelwijzeCode: getFilterValues('inzamelwijze', filters)?.[0],
});

export const useMeldingTypeOptions = () => ({
  isLoading: false,
  options: meldingTypes.map((meldingType: MeldingType) => ({
    value: meldingType,
    label: displayClean(meldingType),
  })),
});

const MELDING_VERWERKING_BASE_URL = '/melding/verwerking';

const MeldingVerwerkingOverview: React.FC<MeldingVerwerkingOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const meldingenQuery = useMeldingVerwerkingen(pagingAndFiltering);
  const deleteAllMutation = useDeleteAllSelectedMeldingenVerwerking();
  const { isBeheerder, isMelder } = useRoleHook();

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapMeldingPaginationValues(pagination, defaultPageSize),
      ...mapMeldingVerwerkingFilterValues(filters),
      type: getFilterValues('type', filters)?.[0] as MeldingType,
    });
  };

  const columns: ColumnsType<MeldingOverview> = [
    isBeheerder ? createTextInputFilterColumn<MeldingVerwerkingOverviewType>('Eigenaar', 'eigenaar', pagingAndFiltering.eigenaar) : null,
    {
      ...createTextInputFilterColumn<MeldingVerwerkingOverviewType>('Identificatie', 'identificatie', pagingAndFiltering.identificatie),
      render: (identificatie: string, melding: MeldingOverview) => <Link to={`${MELDING_VERWERKING_BASE_URL}/${melding.referentie}`}>{identificatie}</Link>,
    },
    createTimeRangeFilterColumn<MeldingVerwerkingOverviewType>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot),
    createTextInputFilterColumn<MeldingVerwerkingOverviewType>('Oorsprong', 'oorsprong', pagingAndFiltering.oorsprong),
    createTextInputFilterColumn<MeldingVerwerkingOverviewType>('Bestemming', 'bestemming', pagingAndFiltering.bestemming),
    createTonnageRangeFilterColumn<MeldingVerwerkingOverviewType>('Tonnage', 'tonnage', pagingAndFiltering.tonnageVan, pagingAndFiltering.tonnageTot),
    {
      ...createSelectWithSearchFilterColumn<MeldingOverview>('Materiaal', 'materiaal', 'materiaal', useMateriaalOptions, pagingAndFiltering.materiaalcode),
      ellipsis: {
        showTitle: false,
      },
      render: (materiaal: any) => renderMateriaal(materiaal),
    },
    createSelectWithSearchFilterColumn<MeldingVerwerkingOverviewType>('Type', 'type', 'types', useMeldingTypeOptions, pagingAndFiltering.type),
    createTimeRangeFilterColumn<MeldingVerwerkingOverviewType>(
      'Laatst gewijzigd',
      'versieTijdstip',
      true,
      pagingAndFiltering.versieTijdstipVan,
      pagingAndFiltering.versieTijdstipTot,
    ),
  ].flatMap((col) => (col ? [col as MeldingOverview] : []));

  const page = meldingenQuery.data;
  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toMeldingVerwerkingOverview),
      }
    : undefined;

  return (
    <MeldingOverviewTable
      title="Meldingen afvalverwerking"
      baseUrl={MELDING_VERWERKING_BASE_URL}
      downloadUrl="/ui/meldingen/verwerking/download"
      allowDownload={isMelder}
      queryStatus={meldingenQuery}
      deleteAllMutation={deleteAllMutation}
      meldingFilters={pagingAndFiltering}
      columns={columns}
      defaultPageSize={defaultPageSize}
      page={mappedPage}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
    />
  );
};

export default MeldingVerwerkingOverview;
