import { IAxiosRetryConfig } from 'axios-retry';
import * as axios from 'axios';

export interface RetryConfigurationFactoryProps {
  retryCondition: (error: axios.AxiosError) => boolean;
  retryDelayInMillis: number;
  timeoutInMillis: number;
}

export const createAxiosRetryConfiguration = ({ retryCondition, retryDelayInMillis, timeoutInMillis }: RetryConfigurationFactoryProps): IAxiosRetryConfig => ({
  retryCondition,
  retryDelay: () => retryDelayInMillis,
  retries: Math.floor(timeoutInMillis / retryDelayInMillis),
});
