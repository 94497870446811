import React from 'react';
import { ContactgegevensJsonResponse } from '../generated';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import { mailTo } from '../components/mailto/mail-to';
import { telTo } from '../components/telto/tel-to';

interface ContactgegevensFormProps {
  contactgegevens: ContactgegevensJsonResponse;
}

const ContactgegevensDetails: React.FC<ContactgegevensFormProps> = ({ contactgegevens }) => (
  <DetailsForm>
    <DetailsFormSection title="Gebruiker">
      <DetailsFormField dataCy="gebruiker-naam" label="Naam" data={contactgegevens.gebruikerNaam} />
      <DetailsFormField dataCy="gebruiker-email" label="E-mailadres" data={mailTo(contactgegevens.gebruikerEmailadres) ?? 'Geen'} />
      <DetailsFormField dataCy="gebruiker-telefoon" label="Telefoonnummer" data={telTo(contactgegevens.gebruikerTelefoonnummer) ?? 'Geen'} />
    </DetailsFormSection>
    <DetailsFormSection title="Organisatie">
      <DetailsFormField dataCy="org-naam" label="Naam" data={contactgegevens.organisatienaam} />
      <DetailsFormField dataCy="org-email" label="E-mailadres" data={mailTo(contactgegevens.organisatieEmailadres) ?? 'Geen'} />
    </DetailsFormSection>
  </DetailsForm>
);

export default ContactgegevensDetails;
