import React, { useMemo, useState } from 'react';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import FileUploader from './file-uploader';
import Alert from '../alert/alert';
import CsvProcessingReport from './csv-processing-report/csv-processing-report';
import SubmitButton from '../button/submit-button';
import { ApiError } from '../../util/axios-error-mapping';
import { DownloadResponse } from '../button/download-button';
import ErrorAlert from '../alert/error-alert';
import CsvInfoBox, { UploadLimitExtractor } from './csv-info-box';
import { createAxiosClientWithRetry } from '../../services/security/authentication';
import AlertWithContact from '../alert/alert-with-contact';
import { createAxiosRetryConfiguration } from '../../services/axios-retry-configuration-factory';
import { useConfigContext } from '../context/config-context';
import { Maybe } from '../../util/maybe';
import { CsvConfigJsonResponse, CsvLineJsonResponse, CsvUploadJsonResponse, ReferentieJsonResponse } from '../../generated';

interface ICsvUploaderState {
  file: UploadFile | null;
  results: CsvLineJsonResponse[];
  summary?: string[];
  failedLinesCsvFileContents?: string;
  error: string;
  disableUploader: boolean;
  uploadStartedAt?: Date;
}

export interface ICsvUploaderProps {
  handleUpload: (formData: FormData) => Promise<ReferentieJsonResponse>;
  title: string;
  csvEntitiesLabel: string;
  chapterInManual: string;
  additionalInfoBullets?: string[];
  uploadLimitExtractor: UploadLimitExtractor;
}

const POLLING_TIMEOUT_ERROR_MESSAGE = 'pollingTimeoutError';

const createCsvUploadResultaatPoller = (csvConfig?: CsvConfigJsonResponse) => {
  const retryStatuscode = 404;
  const axiosRetryClient = createAxiosClientWithRetry(
    createAxiosRetryConfiguration({
      retryCondition: (error) => error.response?.status === retryStatuscode,
      retryDelayInMillis: csvConfig?.uploadPollingIntervalInMillis ?? 500,
      timeoutInMillis: csvConfig?.uploadPollingTimeoutInMillis ?? 360000,
    }),
  );
  return (taakReferentie: string) =>
    axiosRetryClient
      .get<CsvUploadJsonResponse>(`/ui/taken/${taakReferentie}/csv-upload-resultaat`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === retryStatuscode) {
          throw new Error(POLLING_TIMEOUT_ERROR_MESSAGE);
        }
        throw error;
      });
};
const useCsvUploadResultaatPoller = () => {
  const ctxData = useConfigContext();
  const config = Maybe.toNullable(ctxData);
  const csvConfig = config?.csv;

  return {
    pollForCsvUploadResultaat: createCsvUploadResultaatPoller(csvConfig),
  };
};

const CsvUploader: React.FC<ICsvUploaderProps> = ({ handleUpload, title, csvEntitiesLabel, chapterInManual, additionalInfoBullets, uploadLimitExtractor }) => {
  const { pollForCsvUploadResultaat } = useCsvUploadResultaatPoller();

  const initialState = {
    file: null,
    results: [],
    error: '',
    disableUploader: false,
  };
  const [csvState, setCsvState] = useState<ICsvUploaderState>(initialState);

  const mutation = useMutation<CsvUploadJsonResponse, Error | ApiError, FormData, unknown>((values) =>
    handleUpload(values).then((taakCreatedResponse) => pollForCsvUploadResultaat(taakCreatedResponse.referentie)),
  );

  const handleChange = ({ fileList }: UploadChangeParam) => {
    mutation.reset();
    const [file] = fileList;
    if (!!file && !!file.type && !file.name.toLowerCase().endsWith('.csv')) {
      setCsvState((prevState) => ({
        ...prevState,
        error: 'Gelieve een bestand op te laden met de .csv extensie.',
      }));
    } else {
      setCsvState((prevState) => ({
        ...prevState,
        error: '',
        file,
      }));
    }
  };

  const onUpload = () => {
    setCsvState((prevState) => ({
      ...prevState,
      disableUploader: true,
      error: '',
      results: [],
    }));
    const formData = new FormData();
    formData.append('bestand', csvState.file?.originFileObj as RcFile);
    const uploadStartedAt = new Date();
    mutation
      .mutateAsync(formData)
      .then((response) => {
        setCsvState((prevState) => ({
          ...prevState,
          results: response.lines,
          summary: response.errorSummary,
          failedLinesCsvFileContents: response.failedLinesCsv,
          uploadStartedAt,
        }));
      })
      .catch(() => {
        setCsvState((prevState) => ({
          ...prevState,
          disableUploader: false,
        }));
      });
  };

  const failedCsvFile: DownloadResponse | null = useMemo(() => {
    const { failedLinesCsvFileContents, uploadStartedAt } = csvState;
    if (failedLinesCsvFileContents) {
      return {
        fileContents: new Blob([failedLinesCsvFileContents]),
        filename: `gefaalde_${csvEntitiesLabel}_${dayjs(uploadStartedAt).format('YYYY-MM-DD_HH-mm-ss')}.csv`,
      };
    }
    return null;
  }, [csvState.failedLinesCsvFileContents, csvState.uploadStartedAt]);

  return (
    <>
      <section className="vl-region">
        <div className="vl-layout">
          <h2 className="vl-title vl-title--h2" data-cy="page-title">
            {title}
          </h2>
          {csvState.error !== '' && (
            <Alert type="error" title="Fout bestandstype!">
              {csvState.error}
            </Alert>
          )}
          {mutation.isError && (
            <div>
              {mutation.error.message === POLLING_TIMEOUT_ERROR_MESSAGE ? (
                <AlertWithContact>
                  <p>Er is een onverwachte fout opgetreden bij het opladen van dit bestand.</p>
                </AlertWithContact>
              ) : (
                <ErrorAlert error={mutation.error} defaultMessage="Er is een onverwachte fout opgetreden. Gelieve later nog eens opnieuw te proberen." scrollable />
              )}
            </div>
          )}
          <CsvInfoBox csvEntitiesLabel={csvEntitiesLabel} uploadLimitExtractor={uploadLimitExtractor} additionalInfoBullets={additionalInfoBullets} />
          {csvState.results.length === 0 && (
            <FileUploader
              uploadFile={csvState.file}
              onChange={handleChange}
              description={`Sleep het CSV-bestand met ${csvEntitiesLabel} naar hier om op te laden`}
              isLoading={mutation.isLoading}
              disabled={csvState.disableUploader}
            />
          )}
          {csvState.results.length > 0 && (
            <CsvProcessingReport
              results={csvState.results}
              summary={csvState.summary}
              fileName={csvState.file?.name}
              failedCsvFile={failedCsvFile}
              csvEntitiesLabel={csvEntitiesLabel}
              chapterInManual={chapterInManual}
            />
          )}
        </div>
      </section>
      <section className="vl-region">
        <div className="vl-layout">
          <div className="vl-col--12-12">
            <div className="vl-action-group vl-action-group--align-right">
              {(mutation.isLoading || !csvState.disableUploader) && (
                <SubmitButton label="Bestand opladen" isDisabled={!csvState.file || mutation.isLoading} isSubmitting={mutation.isLoading} onClick={onUpload} />
              )}
              {csvState.disableUploader && !mutation.isLoading && <SubmitButton label="Nieuw bestand opladen" onClick={() => setCsvState(initialState)} />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CsvUploader;
