import React from 'react';
import { PeriodeZonderMeldingDetailsJsonResponse } from '../generated';
import { PeriodeZonderMeldingForm, PeriodeZonderMeldingFormValues } from './periode-zonder-melding-form';
import { createPzmMutation } from './periode-zonder-melding-create-form';

const PeriodeZonderMeldingUpdateForm: React.FC<{ periode: PeriodeZonderMeldingDetailsJsonResponse }> = ({ periode }) => {
  const defaultValues: PeriodeZonderMeldingFormValues = {
    referentie: periode.referentie,
    eigenaar: periode.eigenaar,
    vestigingsnummer: periode.vestigingsnummer,
    periodeVan: periode.periodeVan,
    periodeTot: periode.periodeTot,
    onderdeel: periode.onderdeel,
    motivering: periode.motivering,
    andereMotivering: periode.andereMotivering,
    medeExploitant: periode.medeExploitant || '',
  };

  return (
    <PeriodeZonderMeldingForm
      mutateFn={createPzmMutation}
      cancelLink={`/periodes-zonder-melding/${periode.referentie}`}
      submitLabel="Periode corrigeren"
      initialValues={defaultValues}
    />
  );
};

export default PeriodeZonderMeldingUpdateForm;
