import React from 'react';
import DetailHeader from '../components/details/detail.header';
import { VerdelingDetailsJsonResponse } from '../generated';

interface VerdelingDetailHeaderProps {
  verdeling: VerdelingDetailsJsonResponse;
}

const VerdelingDetailHeader: React.FC<VerdelingDetailHeaderProps> = ({ verdeling }) => (
  <div>
    <DetailHeader creatieTijdstip={verdeling.creatieTijdstip} versieTijdstip={verdeling.versieTijdstip} versieGebruiker={verdeling.versieGebruiker} versie={verdeling.versie} />
  </div>
);

export default VerdelingDetailHeader;
