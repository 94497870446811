import axios from 'axios';
import { ReferentieJsonResponse } from '../generated';

export const uploadCsv = async (url: string, formData: FormData): Promise<ReferentieJsonResponse> => {
  const response = await axios.post<ReferentieJsonResponse>(url, formData);
  return response.data;
};

export const uploadNewMeldingInzamelingHACsv = async (formData: FormData): Promise<ReferentieJsonResponse> =>
  uploadCsv('/ui/meldingen/inzameling/huishoudelijk-afval/upload-new', formData);

export const uploadExistingMeldingInzamelingHACsv = async (formData: FormData): Promise<ReferentieJsonResponse> =>
  uploadCsv('/ui/meldingen/inzameling/huishoudelijk-afval/upload-existing', formData);

export const uploadNewMeldingInzamelingBACsv = async (formData: FormData): Promise<ReferentieJsonResponse> =>
  uploadCsv('/ui/meldingen/inzameling/bedrijfsafval/upload-new', formData);

export const uploadExistingMeldingInzamelingBACsv = async (formData: FormData): Promise<ReferentieJsonResponse> =>
  uploadCsv('/ui/meldingen/inzameling/bedrijfsafval/upload-existing', formData);

export const uploadNewMeldingVerwerkingCsv = async (formData: FormData): Promise<ReferentieJsonResponse> => uploadCsv('/ui/meldingen/verwerking/upload-new', formData);

export const uploadExistingMeldingVerwerkingCsv = async (formData: FormData): Promise<ReferentieJsonResponse> => uploadCsv('/ui/meldingen/verwerking/upload-existing', formData);
