import React from 'react';
import { Adres, isEmptyAdres } from './adres.model';
import { useLanden } from '../enumerated-lists/enumeratedlists.hook';
import './adres-details.module.css';

interface AdresDetailsProps {
  adres: Adres;
}

const AdresDetails: React.FC<AdresDetailsProps> = ({ adres }) => {
  const { data: landen } = useLanden(adres.land !== null);

  if (isEmptyAdres(adres)) {
    return null;
  }

  const landNaam = landen?.find((land) => land.code === adres.land)?.omschrijving ?? '';

  return (
    <ol>
      <li>{`${adres.straat ?? ''} ${adres.huisnummer ?? ''}`}</li>
      <li>{adres.uitbreiding}</li>
      <li>{`${adres.postcode ?? ''} ${adres.gemeente ?? ''}`}</li>
      <li>{landNaam}</li>
    </ol>
  );
};

export default AdresDetails;
