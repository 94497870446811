import { useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { FormikErrors } from 'formik/dist/types';

const getFieldErrorNames = (formikErrors: FormikErrors<FormikValues>) => {
  const transformObjectToDotNotation = ({ obj, prefix = '', result = [] }: { obj: any; prefix?: string; result?: any[] }) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation({ obj: value, prefix: nextKey, result });
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation({ obj: formikErrors });
};

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(`div[data-cy='input-field.${fieldErrorNames[0]}']`);
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};
