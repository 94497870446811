import React from 'react';
import axios from 'axios';
import { Exclusiviteit, InzamelrechtHADetailsJsonResponse, InzamelrechtHAJsonRequest } from '../generated';
import { InzamelrechtHAForm, InzamelrechtHAFormValues } from './inzamelrecht-ha-form';
import { toKwartaal } from '../melding/periode.model';

const InzamelrechtHAUpdateForm: React.FC<{ inzamelrecht: InzamelrechtHADetailsJsonResponse }> = ({ inzamelrecht }) => {
  const defaultValues: InzamelrechtHAFormValues = {
    eigenaar: inzamelrecht.eigenaarCode,
    periodeVan: toKwartaal(inzamelrecht.periodeVan),
    periodeTot: toKwartaal(inzamelrecht.periodeTot),
    afvalcodes: inzamelrecht.afvalcodes.map((el) => el.code),
    werkingsgebied: inzamelrecht.werkingsgebied.map((el) => el.code),
    inzamelwijzen: inzamelrecht.inzamelwijzen.map((el) => el.code),
    exclusief: inzamelrecht.exclusief === Exclusiviteit.JA,
  };

  const updateMutation = async (request: InzamelrechtHAJsonRequest) => axios.put<any>(`/ui/inzamelrechten/huishoudelijk-afval/${inzamelrecht.referentie}`, request);

  return (
    <InzamelrechtHAForm
      mutateFn={updateMutation}
      cancelLink={`/inzamelrechten/huishoudelijk-afval/${inzamelrecht.eigenaarCode}/${inzamelrecht.referentie}`}
      submitLabel="Inzamelrecht corrigeren"
      initialValues={defaultValues}
    />
  );
};

export default InzamelrechtHAUpdateForm;
