import React from 'react';
import { Maybe } from '../../util/maybe';
import { ConfigJsonResponse } from '../../generated';

export const ConfigContext = React.createContext<Maybe<ConfigJsonResponse>>(Maybe.none);

export const useConfigContext = (): Maybe<ConfigJsonResponse> => {
  const context = React.useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfigContext must be used in a component within a ConfigContextProvider.');
  }
  return context;
};

export const ConfigContextProvider: React.FC<React.PropsWithChildren<{ value: Maybe<ConfigJsonResponse> }>> = ({ value, children }) => (
  <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
);
