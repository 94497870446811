/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Scope {
  INZAMELING_HUISHOUDELIJK_AFVAL = 'INZAMELING_HUISHOUDELIJK_AFVAL',
  VERDELING_HUISHOUDELIJK_AFVAL = 'VERDELING_HUISHOUDELIJK_AFVAL',
  INZAMELING_BEDRIJFSAFVAL = 'INZAMELING_BEDRIJFSAFVAL',
  AFVALVERWERKING = 'AFVALVERWERKING',
  RAPPORTEN = 'RAPPORTEN',
  RAPPORT_INZAMELING_HA = 'RAPPORT_INZAMELING_HA',
  RAPPORT_INZAMELING_HA_GEMEENTE = 'RAPPORT_INZAMELING_HA_GEMEENTE',
  RAPPORT_INZAMELING_HA_IC = 'RAPPORT_INZAMELING_HA_IC',
  RAPPORT_INZAMELING_BA = 'RAPPORT_INZAMELING_BA',
  RAPPORT_AFVALVERWERKING = 'RAPPORT_AFVALVERWERKING',
  VALIDATIEVRAGEN = 'VALIDATIEVRAGEN',
  VALIDATIEVRAGEN_CREATIE = 'VALIDATIEVRAGEN_CREATIE',
  VALIDATIEVRAGEN_WORKFLOW = 'VALIDATIEVRAGEN_WORKFLOW',
  ADMINISTRATIE = 'ADMINISTRATIE',
  PERIODE_ZONDER_MELDING = 'PERIODE_ZONDER_MELDING',
  BEHEER_ORGANISATIE = 'BEHEER_ORGANISATIE',
  BEHEER_RECHTEN = 'BEHEER_RECHTEN',
  BEHEER_CONTACTGEGEVENS = 'BEHEER_CONTACTGEGEVENS',
  BEHEER_CONTACTGEGEVENS_VERPLICHT = 'BEHEER_CONTACTGEGEVENS_VERPLICHT',
}
