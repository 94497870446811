/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrganisatieType {
  INZAMELAAR_HUISHOUDELIJK_AFVAL = 'INZAMELAAR_HUISHOUDELIJK_AFVAL',
  VERDELER_HUISHOUDELIJK_AFVAL = 'VERDELER_HUISHOUDELIJK_AFVAL',
  INZAMELAAR_BEDRIJFSAFVAL = 'INZAMELAAR_BEDRIJFSAFVAL',
  VERWERKER = 'VERWERKER',
}
