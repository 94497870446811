import React from 'react';
import { isArray, isEmpty, isString } from 'lodash';
import Alert from './alert';
import MailTo from '../mailto/mail-to';
import { ValidatieFout } from '../../generated';
import './alert.css';

interface IValidatieFoutenAlertProps {
  errors: ValidatieFout[];
}

const formatParameters = (parameters: any[]) => `[${parameters.map((parameter: any) => (isArray(parameter) ? `[${parameter.join(', ')}]` : parameter)).join(', ')}]`;

const ValidatieFoutenAlert: React.FC<IValidatieFoutenAlertProps> = ({ errors }) => (
  <Alert title="Onverwachte fout!" type="error" scrollIntoView>
    <p>
      Er is een onverwachte fout opgetreden bij het bewaren van deze melding.
      <br />
      Gelieve contact op te nemen met de OVAM via <MailTo email="matis@ovam.be" />.
    </p>
    <span className="vl-u-text--bold">Technische foutcode(s)</span>
    <ul className="matis-validation-errors-list">
      {errors.map((error: ValidatieFout) => {
        const veldWaarde = isString(error?.veld?.waarde) ? error?.veld?.waarde : JSON.stringify(error?.veld?.waarde);
        return (
          <li key={`validatiefout-${error?.veld?.naam}-${veldWaarde}-${error.code}`}>
            Veld: {error?.veld?.naam}, waarde: {veldWaarde}, code: {error.code}
            {isArray(error.parameters) && !isEmpty(error.parameters) && `, parameters: ${formatParameters(error.parameters)}`}
          </li>
        );
      })}
    </ul>
  </Alert>
);

export default ValidatieFoutenAlert;
