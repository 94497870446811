import React from 'react';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnsType } from 'antd/lib/table/interface';
import { VerdelingMeldingJsonResponse } from '../generated';
import OverviewTable from '../components/table/overview-table';
import { Page } from '../components/table/page';
import Tonnage from '../melding/tonnage.model';
import { QueryStatusInfo } from '../components/table/query-status-info';

interface MatchendeMeldingenOverviewTableProps {
  page?: Page<VerdelingMeldingJsonResponse>;
  updatePaging: (pagination: PaginationProps) => void;
  queryStatus: QueryStatusInfo;
}

const MatchendeMeldingenOverviewTable: React.FC<MatchendeMeldingenOverviewTableProps> = ({ page, updatePaging, queryStatus }) => {
  const columns: ColumnsType<VerdelingMeldingJsonResponse> = [
    { title: 'Eigenaar', dataIndex: 'eigenaar', key: 'eigenaar', className: 'eigenaar-column' },
    { title: 'Identificatie', dataIndex: 'identificatie', key: 'identificatie', className: 'identificatie-column' },
    { title: 'Periode', dataIndex: 'periode', key: 'periode', className: 'periode-column' },
    { title: 'Oorsprong', dataIndex: 'oorsprong', key: 'oorsprong', className: 'oorsprong-column' },
    { title: 'Tonnage', dataIndex: 'tonnage', key: 'tonnage', className: 'tonnage-column', render: (value) => new Tonnage(value).formatted() },
    { title: 'Materiaal', dataIndex: 'afvalcode', key: 'afvalcode', className: 'afvalcode-column' },
    { title: 'Inzamelwijze', dataIndex: 'inzamelwijze', key: 'inzamelwijze', className: 'inzamelwijze-column' },
  ];

  return (
    <OverviewTable
      itemsLabel="meldingen"
      columns={columns}
      page={page && { ...page, content: page.content.map((melding) => ({ ...melding, key: melding.referentie })) }}
      defaultPageSize={10}
      updatePagingAndFilteringState={(pagination) => updatePaging(pagination)}
      isLoading={queryStatus.isLoading}
      isFetching={queryStatus.isFetching}
      error={queryStatus.error}
      notFoundMessage="Geen meldingen gevonden"
      showSizeChanger={false}
      scrollToTopAfterRender
    />
  );
};

export default MatchendeMeldingenOverviewTable;
