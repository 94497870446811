import React from 'react';

import styles from './csv-processing-report-container.module.css';
import { SummaryReportIcon } from './csv-processing-report-icon';

const CsvProcessingReportContainer: React.FC<React.PropsWithChildren<any>> = ({ children }) => (
  <div data-cy="csv-summary" className={`vl-grid vl-alert ${styles.container} ${styles.summary} `}>
    <div className="vl-col--1-12">
      <SummaryReportIcon />
    </div>
    <div className="vl-col--11-12">{children}</div>
  </div>
);

export default CsvProcessingReportContainer;
