import React, { useMemo } from 'react';
import { useField } from 'formik';
import TextField from '../components/form/text-field';
import Radio from '../components/form/radio';
import { isVlarema9VanToepassing, periodeEenheden, periodePlaceholder } from './periode.model';
import {
  sortByCode,
  useAfvalcodes,
  useEuralcodes,
  useGeregistreerdeTraders,
  useInzamelwijzes,
  useInzamelwijzesBA,
  useLanden,
  useMateriaalcodes,
  usePostcodes,
  useRDcodes,
  useVlaamsePostcodes,
} from '../enumerated-lists/enumeratedlists.hook';
import { IInputFieldProps } from '../components/form/input-field';
import Afvalcode from './afvalcode.model';
import InzamelwijzeCode from './inzamelwijze.model';
import { bestemmingTypesInzamelingHA, bestemmingTypesVerwerking, isBelgischeWerfBuitenVlaanderen, isVlaanderen } from './bestemming/bestemming.model';
import TextArea from '../components/form/text-area';
import { meldingTypes } from './melding.type';
import { displayClean, meerdereOorsprongen, toepassingswijzeTypes, vervoerswijzesTypes, verwerkingswijzeLabels, verwerkingswijzeTypes } from './melding-detail.hook';
import { oorsprongTypesVerwerking } from './bestemming/oorsprong.model';
import Materiaal, { EURALCODE, MATERIAALCODE } from './materiaal.model';
import { SimpleSelect } from '../components/form/select/select-with-search';
import { IMateriaalEnum } from './melding-verwerking-form';
import { BestemmingJsonResponse, EnumeratedListJsonResponse } from '../generated';

const typesToRadioOptions = (input: any, cleanup: boolean = true) =>
  input.map((value: any) => ({
    value,
    label: cleanup ? displayClean(value) : value,
  }));

export const TonnageField: React.FC = () => <TextField label="Tonnage" name="tonnage" required placeholder="123,123456" />;

export const PeriodeSelection: React.FC = () => <Radio required label="Periode eenheid" name="periodeEenheid" options={typesToRadioOptions(periodeEenheden)} />;

const upperCaseMapper = (val: string) => val.toUpperCase();

export const PeriodeWaardeField: React.FC = () => {
  const [{ value }] = useField('periodeEenheid');
  const placeholder = useMemo(() => periodePlaceholder(value), [value]);
  return <TextField label="Periode waarde" name="periodeWaarde" required placeholder={placeholder} valueMapper={upperCaseMapper} />;
};

export const LandSelect: React.FC<
  IInputFieldProps & {
    disabled?: boolean;
    required?: boolean;
    filter?: (land: EnumeratedListJsonResponse) => boolean;
  }
> = ({ name, label, disabled = false, required = false, filter = () => true }) => {
  const { data: landen, isLoading } = useLanden(true);
  const selectOptions = useMemo(
    () =>
      sortByCode(landen)
        .filter(filter)
        .map((code) => ({
          value: code.code,
          label: code.omschrijving,
        })),
    [landen],
  );

  return <SimpleSelect required={required} disabled={disabled} name={name} label={label} noDataLabel="landen" isLoading={isLoading} options={selectOptions} />;
};
export const useAfvalCodeOptions = () => {
  const { data: afvalcodes, isLoading } = useAfvalcodes();

  const options = useMemo(
    () =>
      sortByCode(afvalcodes).map((code) => ({
        value: code.code,
        label: Afvalcode.format(code),
      })),
    [afvalcodes],
  );
  return {
    isLoading,
    options,
  };
};
export const AfvalcodeSelect: React.FC<{ required?: boolean }> = ({ required }) => {
  const { isLoading, options } = useAfvalCodeOptions();
  return <SimpleSelect disabled={false} name="afvalcode" label="OVAM afvalcode" noDataLabel="afvalcode" isLoading={isLoading} options={options} required={required} />;
};

export const useEuralcodeOptions = () => {
  const { data: euralcodes, isLoading } = useEuralcodes();

  const options = useMemo(
    () =>
      sortByCode(euralcodes).map((code) => ({
        value: code.code,
        label: Materiaal.format(EURALCODE, code.code, code.omschrijving),
      })),
    [euralcodes],
  );
  return {
    isLoading,
    options,
  };
};
export const isBestemmingForEuralcode = (type: string) => !['GEEN_ONDERNEMING', 'DISPERS_GEBRUIK', 'PARTICULIER'].includes(type);

export const useMateriaalOptions = () => {
  const { data: materiaalcodes, isLoading: isLoadingMateriaal } = useMateriaalcodes();
  const { data: euralcodes, isLoading: isLoadingEural } = useEuralcodes();

  const options = useMemo(
    () => [
      {
        label: MATERIAALCODE,
        options: sortByCode(materiaalcodes).map((code) => ({
          value: code.code,
          label: Materiaal.format(MATERIAALCODE, code.code, code.omschrijving),
          group: MATERIAALCODE,
        })),
      },
      {
        label: EURALCODE,
        options: sortByCode(euralcodes).map((code) => ({
          value: code.code,
          label: Materiaal.format(EURALCODE, code.code, code.omschrijving),
          group: EURALCODE,
        })),
      },
    ],
    [materiaalcodes, euralcodes],
  );
  return {
    isLoading: isLoadingMateriaal || isLoadingEural,
    options,
  };
};
export const MateriaalEnumSelect: React.FC<{ required?: boolean }> = ({ required }) => {
  const { options, isLoading } = useMateriaalOptions();
  const [typeField] = useField<any>('materiaalEnum.type');
  const label = typeField.value ? typeField.value : 'EURAL- of materiaalcode';

  return (
    <SimpleSelect
      disabled={false}
      name="materiaalEnum"
      label={label}
      noDataLabel="codes"
      isLoading={isLoading}
      options={options}
      required={required}
      fieldName="materiaalEnum.value"
    />
  );
};
export const useInzamelwijzeOptions = () => {
  const { data: inzamelwijzes, isLoading } = useInzamelwijzes();

  const options = useMemo(
    () =>
      sortByCode(inzamelwijzes).map((inzamelwijze: EnumeratedListJsonResponse) => ({
        value: inzamelwijze.code,
        label: InzamelwijzeCode.format(inzamelwijze),
      })),
    [inzamelwijzes],
  );
  return {
    isLoading,
    options,
  };
};
export const InzamelwijzeSelect: React.FC<{ required?: boolean }> = ({ required }) => {
  const { isLoading, options } = useInzamelwijzeOptions();
  return <SimpleSelect disabled={false} name="inzamelwijzeCode" label="Inzamelwijze" noDataLabel="inzamelwijzes" isLoading={isLoading} options={options} required={required} />;
};

export const useInzamelwijzeBAOptions = () => {
  const { data: inzamelwijzes, isLoading } = useInzamelwijzesBA();

  const options = useMemo(
    () =>
      sortByCode(inzamelwijzes).map((inzamelwijze: EnumeratedListJsonResponse) => ({
        value: inzamelwijze.code,
        label: inzamelwijze.omschrijving,
      })),
    [inzamelwijzes],
  );
  return {
    isLoading,
    options,
  };
};

export const InzamelwijzeCommentaar: React.FC = () => {
  const [{ value }] = useField('inzamelwijzeCode');
  const isRequired = InzamelwijzeCode.isAndere(value);

  return <TextArea label="Commentaar" name="inzamelwijzeCommentaar" required={isRequired} />;
};

const PostcodeSelect: React.FC<{ postcodes?: EnumeratedListJsonResponse[]; isLoading: boolean } & IInputFieldProps> = ({ name, isLoading, postcodes, label, required }) => {
  const selectOptions = useMemo(
    () =>
      sortByCode(postcodes).map((code) => ({
        value: code.code,
        label: code.code,
      })),
    [postcodes],
  );

  return (
    <SimpleSelect disabled={false} name={name} label={label} noDataLabel="postcodes" isLoading={isLoading} options={selectOptions} required={required} allowClear={!required} />
  );
};

export const BelgischePostcodeSelect: React.FC<IInputFieldProps> = ({ name, label, required }) => {
  const { data: postcodes, isLoading } = usePostcodes();

  return <PostcodeSelect name={name} isLoading={isLoading} postcodes={postcodes} label={label} required={required} />;
};

export const VlaamsePostcodeSelect: React.FC<IInputFieldProps> = ({ name, label, required }) => {
  const { data: postcodes, isLoading } = useVlaamsePostcodes();

  return <PostcodeSelect name={name} isLoading={isLoading} postcodes={postcodes} label={label} required={required} />;
};

export const useVlaamsePostcodeOptions = () => {
  const { data: postcodes, isLoading } = useVlaamsePostcodes();

  const options = useMemo(
    () =>
      sortByCode(postcodes).map((code) => ({
        value: code.code,
        label: code.code,
      })),
    [postcodes],
  );
  return {
    isLoading,
    options,
  };
};

export const MeerdereOorsprongenSelection: React.FC = () => (
  <Radio required label="Meerdere oorsprongen" name="meerdereOorsprongen" options={typesToRadioOptions(meerdereOorsprongen)} />
);

interface IGeregistreerdeTradersProps extends IInputFieldProps {
  required?: boolean;
}

export const GeregistreerdeTraderSelect: React.FC<IGeregistreerdeTradersProps> = ({ name, label, required = false }) => {
  const { data: traders, isLoading } = useGeregistreerdeTraders();

  const traderOptions = useMemo(
    () =>
      sortByCode(traders).map((code) => ({
        value: code.code,
        label: code.omschrijving,
      })),
    [traders],
  );

  return (
    <SimpleSelect
      disabled={false}
      name={name}
      label={label}
      noDataLabel="geregistreerde traders"
      isLoading={isLoading}
      options={traderOptions}
      required={required}
      allowClear={!required}
    />
  );
};

const BestemmingTypeRadio: React.FC<{ options: { label: string; value: string }[]; disabled?: boolean }> = ({ options, disabled = false }) => (
  <Radio required label="Type" name="bestemming.type" options={options} disabled={disabled} />
);

export const BestemmingTypeInzamelingHARadio: React.FC = React.memo(() => <BestemmingTypeRadio options={typesToRadioOptions(bestemmingTypesInzamelingHA)} />);
export const BestemmingTypeVerwerkingRadio: React.FC = React.memo(() => <BestemmingTypeRadio options={typesToRadioOptions(bestemmingTypesVerwerking)} />);

const OorsprongTypeRadio: React.FC<{ options: { label: string; value: string }[] }> = ({ options }) => <Radio required label="Type" name="oorsprong.type" options={options} />;

export const OorsprongTypeVerwerkingRadio: React.FC = React.memo(() => <OorsprongTypeRadio options={typesToRadioOptions(oorsprongTypesVerwerking)} />);

const typesToSelectOptions = (input: any, cleanup: boolean = true) =>
  input.map((value: any) => ({
    value,
    label: cleanup ? displayClean(value) : value,
  }));

const typeMeldingOptions = typesToRadioOptions(meldingTypes);

export const TypeMelding: React.FC = React.memo(() => <Radio required label="Type melding" name="meldingType" options={typeMeldingOptions} />);

export const isToepassingswijzeRequired = (bestemming: BestemmingJsonResponse, materiaalEnum: IMateriaalEnum, vlaamsePostcodes?: EnumeratedListJsonResponse[]): boolean =>
  materiaalEnum?.type !== EURALCODE || isBelgischeWerfBuitenVlaanderen(bestemming, vlaamsePostcodes);

export const Toepassingswijze: React.FC = React.memo(() => {
  const selectOptions = useMemo(() => typesToSelectOptions(toepassingswijzeTypes), []);
  const [{ value: bestemming }] = useField('bestemming');
  const [{ value: materiaalEnum }] = useField('materiaalEnum');
  const { data: vlaamsePostcodes } = useVlaamsePostcodes();

  const required = isToepassingswijzeRequired(bestemming, materiaalEnum, vlaamsePostcodes);

  return (
    <SimpleSelect
      disabled={false}
      name="toepassingswijze"
      label="Toepassingswijze"
      noDataLabel="toepassingswijze"
      isLoading={false}
      options={selectOptions}
      required={required}
      allowClear={!required}
    />
  );
});

export const isVerwerkingRequired = (
  vlarema9VanToepassing: boolean,
  materiaalEnum: IMateriaalEnum,
  type: string,
  bestemming: BestemmingJsonResponse,
  vlaamsePostcodes?: EnumeratedListJsonResponse[],
) =>
  materiaalEnum?.type === EURALCODE &&
  (vlarema9VanToepassing || !(type === 'UIT' && isVlaanderen(bestemming, vlaamsePostcodes))) &&
  !isBelgischeWerfBuitenVlaanderen(bestemming, vlaamsePostcodes);

const useVerwerkingRequired = (): boolean => {
  const [{ value: materiaalEnum }] = useField('materiaalEnum');
  const [{ value: meldingType }] = useField('meldingType');
  const [{ value: bestemming }] = useField('bestemming');
  const [{ value: eenheid }] = useField('periodeEenheid');
  const [{ value: waarde }] = useField('periodeWaarde');
  const { data: vlaamsePostcodes } = useVlaamsePostcodes();
  return isVerwerkingRequired(isVlarema9VanToepassing(eenheid, waarde), materiaalEnum, meldingType, bestemming, vlaamsePostcodes);
};

export const useVlarema9VanToepassing = (): boolean => {
  const [{ value: eenheid }] = useField('periodeEenheid');
  const [{ value: waarde }] = useField('periodeWaarde');
  return isVlarema9VanToepassing(eenheid, waarde);
};

export const RDcodeSelect: React.FC = () => {
  const { data: rdcodes, isLoading } = useRDcodes();

  const selectOptions = useMemo(
    () =>
      sortByCode(rdcodes).map((code) => ({
        value: code.code,
        label: code.omschrijving,
      })),
    [rdcodes],
  );
  const required = useVerwerkingRequired();

  return (
    <SimpleSelect disabled={false} name="rdCode" label="R/D-code" noDataLabel="rdCode" isLoading={isLoading} options={selectOptions} required={required} allowClear={!required} />
  );
};

export const VerwerkingswijzeSelect: React.FC = () => {
  const selectOptions = useMemo(
    () =>
      verwerkingswijzeTypes.map((value: any) => ({
        value,
        label: verwerkingswijzeLabels[value],
      })),
    [],
  );
  const required = useVerwerkingRequired();

  return (
    <SimpleSelect
      disabled={false}
      name="verwerkingswijze"
      label="Verwerkingswijze"
      noDataLabel="verwerkingswijze"
      isLoading={false}
      options={selectOptions}
      required={required}
      allowClear={!required}
    />
  );
};

export const VervoerswijzeSelect: React.FC<{ required?: boolean }> = ({ required }) => {
  const selectOptions = useMemo(() => typesToSelectOptions(vervoerswijzesTypes), []);

  return <SimpleSelect disabled={false} name="vervoerswijze" label="Vervoerswijze" noDataLabel="vervoerswijze" isLoading={false} options={selectOptions} required={required} />;
};
