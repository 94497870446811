import React, { useState } from 'react';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';
import { Link } from 'react-router-dom';
import { OrganisatiePagingAndFilter, useOrganisaties } from './organisatie.hooks';
import { createTextInputFilterColumn } from '../components/table/default.datatable.options';
import getFilterValues from '../util/table-filter-utils';
import RouteButton from '../components/button/route-button';
import { OrganisatieOverviewJsonResponse, OrganisatieType } from '../generated';
import Pill from '../components/pill/pill';
import ActionGroupRight from '../components/button/action-group';
import ListDetails from '../components/details/list-details';
import OverviewTable, { RecordProps } from '../components/table/overview-table';

const organisatieTypeLabels = [
  {
    label: 'Inzamelaar huishoudelijk afval',
    value: OrganisatieType.INZAMELAAR_HUISHOUDELIJK_AFVAL,
  },
  {
    label: 'Inzamelaar bedrijfsafval',
    value: OrganisatieType.INZAMELAAR_BEDRIJFSAFVAL,
  },
  {
    label: 'Verdeler huishoudelijk afval',
    value: OrganisatieType.VERDELER_HUISHOUDELIJK_AFVAL,
  },
];

export interface OrganisatieOverviewRow extends RecordProps {
  key: string;
  organisatiecode: string;
  organisatienaam?: string;
  emailadres?: string;
  inzamelaarVerdeler: string[];
}

const getInzamelaarAndVerdelerLabels = (organisatie: OrganisatieOverviewJsonResponse): string[] =>
  organisatieTypeLabels.filter((t) => organisatie.types.includes(t.value)).map((t) => t.label);

export const toOrganisatieOverviewRow = (value: OrganisatieOverviewJsonResponse): OrganisatieOverviewRow => ({
  ...value,
  referentie: value.organisatiecode,
  key: value.organisatiecode,
  organisatienaam: value.organisatienaam,
  emailadres: value.emailadres,
  inzamelaarVerdeler: getInzamelaarAndVerdelerLabels(value),
});

export interface OrganisatieOverviewProps {
  title: string;
}

const ORGANISATIE_BASE_URL = '/administratie';

const OrganisatieOverview: React.FC<OrganisatieOverviewProps> = ({ title }) => {
  const defaultPageSize = 100;
  const [pagingAndFiltering, setPagingAndFiltering] = useState<OrganisatiePagingAndFilter>({
    page: 0,
    size: defaultPageSize,
  });

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    setPagingAndFiltering({
      page: (pagination.current ?? 1) - 1,
      size: pagination.pageSize ?? defaultPageSize,
      organisatiecode: getFilterValues('organisatiecode', filters)?.[0],
    });
  };

  const columns: ColumnsType<OrganisatieOverviewRow> = [
    {
      ...createTextInputFilterColumn<OrganisatieOverviewRow>('Organisatiecode', 'organisatiecode', pagingAndFiltering.organisatiecode),
      render: (organisatiecode: string, organisatie: OrganisatieOverviewRow) => <Link to={`${ORGANISATIE_BASE_URL}/${organisatie.organisatiecode}`}>{organisatiecode}</Link>,
    },
    {
      title: 'Naam',
      dataIndex: 'organisatienaam',
      key: 'organisatienaam',
      className: 'organisatienaam-column',
    },
    {
      title: 'E-mailadres',
      dataIndex: 'emailadres',
      key: 'emailadres',
      className: 'emailadres-column',
    },
    {
      title: 'Inzamelaar / verdeler',
      dataIndex: 'inzamelaarVerdeler',
      key: 'inzamelaarVerdeler',
      className: 'inzamelaar-verdeler-column',
      render: (types: string[]) => (
        <ListDetails
          type="horizontal"
          data={types.map((type) => (
            <Pill value={type} />
          ))}
        />
      ),
    },
  ].flatMap((col) => (col ? [col] : []));

  const { error, isLoading, isFetching, data: page } = useOrganisaties(pagingAndFiltering);

  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toOrganisatieOverviewRow),
      }
    : undefined;

  return (
    <OverviewTable
      title={title}
      itemsLabel="organisaties"
      columns={columns}
      page={mappedPage}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      notFoundMessage="Geen organisaties gevonden."
    >
      <div className="vl-col--1-1 vl-u-spacer">
        <ActionGroupRight>
          <div className="vl-u-spacer">
            <RouteButton className="vl-button--narrow" label="Nieuwe organisatie" link={`${ORGANISATIE_BASE_URL}/nieuw`} />
          </div>
        </ActionGroupRight>
      </div>
    </OverviewTable>
  );
};

export default OrganisatieOverview;
