import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadExistingVerdelingCsv } from './verdeling.hook';
import VerdelingCsvUploader from './verdeling-csv-uploader';

export const UPDATE_VERDELINGEN_DESCRIPTION = 'Vul aan of corrigeer bestaande verdelingen';

const VerdelingCsvUpdatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/verdeling/csv-aanvulling">{UPDATE_VERDELINGEN_DESCRIPTION}</BreadcrumbsItem>
    <VerdelingCsvUploader handleUpload={uploadExistingVerdelingCsv} title={`${UPDATE_VERDELINGEN_DESCRIPTION} via een CSV-bestand`} />
  </>
);

export default VerdelingCsvUpdatePage;
