import React from 'react';
import './multi-line-text.modules.css';

export interface IMultiLineTextProps {
  value: string;
}

const MultiLineText: React.FC<IMultiLineTextProps> = ({ value }) => <p>{value}</p>;

export default MultiLineText;
