import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '../../components/button/link-button';

interface ProfileLinkProps {
  dataCyPostfix?: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ProfileLink: React.FC<PropsWithChildren<ProfileLinkProps>> = ({ children, dataCyPostfix, handleClick }) => (
  <div className="profile__toggle-wrapper">
    <LinkButton className="profile__toggle" data-cy={`profile-link-${dataCyPostfix}`} handleClick={handleClick}>
      {children}
    </LinkButton>
  </div>
);

interface IProfileProps {
  naam: string;
  organisatie: string;
}

export const Profile: React.FC<IProfileProps> = ({ naam, organisatie }) => (
  <div className="profile__toggle-wrapper">
    <Link to="/contactgegevens" className="profile__toggle">
      <span data-cy="profile">
        {naam} ({organisatie})
      </span>
    </Link>
  </div>
);
