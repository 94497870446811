import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { VerdelingDetailsJsonResponse, VerdelingOverviewJsonResponse } from '../generated';
import { verdelingenBaseUrl } from './verdeling.hook';

export const formatAfvalcodes = (verdeling: VerdelingOverviewJsonResponse) => verdeling.criteria.afvalcodes.slice().sort().join(', ');

export const useVerdeling = (referentie: string): UseQueryResult<VerdelingDetailsJsonResponse, Error> => {
  const verdelingUrl = `${verdelingenBaseUrl}/${referentie}`;
  return useQuery<VerdelingDetailsJsonResponse, Error>(verdelingUrl, async () => {
    const { data } = await axios.get<VerdelingDetailsJsonResponse>(verdelingUrl);
    return data;
  });
};
