import { Nullable } from '../types/nullable';

class ModuloCheck {
  static isValid(value: Nullable<string>): boolean {
    if (value) {
      const cleaned = value.replace(/\./g, '');
      const num = Number(cleaned.substring(0, 8));
      const mod = Number(cleaned.substring(8));
      return 97 - (num % 97) === mod;
    }
    return false;
  }
}

export default ModuloCheck;
