import React from 'react';

const Loading: React.FC<{ message: string }> = ({ message }) => (
  <section className="vl-region">
    <div className="vl-layout">
      <div className="vl-grid">
        <div className="vl-col--1-1">
          <div className="vl-u-align-center">
            <div className="vl-loader" role="status" />
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Loading;
