import * as Yup from 'yup';
import { TestFunction } from 'yup';
import dayjs from 'dayjs';
import Tonnage from './tonnage.model';
import { matchesPattern, parse } from './periode.model';
import requiredField from '../util/required-field';
import { MAATREGELEN_VLAREMA_9_START_DATUM, MELDING_START_DATUM } from './melding.config';
import { PeriodeEenheid } from '../generated';

export const tonnageValidationSchema = requiredField(
  Yup.string()
    .test('tonnage-maxvalue', 'Tonnage moet een waarde hebben tussen 0 en 99.999.999,999999', (value: string | undefined) => Tonnage.validateValue(value))
    .test('tonnage-format', 'Formaat klopt niet: x,x met maximum 6 cijfers na de komma', (value: string | undefined) => Tonnage.validateFormat(value)),
);

const ongeldigePeriodeMessage = (eenheid: string) => {
  switch (eenheid) {
    case PeriodeEenheid.DAG:
      return 'Ongeldige dag';
    case PeriodeEenheid.MAAND:
      return 'Ongeldige maand';
    case PeriodeEenheid.KWARTAAL:
      return 'Ongeldig kwartaal';
    case PeriodeEenheid.JAAR:
      return 'Ongeldig jaar';
    default:
      return 'Ongeldige periode';
  }
};

export const periodeWaardeValidationSchema = requiredField(
  Yup.string()
    .when('periodeEenheid', ([eenheid], schema: Yup.StringSchema) =>
      eenheid ? schema.test('periode-format', `Ongeldig ${eenheid.toLowerCase()}formaat`, ((value: string) => matchesPattern(eenheid, value)) as TestFunction<any, any>) : schema,
    )
    .when('periodeEenheid', ([eenheid], schema: Yup.StringSchema) =>
      eenheid ? schema.test('periode-valid', ongeldigePeriodeMessage(eenheid), ((value: string) => parse(eenheid, value).isValid()) as TestFunction<any, any>) : schema,
    )
    .when('periodeEenheid', ([eenheid], schema: Yup.StringSchema) =>
      eenheid && eenheid !== PeriodeEenheid.JAAR
        ? schema.test('periode-tussen-2020-vandaag', 'Periode moet tussen 1 januari 2020 en vandaag liggen', ((value: string) => {
            const period: dayjs.Dayjs = parse(eenheid, value);
            return !period.isBefore(dayjs(MELDING_START_DATUM)) && !period.isAfter(dayjs());
          }) as TestFunction<any, any>)
        : schema,
    )
    .when('periodeEenheid', ([eenheid], schema: Yup.StringSchema) =>
      eenheid === PeriodeEenheid.JAAR
        ? schema.test('jaar-tussen-2020-2023', 'Jaar moet tussen 2020 en 2023 liggen. Vanaf 2024 bent U verplicht om minstens per kwartaal te melden.', ((
            // ? schema.test('jaar-tussen-2020-2023', 'Vanaf 2024 kan u geen meldingen meer voor een jaar doorgeven. U bent verplicht om minstens per kwartaal te melden.', ((
            value: string,
          ) => {
            const period: dayjs.Dayjs = parse(eenheid, value);
            return !period.isBefore(dayjs(MELDING_START_DATUM)) && period.isBefore(MAATREGELEN_VLAREMA_9_START_DATUM);
          }) as TestFunction<any, any>)
        : schema,
    ),
);
