import React from 'react';
import { displayClean } from '../melding-detail.hook';
import { DetailsFormField, DetailsFormSection } from '../../components/form/details-form';
import AdresDetails from '../../adres/adres-details';
import { isEmptyAdres } from '../../adres/adres.model';
import MultiLineText from '../../components/details/multi-line-text';
import { MeldingVerwerkingDetailsJsonResponse } from '../../generated';

interface OorsprongDetailsFormSectionProps {
  melding: MeldingVerwerkingDetailsJsonResponse;
}

const OorsprongDetailsFormSection: React.FC<OorsprongDetailsFormSectionProps> = ({ melding }) => (
  <DetailsFormSection title="Oorsprong">
    <DetailsFormField label="Type" data={displayClean(melding.oorsprong.type)} />
    <DetailsFormField label="Vestigingseenheid" data={melding.oorsprong.vestigingsnummer} />
    <DetailsFormField label="BTW-nummer" data={melding.oorsprong.btwNummer} />
    <DetailsFormField label="Ondernemingsnummer" data={melding.oorsprong.ondernemingsnummer} />
    <DetailsFormField label="Naam" data={melding.oorsprong.naam} />
    <DetailsFormField label="Adres" data={isEmptyAdres(melding.oorsprong) ? null : <AdresDetails adres={melding.oorsprong} />} />
    <DetailsFormField label="Commentaar" data={melding.oorsprong.commentaar && <MultiLineText value={melding.oorsprong.commentaar} />} />
  </DetailsFormSection>
);

export default OorsprongDetailsFormSection;
