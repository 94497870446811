import React from 'react';
import DOMPurify from 'dompurify';
import cx from 'classnames';

interface JiraRenderedFieldViewerProps {
  fieldContents: string;
  className?: string;
}

const domPurify = DOMPurify(window);

const JiraRenderedFieldViewer: React.FC<JiraRenderedFieldViewerProps> = ({ fieldContents, className = '' }) => (
  // eslint-disable-next-line react/no-danger
  <div className={cx('jira-rendered-field', className)} dangerouslySetInnerHTML={{ __html: domPurify.sanitize(fieldContents) }} />
);

export default JiraRenderedFieldViewer;
