import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams } from 'react-router-dom';
import { useVerdeling } from './verdeling-detail.hook';
import ErrorAlert from '../components/alert/error-alert';
import DetailsLoading from '../components/details/details-loading';
import VerdelingDetails from './verdeling-details';

const VerdelingDetailPage: React.FC = () => {
  const { referentie } = useParams() as { referentie: string };
  const { data: verdeling, error, isLoading } = useVerdeling(referentie);
  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/verdeling/${referentie}`}>{verdeling?.identificatie ?? '...'}</BreadcrumbsItem>
        {error && !isLoading && (
          <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van de verdeling. Gelieve later nog eens opnieuw te proberen." />
        )}
        {!error && isLoading && <DetailsLoading />}
        {!error && !isLoading && verdeling && <VerdelingDetails verdeling={verdeling} />}
      </div>
    </section>
  );
};

export default VerdelingDetailPage;
