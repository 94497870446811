import React, { useState } from 'react';
import { Pageable } from '../components/table/pageable';
import ValidatievraagOverview from './validatievraag-overview';

const defaultPageSize = 100;

const ValidatievraagOverviewPage: React.FC = () => {
  const [pageable, setPageable] = useState<Pageable>({
    page: 0,
    size: defaultPageSize,
  });

  return <ValidatievraagOverview pageable={pageable} defaultPageSize={defaultPageSize} onChange={setPageable} />;
};

export default ValidatievraagOverviewPage;
