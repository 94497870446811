import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { uploadNewVerdelingCsv } from './verdeling.hook';
import VerdelingCsvUploader from './verdeling-csv-uploader';

export const CREATE_VERDELINGEN_DESCRIPTION = 'Importeer nieuwe verdelingen';

const VerdelingCsvCreatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/verdeling/csv-nieuw">{CREATE_VERDELINGEN_DESCRIPTION}</BreadcrumbsItem>
    <VerdelingCsvUploader handleUpload={uploadNewVerdelingCsv} title={`${CREATE_VERDELINGEN_DESCRIPTION} via een CSV-bestand`} />
  </>
);

export default VerdelingCsvCreatePage;
