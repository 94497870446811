import React from 'react';
import cx from 'classnames';
import { useAuthorisationInfo } from '../services/security/authorisation';
import LinkListContainer from '../components/linklist/link-list-container';
import LinkListItem from '../components/linklist/link-list-item';
import NavigationSection from '../components/navigation/navigation-section';
import { CREATE_MELDINGEN_DESCRIPTION, UPDATE_MELDINGEN_DESCRIPTION } from '../melding/melding.labels';
import { CREATE_VERDELINGEN_DESCRIPTION } from '../verdeling/verdeling-csv-create-page';
import { UPDATE_VERDELINGEN_DESCRIPTION } from '../verdeling/verdeling-csv-update-page';
import styles from '../components/navigation/navigation-section.module.css';
import { Scope } from '../generated';
import AuthorisedScope from './authorised-scope';
import LoadingPage from './loading-page';
import { CREATE_VALIDATIEVRAGEN_DESCRIPTION } from '../validatievraag/validatievraag-csv-create.page';

const plusIconClasses = 'fas fa-plus';
const fileUploadIconClasses = 'fas fa-upload';
const searchIconClasses = 'fas fa-search';
const mergeIconClasses = 'fa fa-sitemap';
const listIconClasses = 'fas fa-list';
const haIconClasses = 'fa fa-home';
const baIconClasses = 'fa fa-building';
const vwIconClasses = 'fa fa-industry';

const HomeView: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();
  const tileClasses = cx('vl-col--6-12', 'vl-col--12-12--xs', styles.gridcell);

  return (
    <section className="vl-region">
      <div className="vl-layout">
        <h2 className="vl-title vl-title--h2" data-cy="page-title">
          Startpagina
        </h2>
        <LoadingPage isLoading={isLoading}>
          <div className="vl-grid">
            <AuthorisedScope requiredScope={Scope.INZAMELING_HUISHOUDELIJK_AFVAL}>
              <div className={tileClasses} data-cy="section-inzameling-ha">
                <NavigationSection title="Inzameling huishoudelijk afval" icon="vuilniszak">
                  <div className="vl-grid">
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Gegevens melden">
                        <LinkListItem label="Nieuwe melding" link="melding/inzameling/huishoudelijk-afval/nieuw" iconClasses={plusIconClasses} />
                        <LinkListItem label={CREATE_MELDINGEN_DESCRIPTION} link="melding/inzameling/huishoudelijk-afval/csv-nieuw" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Raadplegen en corrigeren">
                        <LinkListItem label="Mijn meldingen" link="melding/inzameling/huishoudelijk-afval" iconClasses={searchIconClasses} />
                        <LinkListItem label={UPDATE_MELDINGEN_DESCRIPTION} link="melding/inzameling/huishoudelijk-afval/csv-aanvulling" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                  </div>
                </NavigationSection>
              </div>
              <AuthorisedScope requiredScope={Scope.VERDELING_HUISHOUDELIJK_AFVAL}>
                <div className={tileClasses} data-cy="section-verdeling-ha">
                  <NavigationSection title="Verdeling huishoudelijk afval" icon="verdeling">
                    <div className="vl-grid">
                      <div className="vl-col--6-12 vl-col--12-12--xs">
                        <LinkListContainer title="Gegevens melden">
                          <LinkListItem label={CREATE_VERDELINGEN_DESCRIPTION} link="verdeling/csv-nieuw" iconClasses={fileUploadIconClasses} />
                        </LinkListContainer>
                      </div>
                      <div className="vl-col--6-12 vl-col--12-12--xs">
                        <LinkListContainer title="Raadplegen en corrigeren">
                          <LinkListItem label="Mijn verdelingen" link="verdeling" iconClasses={mergeIconClasses} />
                          <LinkListItem label={UPDATE_VERDELINGEN_DESCRIPTION} link="verdeling/csv-aanvulling" iconClasses={fileUploadIconClasses} />
                        </LinkListContainer>
                      </div>
                    </div>
                  </NavigationSection>
                </div>
              </AuthorisedScope>
            </AuthorisedScope>
            <AuthorisedScope requiredScope={Scope.INZAMELING_BEDRIJFSAFVAL}>
              <div className={tileClasses} data-cy="section-inzameling-ba">
                <NavigationSection title="Inzameling bedrijfsafval" icon="container">
                  <div className="vl-grid">
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Gegevens melden">
                        <LinkListItem label={CREATE_MELDINGEN_DESCRIPTION} link="melding/inzameling/bedrijfsafval/csv-nieuw" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Raadplegen en corrigeren">
                        <LinkListItem label="Mijn meldingen" link="melding/inzameling/bedrijfsafval" iconClasses={searchIconClasses} />
                        <LinkListItem label={UPDATE_MELDINGEN_DESCRIPTION} link="melding/inzameling/bedrijfsafval/csv-aanvulling" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                  </div>
                </NavigationSection>
              </div>
            </AuthorisedScope>
            <AuthorisedScope requiredScope={Scope.AFVALVERWERKING}>
              <div className={tileClasses} data-cy="section-verwerking">
                <NavigationSection title="Afvalverwerking" icon="bedrijf">
                  <div className="vl-grid">
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Gegevens melden">
                        <LinkListItem label="Nieuwe melding" link="melding/verwerking/nieuw" iconClasses={plusIconClasses} />
                        <LinkListItem label={CREATE_MELDINGEN_DESCRIPTION} link="melding/verwerking/csv-nieuw" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Raadplegen en corrigeren">
                        <LinkListItem label="Mijn meldingen" link="melding/verwerking" iconClasses={searchIconClasses} />
                        <LinkListItem label={UPDATE_MELDINGEN_DESCRIPTION} link="melding/verwerking/csv-aanvulling" iconClasses={fileUploadIconClasses} />
                      </LinkListContainer>
                    </div>
                  </div>
                </NavigationSection>
              </div>
            </AuthorisedScope>
            <AuthorisedScope requiredScope={Scope.RAPPORTEN}>
              <div className={tileClasses} data-cy="section-rapporten">
                <NavigationSection title="Rapporten" icon="rapport">
                  <LinkListContainer>
                    <AuthorisedScope requiredScope={Scope.RAPPORT_INZAMELING_HA}>
                      <div data-cy="rapport-inzameling-ha">
                        <LinkListItem label="Inzameling huishoudelijk afval" link="rapporten/inzameling/huishoudelijk-afval/eigen" iconClasses={haIconClasses} />
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.RAPPORT_INZAMELING_HA_GEMEENTE}>
                      <div data-cy="rapport-inzameling-ha-gemeente">
                        <LinkListItem label="Inzameling huishoudelijk afval - gemeente" link="rapporten/inzameling/huishoudelijk-afval/gemeente" iconClasses={haIconClasses} />
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.RAPPORT_INZAMELING_HA_IC}>
                      <div data-cy="rapport-inzameling-ha-ic">
                        <LinkListItem
                          label="Inzameling huishoudelijk afval - intercommunale"
                          link="rapporten/inzameling/huishoudelijk-afval/intercommunale"
                          iconClasses={haIconClasses}
                        />
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.RAPPORT_INZAMELING_BA}>
                      <div data-cy="rapport-inzameling-ba">
                        <LinkListItem label="Inzameling bedrijfsafval" link="rapporten/inzameling/bedrijfsafval" iconClasses={baIconClasses} />
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.RAPPORT_AFVALVERWERKING}>
                      <div data-cy="rapport-verwerking">
                        <LinkListItem label="Afvalverwerking" link="rapporten/verwerking" iconClasses={vwIconClasses} />
                      </div>
                    </AuthorisedScope>
                  </LinkListContainer>
                </NavigationSection>
              </div>
            </AuthorisedScope>
            <AuthorisedScope requiredScope={Scope.VALIDATIEVRAGEN}>
              <div className={tileClasses} data-cy="section-validatieproces">
                <NavigationSection title="Validatievragen" icon="vraag">
                  <LinkListContainer>
                    <AuthorisedScope requiredScope={Scope.VALIDATIEVRAGEN_CREATIE}>
                      <LinkListItem label="Nieuwe validatievraag" link="validatievraag/nieuw" iconClasses={plusIconClasses} />
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.VALIDATIEVRAGEN_CREATIE}>
                      <LinkListItem label={CREATE_VALIDATIEVRAGEN_DESCRIPTION} link="validatievraag/csv-nieuw" iconClasses={fileUploadIconClasses} />
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.VALIDATIEVRAGEN_WORKFLOW}>
                      <LinkListItem label="Mijn validatievragen" link="validatievraag" iconClasses={listIconClasses} />
                    </AuthorisedScope>
                  </LinkListContainer>
                </NavigationSection>
              </div>
            </AuthorisedScope>
            <AuthorisedScope requiredScope={Scope.ADMINISTRATIE}>
              <div className={tileClasses} data-cy="section-administratie">
                <NavigationSection title="Administratie" icon="administratie">
                  <div className="vl-grid">
                    <AuthorisedScope requiredScope={Scope.BEHEER_ORGANISATIE}>
                      <div className="vl-col--6-12 vl-col--12-12--xs">
                        <LinkListContainer title="Beheer organisaties">
                          <LinkListItem label="Overzicht van organisaties" link="administratie" iconClasses={listIconClasses} />
                          <LinkListItem label="Nieuwe organisatie" link="administratie/nieuw" iconClasses={plusIconClasses} />
                        </LinkListContainer>
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.BEHEER_RECHTEN}>
                      <div className="vl-col--6-12 vl-col--12-12--xs">
                        <LinkListContainer title="Beheer rechten">
                          <LinkListItem label="Overzicht inzamelrechten HA" link="inzamelrechten/huishoudelijk-afval" iconClasses={listIconClasses} />
                          <LinkListItem label="Nieuw inzamelrecht HA" link="inzamelrechten/huishoudelijk-afval/nieuw" iconClasses={plusIconClasses} />
                        </LinkListContainer>
                      </div>
                    </AuthorisedScope>
                    <AuthorisedScope requiredScope={Scope.PERIODE_ZONDER_MELDING}>
                      <div className="vl-col--6-12 vl-col--12-12--xs">
                        <LinkListContainer title="Beheer periodes zonder melding">
                          <LinkListItem label="Overzicht periodes zonder melding" link="periodes-zonder-melding" iconClasses={listIconClasses} />
                          <LinkListItem label="Nieuwe periode zonder melding" link="periodes-zonder-melding/nieuw" iconClasses={plusIconClasses} />
                        </LinkListContainer>
                      </div>
                    </AuthorisedScope>
                    <div className="vl-col--6-12 vl-col--12-12--xs">
                      <LinkListContainer title="Beheer contactgegevens organisatie en gebruiker">
                        <LinkListItem label="Beheer de contactgegevens" link="/contactgegevens" iconClasses={listIconClasses} />
                      </LinkListContainer>
                    </div>
                  </div>
                </NavigationSection>
              </div>
            </AuthorisedScope>
          </div>
        </LoadingPage>
      </div>
    </section>
  );
};

export default HomeView;
