import React from 'react';
import * as Yup from 'yup';
import { COMMENTAAR } from './bestemming.model';
import TextArea from '../../components/form/text-area';
import { prefixNamespace } from './use-land-hook';
import { vrijeTekstSchema } from '../vrije-tekst-schema';

const maxLength = 4000;
export const dispersGebruikValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  [COMMENTAAR]: vrijeTekstSchema().max(maxLength, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten'),
});

export interface IDispersGebruikFormProps {
  namespace: string;
}

const DispersGebruikForm: React.FC<IDispersGebruikFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);
  return <TextArea maxLength={maxLength} label="Commentaar" name={withNamespace(COMMENTAAR)} />;
};

export default DispersGebruikForm;
