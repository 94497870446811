import React from 'react';

interface IPhoneToProps {
  phone: string;
}

const PhoneTo: React.FC<React.PropsWithChildren<IPhoneToProps>> = ({ phone, children }) => (
  <a className="vl-link" href={`tel:${phone}`}>
    {children || phone}
  </a>
);

export default PhoneTo;
