import React from 'react';
import cx from 'classnames';
import styles from './link-list-container.module.css';

interface LinkListContainerProps {
  title?: string;
}

const LinkListContainer: React.FC<React.PropsWithChildren<LinkListContainerProps>> = ({ title, children }) => (
  <>
    {title ? <h5 className={cx('vl-title', 'vl-title--h5', styles.title)}>{title}</h5> : ''}
    <ul className="vl-link-list">{children}</ul>
  </>
);

export default LinkListContainer;
