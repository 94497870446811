import React, { useMemo } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { createMeldingInzamelingHARequestValidationSchema, defaultMeldingFormValues, MeldingInzamelingHAForm } from './melding-inzameling-ha-form';
import FormTitle from '../components/form/form-title';
import BestemmingForm from './bestemming/bestemming-form';
import TextField from '../components/form/text-field';
import TextArea from '../components/form/text-area';
import {
  AfvalcodeSelect,
  BestemmingTypeInzamelingHARadio,
  InzamelwijzeCommentaar,
  InzamelwijzeSelect,
  MeerdereOorsprongenSelection,
  PeriodeSelection,
  PeriodeWaardeField,
  TonnageField,
  VerwerkingswijzeSelect,
  VlaamsePostcodeSelect,
} from './melding-form-fields';
import { validatieSchemaOrganisatiecodeVrijeTekst } from '../organisatie/organisatiecode.model';
import { vrijeTekstSchema } from './vrije-tekst-schema';
import requiredField from '../util/required-field';
import { useLanden } from '../enumerated-lists/enumeratedlists.hook';
import BtwTextField from './bestemming/btw-text-field';
import { useAuthorisationInfo } from '../services/security/authorisation';
import { MeldingInzamelingHAJsonRequest } from '../generated';
import DierlijkBijproductSelect from './materiaal/dierlijk-bijproduct-select';

const identificatieMaxLength = 36;

const MeldingInzamelingHACreateForm: React.FC = () => {
  const { data: landen } = useLanden(true);
  const { isBeheerder, isVerdeler } = useAuthorisationInfo();

  const createMutation = async (createRequest: MeldingInzamelingHAJsonRequest) => axios.post<any>('/ui/meldingen/inzameling/huishoudelijk-afval', createRequest);

  const meldingRequestValidationSchema = createMeldingInzamelingHARequestValidationSchema(isVerdeler).concat(
    Yup.object().shape({
      identificatie: requiredField(vrijeTekstSchema().max(identificatieMaxLength, `Identificatie kan niet meer dan ${identificatieMaxLength} tekens bevatten`)),
    }),
  );
  const meldingRequestValidationSchemaBeheerder = meldingRequestValidationSchema.concat(
    Yup.object().shape({
      eigenaar: validatieSchemaOrganisatiecodeVrijeTekst('Eigenaar'),
    }),
  );

  const validationSchema = useMemo(() => (isBeheerder ? meldingRequestValidationSchemaBeheerder : meldingRequestValidationSchema), [landen, isBeheerder]);

  return (
    <MeldingInzamelingHAForm
      initialValues={defaultMeldingFormValues}
      validationSchema={validationSchema}
      mutateFn={createMutation}
      submitLabel="Melding aanmaken"
      cancelLink="/melding/inzameling/huishoudelijk-afval"
    >
      <FormTitle title="Melding" />
      <TextField label="Identificatie" name="identificatie" required autoFocus />
      {isBeheerder && <TextField label="Eigenaar" name="eigenaar" required />}
      <TonnageField />
      <PeriodeSelection />
      <PeriodeWaardeField />
      <FormTitle title="Materiaalidentificatie" />
      <AfvalcodeSelect required />
      <TextArea label="Materiaal omschrijving" name="materiaalOmschrijving" />
      <DierlijkBijproductSelect />
      <InzamelwijzeSelect required />
      <InzamelwijzeCommentaar />
      <FormTitle title="Oorsprong" />
      <VlaamsePostcodeSelect label="Gemeente" name="oorsprong.postcode" required />
      {isVerdeler && <MeerdereOorsprongenSelection />}
      <FormTitle title="Bestemming" />
      <BestemmingTypeInzamelingHARadio />
      <BestemmingForm />
      <FormTitle title="Verwerking" />
      <VerwerkingswijzeSelect />
      <FormTitle title="IHM" />
      <BtwTextField label="BTW-nummer" name="ihmBtwNummer" placeholder="BE0123456789" />
    </MeldingInzamelingHAForm>
  );
};

export default MeldingInzamelingHACreateForm;
