import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useAuthorisationInfo } from '../services/security/authorisation';
import VerdelingOverview from './verdeling-overview';
import { VerdelingPagingAndFiltering } from './verdeling.hook';
import VerdelingCsvCreatePage from './verdeling-csv-create-page';
import VerdelingCsvUpdatePage from './verdeling-csv-update-page';
import VerdelingDetailPage from './verdeling-detail.page';
import LoadingPage from '../general/loading-page';
import AuthorisedScope from '../general/authorised-scope';
import { Scope } from '../generated';

const defaultPageSize = 100;

const VerdelingOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<VerdelingPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.VERDELING_HUISHOUDELIJK_AFVAL}>
        <BreadcrumbsItem to="/verdeling">Verdelingen</BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<VerdelingOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
          <Route path="csv-nieuw" element={<VerdelingCsvCreatePage />} />
          <Route path="csv-aanvulling" element={<VerdelingCsvUpdatePage />} />
          <Route path=":referentie" element={<VerdelingDetailPage />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default VerdelingOverviewPage;
