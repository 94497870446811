import React from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useMutation } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import SubmitButton from '../components/button/submit-button';
import TextArea from '../components/form/text-area';
import Radio from '../components/form/radio';
import { ApiError } from '../util/axios-error-mapping';
import ErrorAlert from '../components/alert/error-alert';
import { displayClean } from '../melding/melding-detail.hook';
import VlButton from '../components/button/vlaanderen-button';
import { AntwoordType, ValidatievraagAntwoordJsonRequest, ValidatievraagOverviewJsonResponse } from '../generated';

interface ValidatievraagAntwoordFormProps {
  validatievraag: ValidatievraagOverviewJsonResponse;
  antwoord: AntwoordType;
  onClose: () => void;
  onFinish: () => void;
}

interface AntwoordFormValues {
  antwoord: string;
  commentaar: string;
}

const antwoordOptions = Object.keys(AntwoordType).map((value) => ({ value, label: displayClean(value) }));

const ValidatievraagAntwoordForm: React.FC<ValidatievraagAntwoordFormProps> = ({ validatievraag, antwoord, onClose, onFinish }) => {
  const mutation = useMutation<AxiosResponse<any>, Error | ApiError, ValidatievraagAntwoordJsonRequest, unknown>((request: ValidatievraagAntwoordJsonRequest) =>
    axios.post(`/ui/validatievraag/${validatievraag.key}/antwoord`, request),
  );

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<AntwoordFormValues>) => {
    try {
      const request: ValidatievraagAntwoordJsonRequest = {
        key: validatievraag.key,
        antwoord: values.antwoord,
        commentaar: values.commentaar?.trim() || null,
      };
      await mutation.mutateAsync(request);
      onFinish();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues: AntwoordFormValues = {
    antwoord,
    commentaar: '',
  };

  return (
    <Formik validateOnBlur={false} initialValues={initialValues} onSubmit={handleSubmit}>
      {() => (
        <Form spellCheck={false}>
          {mutation.isError && <ErrorAlert error={mutation.error} defaultMessage="Het versturen van uw antwoord is mislukt." />}

          <div className="vl-form-grid vl-form-grid--is-stacked">
            <Radio required label="Antwoord" name="antwoord" options={antwoordOptions} />
            <TextArea label="Commentaar" name="commentaar" />

            <div className="vl-form-col--1-1 vl-u-spacer-top--medium">
              <div className="vl-action-group vl-action-group--align-right">
                <SubmitButton label="Antwoord versturen" isSubmitting={mutation.isLoading} isNarrow />
                <VlButton label="Annuleren" type="secondary" isNarrow onClick={() => onClose()} />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ValidatievraagAntwoordForm;
