import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import locale from 'antd/lib/locale/nl_BE';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import 'dayjs/locale/nl-be';
import './filter.css';
import { FilterButtons } from './component.filter.buttons';
import { formats } from '../../../melding/periode.model';

interface IFilterTextInputProps extends FilterDropdownProps {
  dataCy: string;
  timePicker: boolean;
  picker: 'month' | 'quarter' | 'date';
}

const formatTimestamp = (date: dayjs.Dayjs | null, index: number, timePicker: boolean, picker: 'month' | 'quarter' | 'date'): string => {
  if (!date) {
    return '';
  }
  if (timePicker) {
    return date.startOf('minute').utc().toISOString();
  }
  if (index === 0) {
    return date.startOf(picker).format(formats.DAG);
  } else {
    return date.endOf(picker).format(formats.DAG);
  }
};

const FilterTimeRangeDropdown: React.FC<IFilterTextInputProps> = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, dataCy, timePicker, picker = 'date' }) => {
  const isResetDisabled = selectedKeys.length === 0;

  const handleReset = () => {
    setSelectedKeys([]);
    clearFilters?.();
  };

  const [valueFrom, valueTo] = selectedKeys.map((ts) => (ts ? dayjs(ts.toString()) : null));

  const dateFormat = picker === 'quarter' || picker === 'month' ? undefined : 'DD-MM-YYYY';
  const format = timePicker ? 'DD-MM-YYYY HH:mm' : dateFormat;

  return (
    <div data-cy={`${dataCy}-filter-dropdown`} className="filter-dropdown">
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          picker={picker}
          autoFocus
          showTime={timePicker ? { format: 'HH:mm' } : false}
          format={format}
          placeholder={['van', 'tot']}
          onChange={(rangeValue) => {
            const rangeAsStrings = rangeValue?.map((ts, index) => formatTimestamp(ts, index, timePicker, picker)) ?? [];
            setSelectedKeys(rangeAsStrings);
          }}
          allowEmpty={[true, true]}
          className="filter-datepicker"
          value={[valueFrom, valueTo]}
        />
      </ConfigProvider>
      <FilterButtons onReset={handleReset} onConfirm={() => confirm()} resetDisabled={isResetDisabled} />
    </div>
  );
};

export default FilterTimeRangeDropdown;
