import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRoleHook } from '../services/security/use-role-hook';
import ValidatievraagOverviewPage from './validatievraag-overview.page';
import ValidatievraagCreatePage from './validatievraag-create.page';
import { useConfigContext } from '../components/context/config-context';
import { Maybe } from '../util/maybe';
import ValidatievraagCsvCreatePage from './validatievraag-csv-create.page';

const ValidatievraagRoutes: React.FC = () => {
  const { isBeheerder } = useRoleHook();
  const config = Maybe.toNullable(useConfigContext());
  if (!config?.validatieprocesEnabled) {
    return null;
  }

  return (
    <>
      <BreadcrumbsItem to="/validatievraag">Validatievragen</BreadcrumbsItem>
      <Routes>
        {isBeheerder ? (
          <>
            <Route path="/" index element={<Navigate to="nieuw" />} />
            <Route path="nieuw" element={<ValidatievraagCreatePage />} />
            <Route path="csv-nieuw" element={<ValidatievraagCsvCreatePage />} />
          </>
        ) : (
          <Route path="/" index element={<ValidatievraagOverviewPage />} />
        )}
        <Route path="/*" element={<Navigate to="/validatievraag" />} />
      </Routes>
    </>
  );
};

export default ValidatievraagRoutes;
