export const FALLBACK_MELDING_CSV_DOWNLOAD_LIMIT = 25000;
export const FALLBACK_VERDELING_CSV_DOWNLOAD_LIMIT = 2500;

export const DEFAULT_LOCALE = 'nl-BE';

declare global {
  export interface Window {
    APP_CONFIG: {
      authServerUrl: string;
    };
  }
}
