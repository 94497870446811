import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useOrganisatie } from './organisatie.hooks';
import Loading from '../components/details/loading';
import OrganisatieDetails from './organisatie-details';
import ErrorAlert from '../components/alert/error-alert';

const OrganisatieDetailPage: React.FC<any> = () => {
  const { organisatiecode } = useParams() as { organisatiecode: string };
  const { data: organisatie, error, isLoading } = useOrganisatie(organisatiecode);

  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/administratie/${organisatiecode}`}>{organisatiecode}</BreadcrumbsItem>

        {error && !isLoading && (
          <ErrorAlert error={error} defaultMessage="Er is een fout opgetreden bij het ophalen van de organisatie. Gelieve later nog eens opnieuw te proberen." />
        )}
        {!error && isLoading && <Loading message={`Bezig met het ophalen van organisatie ${organisatiecode}`} />}
        {!error && !isLoading && organisatie && <OrganisatieDetails organisatie={organisatie} />}
      </div>
    </section>
  );
};

export default OrganisatieDetailPage;
