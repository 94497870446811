import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import React from 'react';
import OrganisatieCreateForm from './organisatie-create-form';

const OrganisatieCreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/administratie/nieuw">Nieuwe organisatie</BreadcrumbsItem>
      <OrganisatieCreateForm />
    </div>
  </section>
);

export default OrganisatieCreatePage;
