export const EURALCODE = 'EURAL-code';
export const MATERIAALCODE = 'Materiaalcode';

class Materiaal {
  static format = (type: string, code: string, omschrijving?: string): string => `${type === EURALCODE ? Materiaal.formatEuralcode(code) : code} ${omschrijving ?? ''}`.trim();

  static formatEuralcode = (euralcode?: string) => (euralcode && [euralcode.substring(0, 2), euralcode.substring(2, 4), euralcode.substring(4, 6)].join(' ').trim()) ?? '';
}

export default Materiaal;
