import { isEmptyString } from '../util/string-utils';
import { BestemmingJsonResponse, MeldingVerwerkingOorsprongJsonResponse } from '../generated';

export interface IAdres {
  straat: string;
  huisnummer: string;
  uitbreiding: string;
  postcode: string;
  gemeente: string;
  land: string;
}

export type Adres = BestemmingJsonResponse | MeldingVerwerkingOorsprongJsonResponse;
const adresProps: Array<keyof Adres> = ['straat', 'huisnummer', 'uitbreiding', 'postcode', 'gemeente', 'land'];

export function isEmptyAdres(adres: Adres): boolean {
  return adresProps.map((prop) => adres[prop]).every((value) => isEmptyString(value));
}
