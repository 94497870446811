import React from 'react';
import * as Yup from 'yup';
import { useField } from 'formik';
import { COMMENTAAR, GEMEENTE, HUISNUMMER, LAND, NAAM, POSTCODE, STRAAT, UITBREIDING } from './bestemming.model';
import { BTW_NUMMER } from './oorsprong.model';
import { BelgischePostcodeSelect, LandSelect } from '../melding-form-fields';
import TextField from '../../components/form/text-field';
import TextArea from '../../components/form/text-area';
import { createBtwNummerValidationSchema } from '../../organisatie/btw-nummer.model';
import BtwTextField from './btw-text-field';
import { prefixNamespace } from './use-land-hook';
import OrganisatienaamField, { organisatienaamSchema } from '../../organisatie/organisatienaam-field';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import requiredField from '../../util/required-field';
import { buitenlandsePostcodeSchema } from '../postcode.schema';

export const werfValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  [BTW_NUMMER]: createBtwNummerValidationSchema(true),
  [NAAM]: requiredField(organisatienaamSchema),
  [STRAAT]: requiredField(vrijeTekstSchema().max(400, 'Straat kan niet meer dan 400 tekens bevatten')),
  [HUISNUMMER]: requiredField(vrijeTekstSchema().max(80, 'Huis- en busnummer kan niet meer dan 80 tekens bevatten')),
  [UITBREIDING]: vrijeTekstSchema().max(400, 'Adresuitbreiding kan niet meer dan 400 tekens bevatten'),
  [POSTCODE]: requiredField(buitenlandsePostcodeSchema),
  [GEMEENTE]: requiredField(vrijeTekstSchema().max(400, 'Gemeente kan niet meer dan 400 tekens bevatten')),
  [LAND]: requiredField(),
  [COMMENTAAR]: vrijeTekstSchema().max(4000, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten'),
});

export interface IWerfFormProps {
  namespace: string;
}

const WerfForm: React.FC<IWerfFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);
  const [, landMeta] = useField(withNamespace(LAND));

  const isBelgie = landMeta.value === 'BE';

  return (
    <>
      <BtwTextField label="BTW-nummer" name={withNamespace(BTW_NUMMER)} required placeholder="BE0123456789" />
      <OrganisatienaamField label="Naam" name={withNamespace(NAAM)} required />
      <TextField label="Straat" name={withNamespace(STRAAT)} required />
      <TextField label="Huis- en busnummer" name={withNamespace(HUISNUMMER)} required />
      <TextField label="Adresuitbreiding" name={withNamespace(UITBREIDING)} />
      {isBelgie && <BelgischePostcodeSelect label="Postcode" name={withNamespace(POSTCODE)} required />}
      {!isBelgie && <TextField label="Postcode" name={withNamespace(POSTCODE)} required />}
      <TextField label="Gemeente" name={withNamespace(GEMEENTE)} required />
      <LandSelect label="Land" name={withNamespace(LAND)} required />
      <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
    </>
  );
};

export default WerfForm;
