import React, { useEffect, useRef } from 'react';

import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { ConfigProvider, Table } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { ExpandableConfig, TableRowSelection } from 'antd/es/table/interface';
import { Page } from './page';
import TabelTotaal from './component.tabel.totaal';
import { isApiError } from '../../util/axios-error-mapping';
import Alert from '../alert/alert';

const ErrorMessage: React.FC<{ error: Error }> = ({ error }) => {
  const message = isApiError(error) ? error.errorCode : 'Het ophalen van de gegevens is mislukt.';
  return <Alert type="error">{message}</Alert>;
};

export interface RecordProps {
  referentie: string;
  bevroren?: boolean;
}

export interface OverviewTableProps<T> {
  title?: string;
  itemsLabel: string;
  columns: ColumnsType<T>;
  page?: Page<T>;
  defaultPageSize: number;
  updatePagingAndFilteringState: (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => void;
  isLoading: boolean;
  isFetching: boolean;
  error: Error | null;
  notFoundMessage: string;
  rowSelection?: TableRowSelection<T>;
  showSizeChanger?: boolean;
  scrollToTopAfterRender?: boolean;
  expandable?: ExpandableConfig<T>;
}

const DEFAULT_PAGE_SIZES = [10, 20, 50, 100, 1000];

const OverviewTable = <T extends RecordProps>({
  title,
  itemsLabel,
  columns,
  page,
  defaultPageSize,
  updatePagingAndFilteringState,
  error,
  notFoundMessage,
  isLoading,
  isFetching,
  rowSelection,
  showSizeChanger = true,
  scrollToTopAfterRender,
  expandable,
  children,
}: React.PropsWithChildren<OverviewTableProps<T>>) => {
  const showTotal = (total: number, range: [number, number]) => <TabelTotaal total={total} range={range} itemsLabel={itemsLabel} />;
  const layoutRef = useRef<HTMLElement>();
  useEffect(() => {
    if (scrollToTopAfterRender) {
      layoutRef.current?.scrollIntoView();
    }
  }, [page]);

  const isLoadingOrFetching = isLoading || isFetching;
  return (
    <section className="vl-region">
      {/* @ts-ignore */}
      <div className="vl-layout" ref={layoutRef}>
        {title && (
          <h2 className="vl-title vl-title--h2" data-cy="page-title">
            {title}
          </h2>
        )}
        <ConfigProvider renderEmpty={error ? () => <ErrorMessage error={error} /> : () => <span>{isLoadingOrFetching ? 'Laden...' : notFoundMessage}</span>}>
          {children}
          <div className="vl-grid">
            <div className="vl-col--1-1" data-cy="overview-table">
              <Table
                dataSource={page?.content}
                columns={columns}
                pagination={{
                  total: page?.totalElements,
                  current: (page?.pageNr ?? 0) + 1,
                  defaultPageSize,
                  hideOnSinglePage: false,
                  pageSize: page?.pageSize,
                  showSizeChanger,
                  position: ['bottomRight'],
                  pageSizeOptions: DEFAULT_PAGE_SIZES,
                  locale: {
                    items_per_page: '/ pagina',
                    prev_page: 'Vorige pagina',
                    next_page: 'Volgende pagina',
                    prev_3: "Vorige 3 pagina's",
                    next_3: "Volgende 3 pagina's",
                    prev_5: "Vorige 5 pagina's",
                    next_5: "Volgende 5 pagina's",
                  },
                  showTotal,
                }}
                onChange={(pagination, filters) => updatePagingAndFilteringState(pagination, filters)}
                rowClassName="table-row"
                loading={isLoadingOrFetching}
                rowSelection={rowSelection}
                expandable={expandable}
              />
            </div>
          </div>
        </ConfigProvider>
      </div>
    </section>
  );
};

export default OverviewTable;
