import React from 'react';
import { MeldingFilters } from './melding.hook';
import { useConfigContext } from '../components/context/config-context';
import { Maybe } from '../util/maybe';
import { FALLBACK_MELDING_CSV_DOWNLOAD_LIMIT } from '../configuration';
import CsvDownloadButton from '../components/table/csv-download-button';

interface MeldingCsvDownloadButtonProps {
  url: string;
  meldingFilters: MeldingFilters;
  totalElementsInOverview: number;
  onErrorMessageChanged: (errorMessage: string | null) => void;

  className?: string;
}

const MeldingCsvDownloadButton: React.FC<MeldingCsvDownloadButtonProps> = ({ url, meldingFilters, totalElementsInOverview, onErrorMessageChanged, className }) => {
  const context = useConfigContext();

  const downloadLimit = Maybe.toNullable(context)?.csv.meldingDownloadLimit ?? FALLBACK_MELDING_CSV_DOWNLOAD_LIMIT;

  return (
    <CsvDownloadButton
      className={className}
      url={url}
      filters={meldingFilters}
      totalElementsInOverview={totalElementsInOverview}
      csvEntitiesLabel="meldingen"
      downloadLimit={downloadLimit}
      onErrorMessageChanged={onErrorMessageChanged}
      isNarrow
    />
  );
};

export default MeldingCsvDownloadButton;
