/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Vervoerswijze {
  WATERWEG = 'WATERWEG',
  SPOORWEG = 'SPOORWEG',
  LUCHTVAART = 'LUCHTVAART',
  PIJPLEIDING = 'PIJPLEIDING',
  WEGVERVOER = 'WEGVERVOER',
  NIET_OPENBARE_WEG = 'NIET_OPENBARE_WEG',
}
