import React from 'react';
import { Modal } from 'antd';
import VlButton from '../button/vlaanderen-button';
import { GebruikerDetailsJsonResponse } from '../../generated';
import ContactgegevensDetails from '../../contactgegevens/contactgegevens-details';
import { ContactgegevensLoader } from '../../contactgegevens/contactgegevens-loader';

type Props = {
  gebruiker: GebruikerDetailsJsonResponse;
  open: boolean;
  onClose: () => void;
};

export const ContactgegevensInfoModal: React.FC<Props> = ({ gebruiker, open, onClose }) => (
  <Modal open={open} footer={null} width="75%" closable onCancel={onClose} data-cy="contactgegevens-info-modal">
    <h3 className="vl-title vl-title--h3">Contactgegevens</h3>
    <div className="vl-modal">
      <div className="vl-modal-dialog__wrapper">
        <div className="vl-modal-dialog__content">
          <ContactgegevensLoader gebruikerId={gebruiker.id} render={(contactgegevens) => <ContactgegevensDetails contactgegevens={contactgegevens} />} />
          <div className="vl-action-group vl-action-group--align-right">
            <VlButton label="Sluiten" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  </Modal>
);
