import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { MELDING_INZAMELING_BA_CHAPTER_IN_MANUAL, UPDATE_MELDINGEN_DESCRIPTION } from '../../melding.labels';
import MeldingCsvUploader from '../../melding-csv-uploader';
import { uploadExistingMeldingInzamelingBACsv } from '../../melding-csv.hook';

const MeldingInzamelingBACsvUpdatePage: React.FC<any> = () => (
  <>
    <BreadcrumbsItem to="/melding/inzameling/bedrijfsafval/csv-aanvulling">{UPDATE_MELDINGEN_DESCRIPTION}</BreadcrumbsItem>
    <MeldingCsvUploader
      handleUpload={uploadExistingMeldingInzamelingBACsv}
      title={`${UPDATE_MELDINGEN_DESCRIPTION} via een CSV-bestand`}
      chapterInManual={MELDING_INZAMELING_BA_CHAPTER_IN_MANUAL}
    />
  </>
);

export default MeldingInzamelingBACsvUpdatePage;
