import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import InzamelrechtHAOverview from './inzamelrecht-ha-overview';

const InzamelrechtenHAOrganisatiePage: React.FC<any> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const { eigenaar } = useParams() as { eigenaar: string };

  return (
    <section className="vl-region">
      <div className="vl-layout">
        <BreadcrumbsItem to={`/inzamelrechten/huishoudelijk-afval/${eigenaar}`}>{eigenaar}</BreadcrumbsItem>
        <InzamelrechtHAOverview defaultPageSize={defaultPageSize} onChange={onChange} pagingAndFiltering={{ ...pagingAndFiltering, eigenaar }} />
      </div>
    </section>
  );
};

export default InzamelrechtenHAOrganisatiePage;
