import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const ISO8601_DATETIME = 'YYYY.MM.DD HH:mm';
const ISO8601_FULL = 'YYYY-MM-DDTHH:mm:ss';

function formatDate(date: string, pattern = ISO8601_DATETIME): string {
  dayjs.extend(utc);
  return dayjs.utc(date, ISO8601_FULL).local().format(pattern);
}

export default formatDate;
