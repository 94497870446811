import React from 'react';
import { Button } from 'antd';

interface FilterSubmitButtonProps {
  onConfirm: () => void;
  submitDisabled?: boolean;
}

interface FilterResetButtonProps {
  onReset: () => void;
  resetDisabled: boolean;
}

export const FilterResetButton: React.FC<FilterResetButtonProps> = ({ onReset, resetDisabled }) => (
  <Button data-cy="filter-reset" onClick={onReset} disabled={resetDisabled} size="small" type="link">
    Wis filter
  </Button>
);

export const FilterSubmitButton: React.FC<FilterSubmitButtonProps> = ({ onConfirm, submitDisabled }) => (
  <Button data-cy="btn-search" type="primary" size="small" onClick={onConfirm} disabled={submitDisabled === true}>
    Ok
  </Button>
);

export const FilterButtons: React.FC<FilterSubmitButtonProps & FilterResetButtonProps> = (props) => (
  <div className="filter-button-container">
    <FilterResetButton {...props} />
    <FilterSubmitButton {...props} />
  </div>
);
