import React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import { ButtonType, getClassForButtonType } from './button-type';

interface IconButtonProps {
  type?: ButtonType;
  description: string;
  className?: string;
  iconClasses: string;
  isNarrow?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ type = 'primary', description, className = '', iconClasses, isNarrow = false, isDisabled = false, onClick }) => (
  <Tooltip title={description}>
    <button
      type="button"
      className={cx('vl-button', 'vl-button--icon', className, getClassForButtonType(type), { 'vl-button--narrow': isNarrow, 'vl-button--disabled': isDisabled })}
      onClick={onClick}
    >
      <i className={cx('vl-button__icon', iconClasses)} aria-hidden />
      <span className="vl-u-visually-hidden">{description}</span>
    </button>
  </Tooltip>
);

export default IconButton;
