import React from 'react';
import { DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import { VerdelingDetailsJsonResponse } from '../generated';
import { useRoleHook } from '../services/security/use-role-hook';

interface VerdelingDetailFormSection {
  verdeling: VerdelingDetailsJsonResponse;
}

const VerdelingDetailsFormSection: React.FC<VerdelingDetailFormSection> = ({ verdeling }) => {
  const { isBeheerder } = useRoleHook();
  return (
    <DetailsFormSection title="Verdeling">
      <DetailsFormField label="Identificatie" data={verdeling.identificatie} />
      {isBeheerder && <DetailsFormField label="Eigenaar" data={verdeling.eigenaar} />}
      <DetailsFormField label="Periode van" data={verdeling.criteria.periodeVan} />
      <DetailsFormField label="Periode tot" data={verdeling.criteria.periodeTot} />
    </DetailsFormSection>
  );
};

export default VerdelingDetailsFormSection;
