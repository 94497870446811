import React from 'react';
import { Popconfirm, TooltipProps } from 'antd';
import DeleteButton from './delete-button';

interface Props {
  confirmationTitle: string;
  title?: string;
  tooltip?: TooltipProps;
  className?: string;
  isDeleting?: boolean;
  isDisabled?: boolean;
  onDelete: () => void;
}

const DeleteButtonWithConfirm: React.FC<Props> = ({ confirmationTitle, title = '', tooltip, className, isDeleting = false, isDisabled = false, onDelete }) => {
  const okText = <span data-cy="confirm-ok">Ja</span>;
  const cancelText = <span data-cy="confirm-cancel">Nee</span>;
  const titleText = <span data-cy="confirm-title">{confirmationTitle}</span>;

  return (
    <Popconfirm placement="top" title={titleText} onConfirm={onDelete} okText={okText} cancelText={cancelText}>
      <DeleteButton label="Verwijderen" title={title} tooltip={tooltip} className={className} isDeleting={isDeleting} isDisabled={isDisabled} />
    </Popconfirm>
  );
};

export default DeleteButtonWithConfirm;
