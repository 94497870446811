import React from 'react';
import * as Yup from 'yup';
import { COMMENTAAR } from './bestemming.model';
import TextArea from '../../components/form/text-area';
import { prefixNamespace } from './use-land-hook';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import requiredField from '../../util/required-field';

const maxLength = 4000;
export const gebruikDoorBurgersValidationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  [COMMENTAAR]: requiredField(vrijeTekstSchema().max(4000, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten')),
});

export interface IGebruikDoorBurgersFormProps {
  namespace: string;
}

const GebruikDoorBurgersForm: React.FC<IGebruikDoorBurgersFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);
  return <TextArea maxLength={maxLength} label="Commentaar" name={withNamespace(COMMENTAAR)} required />;
};

export default GebruikDoorBurgersForm;
