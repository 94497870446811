import React, { useCallback } from 'react';
import { Field, useField } from 'formik';
import cx from 'classnames';
import InputField, { IInputFieldProps } from './input-field';

export interface CheckboxProps extends IInputFieldProps {
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ name, label, autoFocus, required, placeholder, disabled }) => {
  const [value, , helpers] = useField(name);
  const onClickHandler = useCallback(() => {
    helpers.setValue(!value);
  }, []);

  const checkboxId = `${name}-checkbox`;
  const labelDataAttribute = `${checkboxId}_label`;
  return (
    <InputField name={name} label={label} required={required}>
      <label className={cx('vl-checkbox--switch__wrapper', 'vl-checkbox--single', { 'vl-checkbox--disabled': disabled })} htmlFor={checkboxId}>
        <Field
          id={checkboxId}
          data-cy={checkboxId}
          data-testid={checkboxId}
          className="vl-checkbox--switch"
          name={name}
          type="checkbox"
          autoFocus={autoFocus ?? false}
          placeholder={placeholder}
          disabled={disabled}
          onClick={onClickHandler}
          role="switch"
        />
        <span data-cy={labelDataAttribute} data-testid={labelDataAttribute} className="vl-checkbox--switch__label">
          <span aria-hidden="true" />
          <span className="vl-u-visually-hidden">{label}</span>
        </span>
      </label>
    </InputField>
  );
};

export default Checkbox;
