import React from 'react';

const TabelTotaal: React.FC<{ total: number; range: [number, number]; itemsLabel: string }> = ({ total, range, itemsLabel }) => (
  <span>
    <b>
      {range[0]}-{range[1]}
    </b>{' '}
    van <span data-cy="totaal-items">{total}</span> {itemsLabel}
  </span>
);

export default TabelTotaal;
