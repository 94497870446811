import React from 'react';
import ContactgegevensSection from './contactgegevens-section';
import ContactgegevensDetails from './contactgegevens-details';
import RouteButton from '../components/button/route-button';
import CancelButton from '../components/button/cancel-button';

export const ContactgegevensDetailsPage: React.FC = () => (
  <ContactgegevensSection
    render={(gegevens) => (
      <>
        <ContactgegevensDetails contactgegevens={gegevens} />
        <div className="vl-form-col--1-1">
          <div className="vl-action-group vl-action-group--align-right">
            <RouteButton label="Corrigeren" link="correctie" />
            <CancelButton link="/" label="Sluiten" />
          </div>
        </div>
      </>
    )}
  />
);
