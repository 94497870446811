import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { Pageable } from '../components/table/pageable';
import { axiosParamsSerializer } from '../util/axios-params-serializer';
import { Page } from '../components/table/page';
import { ValidatievraagOverviewJsonResponse } from '../generated';

export const useValidatievragen = (pageable: Pageable): UseQueryResult<Page<ValidatievraagOverviewJsonResponse>, Error> => {
  const validatievraagUrl = '/ui/validatievraag';
  return useQuery<Page<ValidatievraagOverviewJsonResponse>, Error>([validatievraagUrl, pageable], async () => {
    const { data } = await axios.get<Page<ValidatievraagOverviewJsonResponse>>(validatievraagUrl, { params: pageable, paramsSerializer: axiosParamsSerializer });
    return data;
  });
};
