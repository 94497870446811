/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PeriodeEenheid {
  JAAR = 'JAAR',
  KWARTAAL = 'KWARTAAL',
  MAAND = 'MAAND',
  DAG = 'DAG',
}
