import { UseQueryResult } from 'react-query';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import ErrorAlert from '../components/alert/error-alert';
import {
  IEmbedConfig,
  reportNotFound,
  useInzamelingBARapportConfig,
  useInzamelingHAGemeenteRapportConfig,
  useInzamelingHAIntercommunaleRapportConfig,
  useMeldingenInzamelingHARapportConfig,
  useMeldingenVerwerkingRapportConfig,
} from './rapportering.hook';
import PowerBIRapport from './powerbi-rapport';
import Alert from '../components/alert/alert';
import LoadingPage from '../general/loading-page';
import styles from './melding-rapport.module.css';
import { Scope } from '../generated';
import { useAuthorisationInfo } from '../services/security/authorisation';
import AuthorisedScope from '../general/authorised-scope';

type MeldingRapportProps = {
  title: string;
  useConfigResult: () => UseQueryResult<IEmbedConfig, Error>;
  requiredScope: Scope;
};

const showErrorMessage = (error: Error, title: string) => (
  <div className="vl-layout">
    {reportNotFound(error) ? (
      <Alert type="warning" title="Opgelet!">
        <p>Het rapport is tijdelijk niet beschikbaar op deze omgeving.</p>
      </Alert>
    ) : (
      <ErrorAlert error={error} defaultMessage={`Er is een fout opgetreden bij het ophalen van het ${title}. Gelieve later nog eens opnieuw te proberen.`} />
    )}
  </div>
);

const MeldingRapport: React.FC<MeldingRapportProps> = ({ title, useConfigResult, requiredScope }) => {
  const { authorisedScopes, isLoading: isAuthorisationLoading } = useAuthorisationInfo();
  const { data: embedConfig, isLoading: isReportConfigLoading, isError, isSuccess, error } = useConfigResult();

  if (!authorisedScopes.includes(requiredScope)) {
    return null;
  }

  return (
    <LoadingPage isLoading={isAuthorisationLoading || isReportConfigLoading}>
      <AuthorisedScope requiredScope={requiredScope}>
        <BreadcrumbsItem to="/rapportering">{title}</BreadcrumbsItem>
        <section className="vl-region">
          {isError && <div className="vl-layout">{showErrorMessage(error, title)}</div>}
          {isSuccess && (
            <div className={styles.reportcontainer}>
              <PowerBIRapport config={embedConfig} />
            </div>
          )}
        </section>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export const MeldingInzamelingHAEigenRapport: React.FC = () => (
  <MeldingRapport title="Rapport eigen meldingen" useConfigResult={useMeldingenInzamelingHARapportConfig} requiredScope={Scope.RAPPORT_INZAMELING_HA} />
);

export const MeldingInzamelingHAGemeenteRapport: React.FC = () => (
  <MeldingRapport title="Rapport inzameling gemeente" useConfigResult={useInzamelingHAGemeenteRapportConfig} requiredScope={Scope.RAPPORT_INZAMELING_HA_GEMEENTE} />
);

export const MeldingInzamelingHAIntercommunaleRapport: React.FC = () => (
  <MeldingRapport title="Rapport inzameling intercommunale" useConfigResult={useInzamelingHAIntercommunaleRapportConfig} requiredScope={Scope.RAPPORT_INZAMELING_HA_IC} />
);

export const MeldingInzamelingBedrijfsafvalRapport: React.FC = () => (
  <MeldingRapport title="Rapport inzameling bedrijfsafval" useConfigResult={useInzamelingBARapportConfig} requiredScope={Scope.RAPPORT_INZAMELING_BA} />
);

export const MeldingVerwerkingRapport: React.FC = () => (
  <MeldingRapport title="Rapport afvalverwerking" useConfigResult={useMeldingenVerwerkingRapportConfig} requiredScope={Scope.RAPPORT_AFVALVERWERKING} />
);
