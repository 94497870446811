import React from 'react';
import axios from 'axios';
import MeldingDetailHeader from './melding-detail.header';
import FormTitle from '../components/form/form-title';
import BestemmingForm from './bestemming/bestemming-form';
import TextArea from '../components/form/text-area';
import {
  BestemmingTypeVerwerkingRadio,
  MateriaalEnumSelect,
  OorsprongTypeVerwerkingRadio,
  PeriodeSelection,
  PeriodeWaardeField,
  RDcodeSelect,
  Toepassingswijze,
  TonnageField,
  TypeMelding,
  VervoerswijzeSelect,
  VerwerkingswijzeSelect,
} from './melding-form-fields';
import FixedValueField from '../components/form/fixed-value-field';
import {
  createMeldingVerwerkingRequestValidationSchema,
  isVestigingsnummerRequiredForBestemming,
  isVestigingsnummerRequiredForOorsprong,
  MeldingVerwerkingForm,
  MeldingVerwerkingFormValues,
} from './melding-verwerking-form';
import OorsprongForm from './bestemming/oorsprong-form';
import { EURALCODE, MATERIAALCODE } from './materiaal.model';
import BtwTextField from './bestemming/btw-text-field';
import { MeldingVerwerkingDetailsJsonResponse, MeldingVerwerkingJsonRequest } from '../generated';
import DierlijkBijproductSelect from './materiaal/dierlijk-bijproduct-select';

const MeldingVerwerkingUpdateForm: React.FC<{ melding: MeldingVerwerkingDetailsJsonResponse }> = ({ melding }) => {
  const formValues: MeldingVerwerkingFormValues = {
    identificatie: melding?.identificatie ?? '',
    meldingType: melding?.meldingType ?? '',
    tonnage: melding?.tonnage?.toString() ? melding.tonnage.toString().replace('.', ',') : '',
    periodeEenheid: melding?.periode.eenheid ?? '',
    periodeWaarde: melding?.periode.waarde ?? '',
    materiaalEnum: {
      type: melding.materiaal.materiaalcode ? MATERIAALCODE : EURALCODE,
      value: (melding.materiaal.materiaalcode ? melding.materiaal.materiaalcode : melding.materiaal.euralcode)!!,
    },
    materiaalOmschrijving: melding.materiaal.omschrijving ?? '',
    materiaalKwaliteit: melding.materiaal.kwaliteit ?? '',
    dierlijkBijproduct: melding.materiaal.dierlijkBijproduct ?? '',
    oorsprong: {
      type: melding.oorsprong.type!!,
      vestigingsnummer: melding.oorsprong?.vestigingsnummer ?? '',
      vestigingsnummerVerplicht: isVestigingsnummerRequiredForOorsprong(melding?.meldingType ?? ''),
      btwNummer: melding.oorsprong.btwNummer ?? '',
      ondernemingsnummer: melding.oorsprong.ondernemingsnummer ?? '',
      naam: melding.oorsprong.naam ?? '',
      straat: melding.oorsprong.straat ?? '',
      huisnummer: melding.oorsprong.huisnummer ?? '',
      uitbreiding: melding.oorsprong.uitbreiding ?? '',
      postcode: melding?.oorsprong?.postcode ?? '',
      gemeente: melding.oorsprong.gemeente ?? '',
      land: melding.oorsprong.land ?? '',
      commentaar: melding.oorsprong.commentaar ?? '',
    },
    bestemming: {
      type: melding?.bestemming?.type!!,
      vestigingsnummer: melding.bestemming?.vestigingsnummer ?? '',
      vestigingsnummerVerplicht: isVestigingsnummerRequiredForBestemming(melding?.meldingType ?? ''),
      btwNummer: melding?.bestemming?.btwNummer ?? '',
      ondernemingsnummer: melding?.bestemming?.ondernemingsnummer ?? '',
      naam: melding?.bestemming?.naam ?? '',
      straat: melding?.bestemming?.straat ?? '',
      huisnummer: melding?.bestemming?.huisnummer ?? '',
      uitbreiding: melding?.bestemming?.uitbreiding ?? '',
      postcode: melding?.bestemming?.postcode ?? '',
      gemeente: melding?.bestemming?.gemeente ?? '',
      land: melding?.bestemming?.land ?? '',
      commentaar: melding?.bestemming?.commentaar ?? '',
    },
    rdCode: melding?.verwerking.rdCode ?? '',
    verwerkingswijze: melding?.verwerking.wijze ?? '',
    verwerkingOmschrijving: melding?.verwerking.omschrijving ?? '',
    inputInRecyclage: melding?.verwerking.inputInRecyclage ?? '',
    vervoerswijze: melding?.vervoerswijze ?? '',
    toepassingswijze: melding?.toepassingswijze ?? '',
    ihmBtwNummer: melding?.btwNummer ?? '',
  };

  const updateMutation = async (request: MeldingVerwerkingJsonRequest) => axios.put<any>(`/ui/meldingen/verwerking/${melding.referentie}`, request);

  return (
    <>
      <MeldingDetailHeader melding={melding} />
      <MeldingVerwerkingForm
        initialValues={formValues}
        validationSchemaFactory={createMeldingVerwerkingRequestValidationSchema}
        mutateFn={updateMutation}
        submitLabel="Melding corrigeren"
        cancelLink={`/melding/Verwerking/${melding.referentie}/`}
      >
        <FormTitle title="Melding" />
        <FixedValueField name="referentie" label="OVAM Referentie" value={melding.referentie} />
        <TypeMelding />
        <TonnageField />
        <PeriodeSelection />
        <PeriodeWaardeField />
        <FormTitle title="Materiaalidentificatie" />
        <MateriaalEnumSelect required />
        <TextArea required label="Materiaal omschrijving" name="materiaalOmschrijving" />
        <TextArea label="Materiaal kwaliteit" name="materiaalKwaliteit" />
        <DierlijkBijproductSelect />
        <FormTitle title="Oorsprong" />
        <OorsprongTypeVerwerkingRadio />
        <OorsprongForm />
        <FormTitle title="Bestemming" />
        <BestemmingTypeVerwerkingRadio />
        <BestemmingForm />
        <FormTitle title="Verwerking" />
        <RDcodeSelect />
        <VerwerkingswijzeSelect />
        <TextArea label="Omschrijving" name="verwerkingOmschrijving" />
        <TextArea label="Input in recyclage" name="inputInRecyclage" />
        <Toepassingswijze />
        <FormTitle title="IHM" />
        <BtwTextField label="BTW-nummer" name="ihmBtwNummer" placeholder="BE0123456789" />
        <FormTitle title="Vervoer" />
        <VervoerswijzeSelect required />
      </MeldingVerwerkingForm>
    </>
  );
};

export default MeldingVerwerkingUpdateForm;
