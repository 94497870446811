import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import MeldingVerwerkingCreateForm from './melding-verwerking-create-form';

const MeldingVerwerkingCreatePage: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <BreadcrumbsItem to="/melding/verwerking/nieuw">Nieuw</BreadcrumbsItem>
      <MeldingVerwerkingCreateForm />
    </div>
  </section>
);

export default MeldingVerwerkingCreatePage;
