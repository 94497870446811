import React from 'react';
import * as Yup from 'yup';
import { BTW_NUMMER, COMMENTAAR, GEMEENTE, HUISNUMMER, LAND, NAAM, POSTCODE, STRAAT, UITBREIDING } from './bestemming.model';
import { createBtwNummerValidationSchema } from '../../organisatie/btw-nummer.model';
import TextField from '../../components/form/text-field';
import TextArea from '../../components/form/text-area';
import { BelgischePostcodeSelect, GeregistreerdeTraderSelect, LandSelect } from '../melding-form-fields';
import BtwTextField from './btw-text-field';
import useLandHook, { prefixNamespace } from './use-land-hook';
import OrganisatienaamField, { organisatienaamSchema } from '../../organisatie/organisatienaam-field';
import { vrijeTekstSchema } from '../vrije-tekst-schema';
import { buitenlandsePostcodeSchema } from '../postcode.schema';

export const traderValidationSchema: Yup.ObjectSchema<any> = Yup.object({
  [BTW_NUMMER]: createBtwNummerValidationSchema(true),
  [NAAM]: organisatienaamSchema,
  [STRAAT]: vrijeTekstSchema().max(400, 'Straat kan niet meer dan 400 tekens bevatten'),
  [HUISNUMMER]: vrijeTekstSchema().max(80, 'Huis- en busnummer kan niet meer dan 80 tekens bevatten'),
  [UITBREIDING]: vrijeTekstSchema().max(400, 'Adresuitbreiding kan niet meer dan 400 tekens bevatten'),
  [POSTCODE]: buitenlandsePostcodeSchema,
  [GEMEENTE]: vrijeTekstSchema().max(400, 'Gemeente kan niet meer dan 400 tekens bevatten'),
  [LAND]: Yup.string(),
  [COMMENTAAR]: vrijeTekstSchema().max(4000, 'Commentaar bestemming kan niet meer dan 4000 tekens bevatten'),
});

export interface ITraderFormProps {
  namespace: string;
}

const BaseTraderForm: React.FC<ITraderFormProps> = ({ namespace }) => {
  const { withNamespace, landIso2 } = useLandHook(namespace);

  return (
    <>
      <OrganisatienaamField label="Naam" name={withNamespace(NAAM)} />
      <TextField label="Straat" name={withNamespace(STRAAT)} />
      <TextField label="Huis- en busnummer" name={withNamespace(HUISNUMMER)} />
      <TextField label="Adresuitbreiding" name={withNamespace(UITBREIDING)} />
      {landIso2 === 'BE' && <BelgischePostcodeSelect label="Postcode" name={withNamespace(POSTCODE)} />}
      {landIso2 !== 'BE' && <TextField label="Postcode" name={withNamespace(POSTCODE)} />}
      <TextField label="Gemeente" name={withNamespace(GEMEENTE)} />
      <LandSelect label="Land" name={withNamespace(LAND)} />
      <TextArea label="Commentaar" name={withNamespace(COMMENTAAR)} />
    </>
  );
};

export const GeregistreerdeTraderForm: React.FC<ITraderFormProps> = ({ namespace }) => {
  const withNamespace = prefixNamespace(namespace);

  return (
    <>
      <GeregistreerdeTraderSelect label="BTW-nummer" name={withNamespace(BTW_NUMMER)} required />
      <BaseTraderForm namespace={namespace} />
    </>
  );
};

export const TraderForm: React.FC<ITraderFormProps> = ({ namespace }) => {
  const withNamespace = namespace ? (fieldName: string) => `${namespace}.${fieldName}` : (fieldName: string) => fieldName;

  return (
    <>
      <BtwTextField label="BTW-nummer" name={withNamespace(BTW_NUMMER)} required placeholder="BE0123456789" />
      <BaseTraderForm namespace={namespace} />
    </>
  );
};
