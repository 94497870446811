import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { OorsprongFormValues, TYPE } from './oorsprong.model';
import BelgischeVestigingForm, { belgischeVestigingValidationSchema } from './belgische-vestiging-form';
import BuitenlandseVestigingForm, { buitenlandseVestigingValidationSchema } from './buitenlandse-vestiging-form';
import { TraderForm, traderValidationSchema } from './trader-form';
import { useGeregistreerdeTraders } from '../../enumerated-lists/enumeratedlists.hook';
import InzamelrondeForm, { inzamelrondeValidationSchema } from './inzamelronde-form';
import WerfForm, { werfValidationSchema } from './werf-form';
import GeenOndernemingForm, { geenOndernemingValidationSchema } from './geen-onderming-form';
import OorsprongParticulierForm, { oorsprongParticulierValidationSchema } from './oorsprong-particulier-form';
import requiredField from '../../util/required-field';

export const oorsprongValidationSchema = Yup.lazy((value: OorsprongFormValues) => {
  switch (value.type) {
    case 'BELGISCHE_VESTIGING':
      return belgischeVestigingValidationSchema;
    case 'BUITENLANDSE_VESTIGING':
      return buitenlandseVestigingValidationSchema;
    case 'TRADER':
      return traderValidationSchema;
    case 'INZAMELRONDE':
      return inzamelrondeValidationSchema;
    case 'WERF':
      return werfValidationSchema;
    case 'GEEN_ONDERNEMING':
      return geenOndernemingValidationSchema;
    case 'PARTICULIER':
      return oorsprongParticulierValidationSchema;
    default:
      return Yup.object().shape({
        [TYPE]: requiredField().nullable(),
      });
  }
});

const OorsprongForm: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const [field, meta, helpers] = useField('oorsprong');
  const { data: traders } = useGeregistreerdeTraders();
  const { type } = field.value;
  const namespace = 'oorsprong';

  useEffect(() => {
    if (initialized || type !== meta.initialValue.type) {
      if (type === 'BELGISCHE_VESTIGING') {
        helpers.setValue(
          {
            ...field.value,
            postcode: field.value.land === 'BE' ? field.value.postcode : '',
            land: 'BE',
          },
          true,
        );
      } else if (type === 'BUITENLANDSE_VESTIGING') {
        helpers.setValue(
          {
            ...field.value,
            vestigingsnummer: '',
            land: field.value.land === 'BE' ? '' : field.value.land,
          },
          true,
        );
      } else if (type === 'INZAMELRONDE') {
        const btwValue = field.value.btwNummer;
        const verifiedBtw = traders?.some((trader) => trader.code === btwValue) ? btwValue : '';
        helpers.setValue(
          {
            ...field.value,
            btwNummer: verifiedBtw,
            vestigingsnummer: '',
          },
          true,
        );
      } else if (type === 'TRADER' || type === 'WERF') {
        helpers.setValue(
          {
            ...field.value,
            vestigingsnummer: '',
          },
          true,
        );
      } else if (type === 'GEEN_ONDERNEMING' || type === 'PARTICULIER') {
        helpers.setValue(
          {
            ...field.value,
            vestigingsnummer: '',
            btwNummer: '',
          },
          true,
        );
      }
    } else {
      setInitialized(true);
    }
  }, [type, traders]);

  return (
    <>
      {type === 'BELGISCHE_VESTIGING' && <BelgischeVestigingForm namespace={namespace} />}
      {type === 'BUITENLANDSE_VESTIGING' && <BuitenlandseVestigingForm namespace={namespace} />}
      {type === 'TRADER' && <TraderForm namespace={namespace} />}
      {type === 'INZAMELRONDE' && <InzamelrondeForm namespace={namespace} />}
      {type === 'WERF' && <WerfForm namespace={namespace} />}
      {type === 'GEEN_ONDERNEMING' && <GeenOndernemingForm namespace={namespace} />}
      {type === 'PARTICULIER' && <OorsprongParticulierForm />}
    </>
  );
};

export default OorsprongForm;
