import React, { PropsWithChildren, useContext } from 'react';
import { Scope } from '../generated';
import AuthenticationContext from '../services/security/authentication.context';

type AuthorisedScopeProps = PropsWithChildren<{ requiredScope: Scope }>;

const AuthorisedScope: React.FC<AuthorisedScopeProps> = ({ requiredScope, children }) => {
  const { authorisedScopes } = useContext(AuthenticationContext);
  return authorisedScopes.includes(requiredScope) && <>{children}</>;
};

export default AuthorisedScope;
