import React, { ReactNode } from 'react';
import { ContactgegevensJsonResponse } from '../generated';
import { ContactgegevensLoader } from './contactgegevens-loader';

type Props = {
  render: (contactgegevens: ContactgegevensJsonResponse) => ReactNode;
};

const ContactgegevensSection: React.FC<Props> = ({ render }) => (
  <section className="vl-region">
    <div className="vl-layout">
      <h2 className="vl-title vl-title--h2" data-cy="page-title">
        Contactgegevens
      </h2>
      <ContactgegevensLoader render={render} />
    </div>
  </section>
);

export default ContactgegevensSection;
