import React from 'react';
import { DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import { bestemmingLabels } from './bestemming/bestemming.model';
import AdresDetails from '../adres/adres-details';
import { isEmptyAdres } from '../adres/adres.model';
import MultiLineText from '../components/details/multi-line-text';
import { MeldingInzamelingHADetailsJsonResponse, MeldingVerwerkingDetailsJsonResponse } from '../generated';

interface BestemmingDetailsFormSectionProps {
  melding: MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse;
}

const BestemmingDetailsFormSection: React.FC<BestemmingDetailsFormSectionProps> = ({ melding }) => (
  <DetailsFormSection title="Bestemming">
    <DetailsFormField label="Type" data={bestemmingLabels[melding.bestemming.type]} />
    <DetailsFormField label="Vestigingseenheid" data={melding.bestemming.vestigingsnummer} />
    <DetailsFormField label="BTW-nummer" data={melding.bestemming.btwNummer} />
    <DetailsFormField label="Ondernemingsnummer" data={melding.bestemming.ondernemingsnummer} />
    <DetailsFormField label="Naam" data={melding.bestemming.naam} />
    <DetailsFormField label="Adres" data={melding.bestemming && isEmptyAdres(melding.bestemming) ? null : <AdresDetails adres={melding.bestemming} />} />
    <DetailsFormField label="Commentaar" data={melding.bestemming.commentaar && <MultiLineText value={melding.bestemming.commentaar} />} />
  </DetailsFormSection>
);

export default BestemmingDetailsFormSection;
