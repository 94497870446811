import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Routes } from 'react-router-dom';
import { ContactgegevensDetailsPage } from './contactgegevens-details-page';
import { ContactgegevensFormPage } from './contactgegevens-form-page';

const ContactgegevensRoutes: React.FC = () => (
  <>
    <BreadcrumbsItem to="/contactgegevens">Contactgegevens</BreadcrumbsItem>
    <Routes>
      <Route path="/" element={<ContactgegevensDetailsPage />} />
      <Route path="/correctie" element={<ContactgegevensFormPage />} />
    </Routes>
  </>
);

export default ContactgegevensRoutes;
