import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';
import React from 'react';
import { Link } from 'react-router-dom';
import OverviewTable, { RecordProps } from '../components/table/overview-table';
import { PeriodeZonderMeldingFiltersJsonRequest, PeriodeZonderMeldingJsonResponse } from '../generated';
import getFilterValues from '../util/table-filter-utils';
import { PeriodeZonderMeldingPagingAndFiltering, usePeriodeZonderMeldingOverview } from './periode-zonder-melding.hook';
import { mapPaginationValues } from '../util/pagination-util';
import { createSelectWithSearchFilterColumn, createTextInputFilterColumn, createTimeRangeFilterColumn } from '../components/table/default.datatable.options';
import ActionGroupRight from '../components/button/action-group';
import RouteButton from '../components/button/route-button';
import { useRoleHook } from '../services/security/use-role-hook';
import { mapMotivering, mapOnderdeel, motiveringOptions, onderdeelOptions } from './periode-zonder-melding-form';
import formatDate from '../util/date-formatting';

export interface IPeriodeZonderMeldingOverview extends RecordProps {
  key: string;
  eigenaar: string;
  periode: string;
  onderdeel: string;
  motivering: string;
  laatstGewijzigd: string;
}

const mapFilterValues = (filters: Record<string, FilterValue | null>): PeriodeZonderMeldingFiltersJsonRequest => ({
  eigenaar: getFilterValues('eigenaar', filters)?.[0],
  periodeVan: getFilterValues('periode', filters)?.[0],
  periodeTot: getFilterValues('periode', filters)?.[1],
  onderdeel: getFilterValues('onderdeel', filters)?.[0],
  motivering: getFilterValues('motivering', filters)?.[0],
  laatstGewijzigdVan: getFilterValues('laatstGewijzigd', filters)?.[0],
  laatstGewijzigdTot: getFilterValues('laatstGewijzigd', filters)?.[1],
});

const toPeriodeZonderMeldingOverview = (value: PeriodeZonderMeldingJsonResponse): IPeriodeZonderMeldingOverview => ({
  ...value,
  key: value.referentie,
  eigenaar: value.eigenaar,
  periode: value.periodeVan.concat(' tot ', value.periodeTot),
  onderdeel: mapOnderdeel(value.onderdeel),
  motivering: mapMotivering(value.motivering),
  laatstGewijzigd: formatDate(value.tijdstipLaatsteWijziging),
});

interface PeriodeZonderMeldingOverviewProps {
  onChange: (newValues: PeriodeZonderMeldingPagingAndFiltering) => void;
  pagingAndFiltering: PeriodeZonderMeldingPagingAndFiltering;
  defaultPageSize: number;
}

export const useOnderdeelOptions = () => {
  const options = onderdeelOptions;
  return {
    isLoading: false,
    options,
  };
};

export const useMotiveringOptions = () => {
  const options = motiveringOptions.map((motivering) => ({
    value: motivering.value,
    label: motivering.label,
  }));
  return {
    isLoading: false,
    options,
  };
};

const PeriodeZonderMeldingOverview: React.FC<PeriodeZonderMeldingOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const { error, isLoading, isFetching, data: page } = usePeriodeZonderMeldingOverview(pagingAndFiltering);
  const { isBeheerder } = useRoleHook();

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapPaginationValues(pagination, defaultPageSize),
      ...mapFilterValues(filters),
    });
  };

  const columns: ColumnsType<IPeriodeZonderMeldingOverview> = [
    ...(isBeheerder ? [createTextInputFilterColumn<IPeriodeZonderMeldingOverview>('Organisatiecode', 'eigenaar', pagingAndFiltering.eigenaar)] : []),
    {
      ...createTimeRangeFilterColumn<IPeriodeZonderMeldingOverview>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot, 'quarter'),
      render: (periode: string, periodeZonderMelding: IPeriodeZonderMeldingOverview) => <Link to={`/periodes-zonder-melding/${periodeZonderMelding.referentie}`}>{periode}</Link>,
    },
    createSelectWithSearchFilterColumn<IPeriodeZonderMeldingOverview>('Onderdeel', 'onderdeel', 'onderdeel', useOnderdeelOptions, pagingAndFiltering.onderdeel),
    createSelectWithSearchFilterColumn<IPeriodeZonderMeldingOverview>('Motivering', 'motivering', 'motivering', useMotiveringOptions, pagingAndFiltering.motivering),
    createTimeRangeFilterColumn<IPeriodeZonderMeldingOverview>(
      'Laatst gewijzigd',
      'laatstGewijzigd',
      true,
      pagingAndFiltering.laatstGewijzigdVan,
      pagingAndFiltering.laatstGewijzigdTot,
    ),
  ];

  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toPeriodeZonderMeldingOverview),
      }
    : undefined;

  return (
    <OverviewTable
      title="Periodes zonder melding"
      itemsLabel="periodes zonder melding"
      columns={columns}
      page={mappedPage}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      notFoundMessage="Geen periodes zonder melding gevonden."
    >
      <div className="vl-col--1-1 vl-u-spacer">
        <ActionGroupRight>
          <div className="vl-u-spacer">
            <RouteButton className="vl-button--narrow" label="Nieuwe periode zonder melding" link="/periodes-zonder-melding/nieuw" />
          </div>
        </ActionGroupRight>
      </div>
    </OverviewTable>
  );
};

export default PeriodeZonderMeldingOverview;
