import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import assertNever from '../types/assert-never';
import { PeriodeEenheid } from '../generated';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);

export const periodeEenheden = Object.values(PeriodeEenheid);

export const patterns: { [key: string]: RegExp } = {
  [PeriodeEenheid.JAAR]: /^\d{4}$/,
  [PeriodeEenheid.KWARTAAL]: /^\d{4}-Q[1-4]$/,
  [PeriodeEenheid.MAAND]: /^\d{4}-\d{2}$/,
  [PeriodeEenheid.DAG]: /^\d{4}-\d{2}-\d{2}$/,
};

export const formats: { [key: string]: string } = {
  [PeriodeEenheid.JAAR]: 'YYYY',
  [PeriodeEenheid.MAAND]: 'YYYY-MM',
  [PeriodeEenheid.DAG]: 'YYYY-MM-DD',
};

export function periodePlaceholder(eenheid?: PeriodeEenheid) {
  if (!eenheid) {
    return '';
  }
  const jaar = dayjs().subtract(1, 'year').format(formats.JAAR);
  switch (eenheid) {
    case PeriodeEenheid.DAG:
      return `${jaar}-12-31`;
    case PeriodeEenheid.MAAND:
      return `${jaar}-12`;
    case PeriodeEenheid.KWARTAAL:
      return `${jaar}-Q1`;
    case PeriodeEenheid.JAAR:
      return jaar;
    default:
      return assertNever(eenheid);
  }
}

const kwartaalToMaand = (waarde?: string) => {
  const [jaar, q] = waarde?.split('-') ?? ['', ''];
  switch (q) {
    case 'Q1':
      return `${jaar}-01`;
    case 'Q2':
      return `${jaar}-04`;
    case 'Q3':
      return `${jaar}-07`;
    case 'Q4':
      return `${jaar}-10`;
    default:
      return '';
  }
};

export const toKwartaal = (waarde?: string) => (waarde ? `${waarde.slice(0, 5)}Q${dayjs(waarde).quarter()}` : '');

export const parse = (eenheid: PeriodeEenheid | string, waarde?: string) =>
  eenheid === PeriodeEenheid.KWARTAAL ? dayjs(kwartaalToMaand(waarde), formats.MAAND, true) : dayjs(waarde, formats[eenheid], true);

export const parseKwartaal = (value: string) => parse(PeriodeEenheid.KWARTAAL, value);

export const matchesPattern = (eenheid: PeriodeEenheid | string, waarde: string | undefined): boolean => patterns[eenheid].test(waarde ?? '');

export const isVlarema9VanToepassing = (periodeEenheid: PeriodeEenheid | string, periodeWaarde: string): boolean =>
  periodeEenheid && periodeWaarde ? matchesPattern(periodeEenheid, periodeWaarde) && parse(periodeEenheid, periodeWaarde).year() >= 2024 : false;
