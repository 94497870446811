import React from 'react';
import cx from 'classnames';
import { login } from '../services/security/authentication';
import InfoTile from '../components/infotile/info-tile';
import LinkButton from '../components/button/link-button';
import ExternalLink from '../components/externallink/external-link';
import PhoneTo from '../components/phoneto/phone-to';
import styles from './not-authenticated.module.css';
import cmartlifeLogo from './cmartlife_logo.png';
import ExternalHomepageLink from '../components/navigation/external-homepage-link';

const NotAuthenticated: React.FC<any> = () => (
  <section className="vl-region">
    <div className="vl-layout">
      <div className={cx('vl-introduction', styles.introductionsmall)}>
        <h2 className="vl-title vl-title--h2" data-cy="page-title">
          MATIS: Het Vlaamse Materialeninformatiesysteem
        </h2>
        <p>In het MATIS-webloket kan u namens uw onderneming de volgende meldingen doen:</p>
        <ul className={styles.bullets}>
          <li>Inzameling van huishoudelijke afvalstoffen door of in opdracht van lokale besturen</li>
          <li>Inzameling van bedrijfsafvalstoffen door erkende IHM’s</li>
          <li>Inkomende en uitgaande afvalstoffen- en materiaalstromen door afvalverwerkers</li>
        </ul>
        <h3 className="vl-title vl-title--h3">Aanmelden op het MATIS-webloket</h3>
        <p>
          Op de <ExternalHomepageLink>OVAM website</ExternalHomepageLink> vindt u een uitgebreide ‘Handleiding registratie’ om de toegang voor uw onderneming of organisatie in orde
          te brengen.
        </p>
        <h4 className="vl-title vl-title--h4">Aanmelden als Belgische onderneming</h4>
        <p>
          De lokale beheerder van uw organisatie bepaalt in het <strong>Gebruikersbeheer van de Vlaamse overheid</strong> wie toegang heeft tot het MATIS-webloket.
        </p>
        <p>
          De Vlaamse overheid biedt een online platform dat u toelaat om te bepalen wie namens uw onderneming of organisatie toegang heeft tot een online toepassing en wat die
          persoon in die toepassing voor uw onderneming of organisatie mag doen. U kunt voor een groot aantal toepassingen de gebruikers en hun toegangsrechten op één plaats
          beheren. Dit online platform is het Gebruikersbeheer van de Vlaamse overheid.
        </p>

        <div className="vl-grid vl-grid--is-stacked vl-u-spacer-bottom">
          <div className="vl-col--4-12">
            <InfoTile title="Ik heb de nodige rechten om het nieuwe MATIS-webloket te gebruiken">
              <p className={styles.introductionsmall}>
                Om de MATIS toepassing te kunnen gebruiken, moet u zich eerst <LinkButton handleClick={login}>Aanmelden</LinkButton>.
              </p>
            </InfoTile>
          </div>
          <div className="vl-col--4-12">
            <InfoTile title="Ik ben bekend met het Gebruikersbeheer van de Vlaamse overheid, maar heb geen toegang tot het nieuwe MATIS-webloket">
              <div className={styles.introductionsmall}>
                <p className="vl-typography">
                  Uw lokale beheerder moet in het Gebruikersbeheer van de Vlaamse overheid het gebruikersrecht
                  <em>&quot;OVAM Gebruiker&quot;</em> met als context <em>&quot;MATIS melder&quot;</em> toekennen aan de gebruikers van deze toepassing.
                </p>
                <p>
                  Uw lokale beheerder gaat hiervoor naar <ExternalLink href="https://vo-gebruikersbeheer.vlaanderen.be/">het gebruikersbeheer</ExternalLink>.
                </p>
              </div>
            </InfoTile>
          </div>
          <div className="vl-col--4-12">
            <InfoTile title="Ik ben niet bekend met het Gebruikersbeheer van de Vlaamse overheid">
              <div className={styles.introductionsmall}>
                <p>
                  Neem contact op met het gratis nummer <PhoneTo phone="1700" /> of bekijk{' '}
                  <ExternalLink href="https://www.youtube.com/channel/UCA4BrAjQsKHe1IBqrghcitQ">deze instructiefilms</ExternalLink>.
                </p>
                <p>
                  Of volg <ExternalLink href="https://overheid.vlaanderen.be/aanmelden-als-ondernemer">deze 4 stappen</ExternalLink> om uw onderneming te registreren.
                </p>
                <p>
                  Belt u vanuit het buitenland? Gebruik dan het nummer <PhoneTo phone="+32 2 553 1700" />.
                </p>
              </div>
            </InfoTile>
          </div>
        </div>

        <h4 className="vl-title vl-title--h4">U heeft geen Belgische (elektronische) identiteitskaart?</h4>
        <p>
          Dan kan u zich laten <ExternalLink href="https://sma-help.fedict.belgium.be/nl/identificatie-zonder-eid#7093">registreren in een registratiekantoor</ExternalLink>.
        </p>

        <h4 className="vl-title vl-title--h4">U heeft geen Belgisch ondernemingsnummer?</h4>
        <p>
          U moet zich registeren in het OVAM-gebruikersbeheer voor het MATIS-webloket. Op de{' '}
          <ExternalLink href="https://sso.ovam.be/">landingspagina van het OVAM-gebruikersbeheer</ExternalLink> vindt u een gebruikershandleiding terug voor het
          OVAM-gebruikersbeheer.
        </p>

        <img src={cmartlifeLogo} alt="Cmartlife logo" className={styles.cmartlifeLogo} />
        <p className="vl-u-text--italic">
          MATIS is ontwikkeld in het kader van het Cmartlife project actie ‘D3 – Software tool for monitoring municipal waste treatment in Flanders and abroad’. Het Cmartlife
          project ontvangt steun van het LIFE programma van de Europese Unie.
        </p>
      </div>
    </div>
  </section>
);

export default NotAuthenticated;
