import React from 'react';
import qs from 'qs';

export interface IMailToProps {
  email: string;
  subject?: string;
  body?: string;
}

const MailTo: React.FC<React.PropsWithChildren<IMailToProps>> = ({ email, subject, body, children }) => {
  const params = qs.stringify(
    {
      subject,
      body,
    },
    { addQueryPrefix: true },
  );
  return (
    <a className="vl-link" href={`mailto:${email}${params}`}>
      {children || email}
    </a>
  );
};

export const mailTo = (email?: string) => (email ? <MailTo email={email} /> : email);

export default MailTo;
