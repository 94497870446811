/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BestemmingType {
  BELGISCHE_VESTIGING = 'BELGISCHE_VESTIGING',
  BUITENLANDSE_VESTIGING = 'BUITENLANDSE_VESTIGING',
  TRADER = 'TRADER',
  GEBRUIK_DOOR_BURGERS = 'GEBRUIK_DOOR_BURGERS',
  WERF = 'WERF',
  GEEN_ONDERNEMING = 'GEEN_ONDERNEMING',
  DISPERS_GEBRUIK = 'DISPERS_GEBRUIK',
  PARTICULIER = 'PARTICULIER',
}
