import React from 'react';
import axios from 'axios';
import Alert from './alert';
import { ApiError, isApiError } from '../../util/axios-error-mapping';
import AlertWithContact from './alert-with-contact';

const ErrorAlert: React.FC<{ error: Error | ApiError; defaultMessage: string; scrollIntoView?: boolean; scrollable?: boolean }> = ({
  error,
  defaultMessage,
  scrollIntoView = false,
  scrollable,
}) => {
  if (axios.isAxiosError(error) && error.response?.status === 404) {
    return (
      <AlertWithContact title="Niet gevonden!" scrollIntoView={scrollIntoView}>
        <p>De gevraagde gegevens werden niet gevonden.</p>
      </AlertWithContact>
    );
  }

  const message = isApiError(error) ? error.errorCode : defaultMessage;
  return (
    <>
      {' '}
      {error && (
        <Alert type="error" title="Fout!" scrollable={scrollable}>
          <p>{message}</p>
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
