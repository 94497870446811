import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { Pageable } from '../components/table/pageable';
import { PeriodeZonderMeldingDetailsJsonResponse, PeriodeZonderMeldingFiltersJsonRequest, PeriodeZonderMeldingJsonResponse } from '../generated';
import { Page } from '../components/table/page';
import { axiosParamsSerializer } from '../util/axios-params-serializer';

export const periodeZonderMeldingBaseUrl = '/ui/pzm';

export interface PeriodeZonderMeldingPagingAndFiltering extends Pageable, PeriodeZonderMeldingFiltersJsonRequest {}

export const usePeriodeZonderMeldingOverview = (params: PeriodeZonderMeldingPagingAndFiltering): UseQueryResult<Page<PeriodeZonderMeldingJsonResponse>, Error> =>
  useQuery<Page<PeriodeZonderMeldingJsonResponse>, Error>([periodeZonderMeldingBaseUrl, params], async () => {
    const { data } = await axios.get<Page<PeriodeZonderMeldingJsonResponse>>(periodeZonderMeldingBaseUrl, {
      params,
      paramsSerializer: axiosParamsSerializer,
    });
    return data;
  });

export const useGetPeriodeZonderMelding = (referentie: string): UseQueryResult<PeriodeZonderMeldingDetailsJsonResponse, Error> => {
  const url = `${periodeZonderMeldingBaseUrl}/${referentie}`;
  return useQuery<PeriodeZonderMeldingDetailsJsonResponse, Error>(url, async () => {
    const { data } = await axios.get<PeriodeZonderMeldingDetailsJsonResponse>(url);
    return data;
  });
};

export const useDeletePeriodeZonderMelding = (): UseMutationResult<AxiosResponse, Error, PeriodeZonderMeldingDetailsJsonResponse> =>
  useMutation<AxiosResponse, Error, PeriodeZonderMeldingDetailsJsonResponse, unknown>((p) => axios.delete(`${periodeZonderMeldingBaseUrl}/${p.referentie}`));
