import React from 'react';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import 'dayjs/locale/nl-be';
import './filter.css';
import cx from 'classnames';
import { Select } from 'antd';
import { noContentFound, renderOption } from '../../form/select/select-with-search';
import { FilterButtons } from './component.filter.buttons';
import handleFilter from '../../form/select/select.filter';

interface IFilterTextInputProps extends FilterDropdownProps {
  dataCy: string;
  noDataLabel: string;
  useOptionsHook: () => UseOptionsResult;
  placeholder: string;
}

export interface UseOptionsResult {
  isLoading: boolean;
  options: any[];
}

const FilterSelectWithSearchDropdown: React.FC<IFilterTextInputProps> = ({
  noDataLabel,
  dataCy,
  useOptionsHook,
  placeholder,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const isResetDisabled = selectedKeys.length === 0;
  const handleReset = () => {
    setSelectedKeys([]);
    clearFilters?.();
  };
  const { isLoading, options } = useOptionsHook();
  return (
    <div data-cy={dataCy} className="filter-dropdown">
      <Select
        aria-label="filterSelectWithSearch"
        filterOption={handleFilter}
        id="filterSelectWithSearch"
        className={cx('vl-select--block', 'filter-text-input')}
        showSearch
        value={selectedKeys[0]?.toString()}
        data-cy="filterSelectWithSearch"
        placeholder={placeholder}
        onSelect={(value) => setSelectedKeys([value])}
        onClear={handleReset}
        loading={isLoading}
        options={options}
        optionRender={renderOption}
        notFoundContent={noContentFound(isLoading, noDataLabel)}
      />
      <FilterButtons onReset={() => clearFilters?.()} onConfirm={() => confirm()} resetDisabled={isResetDisabled} />
    </div>
  );
};

export default FilterSelectWithSearchDropdown;
