import React, { ReactNode } from 'react';

export const DetailsForm: React.FC<React.PropsWithChildren<any>> = ({ children }) => <div className="vl-properties vl-properties--collapsed">{children}</div>;

export const DetailsFormSection: React.FC<React.PropsWithChildren<{ title?: string }>> = ({ title, children }) => (
  <>
    {title && <h1 className="vl-properties__title">{title}</h1>}
    <dl className="vl-properties__list">{children}</dl>
  </>
);

export const DetailsFormField: React.FC<{ label: string; showLabelIfEmpty?: boolean; data: ReactNode; dataCy?: string }> = ({ label, showLabelIfEmpty = false, data, dataCy }) => {
  if (data || showLabelIfEmpty) {
    return (
      <>
        <dt className="vl-properties__label">{label}</dt>
        <dd className="vl-properties__data" data-cy={dataCy ?? label}>
          {data}
        </dd>
      </>
    );
  }
  return null;
};
