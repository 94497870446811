import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import MeldingInzamelingHAOverview from './melding-inzameling-ha-overview';
import MeldingInzamelingHADetailsPage from './melding-inzameling-ha-details.page';
import { MeldingInzamelingHAPagingAndFiltering } from './melding.hook';
import MeldingInzamelingHAUpdatePage from './melding-inzameling-ha-update.page';
import MeldingInzamelingHACreatePage from './melding-inzameling-ha-create.page';
import MeldingInzamelingHACsvCreatePage from './melding-inzameling-ha-csv-create-page';
import MeldingInzamelingHACsvUpdatePage from './melding-inzameling-ha-csv-update-page';
import { useAuthorisationInfo } from '../services/security/authorisation';
import LoadingPage from '../general/loading-page';
import AuthorisedScope from '../general/authorised-scope';
import { Scope } from '../generated';

const defaultPageSize = 100;

const MeldingInzamelingHAOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<MeldingInzamelingHAPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.INZAMELING_HUISHOUDELIJK_AFVAL}>
        <BreadcrumbsItem to="/melding/inzameling/huishoudelijk-afval">Meldingen inzameling huishoudelijk afval</BreadcrumbsItem>
        <Routes>
          <Route path=":referentie/correctie" element={<MeldingInzamelingHAUpdatePage />} />
          <Route path="nieuw" element={<MeldingInzamelingHACreatePage />} />
          <Route path="csv" element={<Navigate to=".." />} />
          <Route path="csv-nieuw" element={<MeldingInzamelingHACsvCreatePage />} />
          <Route path="csv-aanvulling" element={<MeldingInzamelingHACsvUpdatePage />} />
          <Route path=":referentie" element={<MeldingInzamelingHADetailsPage />} />
          <Route path="/" element={<MeldingInzamelingHAOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default MeldingInzamelingHAOverviewPage;
