import 'antd/dist/reset.css';
import { PaginationProps } from 'antd/lib/pagination';
import React from 'react';
import { Tooltip } from 'antd';
import Tonnage from './tonnage.model';
import formatDate from '../util/date-formatting';
import { Pageable } from '../components/table/pageable';
import { MeldingOverview } from './melding-overview';
import {
  MateriaalDetailsJsonResponse,
  MeldingInzamelingBAOverviewJsonResponse,
  MeldingInzamelingHAOverviewJsonResponse,
  MeldingVerwerkingOverviewJsonResponse,
} from '../generated';
import Materiaal, { EURALCODE, MATERIAALCODE } from './materiaal.model';

type MeldingOverviewResponse = MeldingInzamelingHAOverviewJsonResponse | MeldingVerwerkingOverviewJsonResponse | MeldingInzamelingBAOverviewJsonResponse;

export const toMeldingOverview = (value: MeldingOverviewResponse): MeldingOverview => ({
  ...value,
  key: value.referentie,
  versieTijdstip: formatDate(value.versieTijdstip),
  eigenaar: value.eigenaar,
  periode: value.periode.waarde,
  tonnage: new Tonnage(value.tonnage).formatted(),
});

export const mapMeldingPaginationValues = (pagination: PaginationProps, defaultPageSize: number): Pageable => ({
  page: (pagination.current ?? 1) - 1,
  size: pagination.pageSize ?? defaultPageSize,
});

export const renderMateriaal = (materiaal: MateriaalDetailsJsonResponse) => {
  const formatted = materiaal.materiaalcode
    ? Materiaal.format(MATERIAALCODE, materiaal.materiaalcode, materiaal.omschrijving)
    : Materiaal.format(EURALCODE, materiaal.euralcode!!, materiaal.omschrijving);
  return (
    <Tooltip placement="topLeft" title={formatted}>
      {formatted}
    </Tooltip>
  );
};
