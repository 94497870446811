import React from 'react';
import Afvalcode from './afvalcode.model';
import { displayClean, verwerkingswijzeLabels } from './melding-detail.hook';
import RouteButton from '../components/button/route-button';
import DeleteEntityButton from '../components/button/delete-entity-button';
import MeldingDetailHeader from './melding-detail.header';
import MultiLineText from '../components/details/multi-line-text';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import MeldingDetailsFormSection from './melding-details-form-section';
import BestemmingDetailsFormSection from './bestemming-details-form-section';
import IhmDetailsFormSection from './ihm-details-form-section';
import { useDeleteMeldingInzamelingHA } from './melding.hook';
import ErrorAlert from '../components/alert/error-alert';
import InzamelwijzeCode from './inzamelwijze.model';
import { useAuthorisationInfo } from '../services/security/authorisation';
import { MeldingInzamelingHADetailsJsonResponse } from '../generated';
import { useDierlijkeBijproducten } from '../enumerated-lists/enumeratedlists.hook';

const MeldingInzamelingHADetails: React.FC<{ melding: MeldingInzamelingHADetailsJsonResponse }> = ({ melding }) => {
  const deleteMutation = useDeleteMeldingInzamelingHA();
  const { isVerdeler } = useAuthorisationInfo();
  const { resolveOmschrijving: resolveDierlijkBijproductOmschrijving } = useDierlijkeBijproducten();

  return (
    <>
      {deleteMutation.isError && (
        <ErrorAlert error={deleteMutation.error} defaultMessage="Er is een fout opgetreden bij het verwijderen van de melding. Gelieve later nog eens opnieuw te proberen." />
      )}
      <MeldingDetailHeader melding={melding} />
      <DetailsForm>
        <MeldingDetailsFormSection melding={melding} />

        <DetailsFormSection title="Materiaalidentificatie">
          <DetailsFormField label="OVAM afvalcode" data={new Afvalcode(melding.materiaal.afvalcode, melding.materiaal.afvalcodeOmschrijving).formatted()} />
          <DetailsFormField label="Materiaal omschrijving" data={melding.materiaal.materiaalOmschrijving && <MultiLineText value={melding.materiaal.materiaalOmschrijving} />} />
          <DetailsFormField label="Dierlijk bijproduct" data={resolveDierlijkBijproductOmschrijving(melding.materiaal.dierlijkBijproduct)} />
          <DetailsFormField label="Inzamelwijze" data={InzamelwijzeCode.format(melding.inzamelwijze)} />
          <DetailsFormField label="Commentaar" data={melding.inzamelwijze.commentaar && <MultiLineText value={melding.inzamelwijze.commentaar} />} />
        </DetailsFormSection>

        <DetailsFormSection title="Oorsprong">
          <DetailsFormField label="Gemeente" data={melding.oorsprong.postcode} />
          {isVerdeler && <DetailsFormField label="Meerdere oorsprongen" data={displayClean(melding.meerdereOorsprongen)} />}
        </DetailsFormSection>

        <BestemmingDetailsFormSection melding={melding} />
        {melding.verwerkingswijze && (
          <DetailsFormSection title="Verwerking">
            <DetailsFormField label="Verwerkingswijze" data={verwerkingswijzeLabels[melding.verwerkingswijze || '']} />
          </DetailsFormSection>
        )}
        <IhmDetailsFormSection melding={melding} />
      </DetailsForm>
      <div className="vl-form-col--1-1">
        <div className="vl-action-group vl-action-group--align-right">
          <RouteButton
            label="Corrigeren"
            link={`/melding/inzameling/huishoudelijk-afval/${melding.referentie}/correctie`}
            tooltip={melding.bevroren ? { title: 'Gevalideerde meldingen kan u niet corrigeren', placement: 'top' } : undefined}
            isDisabled={melding.bevroren}
          />
          <DeleteEntityButton
            entity={melding}
            successLink="/melding/inzameling/huishoudelijk-afval/"
            deleteMutation={deleteMutation}
            confirmation="Bent u zeker dat u deze melding wil verwijderen?"
            tooltip={melding.bevroren ? { title: 'Gevalideerde meldingen kan u niet verwijderen', placement: 'top' } : undefined}
            isDisabled={melding.bevroren}
          />
          <RouteButton label="Sluiten" className="vl-button--secondary" link="/melding/inzameling/huishoudelijk-afval/" />
        </div>
      </div>
    </>
  );
};

export default MeldingInzamelingHADetails;
