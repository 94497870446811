import React, { useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Routes } from 'react-router-dom';
import { Scope } from '../generated';
import LoadingPage from '../general/loading-page';
import AuthorisedScope from '../general/authorised-scope';
import { useAuthorisationInfo } from '../services/security/authorisation';
import InzamelrechtHAOverview from './inzamelrecht-ha-overview';
import { InzamelrechtHAPagingAndFiltering } from './inzamelrecht-ha.hook';
import InzamelrechtHADetailPage from './inzamelrecht-ha-detail.page';
import InzamelrechtenHAOrganisatiePage from './inzamelrechten-ha-organisatie.page';
import InzamelrechtHACreatePage from './inzamelrecht-ha-create.page';
import InzamelrechtHAUpdatePage from './inzamelrecht-ha-update.page';

const defaultPageSize = 100;

const InzamelrechtHAOverviewPage: React.FC<any> = () => {
  const { isLoading } = useAuthorisationInfo();

  const [pagingAndFiltering, setPagingAndFiltering] = useState<InzamelrechtHAPagingAndFiltering>({
    page: 0,
    size: defaultPageSize,
  });

  return (
    <LoadingPage isLoading={isLoading}>
      <AuthorisedScope requiredScope={Scope.BEHEER_RECHTEN}>
        <BreadcrumbsItem to="/inzamelrechten/huishoudelijk-afval">Inzamelrechten Huishoudelijk Afval</BreadcrumbsItem>
        <Routes>
          <Route path="/" element={<InzamelrechtHAOverview defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />} />
          <Route path="nieuw" element={<InzamelrechtHACreatePage />} />
          <Route
            path=":eigenaar"
            element={<InzamelrechtenHAOrganisatiePage defaultPageSize={defaultPageSize} onChange={setPagingAndFiltering} pagingAndFiltering={pagingAndFiltering} />}
          />
          <Route path=":eigenaar/:referentie/correctie" element={<InzamelrechtHAUpdatePage />} />
          <Route path=":eigenaar/:referentie" element={<InzamelrechtHADetailPage />} />
        </Routes>
      </AuthorisedScope>
    </LoadingPage>
  );
};

export default InzamelrechtHAOverviewPage;
