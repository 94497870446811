import React from 'react';
import cx from 'classnames';

interface ExternalLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  hideExternalIcon?: boolean;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ hideExternalIcon, className, href, children }) => (
  <a className={cx('vl-link', className || '')} href={href} target="_blank" rel="noopener noreferrer">
    {children}
    <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
    {!hideExternalIcon && <span className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external" aria-hidden="true" />}
  </a>
);

export default ExternalLink;
