import React from 'react';
import DetailHeader from '../components/details/detail.header';
import { MeldingInzamelingHADetailsJsonResponse, MeldingVerwerkingDetailsJsonResponse } from '../generated';

interface MeldingDetailHeaderProps {
  melding: MeldingInzamelingHADetailsJsonResponse | MeldingVerwerkingDetailsJsonResponse;
}

const MeldingDetailHeader: React.FC<MeldingDetailHeaderProps> = ({ melding }) => (
  <div>
    <DetailHeader creatieTijdstip={melding.creatieMelding} versieTijdstip={melding.creatieVersie} versieGebruiker={melding.versieGebruiker} versie={melding.versie} />
  </div>
);

export default MeldingDetailHeader;
