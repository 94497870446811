import axios, { AxiosError } from 'axios';

export interface ApiError extends Error {
  errorCode: string;
}

export function isApiError(error: Error | ApiError | null): error is ApiError {
  return (error as ApiError).errorCode !== undefined;
}

export const mapAxiosError = (error: Error) => {
  if (axios.isAxiosError(error)) {
    const errorCode = (error as AxiosError<any>).response?.data?.message;
    if (errorCode) {
      return { ...error, errorCode } as ApiError;
    }
  }
  return error;
};
