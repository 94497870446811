import React from 'react';
import { Field } from 'formik';
import cx from 'classnames';
import InputField, { IInputFieldProps } from './input-field';
import styles from './Radio.module.css';

interface Option {
  value: string | ReadonlyArray<string> | number;
  label: string;
}

export interface IRadioProps extends IInputFieldProps {
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  vertical?: boolean;
  onChange?: (value: any) => void;
}

const Radio: React.FC<IRadioProps> = ({ name, label, options, required, disabled = false, vertical, onChange }) => (
  <InputField name={name} label={label} required={required}>
    {options.map((option) => (
      <label
        className={cx('vl-radio', disabled ? styles.disabled : '', vertical ? styles.vertical : '')}
        htmlFor={`${name}-radio-${option.value}`}
        key={`${name}-radio-${option.value}`}
      >
        <Field
          id={`${name}-radio-${option.value}`}
          data-cy={`${name}-radio-${option.value}`}
          data-testid={`${name}-radio-${option.value}`}
          name={name}
          value={option.value}
          type="radio"
          className="vl-radio__toggle"
          disabled={disabled}
          {...(onChange ? { onChange } : {})}
        />
        <div className="vl-radio__label">{option.label}</div>
      </label>
    ))}
  </InputField>
);

export default Radio;
