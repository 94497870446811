import { EnumeratedListJsonResponse } from '../generated';

export const isValidLandcode = (landcode: string, landen: EnumeratedListJsonResponse[]): boolean => landen.some((land) => land.code === landcode);

export const euLanden = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const isEuLand = (landcode: string): boolean => euLanden.some((land) => land === landcode);
