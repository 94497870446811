import React from 'react';

interface PillProps {
  value: string;
}

const Pill: React.FC<PillProps> = ({ value }) => (
  <span className="vl-pill">
    <span className="vl-pill__text">{value}</span>
  </span>
);

export default Pill;
