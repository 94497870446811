import { useEffect } from 'react';

const appConfig = window.APP_CONFIG as any;

function getName(): string {
  return appConfig.envName ?? '';
}

function getEnvColorTheme<T>(mappingFunction: (envColorTheme: string) => T): T | undefined {
  return appConfig.envColorTheme ? mappingFunction(appConfig.envColorTheme) : undefined;
}

export function useDocumentTitle(title: string) {
  useEffect(() => {
    document.title = `${title} ${getName()}`;
  }, []);
}

export const EnvironmentTheme = {
  getEnvColorTheme,
  getName,
};
