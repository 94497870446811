import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { IEmbedConfig } from './rapportering.hook';
import styles from './melding-rapport.module.css';

type PowerBIRapportProps = {
  config: IEmbedConfig;
};

const PowerBIRapport: React.FC<PowerBIRapportProps> = ({ config }) => (
  <PowerBIEmbed
    embedConfig={{
      type: 'report',
      embedUrl: config.reports[0].embedUrl,
      accessToken: config.embedToken.token,
      tokenType: models.TokenType.Embed,
      settings: {
        navContentPaneEnabled: true,
        localeSettings: {
          language: 'nl',
          formatLocale: 'nl',
        },
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: true,
            position: models.PageNavigationPosition.Left,
          },
        },
        background: models.BackgroundType.Transparent,
      },
    }}
    cssClassName={styles.report}
  />
);

export default PowerBIRapport;
