import React from 'react';
import cx from 'classnames';
import { Tooltip, TooltipProps } from 'antd';

interface DeleteButtonProps {
  label: string;
  title?: string;
  tooltip?: TooltipProps;
  className?: string;
  isDeleting?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ label, title, tooltip, className, isDeleting = false, isDisabled = false, onClick }) => {
  const deleteButton = (
    <button
      type="button"
      title={title}
      disabled={isDisabled}
      className={cx('vl-button vl-button--secondary vl-button--error', className, { 'vl-button--disabled': isDisabled, 'vl-button--loading': isDeleting })}
      onClick={onClick}
      data-cy="delete-btn"
    >
      <span className="vl-button__label">{label}</span>
    </button>
  );
  return tooltip ? <Tooltip {...tooltip}>{deleteButton}</Tooltip> : deleteButton;
};

export default DeleteButton;
