import { PaginationProps } from 'antd/lib/pagination';
import { ColumnsType, FilterValue } from 'antd/lib/table/interface';
import React, { Key, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import OverviewTable, { RecordProps } from '../components/table/overview-table';
import formatDate from '../util/date-formatting';
import { Pageable } from '../components/table/pageable';
import { useRoleHook } from '../services/security/use-role-hook';
import { useDeleteAllSelectedVerdelingen, useVerdelingen, VerdelingPagingAndFiltering } from './verdeling.hook';
import ActionGroupRight from '../components/button/action-group';
import RouteButton from '../components/button/route-button';
import { CREATE_VERDELINGEN_DESCRIPTION } from './verdeling-csv-create-page';
import { UPDATE_VERDELINGEN_DESCRIPTION } from './verdeling-csv-update-page';
import { formatAfvalcodes } from './verdeling-detail.hook';
import { VerdelingFiltersJsonRequest, VerdelingOverviewJsonResponse } from '../generated';
import getFilterValues from '../util/table-filter-utils';
import { createSelectWithSearchFilterColumn, createTextInputFilterColumn, createTimeRangeFilterColumn } from '../components/table/default.datatable.options';
import { useAfvalCodeOptions, useInzamelwijzeOptions } from '../melding/melding-form-fields';
import DeleteButtonWithConfirm from '../components/button/delete-with-confirm';
import { useConfigContext } from '../components/context/config-context';
import { Maybe } from '../util/maybe';
import { FALLBACK_VERDELING_CSV_DOWNLOAD_LIMIT } from '../configuration';
import CsvDownloadButton from '../components/table/csv-download-button';
import Alert from '../components/alert/alert';
import ErrorAlert from '../components/alert/error-alert';

export interface IVerdelingOverview extends RecordProps {
  key: string;
  eigenaar: string;
  identificatie: string;
  periode: string;
  oorsprong: string;
  afvalcodes: string;
  inzamelwijze: string;
  versieTijdstip: string;
}

const mapVerdelingFilterValues = (filters: Record<string, FilterValue | null>): VerdelingFiltersJsonRequest => ({
  eigenaar: getFilterValues('eigenaar', filters)?.[0],
  identificatie: getFilterValues('identificatie', filters)?.[0],
  periodeVan: getFilterValues('periode', filters)?.[0],
  periodeTot: getFilterValues('periode', filters)?.[1],
  oorsprongPostcode: getFilterValues('oorsprong', filters)?.[0],
  inzamelwijzeCode: getFilterValues('inzamelwijze', filters)?.[0],
  versieTijdstipVan: getFilterValues('versieTijdstip', filters)?.[0],
  versieTijdstipTot: getFilterValues('versieTijdstip', filters)?.[1],
  afvalcode: getFilterValues('afvalcodes', filters)?.[0],
});

const toVerdelingOverview = (value: VerdelingOverviewJsonResponse): IVerdelingOverview => ({
  ...value,
  key: value.referentie,
  eigenaar: value.eigenaar,
  periode: value.criteria.periodeVan.concat(' tot ', value.criteria.periodeTot),
  oorsprong: value.criteria.oorsprong,
  afvalcodes: formatAfvalcodes(value),
  inzamelwijze: value.criteria.inzamelwijze,
  versieTijdstip: formatDate(value.versieTijdstip),
});

interface VerdelingOverviewProps {
  onChange: (newValues: VerdelingPagingAndFiltering) => void;
  pagingAndFiltering: VerdelingPagingAndFiltering;
  defaultPageSize: number;
}

const mapVerdelingPaginationValues = (pagination: PaginationProps, defaultPageSize: number): Pageable => ({
  page: (pagination.current ?? 1) - 1,
  size: pagination.pageSize ?? defaultPageSize,
});

const VerdelingOverview: React.FC<VerdelingOverviewProps> = ({ onChange, pagingAndFiltering, defaultPageSize }) => {
  const { error, isLoading, isFetching, data: page } = useVerdelingen(pagingAndFiltering);
  const deleteAllMutation = useDeleteAllSelectedVerdelingen();
  const { isBeheerder } = useRoleHook();

  const context = useConfigContext();
  const downloadLimit = Maybe.toNullable(context)?.csv.verdelingDownloadLimit ?? FALLBACK_VERDELING_CSV_DOWNLOAD_LIMIT;
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<string | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const handleClickDelete = useCallback(() => {
    if (selectedRowKeys.length > 0) {
      deleteAllMutation.mutate(selectedRowKeys.map((k) => k.toString()));
      setSelectedRowKeys([]);
    }
  }, [selectedRowKeys, deleteAllMutation, setSelectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record: RecordProps) => ({ disabled: record?.bevroren ?? false }),
    renderCell: (value: boolean, record: RecordProps, index: number, originNode: React.ReactNode) =>
      record.bevroren ? (
        <Tooltip title="Gevalideerde verdelingen kan u niet verwijderen" placement="right">
          {originNode}
        </Tooltip>
      ) : (
        originNode
      ),
  };

  const updatePagingAndFilteringState = (pagination: PaginationProps, filters: Record<string, FilterValue | null>) => {
    onChange({
      ...mapVerdelingPaginationValues(pagination, defaultPageSize),
      ...mapVerdelingFilterValues(filters),
    });
  };

  const columns: ColumnsType<IVerdelingOverview> = [
    isBeheerder ? createTextInputFilterColumn<IVerdelingOverview>('Eigenaar', 'eigenaar', pagingAndFiltering.eigenaar) : null,
    {
      ...createTextInputFilterColumn<IVerdelingOverview>('Identificatie', 'identificatie', pagingAndFiltering.identificatie),
      render: (identificatie: string, verdeling: IVerdelingOverview) => <Link to={`/verdeling/${verdeling.referentie}`}>{identificatie}</Link>,
    },
    createTimeRangeFilterColumn<IVerdelingOverview>('Periode', 'periode', false, pagingAndFiltering.periodeVan, pagingAndFiltering.periodeTot, 'month'),
    createTextInputFilterColumn<IVerdelingOverview>('Oorsprong', 'oorsprong', pagingAndFiltering.oorsprongPostcode),
    createSelectWithSearchFilterColumn<IVerdelingOverview>('Afvalcodes', 'afvalcodes', 'afvalcodes', useAfvalCodeOptions, pagingAndFiltering.afvalcode),
    createSelectWithSearchFilterColumn<IVerdelingOverview>('Inzamelwijze', 'inzamelwijze', 'inzamelwijzes', useInzamelwijzeOptions, pagingAndFiltering.inzamelwijzeCode),
    createTimeRangeFilterColumn<IVerdelingOverview>('Laatst gewijzigd', 'versieTijdstip', true, pagingAndFiltering.versieTijdstipVan, pagingAndFiltering.versieTijdstipTot),
  ].flatMap((col) => (col ? [col] : []));

  const mappedPage = page
    ? {
        ...page,
        content: page.content.map(toVerdelingOverview),
      }
    : undefined;

  return (
    <OverviewTable
      title="Verdelingen"
      itemsLabel="verdelingen"
      columns={columns}
      page={mappedPage}
      defaultPageSize={defaultPageSize}
      updatePagingAndFilteringState={updatePagingAndFilteringState}
      error={error}
      notFoundMessage="Geen verdelingen gevonden."
      isLoading={isLoading || deleteAllMutation.isLoading}
      isFetching={isFetching}
      rowSelection={rowSelection}
    >
      <div className="vl-grid">
        {!!downloadErrorMessage && (
          <div className="vl-col--1-1">
            <Alert type="error" title="Fout" scrollIntoView>
              {downloadErrorMessage}
            </Alert>
          </div>
        )}
        {deleteAllMutation.isError && (
          <div className="vl-col--1-1">
            <ErrorAlert
              error={deleteAllMutation.error}
              defaultMessage="Er is een fout opgetreden bij het verwijderen van de verdelingen. Gelieve later nog eens opnieuw te proberen."
              scrollIntoView
            />
          </div>
        )}
        <div className="vl-col--1-1 vl-u-spacer">
          <ActionGroupRight>
            <RouteButton className="vl-button--secondary vl-button--narrow" label={CREATE_VERDELINGEN_DESCRIPTION} link="/verdeling/csv-nieuw" />
            <RouteButton className="vl-button--secondary  vl-button--narrow" label={UPDATE_VERDELINGEN_DESCRIPTION} link="/verdeling/csv-aanvulling" />
            <DeleteButtonWithConfirm
              className="vl-button--narrow"
              confirmationTitle={`Bent u zeker dat u deze ${selectedRowKeys.length} verdelingen wil verwijderen?`}
              onDelete={handleClickDelete}
              isDisabled={selectedRowKeys.length === 0}
              isDeleting={deleteAllMutation.isLoading}
            />
            {!isBeheerder && (
              <CsvDownloadButton
                url="/ui/verdelingen/download"
                filters={pagingAndFiltering}
                downloadLimit={downloadLimit}
                totalElementsInOverview={page?.totalElements ?? 0}
                csvEntitiesLabel="verdelingen"
                onErrorMessageChanged={(errorMessage) => setDownloadErrorMessage(errorMessage)}
                isNarrow
              />
            )}
          </ActionGroupRight>
        </div>
      </div>
    </OverviewTable>
  );
};

export default VerdelingOverview;
