import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Tooltip } from 'antd';
import AppHeader from './header/appheader/app-header';
import { checkAuthenticated, IAuthentication, initialAuthentication } from './services/security/authentication';
import { LastSeparatedBreadcrumb, SeparatedBreadcrumb } from './header/breadcrumbs/separated-breadcrumb';
import HomePage from './general/home.page';
import NotAuthenticated from './general/not-authenticated';
import IncompleteProfile from './general/incomplete-profile';
import { extractSupportedRoles } from './services/security/use-role-hook';
import { ActionSpinner } from './components/actionspinner/action-spinner';
import AuthenticationContext from './services/security/authentication.context';
import ScrollToTopOnNavigation from './components/scroll/scroll-to-top-on-navigation';
import { useDocumentTitle } from './components/theme/environment-theme';
import { ConfigContextProvider } from './components/context/config-context';
import { Maybe } from './util/maybe';
import { useConfig } from './api/config-hook';
import { Scope } from './generated';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const versie = process.env.REACT_APP_VERSION ?? 'latest';

const App = () => {
  const [authentication, setAuthentication] = useState(initialAuthentication);
  const { data: config } = useConfig({ enabled: authentication.authenticated });
  useDocumentTitle('MATIS');

  const keycloakInitializationStarted = useRef(false);

  const updateAuthorisedScopes = (scopes: Scope[]) => {
    setAuthentication((auth) => ({
      ...auth,
      authorisedScopes: scopes,
    }));
  };

  useEffect(() => {
    if (!keycloakInitializationStarted.current) {
      keycloakInitializationStarted.current = true;
      (async () => {
        setAuthentication((prevState: IAuthentication) => ({
          ...prevState,
          busyAuthenticating: true,
        }));
        try {
          const auth = await checkAuthenticated();
          const supportedRoles = extractSupportedRoles(auth.rollen ?? []);
          if (!auth.authenticated || supportedRoles.length === 0) {
            setAuthentication({
              ...auth,
              rollen: [],
              authorised: false,
            });
          } else {
            setAuthentication({
              ...auth,
              rollen: supportedRoles,
              busyAuthenticating: false,
            });
          }
        } catch (error: unknown) {
          setAuthentication({
            ...initialAuthentication,
            busyAuthenticating: false,
          });
        }
      })();
    }
  }, []);

  const page = useMemo(() => {
    if (authentication.busyAuthenticating) {
      return <ActionSpinner />;
    } else if (authentication.authenticated) {
      if (!authentication.authorised) {
        return <IncompleteProfile />;
      }
      return (
        <ConfigContextProvider value={Maybe.fromNullable(config)}>
          <HomePage />
        </ConfigContextProvider>
      );
    }
    return <NotAuthenticated />;
  }, [authentication.busyAuthenticating, authentication.authenticated, authentication.authorised, authentication.authorisedScopes, config]);

  const providerValue = useMemo(() => ({ ...authentication, updateAuthorisedScopes }), [authentication, updateAuthorisedScopes]);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTopOnNavigation />
          <div>
            <AppHeader title="Materialeninformatiesysteem">
              {authentication.authorised && (
                <div className="vl-breadcrumb-bar">
                  <nav aria-label="U bent hier: " className="vl-breadcrumb">
                    <Breadcrumbs container="ol" containerProps={{ className: 'vl-breadcrumb__list' }} item={SeparatedBreadcrumb} finalItem={LastSeparatedBreadcrumb} />
                  </nav>
                  <div className="vl-breadcrumb-bar__actions">
                    <Tooltip title="Applicatieversie">
                      <div className="version-tag">{versie}</div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </AppHeader>
            <main>{page}</main>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthenticationContext.Provider>
  );
};

export default App;
