import React from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { ActionSpinnerWithoutDelay } from '../actionspinner/action-spinner';
import Uploader from '../form/uploader/uploader';

interface IFileUploaderProps {
  isLoading: boolean;
  disabled: boolean;
  description: string;
  onChange?: (info: UploadChangeParam) => void;
  uploadFile: UploadFile | null;
}

const FileUploader: React.FC<IFileUploaderProps> = ({ isLoading, disabled, description, onChange, uploadFile }) => {
  const handleBeforeUpload = () => false;

  return (
    <>
      <Uploader
        uploaderTitle="CSV-bestand opladen"
        uploaderDescription={description}
        accept=".csv"
        multiple={false}
        maxCount={1}
        beforeUpload={handleBeforeUpload}
        onChange={onChange}
        disabled={disabled}
        fileList={!uploadFile ? [] : [uploadFile]}
      />
      {isLoading && <ActionSpinnerWithoutDelay message="Bezig met het opladen van het bestand" />}
    </>
  );
};

export default FileUploader;
