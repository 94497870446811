import React from 'react';
import { PeriodeZonderMeldingDetailsJsonResponse } from '../generated';
import DetailHeader from '../components/details/detail.header';
import { DetailsForm, DetailsFormField, DetailsFormSection } from '../components/form/details-form';
import { mapMotivering, onderdeelOptions } from './periode-zonder-melding-form';
import RouteButton from '../components/button/route-button';
import { useRoleHook } from '../services/security/use-role-hook';
import { useDeletePeriodeZonderMelding } from './periode-zonder-melding.hook';
import ErrorAlert from '../components/alert/error-alert';
import DeleteEntityButton from '../components/button/delete-entity-button';

const PeriodeZonderMeldingDetail: React.FC<{ periode: PeriodeZonderMeldingDetailsJsonResponse }> = ({ periode }) => {
  const { isBeheerder } = useRoleHook();
  const deleteMutation = useDeletePeriodeZonderMelding();
  return (
    <>
      {deleteMutation.isError && (
        <ErrorAlert
          error={deleteMutation.error}
          defaultMessage="Er is een fout opgetreden bij het verwijderen van de periode zonder melding. Gelieve later nog eens opnieuw te proberen."
        />
      )}
      <DetailHeader creatieTijdstip={periode.creatieTijdstip} versieTijdstip={periode.updateTijdstip} versieGebruiker={periode.updateGebruiker} />
      <DetailsForm>
        <DetailsFormSection title="Periode zonder melding">
          {isBeheerder && <DetailsFormField label="Organisatiecode" data={periode.eigenaar} />}
          <DetailsFormField label="Onderdeel" data={onderdeelOptions.filter((o) => periode.onderdeel === o.value).map((o) => o.label)} />
          {periode.onderdeel === 'VW' && <DetailsFormField label="Vestigingsnummer" data={periode.vestigingsnummer} />}
          <DetailsFormField label="Periode" data={`van ${periode.periodeVan} tot ${periode.periodeTot}`} />
          <DetailsFormField label="Motivering" data={mapMotivering(periode.motivering)} />
          {periode.motivering === 'ANDERE' && <DetailsFormField label="Andere motivering" data={periode.andereMotivering} />}
          {periode.motivering === 'MEDE_EXPLOITANT' && <DetailsFormField label="Ondernemingsnummer mede-exploitant" data={periode.medeExploitant} />}
        </DetailsFormSection>
      </DetailsForm>
      <div className="vl-form-col--1-1">
        <div className="vl-action-group vl-action-group--align-right">
          <RouteButton data-cy="correctie-button" label="Corrigeren" link={`/periodes-zonder-melding/${periode.referentie}/correctie`} />
          <DeleteEntityButton
            entity={periode}
            confirmation="Bent u zeker dat u deze periode zonder melding wilt verwijderen?"
            successLink="/periodes-zonder-melding"
            deleteMutation={deleteMutation}
          />
          <RouteButton label="Sluiten" className="vl-button--secondary" link="/periodes-zonder-melding" />
        </div>
      </div>
    </>
  );
};

export default PeriodeZonderMeldingDetail;
