import React from 'react';
import { Select, Spin } from 'antd';
import InputField, { IInputFieldProps } from '../input-field';
import handleFilter from './select.filter';
import { useHandleSelect } from './select.hooks';

export interface SelectProps extends IInputFieldProps {
  disabled?: boolean;
  noDataLabel?: string;
  isLoading?: boolean;
  allowClear?: boolean;
  options: any[];
  filterOnLabel?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  warningMessageWhenEmpty?: string;
  fieldName?: string;
}

interface InnerSelectProps extends SelectProps {
  multi: boolean;
}

export const renderOption = (option: any) => (
  <span data-cy={option.value} data-testid={option.value}>
    {option.label}
  </span>
);

export const noContentFound = (isLoading: boolean, noDataLabel: string) => (isLoading ? <Spin size="small" /> : <div>Geen {noDataLabel.toLowerCase()} gevonden</div>);

export const MultiSelect: React.FC<SelectProps> = (props) => <InnerSelect {...props} multi />;

export const SimpleSelect: React.FC<SelectProps> = (props) => <InnerSelect {...props} multi={false} />;

const InnerSelect: React.FC<InnerSelectProps> = React.memo(
  ({
    required,
    disabled = false,
    name,
    label,
    noDataLabel = '',
    isLoading = false,
    options,
    allowClear = !required,
    filterOnLabel = true,
    autoFocus = false,
    placeholder,
    warningMessageWhenEmpty = undefined,
    multi,
    fieldName = name,
  }) => {
    const { field, handleChange, handleClear } = useHandleSelect(name, allowClear, fieldName);

    return (
      <InputField name={name} label={label} required={required}>
        <Select
          aria-label={name}
          filterOption={handleFilter}
          id={name}
          className={multi ? 'vl-multiselect' : 'vl-select--block'}
          optionFilterProp={filterOnLabel ? 'children' : 'value'}
          allowClear={allowClear}
          showSearch
          autoFocus={autoFocus}
          mode={multi ? 'multiple' : undefined}
          style={multi ? { width: '100%' } : {}}
          tokenSeparators={[', ', '\n', '\r\n', '\r']}
          value={field.value}
          data-cy={name}
          data-testid={`select-with-search-${name}`}
          placeholder={placeholder}
          onSelect={multi ? undefined : handleChange}
          onChange={multi ? handleChange : undefined}
          onClear={handleClear}
          loading={isLoading}
          disabled={disabled}
          popupClassName={name}
          virtual={false}
          options={options}
          optionRender={renderOption}
          notFoundContent={isLoading ? <Spin size="small" /> : <div>Geen {noDataLabel.toLowerCase()} gevonden</div>}
        />
        {field.value?.length === 0 && !!warningMessageWhenEmpty && (
          <p className="vl-form__annotation vl-u-text--xsmall" data-cy-multiselect-empty-message={name}>
            {warningMessageWhenEmpty}
          </p>
        )}
      </InputField>
    );
  },
);
